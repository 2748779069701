import { request, getHost } from './utils';

// 美國地區選項.
export const getOptionState = () => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/option/state`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };

  return request(options);
};

// 獵場資料的選項, 例如: 獵物...
export const getOptionInfo = () => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/option/info`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };

  return request(options);
};
