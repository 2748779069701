import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import i18n from 'src/i18n';
import BookingServices from 'src/services/BookingServices';
import ErrorServices from 'src/services/ErrorServices';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';

export const BATCH_BUTTON_TYPE = {
  accept: 'accept',
  reject: 'reject'
};

class BatchButtonViewModel {
  @observable bookings = [];
  @observable type = BATCH_BUTTON_TYPE.accept;
  @observable isAwait = false;

  modalVM = new ActionModalViewModel();

  changed = () => {};

  @computed
  get selectedBookings() {
    return this.bookings.filter((booking) => booking.selected);
  }

  @computed
  get disabled() {
    return {
      modal: this.isAwait,
      button: !this.selectedBookings.length
    };
  }

  @computed
  get labels() {
    const button
      = this.type === BATCH_BUTTON_TYPE.accept
        ? i18n.t('batch_bookings_page_batch_button_accept_main_button_label')
        : i18n.t('batch_bookings_page_batch_button_reject_main_button_label');

    const modalContent = i18n.t('batch_bookings_page_batch_button_modal_content');
    const modalContentEnd
      = this.type === BATCH_BUTTON_TYPE.accept
        ? i18n.t('batch_bookings_page_batch_button_modal_accept_content')
        : i18n.t('batch_bookings_page_batch_button_modal_reject_content');
    const modalConfirm = this.type === BATCH_BUTTON_TYPE.accept
      ? i18n.t('batch_bookings_page_batch_button_modal_accept_button_label')
      : i18n.t('batch_bookings_page_batch_button_modal_reject_button_label');

    return {
      button,
      type: this.type === BATCH_BUTTON_TYPE.accept ? 'primary' : 'reject',
      danger: this.type === BATCH_BUTTON_TYPE.reject,
      modalContent: `${this.selectedBookings.length} ${modalContent} ${modalContentEnd}`,
      modalConfirm
    };
  }

  constructor(props) {
    this.bookings = props.bookings;
    this.type = props.type;
    this.changed = props.changed;

    makeObservable(this);
  }

  @action
  didUpdate = (props, preProps) => {
    const diffBookings = !!diff(props.bookings, preProps.bookings);

    if (diffBookings) {
      this.bookings = props.bookings;
    }
  };

  onConfirm = () => {
    this.putBookingAPI();
  }

  @action
  putBookingAPI = async () => {
    this.isAwait = true;

    const api
      = this.type === BATCH_BUTTON_TYPE.accepted
        ? BookingServices.putBookingAccept
        : BookingServices.putBookingReject;
    const errorService
      = this.type === BATCH_BUTTON_TYPE.accepted
        ? ErrorServices.putBookingAccept
        : ErrorServices.putBookingReject;

    try {
      await Promise.all(
        this.selectedBookings.map((booking) => api({ id: booking.id }))
      );

      this.modalVM.close();

      this.changed(this.selectedBookings);
    } catch (error) {
      const msg = errorService(error);

      console.log('BatchButtonViewModel', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default BatchButtonViewModel;
