class PackageDataModel {
  constructor(data) {
    this.id = data.id;

    this.name = data.name;

    this.type = data.type;

    this.price = data.price;

    this.description = data.description;

    // 訂單專用.
    this.count = data.count;
  }
}

export default PackageDataModel;
