import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import {
  BOOKING_STATUS,
  getBookingStatus,
  getBookingStatusStyle
} from 'src/constants/booking/booking';
import { assemble } from 'src/constants/methods';
import BookingServices from 'src/services/BookingServices';
import ErrorServices from 'src/services/ErrorServices';
import BookingDetailDrawerViewModel from 'src/components/BookingDetailDrawer/viewModel';
import ICONS from 'src/constants/icons';

class BookingCardViewModel {
  @observable booking = null;
  @observable selected = false;
  @observable isAwait = false;

  detailDrawerVM = new BookingDetailDrawerViewModel();

  @computed
  get check() {
    const isAccepted = this.booking?.status === BOOKING_STATUS.accepted.value;

    return {
      contact:
        this.booking.contact.email && this.booking.contact.phone && isAccepted
    };
  }

  @computed
  get disabled() {
    return {
      accept: this.isAwait,
      reject: this.isAwait
    };
  }

  @computed
  get id() {
    return this.booking.id;
  }

  @computed
  get status() {
    const valueStatus = BOOKING_STATUS[this.booking.status];
    const labelStatus = getBookingStatus({
      status: this.booking.status,
      startAt: this.booking.startAt,
      endAt: this.booking.endAt,
      disputedAt: this.booking.disputedAt
    });

    return {
      value: valueStatus.value,
      label: labelStatus.label,
      style: getBookingStatusStyle(labelStatus.value)
    };
  }

  @computed
  get startAt() {
    return dayjs(this.booking.startAt).format('MMMM D, YYYY');
  }

  @computed
  get endAt() {
    return dayjs(this.booking.endAt).format('MMMM D, YYYY');
  }

  @computed
  get consumer() {
    return assemble.name({
      firstName: this.booking.consumer.firstName,
      lastName: this.booking.consumer.lastName
    });
  }

  @computed
  get icons() {
    return {
      select: this.selected ? ICONS.selected : ICONS.unSelect
    };
  }

  @computed
  get land() {
    return this.booking.land;
  }

  constructor(props) {
    this.booking = props;
    makeObservable(this);
  }

  onSelectClick = (event) => {
    event.stopPropagation();
  };

  @action
  onSelectedChange = (event) => {
    this.selected = event.target.checked;
    event.stopPropagation();
  };

  @action
  resetSelected = () => {
    this.selected = false;
  };

  onAccept = (event) => {
    this.putBookingAcceptAPI();
    event.stopPropagation();
  };

  onReject = (event) => {
    this.putBookingRejectAPI();
    event.stopPropagation();
  };

  onEmail = (event) => {
    const label = 'mailto';
    const a = document.createElement('a');

    a.href = `${label}: ${this.booking.contact.email}`;

    a.click();

    event.stopPropagation();
  };

  onPhone = (event) => {
    const label = 'tel';
    const a = document.createElement('a');

    a.href = `${label}: ${this.booking.contact.phone}`;

    a.click();

    event.stopPropagation();
  };

  onDetail = (event) => {
    this.detailDrawerVM.openBooking({
      bookingId: this.id,
      changed: this.update
    });

    event.stopPropagation();
  };

  @action
  update = (booking) => {
    this.booking = booking;
  };

  @action
  putBookingAcceptAPI = async () => {
    this.isAwait = true;

    try {
      await BookingServices.putBookingAccept({ id: this.id });

      const res = await BookingServices.getBookingDetail({ id: this.id });

      runInAction(() => {
        this.booking = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.putBookingAccept(error);

      console.log('putBookingAccept', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  putBookingRejectAPI = async () => {
    this.isAwait = true;

    try {
      await BookingServices.putBookingReject({ id: this.id });

      const res = await BookingServices.getBookingDetail({ id: this.id });

      runInAction(() => {
        this.booking = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.putBookingReject(error);

      console.log('putBookingReject', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default BookingCardViewModel;
