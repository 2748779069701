import i18n from 'src/i18n';

export const ROUTES = {
  backstage: {
    value: ''
  },

  client: {
    value: i18n.t('routes_client_value')
  },

  lands: {
    value: i18n.t('routes_lands_value'),
    label: i18n.t('routes_lands_label')
  },

  bookings: {
    value: i18n.t('routes_bookings_value'),
    label: i18n.t('routes_bookings_label')
  },

  batchBookings: {
    value: i18n.t('routes_batch_bookings_value')
  },

  checkBooking: {
    value: 'booking/:id'
  },

  packages: {
    value: i18n.t('routes_packages_value'),
    label: i18n.t('routes_packages_label')
  },

  rentals: {
    value: i18n.t('routes_rentals_value'),
    label: i18n.t('routes_rentals_label')
  },

  payouts: {
    value: i18n.t('route_payouts_value'),
    label: i18n.t('route_payouts_label')
  },

  payoutDetails: {
    value: i18n.t('route_payout_details_value')
  },

  calendar: {
    value: i18n.t('route_calendar_value'),
    label: i18n.t('route_calendar_label')
  },

  visitors: {
    value: 'visitors'
  },

  post: {
    value: 'post'
  }
};

export const PARAMS = {
  bookingId: 'reservation-id',
  payoutType: 'income-type',
  payoutStatus: 'income-status',
  payoutId: 'income-id',
  landId: 'property-id',
  createLand: 'create-property',
  login: 'login'
};
