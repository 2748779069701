import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import i18n from 'src/i18n';
import { LAND_MESSAGE_TYPES } from 'src/constants/land';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import MessageViewModel from './components/Message/viewModel';

class LandMessagesDrawerViewModel {
  @observable type = LAND_MESSAGE_TYPES.qas;

  @observable land = null;

  @observable data = [];
  @observable anchor = undefined;

  @observable item = null;

  @observable isAwait = false;

  changed = () => {};

  drawerVM = new ActionDrawerViewModel();

  @computed
  get header() {
    switch (this.type) {
      case LAND_MESSAGE_TYPES.qas:
        return i18n.t('land_messages_drawer_qas_title');

      case LAND_MESSAGE_TYPES.ratings:
        return i18n.t('land_messages_drawer_ratings_title');

      default:
        return '';
    }
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  openQas = ({ land, changed }) => {
    this.type = LAND_MESSAGE_TYPES.qas;
    this.land = land;
    this.changed = changed;

    this.reset();

    this.drawerVM.open();
  };

  @action
  openRatings = ({ land, changed }) => {
    this.type = LAND_MESSAGE_TYPES.ratings;
    this.land = land;

    this.changed = changed;

    this.reset();

    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  onWaypointEnter = () => {
    if (this.anchor !== null && !this.isAwait) {
      this.getMessagesListAPI();
    }
  };

  @action
  reset = () => {
    this.data = [];
    this.anchor = undefined;
    this.getMessagesListAPI();
  };

  @action
  getMessagesListAPI = async () => {
    this.isAwait = true;

    const api
      = this.type === LAND_MESSAGE_TYPES.qas
        ? LandServices.getLandQAList
        : LandServices.getLandRatingList;

    const service
      = this.type === LAND_MESSAGE_TYPES.qas
        ? ErrorServices.getLandQAList
        : ErrorServices.getLandRatingList;

    try {
      const res = await api({
        id: this.land.id,
        limit: this.limit,
        anchor: this.anchor
      });

      runInAction(() => {
        const data = res.data.map((item) => new MessageViewModel(item));

        this.data = [...this.data, ...data];
        this.anchor = res.anchor;
      });
    } catch (error) {
      const msg = service(error);
      console.log('getMessagesListAPI', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default LandMessagesDrawerViewModel;
