import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { InputNumber } from 'antd';
import clsx from 'clsx';
import './reset.scss';

@observer
class ResetInputNumber extends React.Component {
  render() {
    const { className, customStepButton, ...ps } = this.props;

    return (
      <InputNumber
        className={clsx([
          'resetInputNumber',
          customStepButton && 'customStepButton',
          className
        ])}
        {...ps}
      />
    );
  }
}

ResetInputNumber.propTypes = {
  className: PropTypes.string,
  customStepButton: PropTypes.bool
};

ResetInputNumber.defaultProps = {
  className: '',
  customStepButton: false
};

export default ResetInputNumber;
