import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import ErrorServices from 'src/services/ErrorServices';
import RentalServices from 'src/services/RentalServices';

class ParcelNoteModalViewModel {
  @observable parcel = null;
  @observable isAwait = false;

  changed = () => {};

  modalVM = new ActionModalViewModel();

  form = null;

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      footer: this.isAwait
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form }) => {
    this.form = form;
  };

  @action
  open = ({ parcel, changed }) => {
    this.parcel = parcel;
    this.changed = changed;

    this.form.setFieldsValue({ report: parcel.report || '' });

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  onConfirm = () => {
    this.putUpdateRentalParcelAPI();
  };

  @action
  putUpdateRentalParcelAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();
      await RentalServices.putUpdateRentalParcel({
        id: this.parcel.id,
        report: data.report
      });

      await this.changed();

      this.close();
    } catch (error) {
      const msg = ErrorServices.putUpdateRentalParcel(error);
      console.log('putUpdateRentalParcel', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default ParcelNoteModalViewModel;
