import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ResetSelect from 'src/components/ResetSelect';
import ResetInput from 'src/components/ResetInput';
import ResetFormItem from 'src/components/ResetFormItem';
import styles from './styles.module.scss';

@observer
class Address extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ land: props.land, form: props.form });
  }

  render() {
    return (
      <div className={styles.container}>
        <Form
          className={styles.form}
          form={this.vm.form}
          disabled={this.props.disabled}
        >
          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_address_page_state_label')}
            </div>

            <ResetFormItem
              errorMessageAbsolute
              name="state"
              rules={this.vm.rules.required}
            >
              <ResetSelect
                options={this.vm.options.states}
                onChange={this.vm.onStateChange}
                placeholder={i18n.t(
                  'create_land_drawer_address_page_state_select_placeholder'
                )}
                showSearch
                optionFilterProp="label"
              />
            </ResetFormItem>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_address_page_county_label')}
            </div>

            <ResetFormItem
              errorMessageAbsolute
              name="county"
              rules={this.vm.rules.required}
            >
              <ResetSelect
                options={this.vm.options.counties}
                placeholder={i18n.t(
                  'create_land_drawer_address_page_county_select_placeholder'
                )}
                showSearch
                optionFilterProp="label"
              />
            </ResetFormItem>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_address_page_street_label')}
            </div>

            <ResetFormItem
              errorMessageAbsolute
              name="street"
              rules={this.vm.rules.required}
            >
              <ResetInput
                placeholder={i18n.t(
                  'create_land_drawer_address_page_street_input_placeholder'
                )}
              />
            </ResetFormItem>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_address_page_postcode_label')}
            </div>

            <ResetFormItem
              errorMessageAbsolute
              name="postcode"
              rules={this.vm.rules.postcode}
            >
              <ResetInput
                placeholder={i18n.t(
                  'create_land_drawer_address_page_postcode_input_placeholder'
                )}
              />
            </ResetFormItem>
          </div>
        </Form>
      </div>
    );
  }
}

Address.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

Address.defaultProps = {
  land: null,
  disabled: false
};

export default withForm(Address);
