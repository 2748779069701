import API from 'src/apis';
import LandDetailDataModel from 'src/models/response/LandDetailDataModel';
import GetUploadPhotosUrlDataModel from 'src/models/response/GetUploadPhotosUrlDataModel';
import LandListItemDataModel from 'src/models/response/LandListItemDataModel';
import LandSpecialDateDataModel from 'src/models/response/LandSpecialDateDataModel';
import LandDiscountDataModel from 'src/models/response/LandDiscountDataModel';
import PackageDataModel from 'src/models/response/PackageDataModel';
import MessageDataModel from 'src/models/response/MessageDataModel';
import BusinessDayDataModel from 'src/models/response/BusinessDayDataModel';
import Auth from './auth';

class LandServices {
  // 新增獵場, 設定地址.
  static async postLandCreate({ street, county, state, postcode }) {
    const res = await API.land.postLandCreate({
      token: Auth.getToken(),
      street,
      county,
      state,
      postcode
    });

    return {
      data: { id: res.data.id }
    };
  }

  // 讀取獵場資料.
  static async getLandDetail({ id }) {
    const res = await API.land.getLandDetail({
      token: Auth.getToken(),
      id
    });

    return {
      data: new LandDetailDataModel(res.data)
    };
  }

  // 更新獵場的基本資料.
  static async putUpdateLandInfo({
    id,
    // 可以更新以下項目.
    name,
    description,
    rules,
    street,
    county,
    state,
    postcode,
    position,
    acres,
    species,
    basicFee,
    extraFee,
    basicHunters,
    extraHunters,
    finalBookingDate,
    meetStreet,
    meetState,
    meetCity,
    meetPostcode,
    meetPosition,
    meetDescription,
    agreeTerms
  }) {
    const res = await API.land.putUpdateLandInfo({
      token: Auth.getToken(),
      id,
      name,
      description,
      rules,
      street,
      county,
      state,
      postcode,
      position,
      acres,
      species,
      basicFee,
      extraFee,
      basicHunters,
      extraHunters,
      finalBookingDate,
      meetStreet,
      meetState,
      meetCity,
      meetPostcode,
      meetPosition,
      meetDescription,
      agreeTerms
    });

    return {
      data: res.data
    };
  }

  // 獲取上傳照片需要的網址.
  static async postGetUploadPhotosUrl({ id, photos }) {
    const res = await API.land.postGetUploadPhotosUrl({
      token: Auth.getToken(),
      id,
      photos
    });

    return {
      data: res.data.urls.map((item) => new GetUploadPhotosUrlDataModel(item))
    };
  }

  // 將上傳完成的照片告訴 server.
  static async putUpdatePhotos({ id, photos }) {
    const res = await API.land.putUpdatePhotos({
      token: Auth.getToken(),
      id,
      photos
    });

    return {
      data: res.data
    };
  }

  // 讀取所有獵場.
  static async getLandList() {
    const res = await API.land.getLandList({ token: Auth.getToken() });

    return {
      data: res.data.list.map((item) => new LandListItemDataModel(item))
    };
  }

  // 更新獵場狀態.
  static async putLandStatus({ id, status }) {
    const res = await API.land.putLandStatus({
      token: Auth.getToken(),
      id,
      status
    });

    return {
      data: res.data
    };
  }

  // 刪除獵場草稿.
  static async deleteLandDraft({ id }) {
    const res = await API.land.deleteLandDraft({
      token: Auth.getToken(),
      id
    });

    return {
      data: res.data
    };
  }

  // 讀取獵場特別日收費列表.
  static async getLandSpecialDateList({ id }) {
    const res = await API.land.getLandSpecialDateList({
      token: Auth.getToken(),
      id
    });

    return {
      data: res.data.list.map((item) => new LandSpecialDateDataModel(item))
    };
  }

  // 新增獵場特別日收費.
  static async postLandSpecialDate({ id, startAt, endAt, ratio }) {
    const res = await API.land.postLandSpecialDate({
      token: Auth.getToken(),
      id,
      startAt,
      endAt,
      ratio
    });

    return {
      data: new LandSpecialDateDataModel(res.data)
    };
  }

  // 刪除獵場特別日收費.
  static async deleteLandSpecialDate({ id, sid }) {
    const res = await API.land.deleteLandSpecialDate({
      token: Auth.getToken(),
      id,
      sid
    });

    return {
      data: res.data
    };
  }

  // 新增獵場長期預約折扣.
  static async postLandDiscount({ id, type, name, discount }) {
    const res = await API.land.postLandDiscount({
      token: Auth.getToken(),
      id,
      type,
      name,
      discount
    });

    return {
      data: new LandDiscountDataModel(res.data.discount)
    };
  }

  // 刪除獵場長期預約折扣.
  static async deleteLandDiscount({ id, did }) {
    const res = await API.land.deleteLandDiscount({
      token: Auth.getToken(),
      id,
      did
    });

    return {
      data: res.data
    };
  }

  // 讀取獵場長期折扣列表.
  static async getLandDiscountList({ id }) {
    const res = await API.land.getLandDiscountList({
      token: Auth.getToken(),
      id
    });

    return {
      data: res.data.list.map((item) => new LandDiscountDataModel(item))
    };
  }

  // 新增獵場的行程.
  static async postLandPackage({ id, pid }) {
    const res = await API.land.postLandPackage({
      token: Auth.getToken(),
      id,
      presetPackageId: pid
    });

    return {
      data: {
        id: res.data.id,
        data: new PackageDataModel(res.data.presetPackage)
      }
    };
  }

  // 刪除獵場的行程.
  static async deleteLandPackage({ id, pid }) {
    const res = await API.land.deleteLandPackage({
      token: Auth.getToken(),
      id,
      pid
    });

    return {
      data: res.data
    };
  }

  // 讀取獵場的行程.
  static async getLandPackageList({ id }) {
    const res = await API.land.getLandPackageList({
      token: Auth.getToken(),
      id
    });

    return {
      data: res.data.list.map((item) => {
        return {
          id: item.id,
          data: new PackageDataModel(item.presetPackage)
        };
      })
    };
  }

  // 讀取獵場提問列表.
  static async getLandQAList({ id, limit, anchor }) {
    const res = await API.land.getLandQAList({
      token: Auth.getToken(),
      id,
      limit,
      anchor
    });

    return {
      data: res.data.list.map((item) => new MessageDataModel(item)),
      anchor: res.data.anchor
    };
  }

  // 讀取獵場評分列表.
  static async getLandRatingList({ id, limit, anchor }) {
    const res = await API.land.getLandRatingList({
      token: Auth.getToken(),
      id,
      limit,
      anchor
    });

    return {
      data: res.data.list.map((item) => new MessageDataModel(item)),
      anchor: res.data.anchor
    };
  }

  // 讀取獵場某月的每天是否開啟.
  static async getLandBusinessDay({ id, date }) {
    const res = await API.land.getLandBusinessDay({
      token: Auth.getToken(),
      id,
      date
    });

    return {
      data: res.data.days.map((item) => {
        const dataModel = new BusinessDayDataModel(item);

        return {
          ...dataModel,
          timezoneId: res.data.timezoneId,
          timezoneOffset: res.data.timezoneOffset,
          bookingAfterDays: res.data.bookingAfterDays
        };
      }),
      // 時區.
      timezoneOffset: res.data.timezoneOffset,
      timezoneId: res.data.timezoneId,
      finalBookingDate: res.data.finalBookingDate,
      bookingAfterDays: res.data.bookingAfterDays
    };
  }

  // 開啟或關閉獵場的日期.
  static async putLandBusinessDay({ id, days }) {
    const res = await API.land.putLandBusinessDay({
      token: Auth.getToken(),
      id,
      days
    });

    return {
      data: res.data
    };
  }

  static async deleteLand({ landId }) {
    const res = await API.land.deleteLand({
      token: Auth.getToken(),
      landId
    });

    return {
      data: res.data
    };
  }
}

export default LandServices;
