import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import withRouter from 'src/components/withRouter';
import { ROUTES } from 'src/constants/routes';
import styles from './styles.module.scss';

// 網址錯誤.
function NotFoundPage(props) {
  const toHome = () => {
    const route = `/${ROUTES.client.value}`;

    // 這樣寫可以刪除當前的歷史紀錄.
    props.router.navigate(route, {
      replace: true
    });
  };

  return (
    <div className={styles.page}>
      <Button className={styles.button} onClick={toHome}>
        <div className={styles.content}>404</div>
      </Button>
    </div>
  );
}

NotFoundPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(NotFoundPage);
