import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';

class DeleteLandModalViewModel {
  @observable landId = null;

  @observable title = '';
  @observable content = '';

  @observable isAwait = false;

  modalVM = new ActionModalViewModel();

  deleted = async () => {};

  @computed
  get disabled() {
    return {
      confirm: this.isAwait
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({ landId, title = '', content = '', deleted = async () => {} }) => {
    this.landId = landId;
    this.title = title;
    this.content = content;
    this.deleted = deleted;

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  onConfirm = () => {
    this.deleteLandAPI();
  };

  @action
  deleteLandAPI = async () => {
    this.isAwait = true;

    try {
      await LandServices.deleteLand({ landId: this.landId });
      await this.deleted();
      this.close();
    } catch (error) {
      const msg = ErrorServices.deleteLand(error);
      console.log('deleteLand', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default DeleteLandModalViewModel;
