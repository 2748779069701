import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ActionModalAlertContainer from 'src/components/ActionModal/components/ActionModalAlertContainer';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetRangePicker from 'src/components/ResetRangePicker';
import styles from './styles.module.scss';

@observer
class CalendarBatchTagModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ form: props.form });
  }

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        <ActionModalAlertContainer className={styles.container}>
          <Form
            className={styles.form}
            form={this.vm.form}
            disabled={this.vm.disabled.form}
          >
            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('calendar_batch_tag_modal_date_label')}
              </div>

              <ResetFormItem name="dates" rules={this.vm.rules.required}>
                <ResetRangePicker
                  className={styles.datePicker}
                  inputReadOnly
                />
              </ResetFormItem>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('calendar_batch_tag_modal_tag_label')}
              </div>

              <ResetFormItem name="tag" rules={this.vm.rules.required}>
                <Input.TextArea
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  showCount
                  maxLength={50}
                  placeholder={i18n.t(
                    'calendar_batch_tag_modal_tag_placeholder'
                  )}
                />
              </ResetFormItem>
            </div>
          </Form>

          <ActionModalFooter
            cancelProps={{
              type: 'primary',
              danger: true,
              children: i18n.t('calendar_batch_tag_modal_delete_button_label'),
              onClick: this.vm.onDelete,
              disabled: this.vm.disabled.footer
            }}
            confirmProps={{
              type: 'primary',
              children: i18n.t('calendar_batch_tag_modal_add_button_label'),
              onClick: this.vm.onAdd,
              disabled: this.vm.disabled.footer
            }}
          />
        </ActionModalAlertContainer>
      </ActionModal>
    );
  }
}

CalendarBatchTagModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

CalendarBatchTagModal.defaultProps = {};

export default withForm(CalendarBatchTagModal);
