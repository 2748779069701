import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Switch } from 'antd';
import i18n from 'src/i18n';
import ICONS from 'src/constants/icons';
import ActionDrawer from 'src/components/ActionDrawer';
import ActionDrawerCard from 'src/components/ActionDrawer/components/ActionDrawerCard';
import ResetButton from 'src/components/ResetButton';
import BookingDetailDrawer from 'src/components/BookingDetailDrawer';
import TagModal from '../TagModal';
import styles from './styles.module.scss';

@observer
class BusinessDayDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ land: props.land, businessDay: props.businessDay });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <>
        <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
          {this.vm.check.show ? (
            <div className={styles.container}>
              <div className={styles.header}>
                <div className={styles.title}>{this.vm.labels.date}</div>

                <div className={styles.buttons}>
                  <ResetButton
                    className={styles.button}
                    type="text"
                    onClick={this.props.onDatePrev}
                  >
                    <img src={ICONS.arrowLeft} alt="arrow-left" />
                  </ResetButton>

                  <ResetButton
                    className={styles.button}
                    type="text"
                    onClick={this.props.onDateNext}
                  >
                    <img src={ICONS.arrowRight} alt="arrow-right" />
                  </ResetButton>
                </div>
              </div>

              <div className={styles.card}>
                <div className={styles.label}>
                  {i18n.t('calendar_page_business_day_status_label')}
                </div>

                <div className={styles.content}>
                  <div className={styles.text}>{this.vm.labels.status}</div>

                  {this.vm.check.statusSwitch && (
                    <Switch
                      checked={this.vm.labels.isAvailable}
                      onChange={this.vm.onAvailableChange}
                      disabled={this.vm.disabled.status}
                    />
                  )}
                </div>
              </div>

              {this.vm.check.booking && (
                <ActionDrawerCard
                  label={this.vm.labels.booking}
                  next
                  onClick={this.vm.onBooking}
                />
              )}

              {this.vm.businessDay.tags.map((tag) => (
                <ActionDrawerCard
                  label={tag}
                  onClick={() => this.vm.onTagEdit(tag)}
                  next
                  key={tag}
                />
              ))}

              <ResetButton
                className={styles.addTagButton}
                type="primary"
                ghost
                onClick={this.vm.onTagCreate}
              >
                <img src={ICONS.addBusinessDayTag} alt="addBusinessDayTag" />
              </ResetButton>
            </div>
          ) : null}

          <BookingDetailDrawer vm={this.vm.bookingDetailDrawerVM} />
        </ActionDrawer>

        <TagModal vm={this.vm.tagModalVM} />
      </>
    );
  }
}

BusinessDayDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object,
  businessDay: PropTypes.object,
  onDateNext: PropTypes.func,
  onDatePrev: PropTypes.func
};

BusinessDayDrawer.defaultProps = {
  land: null,
  businessDay: null,
  onDateNext: () => {},
  onDatePrev: () => {}
};

export default BusinessDayDrawer;
