import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import PageCardsContainer from 'src/components/PageCardsContainer';
import Back from '../Back';
import Header from '../Header';
import LandsSelect from '../LandsSelect';
import RentalCard from './components/RentalCard';
import RentalPayoutRecordsViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class RentalPayoutRecords extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new RentalPayoutRecordsViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <>
        <Back />

        <Header subtitle={this.vm.subtitle} />

        <LandsSelect
          value={this.vm.landId}
          onChange={this.vm.onLandIdChange}
          disabled={this.vm.disabled.landId}
          showSearch
          optionFilterProp="label"
        />

        <PageCardsContainer
          paddingBottom
          onWaypointEnter={this.vm.onWaypointEnter}
        >
          {this.vm.records.map((vm) => (
            <RentalCard vm={vm} key={vm.id} />
          ))}
        </PageCardsContainer>
      </>
    );
  }
}

RentalPayoutRecords.propTypes = {};

RentalPayoutRecords.defaultProps = {};

export default RentalPayoutRecords;
