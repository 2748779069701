import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import i18n from 'src/i18n';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';
import BookingPayouts from './components/BookingPayouts';
import RentalPayouts from './components/RentalPayouts';
import NoStripeLabel from './components/NoStripeLabel';

const PAGES = {
  booking: 'booking',
  rental: 'rental'
};

class PayoutsPageViewModel {
  @observable stripe = null;
  @observable inited = false;
  @observable isAwait = false;

  @computed
  get check() {
    return {
      stripe: !!this.stripe?.id
    };
  }

  @computed
  get disabled() {
    return {
      stripe: this.isAwait
    };
  }

  @computed
  get labels() {
    const checkSpriteButtonLabel = i18n.t(
      'payouts_page_check_stripe_button_label'
    );
    const setSpriteButtonLabel = i18n.t('payouts_page_set_stripe_button_label');

    return {
      stripeLabel: this.check.stripe
        ? checkSpriteButtonLabel
        : setSpriteButtonLabel,
      spriteButtonType: this.check.stripe ? 'default' : 'primary',
      spriteButtonGhost: this.check.stripe || undefined
    };
  }

  @computed
  get pages() {
    return [
      {
        key: PAGES.booking,
        label: i18n.t('payouts_page_booking_tab_title'),
        children: this.check.stripe ? <BookingPayouts /> : <NoStripeLabel />
      },
      {
        key: PAGES.rental,
        label: i18n.t('payouts_page_rental_tab_title'),
        children: this.check.stripe ? <RentalPayouts /> : <NoStripeLabel />
      }
    ];
  }

  constructor() {
    makeObservable(this);
  }

  didMount = () => {
    this.getStripeInfoAPI();
  };

  onStripe = () => {
    this.postStripeLinkAPI();
  };

  @action
  getStripeInfoAPI = async () => {
    try {
      const res = await UserService.getStripeInfo();

      runInAction(() => {
        this.stripe = res.data;
        console.log('stripe', res.data);
      });
    } catch (error) {
      const msg = ErrorServices.getStripeInfo(error);
      console.log('getStripeInfo', msg);
    }

    runInAction(() => {
      this.inited = true;
    });
  };

  @action
  postStripeLinkAPI = async () => {
    this.isAwait = true;

    try {
      const res = await UserService.postStripeLink();

      window.open(res.data.url);
    } catch (error) {
      const msg = ErrorServices.postStripeLink(error);
      console.log('postStripeLink', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default PayoutsPageViewModel;
