import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import { REGEXPS, FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import ErrorServices from 'src/services/ErrorServices';
import UserService from 'src/services/UserService';

class DisplayNameModalViewModel {
  @observable profile = null;
  @observable isAwait = false;

  modalVM = new ActionModalViewModel();

  form = null;

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      confirm: this.isAwait
    };
  }

  get rules() {
    return {
      displayName: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form }) => {
    this.form = form;
  };

  @action
  open = ({ profile, changed }) => {
    this.profile = profile;
    this.changed = changed;

    this.form.setFieldsValue({
      displayName: profile.displayName || ''
    });

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  onConfirm = () => {
    this.putUserProfileAPI();
  };

  @action
  putUserProfileAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();

      const bodyFormData = new FormData();

      bodyFormData.append('displayName', data.displayName);

      const res = await UserService.putUserProfile(bodyFormData);

      await this.changed(res.data);

      this.close();
    } catch (error) {
      const msg = ErrorServices.putUserProfile(error);

      console.log('putUserProfile', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default DisplayNameModalViewModel;
