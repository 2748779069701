import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';

class NoteViewModel {
  @observable booking = null;

  form = null;

  @computed
  get rules() {
    return {
      note: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = ({ booking, form }) => {
    this.booking = booking;
    this.form = form;

    this.form.setFieldsValue({ note: booking.note || '' });
  }

  validateFields = async () => {
    const res = await this.form.validateFields();
    return res;
  }
}

export default NoteViewModel;
