import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ActionModal from 'src/components/ActionModal';
import ActionModalAlertContainer from 'src/components/ActionModal/components/ActionModalAlertContainer';
import styles from './styles.module.scss';

@observer
class SignatureModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        {this.vm.check.parcel ? (
          <ActionModalAlertContainer>
            <img
              className={styles.img}
              src={this.vm.signature}
              alt="signature"
            />
          </ActionModalAlertContainer>
        ) : null}
      </ActionModal>
    );
  }
}

SignatureModal.propTypes = {
  vm: PropTypes.object.isRequired
};

SignatureModal.defaultProps = {};

export default SignatureModal;
