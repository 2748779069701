import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import clsx from 'clsx';
import './reset.scss';

@observer
class ResetTable extends React.Component {
  render() {
    const { data, className, children, tHeadSticky, ...ps } = this.props;

    return (
      <Table
        className={clsx([
          'resetTable',
          tHeadSticky && 'tHeadSticky',
          className
        ])}
        dataSource={data}
        {...ps}
      >
        {children}
      </Table>
    );
  }
}

ResetTable.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  rowKey: PropTypes.func,
  pagination: PropTypes.bool,
  children: PropTypes.node,
  tHeadSticky: PropTypes.bool
};

ResetTable.defaultProps = {
  data: [],
  className: '',
  rowKey: (item) => item.id,
  pagination: false,
  children: null,
  tHeadSticky: false
};

export default ResetTable;
