import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { RENTAL_STATUS } from 'src/constants/rental/rental';
import RentalServices from 'src/services/RentalServices';
import ErrorServices from 'src/services/ErrorServices';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';

class RentalStatusHistoriesDrawerViewModel {
  @observable rental = null;
  @observable histories = [];

  drawerVM = new ActionDrawerViewModel();

  @computed
  get mapHistories() {
    return this.histories.map((item) => {
      const date = dayjs(item.createdAt)
        .tz(this.rental.land.timezoneId)
        .format('MMM DD, YYYY');

      return {
        id: item.id,
        label: date,
        value: RENTAL_STATUS[item.status].label
      };
    });
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = (rental) => {
    this.rental = rental;
    this.drawerVM.open();
    this.getRentalStatusHistoryAPI();
  };

  close = () => {
    this.drawerVM.close();
  };

  getRentalStatusHistoryAPI = async () => {
    try {
      const res = await RentalServices.getRentalStatusHistory({
        id: this.rental.id
      });

      runInAction(() => {
        this.histories = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getRentalStatusHistory(error);

      console.log('getRentalStatusHistory', msg);
    }
  };
}

export default RentalStatusHistoriesDrawerViewModel;
