class RentalParcelDetailDataModel {
  constructor(props) {
    this.id = props.id;
    this.status = props.status;
    this.isLocked = props.isLocked;
    this.name = props.name;

    this.size = {
      length: props.size.length,
      width: props.size.width,
      height: props.size.height,
      distanceUnit: props.size.distanceUnit,
      weight: props.size.weight,
      massUnit: props.size.massUnit
    };

    this.signature = props.signature;
    this.report = props.report;

    this.warehouse = {
      id: props.warehouse.id,
      name: props.warehouse.name
    };

    this.label = {
      id: props.label.id,
      trackingNumber: props.label.trackingNumber,
      trackingUrl: props.label.trackingUrl,
      labelUrl: props.label.labelUrl,
      shipmentId: props.label.shipmentId,
      rateId: props.label.rateId,
      servicelevelToken: props.label.servicelevelToken,
      rateAmount: props.label.rateAmount,
      rateCurrency: props.label.rateCurrency,
      rateProvider: props.label.rateProvider
    };

    this.returnLabel = {
      id: props.returnLabel.id,
      trackingNumber: props.returnLabel.trackingNumber,
      trackingUrl: props.returnLabel.trackingUrl,
      labelUrl: props.returnLabel.labelUrl,
      shipmentId: props.returnLabel.shipmentId,
      rateId: props.returnLabel.rateId,
      servicelevelToken: props.returnLabel.servicelevelToken,
      rateAmount: props.returnLabel.rateAmount,
      rateCurrency: props.returnLabel.rateCurrency,
      rateProvider: props.returnLabel.rateProvider
    };

    this.addressFrom = {
      id: props.addressFrom.id,
      objectId: props.addressFrom.objectId,
      country: props.addressFrom.country,
      city: props.addressFrom.city,
      state: props.addressFrom.state,
      street1: props.addressFrom.street1,
      street2: props.addressFrom.street2,
      street3: props.addressFrom.street3,
      streetNo: props.addressFrom.streetNo,
      zip: props.addressFrom.zip,
      company: props.addressFrom.company,
      isResidential: props.addressFrom.isResidential
    };

    this.addressTo = {
      id: props.addressTo.id,
      objectId: props.addressTo.objectId,
      country: props.addressTo.country,
      city: props.addressTo.city,
      state: props.addressTo.state,
      street1: props.addressTo.street1,
      street2: props.addressTo.street2,
      street3: props.addressTo.street3,
      streetNo: props.addressTo.streetNo,
      zip: props.addressTo.zip,
      company: props.addressTo.company,
      isResidential: props.addressTo.isResidential
    };

    this.items = props.items.map((item) => {
      return {
        id: item.id,
        status: item.status,
        report: item.report,

        itemCopy: {
          id: item.itemCopy.id,
          warehouseId: item.itemCopy.warehouseId,
          plan: item.itemCopy.plan,
          count: item.itemCopy.count,

          itemVariety: {
            id: item.itemCopy.itemVariety.id,
            customId: item.itemCopy.itemVariety.customId,
            name: item.itemCopy.itemVariety.name,
            thumbnail: item.itemCopy.itemVariety.thumbnail,

            item: {
              id: item.itemCopy.itemVariety.item.id,
              name: item.itemCopy.itemVariety.item.name
            }
          }
        }
      };
    });
  }
}

export default RentalParcelDetailDataModel;
