import { action, computed } from 'mobx';
import { CREATE_LAND_PAGES } from 'src/constants/land';
import i18n from 'src/i18n';

class FactoryViewModel {
  parent = null;

  @computed
  get header() {
    switch (this.parent.page) {
      case CREATE_LAND_PAGES.address.value:
        return i18n.t('create_land_drawer_address_page_title');

      case CREATE_LAND_PAGES.position.value:
        return i18n.t('create_land_drawer_position_page_title');

      case CREATE_LAND_PAGES.basicInfo.value:
        return i18n.t('create_land_drawer_basic_info_page_title');

      case CREATE_LAND_PAGES.price.value:
        return i18n.t('create_land_drawer_price_page_title');

      case CREATE_LAND_PAGES.photos.value:
        return i18n.t('create_land_drawer_photos_page_title');

      case CREATE_LAND_PAGES.description.value:
        return i18n.t('create_land_drawer_description_page_title');

      default:
        return 'Title';
    }
  }

  @computed
  get footer() {
    switch (this.parent.page) {
      case CREATE_LAND_PAGES.address.value:
        return {
          back: false,
          next: true,
          done: false
        };

      case CREATE_LAND_PAGES.position.value:
        return {
          back: true,
          next: true,
          done: false
        };

      case CREATE_LAND_PAGES.basicInfo.value:
        return {
          back: true,
          next: true,
          done: false
        };

      case CREATE_LAND_PAGES.price.value:
        return {
          back: true,
          next: true,
          done: false
        };

      case CREATE_LAND_PAGES.photos.value:
        return {
          back: true,
          next: true,
          done: false
        };

      case CREATE_LAND_PAGES.description.value:
        return {
          back: true,
          next: false,
          done: true
        };

      default:
        return {
          back: false,
          next: false,
          done: false
        };
    }
  }

  constructor(parent) {
    this.parent = parent;
  }

  toNextPage = () => {
    switch (this.parent.page) {
      case CREATE_LAND_PAGES.address.value: {
        const page = CREATE_LAND_PAGES.position.value;
        this.parent.setPage(page);
        break;
      }

      case CREATE_LAND_PAGES.position.value: {
        const page = CREATE_LAND_PAGES.basicInfo.value;
        this.parent.setPage(page);
        break;
      }

      case CREATE_LAND_PAGES.basicInfo.value: {
        const page = CREATE_LAND_PAGES.price.value;
        this.parent.setPage(page);
        break;
      }

      case CREATE_LAND_PAGES.price.value: {
        const page = CREATE_LAND_PAGES.photos.value;
        this.parent.setPage(page);
        break;
      }

      case CREATE_LAND_PAGES.photos.value: {
        const page = CREATE_LAND_PAGES.description.value;
        this.parent.setPage(page);
        break;
      }

      default:
        break;
    }
  };

  onBack = () => {
    switch (this.parent.page) {
      case CREATE_LAND_PAGES.position.value: {
        this.parent.setPage(CREATE_LAND_PAGES.address.value);
        break;
      }

      case CREATE_LAND_PAGES.basicInfo.value: {
        this.parent.basicInfoVM.temporaryStorage();
        this.parent.setPage(CREATE_LAND_PAGES.position.value);
        break;
      }

      case CREATE_LAND_PAGES.price.value: {
        this.parent.pricesVM.temporaryStorage();
        this.parent.setPage(CREATE_LAND_PAGES.basicInfo.value);
        break;
      }

      case CREATE_LAND_PAGES.photos.value: {
        this.parent.setPage(CREATE_LAND_PAGES.price.value);
        break;
      }

      case CREATE_LAND_PAGES.description.value: {
        this.parent.descriptionVM.temporaryStorage();
        this.parent.setPage(CREATE_LAND_PAGES.photos.value);
        break;
      }

      default:
        break;
    }
  };

  onNext = async () => {
    this.parent.loading();

    try {
      switch (this.parent.page) {
        case CREATE_LAND_PAGES.address.value: {
          const checkAddress = !!this.parent.land?.country?.trim();

          if (checkAddress) {
            this.toNextPage();
          } else {
            const res = await this.parent.addressVM.validateFields();
            await this.parent.createLand(res, this.toNextPage);
          }

          console.log(this.parent.land);

          break;
        }

        case CREATE_LAND_PAGES.position.value: {
          const res = await this.parent.positionVM.validateFields();
          await this.parent.putUpdateLandInfoAPI(res, this.toNextPage);
          break;
        }

        case CREATE_LAND_PAGES.basicInfo.value: {
          const res = await this.parent.basicInfoVM.validateFields();
          await this.parent.putUpdateLandInfoAPI(res, this.toNextPage);
          break;
        }

        case CREATE_LAND_PAGES.price.value: {
          const res = await this.parent.pricesVM.validateFields();
          await this.parent.putUpdateLandInfoAPI(res, this.toNextPage);
          break;
        }

        case CREATE_LAND_PAGES.photos.value: {
          const res = await this.parent.photosVM.validateFields();
          this.parent.updateLand(res);
          this.toNextPage();
          break;
        }

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }

    this.parent.completed();
  };

  onDone = async () => {
    this.parent.loading();

    try {
      const res = await this.parent.descriptionVM.validateFields();
      await this.parent.putUpdateLandInfoAPI(res, this.parent.putLandStatusAPI);
    } catch (error) {
      console.log(error);
    }

    this.parent.completed();
  };
}

export default FactoryViewModel;
