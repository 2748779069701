import API from 'src/apis';
import Auth from './auth';

class QAServices {
  // 更新獵場提問的回答.
  static async putQAReply({ id, reply }) {
    const res = await API.qa.putQAReply({
      token: Auth.getToken(),
      id,
      reply
    });

    return {
      data: res.data
    };
  }
}

export default QAServices;
