import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';

export const PAGES = {
  login: 'login',
  booking: 'booking'
};

class CheckBookingPageViewModel {
  @observable page = PAGES.login;
  @observable profile = null;
  @observable isAwait = false;

  context = null;

  constructor(props) {
    this.page = props.profile ? PAGES.booking : PAGES.login;
    this.profile = props.profile;
    makeObservable(this);
  }

  @action
  didUpdate = (props, preProps) => {
    const diffProfile = diff(props.profile, preProps.profile);

    if (props.profile && diffProfile) {
      this.page = PAGES.booking;
      this.profile = props.profile;
    }
  }

  @action
  toLogin = () => {
    this.page = PAGES.login;
  }
}

export default CheckBookingPageViewModel;
