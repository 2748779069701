import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { PARAMS } from 'src/constants/routes';
import { routerMethods } from 'src/constants/methods';
import ErrorServices from 'src/services/ErrorServices';
import LandServices from 'src/services/LandServices';
import LandCardViewModel from './components/LandCard/viewModel';
import CreateLandDrawerViewModel from './components/CreateLandDrawer/viewModel';

class LandsPageViewModel {
  @observable landId = null;
  @observable lands = [];
  @observable inited = false;
  @observable isAwait = false;

  createLandDrawerVM = new CreateLandDrawerViewModel();

  @computed
  get check() {
    return {
      lands: this.lands.length
    };
  }

  @computed
  get disabled() {
    return {
      create: this.isAwait,
      filter: this.isAwait || !this.check.lands
    };
  }

  @computed
  get options() {
    return {
      lands: this.lands.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      })
    };
  }

  @computed
  get filterLands() {
    return this.lands.filter((item) => {
      return this.landId ? item.id === this.landId : true;
    });
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  init = (props) => {
    const createLand = routerMethods.getSearch(props.router, PARAMS.createLand);

    if (createLand) {
      this.onCreate();
    }
  }

  didMount = async () => {
    await this.getLandListAPI();

    runInAction(() => {
      this.inited = true;
    });
  };

  onCreate = () => {
    this.createLandDrawerVM.open({
      created: this.created
    });
  };

  @action
  onLandIdChange = (val) => {
    this.landId = val;
  }

  @action
  created = async () => {
    this.lands = [];
    await this.getLandListAPI();
  }

  deleted = async () => {
    await this.getLandListAPI();
  }

  @action
  getLandListAPI = async () => {
    this.isAwait = true;

    try {
      const res = await LandServices.getLandList();

      runInAction(() => {
        this.lands = res.data.map((item) => new LandCardViewModel(item));
      });
    } catch (error) {
      const msg = ErrorServices.getLandList(error);

      console.log('getLandList', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default LandsPageViewModel;
