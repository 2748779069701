import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ActionDrawer from 'src/components/ActionDrawer';
import ResetButton from 'src/components/ResetButton';
import Loading from 'src/components/Loading';
import LaunchLand from 'src/components/LaunchLand';
import { PAGES } from './viewModel';
import Status from './components/Status';
import Rules from './components/Rules';
import FinalBookingDate from './components/FinalBookingDate';
import SpecialDates from './components/SpecialDates';
import Discounts from './components/Discounts';
import PriceAlertModal from './components/PriceAlertModal';
import Photos from '../../../CreateLandDrawer/components/Photos';
import BasicInfo from '../../../CreateLandDrawer/components/BasicInfo';
import Description from '../../../CreateLandDrawer/components/Description';
import Position from '../../../CreateLandDrawer/components/Position';
import { POSITION_TYPES } from '../../../CreateLandDrawer/components/Position/viewModel';
import Prices from '../../../CreateLandDrawer/components/Prices';
import styles from './styles.module.scss';

@observer
class LandEditDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  renderPage = () => {
    switch (this.vm.page) {
      case PAGES.status:
        return <Status vm={this.vm.statusVM} land={this.vm.land} />;

      case PAGES.photos:
        return <Photos vm={this.vm.photosVM} land={this.vm.land} />;

      case PAGES.basicInfo:
        return <BasicInfo vm={this.vm.basicInfoVM} land={this.vm.land} />;

      case PAGES.description:
        return <Description vm={this.vm.descriptionVM} land={this.vm.land} />;

      case PAGES.position:
        // land edit drawer 使用座標定位, 因為獵場已新增完成, 會有座標
        return (
          <Position
            vm={this.vm.positionVM}
            land={this.vm.land}
            type={POSITION_TYPES.position}
          />
        );

      case PAGES.rules:
        return <Rules vm={this.vm.rulesVM} land={this.vm.land} />;

      case PAGES.finalBookingDate:
        return (
          <FinalBookingDate
            vm={this.vm.finalBookingDateVM}
            land={this.vm.land}
          />
        );

      case PAGES.prices:
        return <Prices vm={this.vm.pricesVM} land={this.vm.land} />;

      case PAGES.specialDates:
        return (
          <SpecialDates
            vm={this.vm.specialDatesVM}
            land={this.vm.land}
            changed={this.vm.changed}
          />
        );

      case PAGES.discounts:
        return (
          <Discounts
            vm={this.vm.discountsVM}
            land={this.vm.land}
            changed={this.vm.changed}
          />
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
          <div className={styles.container}>
            <div className={styles.page}>{this.renderPage()}</div>

            {this.vm.footer && (
              <div className={styles.footer}>
                <ResetButton
                  className={styles.button}
                  align="center"
                  onClick={this.vm.close}
                >
                  {i18n.t('land_edit_drawer_cancel_button_label')}
                </ResetButton>

                <ResetButton
                  className={styles.button}
                  type="primary"
                  align="center"
                  onClick={this.vm.onConfirm}
                  disabled={this.vm.disabled.confirm}
                >
                  {i18n.t('land_edit_drawer_confirm_button_label')}
                </ResetButton>
              </div>
            )}
          </div>

          <PriceAlertModal vm={this.vm.priceAlertModalVM} />

          <LaunchLand vm={this.vm.launchLandVM} />
        </ActionDrawer>

        {this.vm.isAwait && <Loading />}
      </>
    );
  }
}

LandEditDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

LandEditDrawer.defaultProps = {};

export default LandEditDrawer;
