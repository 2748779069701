import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import BookingServices from 'src/services/BookingServices';
import ErrorServices from 'src/services/ErrorServices';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import NoteViewModel from './components/Note/viewModel';

export const PAGES = {
  note: 'note'
};

class BookingEditDrawerViewModel {
  @observable page = null;
  @observable booking = null;

  @observable isAwait = false;

  changed = async () => {};

  drawerVM = new ActionDrawerViewModel();

  noteVM = new NoteViewModel();

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({ booking, changed = async () => {} }) => {
    this.booking = booking;
    this.changed = changed;
    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  @action
  toNotePage = () => {
    this.page = PAGES.note;
  };

  @action
  onSave = async () => {
    this.isAwait = true;

    try {
      switch (this.page) {
        case PAGES.note: {
          const res = await this.noteVM.validateFields();
          await this.putBookingInfoAPI(res, this.close);
          break;
        }

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  putBookingInfoAPI = async (data, callBack = () => {}) => {
    try {
      await BookingServices.putBookingInfo({
        ...data,
        bookingId: this.booking.id
      });
      await this.changed(data);
      callBack();
    } catch (error) {
      const msg = ErrorServices.putBookingInfo(error);
      console.log('putBookingInfo', msg);
    }
  };
}

export default BookingEditDrawerViewModel;
