import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { VERIFIES } from 'src/constants/config';
import { LAND_STATUS } from 'src/constants/land';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';
import ActionModalViewModel from '../ActionModal/viewModel';
import ProfileDrawerViewModel from '../ProfileDrawer/viewModel';

export const PAGES = {
  profile: 'profile', // 資料不齊
  failed: 'failed', // admin 驗證失敗
  pending: 'pending', // 等待驗證中
  terms: 'terms' // 條款
};

// 檢查獵場是否可以上架
class LaunchLandViewModel {
  @observable page = null;

  @observable profile = null;
  @observable land = null;

  @observable termsAgreed = false;
  @observable scrollEnd = false;

  @observable isAwait = null;

  modalVM = new ActionModalViewModel();
  profileDrawerVM = new ProfileDrawerViewModel();

  changed = async () => {}

  context = null;

  @computed
  get disabled() {
    return {
      termsAgreed: this.isAwait || !this.scrollEnd,
      termsAgreedConfirm: this.isAwait || !this.termsAgreed
    };
  }

  @computed
  get check() {
    return {
      phone: !!this.profile.phone,
      idCard: !!this.profile.idCard,
      idCardPending: this.profile.idCardStatus === VERIFIES.pending.value,
      idCardFailed: this.profile.idCardStatus === VERIFIES.failed.value
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  didMount = (context) => {
    this.profile = context.state.profile;
  }

  @action
  didUpdate = (context) => {
    const diffProfile = diff(this.profile, context.state.profile);

    if (context.state.profile && diffProfile) {
      this.profile = context.state.profile;
    }
  }

  close = () => {
    this.modalVM.close();
  }

  @action
  launch = async ({ land, changed = async () => {} }) => {
    this.land = land;
    this.changed = changed;

    this.termsAgreed = false;
    this.scrollEnd = false;

    await this.validateFields();
  }

  @action
  validateFields = async () => {
    this.isAwait = true;
    this.page = null;

    if (!this.check.phone || !this.check.idCard) {
      this.page = PAGES.profile;
      this.modalVM.open();
    } else if (this.check.idCardPending) {
      this.page = PAGES.pending;
      this.modalVM.open();
    } else if (this.check.idCardFailed) {
      this.page = PAGES.failed;
      this.modalVM.open();
    } else if (!this.land.termsAgreedIn) {
      this.page = PAGES.terms;
      this.modalVM.open();
    } else {
      await this.putLandStatusAPI();
    }

    runInAction(() => {
      this.isAwait = false;
    });
  }

  onProfile = () => {
    if (!this.check.phone) {
      this.profileDrawerVM.openPhone();
      this.close();
    } else if (!this.check.idCard || this.check.idCardFailed) {
      this.profileDrawerVM.openIdCard();
      this.close();
    }
  }

  @action
  onScrollEnd = () => {
    this.scrollEnd = true;
  }

  @action
  onTermsAgreedChange = (event) => {
    this.termsAgreed = event.target.checked;
  }

  @action
  onTermsAgreedConfirm = async () => {
    this.isAwait = true;

    await this.putUpdateLandInfo();
    await this.putLandStatusAPI();

    runInAction(() => {
      this.isAwait = false;
    });
  }

  @action
  putUpdateLandInfo = async () => {
    try {
      await LandServices.putUpdateLandInfo({
        id: this.land.id,
        agreeTerms: true
      });
    } catch (error) {
      const msg = ErrorServices.putUpdateLandInfo(error);
      console.log('putUpdateLandInfo', msg);
    }
  };

  @action
  putLandStatusAPI = async () => {
    try {
      await LandServices.putLandStatus({
        id: this.land.id,
        status: LAND_STATUS.listed.value
      });

      this.changed({ status: LAND_STATUS.listed.value });

      this.close();
    } catch (error) {
      const msg = ErrorServices.putLandStatus(error);
      this.changed({ status: null });
      console.log(msg);
    }
  };
}

export default LaunchLandViewModel;
