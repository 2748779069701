import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { ROUTES } from 'src/constants/routes';
import ICONS from 'src/constants/icons';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';

class MenuViewModel {
  drawerVM = new ActionDrawerViewModel();
  router = null;

  get routes() {
    return [
      {
        value: ROUTES.lands.value,
        label: ROUTES.lands.label,
        icon: ICONS.menuLands
      },
      {
        value: ROUTES.bookings.value,
        label: ROUTES.bookings.label,
        icon: ICONS.menuBookings
      },
      {
        value: ROUTES.calendar.value,
        label: ROUTES.calendar.label,
        icon: ICONS.menuCalendar
      },
      {
        value: ROUTES.packages.value,
        label: ROUTES.packages.label,
        icon: ICONS.menuPackages
      },
      {
        value: ROUTES.payouts.value,
        label: ROUTES.payouts.label,
        icon: ICONS.menuPayouts
      },
      {
        value: ROUTES.rentals.value,
        label: ROUTES.rentals.label,
        icon: ICONS.menuRentals
      }
    ];
  }

  init = ({ router }) => {
    this.router = router;
  }

  open = () => {
    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  @action
  onRouteClick = (item) => {
    this.router.navigate(`/${item.value}`);
    this.close();
  };
}

export default MenuViewModel;
