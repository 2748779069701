import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ActionDrawer from 'src/components/ActionDrawer';
import ResetTabs from '../ResetTabs';
import './reset.scss';

@observer
class BookingDetailDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <>
        <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
          {this.vm.check.show ? (
            <ResetTabs
              className="bookingDetailDrawerTabs"
              defaultActiveKey={this.vm.defaultPage}
              items={this.vm.pages}
              onTabClick={this.vm.onTabClick}
              tabFull
              size="large"
            />
          ) : null}
        </ActionDrawer>
      </>
    );
  }
}

BookingDetailDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

BookingDetailDrawer.defaultProps = {};

export default BookingDetailDrawer;
