import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Divider, Form, Input } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ActionModalAlertContainer from 'src/components/ActionModal/components/ActionModalAlertContainer';
import ActionModalWarning from 'src/components/ActionModal/components/ActionModalWarning';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ActionDrawerItem from 'src/components/ActionDrawer/components/ActionDrawerItem';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetInputNumber from 'src/components/ResetInputNumber';
import ResetSelect from 'src/components/ResetSelect';
import styles from './styles.module.scss';

@observer
class ReportItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ form: props.form });
  }

  render() {
    return (
      <>
        <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
          {this.vm.check.item ? (
            <ActionModalAlertContainer>
              <ActionDrawerItem label={this.vm.deconstruct.itemSource.name}>
                {this.vm.deconstruct.itemVariety.name}
                <Divider className={styles.divider} />
              </ActionDrawerItem>

              <Form
                className={styles.form}
                form={this.vm.form}
                disabled={this.vm.disabled.form}
              >
                <div className={styles.item}>
                  <div className={styles.label}>
                    {i18n.t('report_item_modal_count_label')}
                  </div>

                  <ResetFormItem
                    name="count"
                    rules={this.vm.rules.count}
                    errorMessageAbsolute
                  >
                    <ResetInputNumber
                      className={styles.inputNumber}
                      value={1}
                      disabled
                    />
                  </ResetFormItem>
                </div>

                <div className={styles.item}>
                  <div className={styles.label}>
                    {i18n.t('report_item_modal_status_label')}
                  </div>

                  <ResetFormItem
                    name="status"
                    rules={this.vm.rules.status}
                    errorMessageAbsolute
                  >
                    <ResetSelect
                      options={this.vm.options.status}
                      placeholder={i18n.t(
                        'report_item_modal_count_placeholder'
                      )}
                    />
                  </ResetFormItem>
                </div>

                <div className={styles.item}>
                  <div className={styles.label}>
                    {i18n.t('report_item_modal_note_label')}
                  </div>

                  <ResetFormItem name="report" errorMessageAbsolute>
                    <Input.TextArea
                      size="large"
                      autoSize={{ minRows: 6 }}
                      placeholder={i18n.t('report_item_modal_note_placeholder')}
                    />
                  </ResetFormItem>
                </div>
              </Form>

              <ActionModalFooter
                cancelProps={{
                  onClick: this.vm.close,
                  disabled: this.vm.disabled.footer,
                  children: i18n.t('report_item_modal_cancel_button_label')
                }}
                confirmProps={{
                  onClick: this.vm.onModalConfirm,
                  disabled: this.vm.disabled.footer,
                  children: i18n.t('report_item_modal_confirm_button_label')
                }}
              />
            </ActionModalAlertContainer>
          ) : null}
        </ActionModal>

        <ActionModal
          vm={this.vm.alertVM}
          width={375}
          onCancel={this.vm.alertVM.close}
        >
          {this.vm.check.item ? (
            <ActionModalAlertContainer>
              <ActionModalWarning content={this.vm.labels.alert} />

              <ActionModalFooter
                cancelProps={{
                  type: 'primary',
                  onClick: this.vm.onAlertConfirm,
                  disabled: this.vm.disabled.alert,
                  children: i18n.t('parcel_restore_alert_confirm_button_label')
                }}
                confirmProps={{
                  type: 'default',
                  onClick: this.vm.alertVM.close,
                  disabled: this.vm.disabled.alert,
                  children: i18n.t('parcel_restore_alert_cancel_button_label')
                }}
              />
            </ActionModalAlertContainer>
          ) : null}
        </ActionModal>
      </>
    );
  }
}

ReportItemModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

ReportItemModal.defaultProps = {};

export default withForm(ReportItemModal);
