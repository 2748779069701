class RentalOrderDataModel {
  constructor(props) {
    this.id = props.id;
    this.bookingId = props.bookingId;

    this.status = props.status;

    this.isPackingCompleted = props.isPackingCompleted;

    this.startAt = props.startAt;
    this.endAt = props.endAt;

    this.consumer = {
      id: props.consumer.id,
      firstName: props.consumer.firstName,
      lastName: props.consumer.lastName,
      displayName: props.consumer.displayName,
      email: props.consumer.email
    };

    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }
}

export default RentalOrderDataModel;
