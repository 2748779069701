import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import { ROUTES } from 'src/constants/routes';
import Authenticate from '../Authenticate';
import AuthenticateViewModel from '../Authenticate/viewModel';
import styles from './styles.module.scss';

const huntersHref = `/${ROUTES.visitors.value}`;

@observer
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.modalVM = new AuthenticateViewModel();
  }

  render() {
    return (
      <>
        <section
          className={styles.wrapper}
          style={{
            '--src': `url(${require('src/assets/findHunt/8af152f1e884e87a7bc6220a07ef02b7.jpg')})`
          }}
        >
          <div className={styles.col}>
            <div className={styles.row}>
              <div className={styles.row__item}>
                <div className={styles.row1}>
                  <div className={styles.row1__item}>
                    <img
                      className={styles.image1}
                      src={require('src/assets/findHunt/7658aaef0951efbc088d3e67d57b8f7f.png')}
                      alt="alt text"
                    />
                  </div>
                  <div className={styles.row1__spacer} />
                  <div className={styles.row1__item1}>
                    <div className={styles.wrapper3}>
                      <a className={styles.wrapper3__item} href={huntersHref}>
                        <h2 className={styles.medium_title1}>
                          {i18n.t('find_hunt_page_navigation_visitors_label')}
                        </h2>
                      </a>
                      <div className={styles.wrapper3__spacer} />
                      <div className={styles.wrapper3__item1}>
                        <h2 className={styles.medium_title11}>
                          {i18n.t('find_hunt_page_navigation_landowners_label')}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.row__spacer} />
              <div className={styles.row__item1}>
                <button
                  type="button"
                  className={styles.medium_title}
                  onClick={this.modalVM.open}
                >
                  {i18n.t('find_hunt_page_sign_button_label')}
                </button>
              </div>
            </div>

            <div className={styles.col1}>
              <div className={styles.wrapper2}>
                <div className={styles.titles}>
                  <h1 className={styles.hero_title1}>
                    {i18n.t('find_hunt_page_hero_title_1')}
                    <br />
                    {i18n.t('find_hunt_page_hero_title_2')}
                    <br />
                    {i18n.t('find_hunt_page_hero_title_3')}
                  </h1>

                  <h2 className={styles.subtitle}>
                    {i18n.t('find_hunt_page_hero_subtitle')}
                  </h2>
                </div>
              </div>

              <div className={styles.wrapper1}>
                <h1 className={styles.hero_title}>
                  {i18n.t('find_hunt_page_hero_label')}
                </h1>
                <h4 className={styles.highlights}>
                  {i18n.t('find_hunt_page_hero_sublabel')}
                </h4>
              </div>
            </div>
          </div>
        </section>

        <Authenticate profile={this.props.profile} vm={this.modalVM} />
      </>
    );
  }
}

Header.propTypes = {
  profile: PropTypes.object
};

Header.defaultProps = {
  profile: null
};

export default Header;
