class RentalStatusHistoryItemDataModel {
  constructor(props) {
    this.id = props.id;
    this.status = props.status;
    this.deducted = props.deducted;
    this.compensation = props.compensation;
    this.note = props.note;
    this.createdAt = props.createdAt;
  }
}

export default RentalStatusHistoryItemDataModel;
