import dayjs from 'dayjs';
import {
  makeObservable,
  observable,
  computed,
  action
} from 'mobx';
import {
  RENTAL_STATUS,
  getRentalStatusStyle
} from 'src/constants/rental/rental';
import { assemble } from 'src/constants/methods';
import BookingDetailDrawerViewModel from 'src/components/BookingDetailDrawer/viewModel';

class RentalCardViewModel {
  @observable rental = null;

  detailDrawerVM = new BookingDetailDrawerViewModel();

  @computed
  get check() {
    return {
      shipping: this.status.value === RENTAL_STATUS.waitingForShipping.value
    };
  }

  @computed
  get id() {
    return this.rental.id;
  }

  @computed
  get status() {
    return RENTAL_STATUS[this.rental.status];
  }

  @computed
  get statusStyle() {
    return getRentalStatusStyle(this.status.value);
  }

  @computed
  get startAt() {
    return dayjs(this.rental.startAt).format('MMM DD, YYYY');
  }

  @computed
  get endAt() {
    return dayjs(this.rental.endAt).format('MMM DD, YYYY');
  }

  @computed
  get consumer() {
    return assemble.name({
      firstName: this.rental.consumer.firstName,
      lastName: this.rental.consumer.lastName
    });
  }

  constructor(props) {
    this.rental = props;
    makeObservable(this);
  }

  @action
  update = (data) => {
    this.rental = { ...this.rental, ...data };
  };

  onDetail = () => {
    this.detailDrawerVM.openRental({
      rentalId: this.id,
      changed: this.update
    });
  };

  onShipping = () => {
    this.detailDrawerVM.openShipping({
      rentalId: this.id,
      changed: this.update
    });
  };
}

export default RentalCardViewModel;
