class PayoutPaidDateDataModel {
  constructor(props) {
    this.id = props.id;

    this.amount = props.amount;

    this.transactionsCount = props.transactionsCount;

    this.createdAt = props.createdAt;

    this.updatedAt = props.updatedAt;
  }
}

export default PayoutPaidDateDataModel;
