import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import PageBack from 'src/components/PageBack';
import styles from './styles.module.scss';

@observer
class Back extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <PageBack />
      </div>
    );
  }
}

Back.propTypes = {};

Back.defaultProps = {};

export default Back;
