import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import styles from './styles.module.scss';

@observer
class Terms extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.item}>
          <div className={styles.title}>
            {i18n.t('launch_land_modal_terms_8_title')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_8_1_content')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_8_2_content')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_8_3_content')}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>
            {i18n.t('launch_land_modal_terms_9_title')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_9_1_content')}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>
            {i18n.t('launch_land_modal_terms_10_title')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_10_1_content')}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>
            {i18n.t('launch_land_modal_terms_11_title')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_11_1_content')}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>
            {i18n.t('launch_land_modal_terms_12_title')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_12_1_content')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_12_2_content')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_12_3_content')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_12_4_content')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_12_5_content')}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>
            {i18n.t('launch_land_modal_terms_13_title')}
          </div>

          <div className={styles.content}>
            {i18n.t('launch_land_modal_terms_13_1_content')}
          </div>
        </div>
      </div>
    );
  }
}

Terms.propTypes = {};

Terms.defaultProps = {};

export default Terms;
