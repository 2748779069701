import React from 'react';
import PropTypes from 'prop-types';
import { makeObservable, observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { VERIFY_CODE_LENGTH } from 'src/constants/config';
import ReactCodeInput from 'react-verification-code-input';
import i18n from 'src/i18n';
import styles from './styles.module.scss';

@observer
class VerifyCode extends React.Component {
  @observable code = '';

  @computed
  get showError() {
    // code 不存在.
    if (!this.code) {
      return true;
    }

    // code 長度不足.
    if (this.code && this.code.length !== VERIFY_CODE_LENGTH) {
      return true;
    }

    // 外面要顯示.
    if (this.props.showError) {
      return true;
    }

    return false;
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  setCode = (code) => {
    this.code = code;
  };

  onChangeCode = (code) => {
    this.setCode(code);
    this.props.onChangeCode(code);
  };

  render() {
    return (
      <div className={styles.container}>
        <ReactCodeInput
          className={styles.codeInput}
          type="number"
          fields={VERIFY_CODE_LENGTH}
          value={this.code}
          onChange={this.onChangeCode}
        />

        {this.showError && (
          <div className={styles.errorMessage}>{this.props.errorMessage}</div>
        )}
      </div>
    );
  }
}

VerifyCode.propTypes = {
  onChangeCode: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  showError: PropTypes.bool
};

VerifyCode.defaultProps = {
  errorMessage: i18n.t('verify_code_error_message'),
  showError: false
};

export default VerifyCode;
