import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';
import { ROUTES } from 'src/constants/routes';

class LoginViewModel {
  @observable password = '';
  @observable errorMessage = '';
  @observable showErrorMessage = false;
  @observable isAwait = false;

  @computed
  get disabledContinue() {
    return this.isAwait || !this.password;
  }

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  didMount = ({ contextActions }) => {
    this.contextActions = contextActions;
  };

  @action
  onChangePassword = (event) => {
    const password = event.target.value;

    this.password = password;

    console.log('password', password);
  };

  @action
  onContinue = () => {
    this.showErrorMessage = false;

    this.postUserLoginAPI();
  };

  @action
  postUserLoginAPI = async () => {
    this.isAwait = true;

    try {
      const res = await UserService.postUserLogin({
        email: this.props.email,
        password: this.password
      });

      this.props.router.navigate(`/${ROUTES.lands.value}`);

      // 更新 profile.
      this.contextActions.updateProfile(res.data);

      console.log('登入', '會員資料', res.data);
    } catch (error) {
      runInAction(() => {
        const message = ErrorServices.postUserLogin(error);

        this.errorMessage = message;
        this.showErrorMessage = true;

        console.log('postUserLogin', message, error);
      });
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default LoginViewModel;
