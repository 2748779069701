import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { AppStateContext } from 'src/stores';
import { Form } from 'antd';
import i18n from 'src/i18n';
import ICONS from 'src/constants/icons';
import withForm from 'src/components/withForm';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetInput from 'src/components/ResetInput';
import ResetButton from 'src/components/ResetButton';
import MainHeader from 'src/components/MainLayout/components/MainHeader';
import BasicCard from 'src/components/BasicCard';
import LoginViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class Login extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = new LoginViewModel({
      form: props.form
    });
  }

  componentDidMount() {
    this.vm.didMount(this.context);
  }

  render() {
    return (
      <div className={styles.container}>
        <MainHeader />

        <div className={styles.space} />

        <BasicCard className={styles.mainContent}>
          <div className={styles.title}>
            {i18n.t('check_booking_page_login_page_title')}
          </div>

          <Form
            className={styles.form}
            form={this.vm.form}
            disabled={this.vm.disabled.form}
          >
            <ResetFormItem name="email" rules={this.vm.rules.email}>
              <ResetInput
                placeholder={i18n.t(
                  'check_booking_page_login_page_email_placeholder'
                )}
              />
            </ResetFormItem>

            <ResetFormItem name="password" rules={this.vm.rules.password}>
              <ResetInput
                placeholder={i18n.t(
                  'check_booking_page_login_page_password_placeholder'
                )}
              />
            </ResetFormItem>

            <ResetButton
              className={styles.confirm}
              type="primary"
              onClick={this.vm.onConfirm}
              disabled={this.vm.disabled.confirm}
            >
              {i18n.t('check_booking_page_login_page_confirm_button_label')}
            </ResetButton>
          </Form>

          <div className={styles.divider}>
            {i18n.t('check_booking_page_login_page_divider_label')}
          </div>

          <div className={styles.others}>
            <ResetButton
              className={styles.button}
              type="text"
              onClick={this.vm.onFacebook}
              disabled={this.vm.disabled.others}
            >
              <img src={ICONS.loginFacebook} alt="facebook" />
            </ResetButton>

            <ResetButton
              className={styles.button}
              type="text"
              onClick={this.vm.onApple}
              disabled={this.vm.disabled.others}
            >
              <img src={ICONS.loginApple} alt="apple" />
            </ResetButton>
          </div>
        </BasicCard>

        <div className={styles.space} />
      </div>
    );
  }
}

Login.propTypes = {
  form: PropTypes.object.isRequired
};

Login.defaultProps = {};

export default withForm(Login);
