import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { PAYOUT_TYPES } from 'src/constants/payout';
import withRouter from 'src/components/withRouter';
import PageContainer from 'src/components/PageContainer';
import Bookings from './components/Bookings';
import BookingPayoutRecords from './components/BookingPayoutRecords';
import Rentals from './components/Rentals';
import RentalPayoutRecords from './components/RentalPayoutRecords';
import PayoutDetailsPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class PayoutDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new PayoutDetailsPageViewModel({
      router: props.router
    });
  }

  renderCards = () => {
    switch (this.vm.type) {
      case PAYOUT_TYPES.booking:
        return this.vm.check.payoutId ? <Bookings /> : <BookingPayoutRecords />;

      case PAYOUT_TYPES.rental:
        return this.vm.check.rentalRecords ? (
          <RentalPayoutRecords />
        ) : (
          <Rentals />
        );

      default:
        return null;
    }
  };

  render() {
    return <PageContainer>{this.renderCards()}</PageContainer>;
  }
}

PayoutDetailsPage.propTypes = {
  router: PropTypes.object.isRequired
};

PayoutDetailsPage.defaultProps = {};

export default withRouter(PayoutDetailsPage);
