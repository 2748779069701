import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import Loading from 'src/components/Loading';
import VerifyCode from 'src/components/VerifyCode';
import ResendButton from 'src/components/ResendButton';
import FormInput from '../FormInput';
import ContinueButton from '../ContinueButton/index';
import ResetPasswordViewModel, { PAGES } from './viewModel';
import styles from './styles.module.scss';

@observer
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ResetPasswordViewModel(props);
  }

  componentWillUnmount() {
    this.vm.unMount();
  }

  renderPage = () => {
    switch (this.vm.page) {
      case PAGES.reset:
        return this.renderResetPage();
      default:
        return this.renderSendPage();
    }
  };

  renderSendPage = () => {
    return (
      <div className={styles.sendContainer}>
        <ContinueButton
          text={i18n.t('find_hunt_page_reset_password_send_btn')}
          onClick={this.vm.toReset}
        />
      </div>
    );
  };

  renderResetPage = () => {
    return (
      <div className={styles.resetContainer}>
        {/* 輸入驗證碼. */}
        <div className={styles.verifyCodeContainer}>
          <VerifyCode onChangeCode={this.vm.onChangeCode} />
        </div>

        {/* 重新發送驗證信. */}
        <div className={styles.item}>
          <ResendButton
            type="link"
            vm={this.vm.resendButtonVM}
            onClick={this.vm.onResend}
            label={i18n.t('find_hunt_resend_button_label')}
            unit={i18n.t('find_hunt_resend_unit')}
          />
        </div>

        {/* 輸入密碼. */}
        <div className={styles.item}>
          <div className={styles.inputContainer}>
            <FormInput
              type="password"
              name="password"
              placeholder={i18n.t(
                'find_hunt_page_reset_password_password_placeholder'
              )}
              value={this.vm.password}
              onChange={this.vm.onChangePassword}
            />
          </div>
        </div>

        <div className={styles.saveButtonContainer}>
          <ContinueButton
            text={i18n.t('find_hunt_page_reset_password_continue_btn')}
            onClick={this.vm.onSave}
            disabled={this.vm.disabledSave}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.mainInfoContainer}>
            <div className={styles.fontSize1}>{this.vm.emailLabel}</div>
          </div>

          {this.renderPage()}
        </div>

        {this.vm.isAwait && <Loading />}
      </div>
    );
  }
}

ResetPassword.propTypes = {
  email: PropTypes.string,
  toLogin: PropTypes.func
};

ResetPassword.defaultProps = {
  email: '',
  toLogin: () => {}
};

export default ResetPassword;
