import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';

class PriceAlertModalViewModel {
  @observable isAwait = false;

  confirm = async () => {};

  modalVM = new ActionModalViewModel();

  @computed
  get disabled() {
    return {
      confirm: this.isAwait
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  open = ({ confirm = async () => {} }) => {
    this.confirm = confirm;
    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  @action
  onConfirm = async () => {
    this.isAwait = true;

    await this.confirm();

    runInAction(() => {
      this.isAwait = false;
      this.close();
    });
  }
}

export default PriceAlertModalViewModel;
