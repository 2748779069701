import { makeObservable, observable, action, computed } from 'mobx';

class ConfigStore {
  @observable config = null;

  @computed
  get version() {
    return this.config?.version || 1;
  }

  @computed
  get timezone() {
    return this.config?.timezone || 'Asia/Taipei';
  }

  @computed
  get bookingAfterDays() {
    return this.config?.bookingAfterDays ?? 4;
  }

  @computed
  get platformServiceFeeRatio() {
    return this.config?.platformServiceFeeRatio || '0.19';
  }

  @computed
  get taxRate() {
    return this.config?.taxRate || '0';
  }

  @computed
  get emailAuthLimit() {
    return this.config?.emailAuthLimit || 180;
  }

  @computed
  get phoneAuthLimit() {
    return this.config?.phoneAuthLimit || 180;
  }

  @computed
  get resetPasswordLimit() {
    return this.config?.resetPasswordLimit || 180;
  }

  @computed
  get bookingTokenExpires() {
    return this.config?.bookingTokenExpires || 1200;
  }

  @computed
  get rentalProfitRatio() {
    return this.config?.rentalProfitRatio || 0.1;
  }

  constructor() {
    makeObservable(this);
  }

  @action
  update = (value) => {
    this.config = value;
  };
}

const configStore = new ConfigStore();

export default configStore;
