class StripeInfoDataModel {
  constructor(data) {
    this.id = data.id;
    this.status = data.status;

    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }
}

export default StripeInfoDataModel;
