import { request, getHost } from './utils';

// 查詢已收款.
export const getPayoutPaid = ({ token, year }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/payout/paid/monthly`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      year
    }
  };

  return request(options);
};


// 查詢尚未收款.
export const getPayoutPayable = ({ token, year }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/payout/payable/monthly`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      year
    }
  };

  return request(options);
};

// 使用 payout id 讀取 bookings.
export const getPayoutBookings = ({ token, limit, anchor, id, land }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/payout/${id}/bookings`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      limit, anchor, land
    }
  };

  return request(options);
};

// 查詢 payout total
export const getPayoutStatistics = ({ token, landId }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/payout/statistics`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      land: landId
    }
  };

  return request(options);
};

// 讀取 booking payout paid 所有的紀錄
// 這是 record 不是 payout 也不是 booking
export const getPayoutPaidRecords = ({ token, landId, limit, anchor }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/payout/paid/records`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      land: landId,
      limit,
      anchor
    }
  };

  return request(options);
};

export const getPayoutPayableRecords = ({ token, landId, limit, anchor }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/payout/payable/records`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      land: landId,
      limit,
      anchor
    }
  };

  return request(options);
};
