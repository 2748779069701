import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { formatPrice } from 'src/constants/price';
import {
  DISCOUNTS,
  SpecialDateCalc,
  formatDiscount
} from 'src/constants/land';
import i18n from 'src/i18n';
import LandEditDrawerViewModel, { PAGES } from '../LandEditDrawer/viewModel';

class LandPricesViewModel {
  @observable land = null;

  changed = () => {};

  landEditDrawerVM = new LandEditDrawerViewModel();

  @computed
  get basicFee() {
    return `US$ ${formatPrice(this.land.basicFee)}`;
  }

  @computed
  get basicHunters() {
    return this.land.basicHunters;
  }

  @computed
  get extraHunters() {
    return this.land.extraHunters;
  }

  @computed
  get extraFee() {
    return this.land.extraHunters
      ? `US$ ${formatPrice(this.land.extraFee)}`
      : i18n.t('land_detail_drawer_null_item_placeholder');
  }

  @computed
  get specialDates() {
    const check = !!this.land.specialDates.length;

    return {
      check,
      items: this.land.specialDates.map((item) => {
        const format = SpecialDateCalc.toFormat({
          startAt: item.startAt,
          endAt: item.endAt,
          ratio: item.ratio,
          timezoneId: this.land.timezoneId
        });

        return {
          key: item.id,
          label: format.date
        };
      })
    };
  }

  @computed
  get discounts() {
    const check = !!this.land.discounts.length;
    const unit = i18n.t(
      'land_detail_drawer_fees_page_discounts_off_label'
    );

    return {
      check,
      items: Object.values(DISCOUNTS).map((discount) => {
        const find = this.land.discounts.find(
          (item) => item.name === discount.value
        );
        const ratio = find
          ? `${formatDiscount.toPercentage(find.discount)}% ${unit}`
          : i18n.t('land_detail_drawer_null_item_placeholder');
        const label = `${discount.label} (${ratio})`;

        return {
          key: discount.value,
          label
        };
      })
    };
  }

  constructor(props) {
    this.land = props.land;
    this.changed = props.changed;

    makeObservable(this);
  }

  @action
  didUpdate = (props, preProps) => {
    const diffLand = diff(props.land, preProps.land);

    if (diffLand) {
      this.land = props.land;
    }
  };

  onPrices = () => {
    this.landEditDrawerVM.toPage(PAGES.prices);
    this.landEditDrawerVM.open({
      land: this.land,
      changed: this.changed
    });
  };

  onSpecialDates = () => {
    this.landEditDrawerVM.toPage(PAGES.specialDates);
    this.landEditDrawerVM.open({
      land: this.land,
      changed: this.changed
    });
  };

  onDiscounts = () => {
    this.landEditDrawerVM.toPage(PAGES.discounts);
    this.landEditDrawerVM.open({
      land: this.land,
      changed: this.changed
    });
  };
}

export default LandPricesViewModel;
