import PackageDataModel from './PackageDataModel';
import LandSpecialDateDataModel from './LandSpecialDateDataModel';
import LandDiscountDataModel from './LandDiscountDataModel';

class BookingDetailDataModel {
  constructor(data) {
    this.id = data.id;
    this.status = data.status;

    // 取消原因.
    this.cancelReason = data.cancelReason;
    // 取消時間.
    this.cancelAt = data.cancelAt;

    // 退款比率.
    this.refundRatio = data.refundRatio;
    // 有沒有退款.
    this.isRefund = !!data.refundRatio;

    // 爭議時間.
    this.disputedAt = data.disputedAt;
    // 是否有爭議.
    this.isDisputed = !!data.disputedAt;

    // 基本獵人數.
    this.basicHunters = data.basicHunters;
    // 額外獵人數.
    this.extraHunters = data.extraHunters;
    // 參與的獵人數.
    this.hunters = data.hunters;

    // 基本費用.
    this.basicFee = data.basicFee;
    // 額外費用.
    this.extraFee = data.extraFee;

    // 預約開始時間.
    this.startAt = data.startAt;
    // 預約結束時間.
    this.endAt = data.endAt;
    // 預約完成時間.
    this.completedAt = data.completedAt;

    // 預約的行程.
    this.packages = data.packages.map((item) => new PackageDataModel(item));
    // 預約的特殊日售價.
    this.specialDates = data.specialDates.map((item) => {
      return new LandSpecialDateDataModel(item);
    });
    // 預約長時間的折扣.
    this.discounts = data.discounts.map((item) => {
      return new LandDiscountDataModel(item);
    });

    // 預約的獵場.
    this.land = {
      id: data.land.id,
      name: data.land.name,
      timezoneId: data.land.timezoneId
    };

    // 訂單交易的資料.
    this.trade = {
      id: data.trade.id,
      transactionId: data.trade.transactionId,
      status: data.trade.status,
      email: data.trade.email,
      currency: data.trade.currency,
      // 預約的費用.
      amount: data.trade.amount,
      // 預約的服務費.
      platformServiceFee: data.trade.platformServiceFee
    };

    // 預約的獵人
    this.consumer = {
      id: data.consumer.id,
      firstName: data.consumer.firstName,
      lastName: data.consumer.lastName,
      displayName: data.consumer.displayName,
      idCardStatus: data.consumer.idCardStatus,
      huntingLicenseStatus: data.consumer.huntingLicenseStatus
    };

    // 預約的獵人的聯絡人
    this.contact = {
      id: data.contact?.id,
      firstName: data.contact?.firstName,
      lastName: data.contact?.lastName,
      displayName: data.contact?.displayName,
      gender: data.contact?.gender,
      email: data.contact?.email,
      phone: data.contact?.phone
    };

    // 租借物品
    this.rentalId = data.rentalId;

    this.note = data.note;

    // 訂單費用?
    this.price = data.price;

    // 結算的款項(不一定完成付款)
    this.payouts = data.payouts.map((item) => {
      return {
        id: item.id,
        status: item.status,
        startAt: item.startAt,
        endAt: item.endAt,
        date: item.date,
        amount: item.amount
      };
    });
  }
}

export default BookingDetailDataModel;
