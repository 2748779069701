import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import i18n from 'src/i18n';
import { formatPrice } from 'src/constants/price';
import ErrorServices from 'src/services/ErrorServices';
import RentalServices from 'src/services/RentalServices';
import RentalCardViewModel from './components/RentalCard/viewModel';

class RentalPayoutRecordsViewModel {
  @observable landId = null;
  @observable records = [];

  @observable statistics = {
    paid: {
      total: '0',
      startAt: null,
      endAt: null
    },
    payable: {
      startAt: null,
      endAt: null,
      total: '0'
    }
  };

  @observable inited = false;
  @observable isAwait = false;

  anchor = null;

  @computed
  get disabled() {
    return {
      landId: this.isAwait
    };
  }

  @computed
  get subtitle() {
    const data = this.statistics.paid;

    if (data && data.startAt && data.endAt) {
      const label = i18n.t('payout_details_page_rental_subtitle_label');
      const startAt = dayjs(data.startAt).format('YYYY');
      const endAt = dayjs(data.endAt).format('YYYY');
      const total = formatPrice(data.total, true);

      return `${startAt} - ${endAt} ${label} ${total}`;
    }

    return '';
  }

  constructor(props) {
    makeObservable(this);
  }

  didMount = async () => {
    await this.reset();

    runInAction(() => {
      this.inited = true;
    });
  };

  @action
  onLandIdChange = (val) => {
    this.landId = val;
  };

  onWaypointEnter = () => {
    if (this.inited && !this.isAwait && this.anchor !== null) {
      this.getRentalPaidRecordsAPI();
    }
  };

  @action
  reset = async () => {
    this.isAwait = true;

    this.records = [];
    this.anchor = undefined;

    await Promise.all([
      this.getRentalPayoutStatisticsAPI(),
      this.getRentalPaidRecordsAPI()
    ]);

    runInAction(() => {
      this.isAwait = false;
    });
  };

  getRentalPayoutStatisticsAPI = async () => {
    try {
      const res = await RentalServices.getRentalPayoutStatistics({
        landId: this.landId || undefined
      });

      runInAction(() => {
        this.statistics = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getRentalPayoutStatistics(error);
      console.log('getRentalPayoutStatistics', msg);
    }
  };

  getRentalPaidRecordsAPI = async () => {
    try {
      const res = await RentalServices.getRentalPaidRecords({
        landId: this.landId || undefined,
        anchor: this.anchor
      });

      runInAction(() => {
        const data = res.data.map((item) => new RentalCardViewModel(item));

        this.records = [...this.records, ...data];
        this.anchor = res.anchor;
      });
    } catch (error) {
      const msg = ErrorServices.getRentalPaidRecords(error);
      console.log('getRentalPaidRecords', msg);
    }
  };
}

export default RentalPayoutRecordsViewModel;
