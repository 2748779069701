import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { AppStateContext } from 'src/stores';
import i18n from 'src/i18n';
import withRouter from 'src/components/withRouter';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetSelect from 'src/components/ResetSelect';
import { PAGES } from './viewModel';
import styles from './styles.module.scss';

@observer
class DeactivateAccountModal extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ router: props.router, form: props.form });
  }

  componentDidMount() {
    this.vm.didMount(this.context);
  }

  renderContent = () => {
    switch (this.vm.page) {
      case PAGES.description:
        return (
          <div className={styles.description}>
            {i18n.t('deactivate_account_modal_description')}
          </div>
        );

      case PAGES.form:
        return (
          <Form
            className={styles.form}
            form={this.vm.form}
            disabled={this.vm.disabled.form}
          >
            <div className={styles.item}>
              <div className={styles.item}>
                <div className={styles.label}>
                  {i18n.t('deactivate_account_modal_state_label')}
                </div>

                <ResetFormItem
                  name="state"
                  rules={this.vm.rules.state}
                  errorMessageAbsolute
                >
                  <ResetSelect
                    options={this.vm.options.states}
                    placeholder={i18n.t(
                      'deactivate_account_modal_state_placeholder'
                    )}
                  />
                </ResetFormItem>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('deactivate_account_modal_reason_label')}
              </div>

              <ResetFormItem
                name="reason"
                rules={this.vm.rules.reason}
                errorMessageAbsolute
              >
                <ResetSelect
                  options={this.vm.options.reasons}
                  placeholder={i18n.t(
                    'deactivate_account_modal_reason_placeholder'
                  )}
                />
              </ResetFormItem>
            </div>
          </Form>
        );

      case PAGES.confirm:
        return (
          <div className={styles.description}>
            {i18n.t('deactivate_account_modal_delete_description')}
          </div>
        );

      default:
        return null;
    }
  };

  renderFooter() {
    switch (this.vm.page) {
      case PAGES.description:
        return (
          <ActionModalFooter
            cancelProps={{
              onClick: this.vm.close,
              disabled: this.vm.disabled.footer,
              children: i18n.t('deactivate_account_modal_cancel_button_label')
            }}
            confirmProps={{
              onClick: this.vm.onNext,
              disabled: this.vm.disabled.footer,
              children: i18n.t('deactivate_account_modal_next_button_label')
            }}
          />
        );

      case PAGES.form:
        return (
          <ActionModalFooter
            cancelProps={{
              onClick: this.vm.onBack,
              disabled: this.vm.disabled.footer,
              children: i18n.t('deactivate_account_modal_back_button_label')
            }}
            confirmProps={{
              onClick: this.vm.onNext,
              disabled: this.vm.disabled.footer,
              children: i18n.t('deactivate_account_modal_next_button_label')
            }}
          />
        );

      case PAGES.confirm:
        return (
          <ActionModalFooter
            cancelProps={{
              onClick: this.vm.onBack,
              disabled: this.vm.disabled.footer,
              children: i18n.t('deactivate_account_modal_back_button_label')
            }}
            confirmProps={{
              onClick: this.vm.onDelete,
              disabled: this.vm.disabled.footer,
              children: i18n.t('deactivate_account_modal_delete_button_label')
            }}
          />
        );

      default:
        return null;
    }
  }

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        <div className={styles.container}>
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </ActionModal>
    );
  }
}

DeactivateAccountModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

DeactivateAccountModal.defaultProps = {};

export default withRouter(withForm(DeactivateAccountModal));
