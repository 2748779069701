import React from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { assemble } from 'src/constants/methods';
import i18n from 'src/i18n';

class ParcelPDFViewModel {
  @observable rental = null;
  @observable parcel = null;

  @computed
  get landName() {
    return this.rental.land.name;
  }

  @computed
  get landAddress() {
    return assemble.address(this.rental.land);
  }

  @computed
  get bookingId() {
    return this.rental.bookingId;
  }

  @computed
  get consumer() {
    return assemble.name(this.rental.consumer);
  }

  @computed
  get parcelName() {
    const name = this.parcel.name;
    const size = assemble.size(this.parcel.size);

    return `${name}${size}`;
  }

  @computed
  get shippingLabel() {
    const label = i18n.t('rental_drawer_parcel_page_parcel_pdf_shipping_label');
    const trackingNumber = this.parcel.label.trackingNumber;

    return `${label} ${trackingNumber}`;
  }

  @computed
  get returnLabel() {
    const label = i18n.t('rental_drawer_parcel_page_parcel_pdf_return_label');
    const trackingNumber = this.parcel.returnLabel.trackingNumber;

    return `${label} ${trackingNumber}`;
  }

  constructor(props) {
    this.rental = props.rental;
    this.parcel = props.parcel;

    makeObservable(this);
  }
}

export default ParcelPDFViewModel;
