export const THEME = {
  token: {
    colorPrimary: '#ea9e00',
    colorInfo: '#ea9e00',
    colorSuccess: '#558935',
    colorWarning: '#e43f35',
    colorError: '#e43f35',
    borderRadius: 4,
    fontSize: 16
  }
};
