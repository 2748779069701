import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import optionStore from 'src/global/optionStore';
import {
  FORM_INPUT_REQUIRED_MESSAGE,
  FORM_INPUT_FORMAT_ERROR_MESSAGE,
  REGEXPS
} from 'src/constants/config';

class AddressViewModel {
  @observable land = null;
  @observable state = null;

  form = null;

  @computed
  get options() {
    const states = Object.keys(optionStore.states).map((key) => {
      return {
        value: key,
        label: optionStore.states[key].name
      };
    });
    const counties = this.state
      ? Object.keys(optionStore.states[this.state].counties).map((key) => {
        return {
          value: key,
          label: optionStore.states[this.state].counties[key]
        };
      })
      : [];

    return {
      states,
      counties
    };
  }

  get rules() {
    const required = {
      required: true,
      message: FORM_INPUT_REQUIRED_MESSAGE
    };

    return {
      required: [required],
      postcode: [
        required,
        {
          pattern: REGEXPS.postcode,
          message: FORM_INPUT_FORMAT_ERROR_MESSAGE
        }
      ]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.land = props.land;
    this.form = props.form;

    this.setForm();
  };

  @action
  onStateChange = (val) => {
    this.state = val;
    this.form.setFieldsValue({
      county: null
    });
  };

  @action
  setForm = () => {
    if (this.land) {
      this.state = this.land.state;

      this.form.setFieldsValue({
        state: this.land?.state || '',
        county: this.land?.county || '',
        street: this.land?.street || '',
        postcode: this.land?.postcode || ''
      });
    }
  };

  validateFields = async () => {
    const res = await this.form.validateFields();
    return res;
  };
}

export default AddressViewModel;
