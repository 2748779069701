import dayjs from 'dayjs';
import { makeObservable, observable, action, computed } from 'mobx';
import { formatPrice } from 'src/constants/price';

class PayoutViewModel {
  @observable payout = null;

  @computed
  get id() {
    return this.payout.id;
  }

  @computed
  get labels() {
    return {
      date: dayjs(this.payout.createAt).format('MMM D, YYYY'),
      amount: `$ ${formatPrice(this.payout.amount)}`
    };
  }

  constructor(props) {
    this.payout = props;
    makeObservable(this);
  }
}

export default PayoutViewModel;
