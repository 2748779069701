import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { AppStateContext } from 'src/stores';
import withRouter from 'src/components/withRouter';
import i18n from 'src/i18n';
import clsx from 'clsx';
import FormInput from '../FormInput';
import ContinueButton from '../ContinueButton/index';
import LoginViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class Login extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = new LoginViewModel({
      email: props.email,
      close: props.close,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount({ contextActions: this.context.actions });
  }

  render() {
    return (
      <div id="login" className={styles.container}>
        <div className={styles.content}>
          <div
            className={clsx([
              styles.inputContainer,
              this.vm.showErrorMessage && styles.showErrorMessage
            ])}
          >
            <FormInput
              name="password"
              placeholder={i18n.t('find_hunt_page_login_password_placeholder')}
              value={this.vm.password}
              onChange={this.vm.onChangePassword}
              type="password"
              hasBorder
              errorMessage={this.vm.errorMessage}
              showErrorMessage={this.vm.showErrorMessage}
            />
          </div>

          <div className={styles.itemContainer}>
            <ContinueButton
              text={i18n.t('find_hunt_page_login_continue_btn')}
              onClick={this.vm.onContinue}
              disabled={this.vm.disabledContinue}
            />
          </div>

          <div className={styles.itemContainer}>
            <Button
              type="text"
              size="large"
              className={styles.resetButton}
              onClick={this.props.toResetPassword}
            >
              {i18n.t('find_hunt_page_login_reset_password_btn')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  email: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  toResetPassword: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired
};

Login.defaultProps = {};

export default withRouter(Login);
