import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import ActionDrawerCard from 'src/components/ActionDrawer/components/ActionDrawerCard';
import LandDetailViewModel from './viewModel';
import LandEditDrawer from '../LandEditDrawer';
import LandMeetDrawer from '../LandMeetDrawer';
import styles from './styles.module.scss';

@observer
class LandDetail extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new LandDetailViewModel({
      land: props.land,
      changed: props.changed
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_detail_page_status_label')}
            onClick={this.vm.onStatus}
            next
          >
            <span className={this.vm.status.style}>{this.vm.status.label}</span>
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_detail_page_photos_label')}
            onClick={this.vm.onPhotos}
            next
          >
            <div className={styles.photos}>
              {this.vm.photos.srcs.map((item, i) => {
                const isLast = i === this.vm.photos.srcs.length - 1;

                return (
                  <div
                    className={clsx([styles.photo, isLast && styles.last])}
                    data-leftover={this.vm.photos.leftover}
                    key={item.id}
                  >
                    <img className={styles.img} src={item.src} alt={item.id} />
                  </div>
                );
              })}
            </div>
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_detail_page_name_label')}
            onClick={this.vm.onBasicInfo}
            next
          >
            {this.vm.name}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_detail_page_acres_label')}
            onClick={this.vm.onBasicInfo}
            next
          >
            {this.vm.acres}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_detail_page_species_label')}
            onClick={this.vm.onBasicInfo}
            next
          >
            {this.vm.species.label}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_detail_page_description_label')}
            onClick={this.vm.onDescription}
            next
          >
            {this.vm.description}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_detail_page_address_label')}
            onClick={this.vm.onPosition}
            next
          >
            {this.vm.address}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_detail_page_meet_address_label')}
            onClick={this.vm.onMeet}
            next
          >
            {this.vm.meet}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_detail_page_rules_label')}
            onClick={this.vm.onRules}
            next
          >
            {this.vm.rules}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t(
              'land_detail_drawer_detail_page_final_booking_date_label_1'
            )}
            onClick={this.vm.onFinalBookingDate}
            next
          >
            {this.vm.finalBookingDate}
          </ActionDrawerCard>
        </div>

        <LandEditDrawer vm={this.vm.landEditDrawerVM} />

        <LandMeetDrawer vm={this.vm.landMeetDrawerVM} />
      </>
    );
  }
}

LandDetail.propTypes = {
  land: PropTypes.object.isRequired,
  changed: PropTypes.func
};

LandDetail.defaultProps = {
  changed: async () => {}
};

export default LandDetail;
