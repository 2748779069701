import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import { AMOUNT_LABEL } from 'src/constants/payout';
import withRouter from 'src/components/withRouter';
import ResetTable from 'src/components/ResetTable';
import LinkButton from 'src/components/LinkButton';
import BookingPayoutsViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class BookingPayouts extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new BookingPayoutsViewModel({
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.inited ? (
      <div className={styles.container}>
        <div className={styles.scrollY}>
          <div className={styles.totalsTable}>
            <ResetTable data={this.vm.total.data}>
              <Table.Column
                width={500}
                title={i18n.t('payouts_page_booking_tab_paid_total_label')}
                align="center"
                render={(item) => (
                  <LinkButton onClick={this.vm.onPaidAll}>
                    {item.paid}
                  </LinkButton>
                )}
              />

              <Table.Column
                width={500}
                title={i18n.t('payouts_page_booking_tab_payable_total_label')}
                align="center"
                render={(item) => (
                  <LinkButton onClick={this.vm.onPayableAll}>
                    {item.payable}
                  </LinkButton>
                )}
              />
            </ResetTable>
          </div>

          <div className={styles.payoutsTable}>
            <ResetTable data={this.vm.payouts} tHeadSticky>
              <Table.Column
                width={500}
                title={i18n.t('payouts_page_booking_tab_payout_date_label')}
                align="center"
                render={(vm) => (
                  <div className={styles.date}>{vm.labels.date}</div>
                )}
              />

              <Table.Column
                width={500}
                title={i18n.t('payouts_page_booking_tab_payout_paid_label')}
                align="center"
                render={(vm) =>
                  vm.checks.paid ? (
                    <LinkButton onClick={() => this.vm.onPaid(vm)}>
                      {vm.labels.amount}
                    </LinkButton>
                  ) : (
                    AMOUNT_LABEL
                  )}
              />

              <Table.Column
                width={500}
                title={i18n.t('payouts_page_booking_tab_payout_payable_label')}
                align="center"
                render={(vm) =>
                  vm.checks.payable ? (
                    <LinkButton onClick={() => this.vm.onPayable(vm)}>
                      {vm.labels.amount}
                    </LinkButton>
                  ) : (
                    AMOUNT_LABEL
                  )}
              />
            </ResetTable>
          </div>
        </div>
      </div>
    ) : null;
  }
}

BookingPayouts.propTypes = {
  router: PropTypes.object.isRequired
};

BookingPayouts.defaultProps = {};

export default withRouter(BookingPayouts);
