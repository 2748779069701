class PayoutRentalDataModel {
  constructor(props) {
    this.id = props.id;

    this.status = props.status;

    this.profit = props.profit;

    this.startAt = props.startAt;
    this.endAt = props.endAt;

    this.completedAt = props.completedAt;

    this.land = {
      id: props.land.id,
      name: props.land.name
    };

    this.consumer = {
      id: props.consumer.id,
      displayName: props.consumer.displayName,
      firstName: props.consumer.firstName,
      lastName: props.consumer.lastName
    };

    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }
}

export default PayoutRentalDataModel;
