import dayjs from 'dayjs';
import configStore from 'src/global/configStore';
import { toLocalTimeZone } from './land';

// 月曆區分 BusinessDayCardViewModel
export const BUSINESS_DAY_KEY_FORMAT = 'YYYY-MM-DD';

export const BUSINESS_DAY_TAG_MAX_LENGTH = 50;

export const BUSINESS_DAY_CARD_TAGS_MAX_LENGTH = 2;

export const getBatchDates = ({ startAt, endAt }) => {
  const start = dayjs(startAt).startOf('date');
  const end = dayjs(endAt).endOf('date');
  const dates = end.diff(start, 'day') + 1;

  return Array.from({ length: dates }).map((_, i) =>
    dayjs(startAt).add(i, 'day').format(BUSINESS_DAY_KEY_FORMAT));
};

// 合法日期
export const isBookingAfterDays = (date = null) => {
  const localDate = toLocalTimeZone(date).startOf('date');
  // 合法的日期
  const validDate = dayjs()
    .startOf('date')
    .add(configStore.bookingAfterDays, 'date');

  return localDate.isSameOrAfter(validDate);
};

// 小於獵場最後的預約日期
export const isLessFinalBookingDate = ({
  date = null,
  finalBookingDate = null
}) => {
  const localDate = toLocalTimeZone(date).startOf('date');
  const final = toLocalTimeZone(finalBookingDate).startOf('date');

  return finalBookingDate ? localDate.isSameOrBefore(final) : true;
};
