import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import CustomLi from 'src/components/CustomLi';
import ActionDrawerCard from 'src/components/ActionDrawer/components/ActionDrawerCard';
import LandEditDrawer from '../LandEditDrawer';
import LandPricesViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class LandPrices extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new LandPricesViewModel({
      land: props.land,
      changed: props.changed
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_fees_page_basic_fee_label')}
            onClick={this.vm.onPrices}
            next
          >
            {this.vm.basicFee}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_fees_page_basic_hunters_label')}
            onClick={this.vm.onPrices}
            next
          >
            {this.vm.basicHunters}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_fees_page_extra_hunters_label')}
            onClick={this.vm.onPrices}
            next
          >
            {this.vm.extraHunters}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_fees_page_extra_fee_label')}
            onClick={this.vm.onPrices}
            next
          >
            {this.vm.extraFee}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_fees_page_special_dates_label')}
            onClick={this.vm.onSpecialDates}
            next
          >
            {this.vm.specialDates.check
              ? this.vm.specialDates.items.map((item) => (
                <CustomLi key={item.key}>{item.label}</CustomLi>
              ))
              : i18n.t('land_detail_drawer_null_item_placeholder')}
          </ActionDrawerCard>

          <ActionDrawerCard
            label={i18n.t('land_detail_drawer_fees_page_discounts_label')}
            onClick={this.vm.onDiscounts}
            next
          >
            {this.vm.discounts.check
              ? this.vm.discounts.items.map((item) => (
                <CustomLi key={item.key}>{item.label}</CustomLi>
              ))
              : i18n.t('land_detail_drawer_null_item_placeholder')}
          </ActionDrawerCard>
        </div>

        <LandEditDrawer vm={this.vm.landEditDrawerVM} />
      </>
    );
  }
}

LandPrices.propTypes = {
  land: PropTypes.object.isRequired,
  changed: PropTypes.func
};

LandPrices.defaultProps = {
  changed: () => {}
};

export default LandPrices;
