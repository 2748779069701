import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import { PACKAGE_TYPES } from 'src/constants/package';
import { formatPrice } from 'src/constants/price';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import i18n from 'src/i18n';
import PackageServices from 'src/services/PackageServices';
import ErrorServices from 'src/services/ErrorServices';
import ActionModalViewModel from '../ActionModal/viewModel';

/**
 * Package Drawer 有三種狀態
 * 新增: 表單 + 按鈕
 * 編輯: 文字 + 按鈕 -> 表單 + 按鈕
 * 檢視: 文字
 */
export const TYPES = {
  create: 'create',
  edit: 'edit',
  view: 'view'
};

class PackageDrawerViewModel {
  @observable package = null;
  @observable type = TYPES.create;
  @observable isEdit = false;
  @observable isAwait = false;

  form = null;

  drawerVM = new ActionDrawerViewModel();
  deleteModalVM = new ActionModalViewModel();

  created = () => {};
  changed = () => {};
  deleted = () => {};

  @computed
  get check() {
    const create = this.type === TYPES.create;
    const edit = this.type === TYPES.edit;

    return {
      form: create || (edit && this.isEdit),
      footer: this.type !== TYPES.view
    };
  }

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      footer: this.isAwait
    };
  }

  @computed
  get header() {
    switch (this.type) {
      case TYPES.edit:
        return this.isEdit
          ? i18n.t('package_drawer_edit_title')
          : i18n.t('package_drawer_title');

      case TYPES.create:
        return i18n.t('package_drawer_create_title');

      default:
        return i18n.t('package_drawer_title');
    }
  }

  @computed
  get labels() {
    return {
      name: this.package?.name || '',
      type: this.package?.type ? PACKAGE_TYPES[this.package.type].label : '',
      price: this.package?.price ? `US$${formatPrice(this.package.price)}` : '',
      description: this.package?.description || ''
    };
  }

  @computed
  get rules() {
    const required = {
      required: true,
      message: FORM_INPUT_REQUIRED_MESSAGE
    };

    return [required];
  }

  @computed
  get options() {
    return {
      types: Object.values(PACKAGE_TYPES)
    };
  }

  @computed
  get footer() {
    switch (this.type) {
      case TYPES.edit:
        return {
          delete: !this.isEdit,
          edit: !this.isEdit,
          cancel: this.isEdit,
          save: this.isEdit,
          create: false
        };

      case TYPES.view:
        return {
          delete: false,
          cancel: false,
          edit: false,
          save: false,
          create: false
        };

      default:
        return {
          delete: false,
          cancel: false,
          edit: false,
          save: false,
          create: true
        };
    }
  }

  constructor(props) {
    makeObservable(this);
  }

  init = (props) => {
    this.form = props.form;
  };

  @action
  openCreate = ({ created = () => {} }) => {
    this.type = TYPES.create;

    this.created = created;

    this.drawerVM.open();
  };

  @action
  openEdit = ({ data, changed = () => {}, deleted = () => {} }) => {
    this.type = TYPES.edit;

    this.package = data;

    this.changed = changed;
    this.deleted = deleted;

    this.drawerVM.open();
  };

  @action
  openView = (data) => {
    this.type = TYPES.view;

    this.package = data;

    this.drawerVM.open();
  };

  @action
  close = () => {
    this.isEdit = false;

    this.form.resetFields();

    this.drawerVM.close();
  };

  @action
  onDelete = () => {
    this.deletePackageAPI();
  };

  @action
  onEdit = () => {
    this.form.setFieldsValue({
      name: this.package.name,
      type: this.package.type,
      price: this.package.price,
      description: this.package.description
    });

    this.isEdit = true;
  };

  @action
  onCancel = () => {
    this.isEdit = false;
  };

  @action
  onSave = () => {
    this.putPackageAPI();
  };

  @action
  onCreate = () => {
    this.postPackageCreateAPI();
  };

  @action
  postPackageCreateAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();
      await PackageServices.postPackageCreate({
        name: data.name,
        type: data.type,
        price: data.price,
        description: data.description
      });

      await this.created();

      this.close();
    } catch (error) {
      const msg = ErrorServices.postPackageCreate(error);

      console.log('postPackageCreate', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  putPackageAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();

      await PackageServices.putPackage({
        id: this.package.id,
        name: data.name,
        type: data.type,
        price: data.price,
        description: data.description
      });

      // 更外部資料.
      await this.changed(data);

      // 關閉彈窗.
      this.close();
    } catch (error) {
      const msg = ErrorServices.putPackage(error);

      console.log('putPackage', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  deletePackageAPI = async () => {
    this.isAwait = true;

    try {
      await PackageServices.deletePackage({
        id: this.package.id
      });

      await this.deleted();

      this.close();
    } catch (error) {
      const msg = ErrorServices.deletePackage(error);

      console.log('deletePackage', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default PackageDrawerViewModel;
