class GeocodingDataModel {
  constructor(props) {
    // const data = {
    //   results: [
    //     {
    //       address_components: [
    //         {
    //           long_name: 'Autauga County',
    //           short_name: 'Autauga County',
    //           types: [
    //             'administrative_area_level_2',
    //             'political'
    //           ]
    //         },
    //         {
    //           long_name: 'Alabama',
    //           short_name: 'AL',
    //           types: [
    //             'administrative_area_level_1',
    //             'political'
    //           ]
    //         },
    //         {
    //           long_name: 'United States',
    //           short_name: 'US',
    //           types: [
    //             'country',
    //             'political'
    //           ]
    //         }
    //       ],
    //       formatted_address: 'Autauga County, AL, USA',
    //       geometry: {
    //         bounds: {
    //           northeast: {
    //             lat: 32.7082131,
    //             lng: -86.41117199999999
    //           },
    //           southwest: {
    //             lat: 32.307574,
    //             lng: -86.9212369
    //           }
    //         },
    //         location: {
    //           lat: 32.5791817,
    //           lng: -86.49965460000001
    //         },
    //         location_type: 'APPROXIMATE',
    //         viewport: {
    //           northeast: {
    //             lat: 32.7082131,
    //             lng: -86.41117199999999
    //           },
    //           southwest: {
    //             lat: 32.307574,
    //             lng: -86.9212369
    //           }
    //         }
    //       },
    //       place_id: 'ChIJg9z7ewWPjogRA_8QrB0va7o',
    //       types: [
    //         'administrative_area_level_2',
    //         'political'
    //       ]
    //     }
    //   ],
    //   status: 'OK'
    // };

    const data = props?.results[0];

    // 地址 id.
    this.placeId = data.place_id;

    // 定位.
    this.location = {
      lat: data?.geometry?.location?.lat,
      lng: data?.geometry?.location?.lng
    };
  }
}

export default GeocodingDataModel;
