import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ICONS from 'src/constants/icons';
import logo from 'src/assets/backstage_logo.png';
import ResetButton from 'src/components/ResetButton';
import ProfileDrawer from 'src/components/ProfileDrawer';
import ProfileDrawerViewModel from 'src/components/ProfileDrawer/viewModel';
import MenuViewModel from '../Menu/viewModel';
import styles from './styles.module.scss';
import Menu from '../Menu';

@observer
class MainHeader extends React.Component {
  profileDrawerVM = new ProfileDrawerViewModel();
  menuVM = new MenuViewModel();

  render() {
    return (
      <>
        <div className={styles.container}>
          <img src={logo} alt="logo" className={styles.logo} />

          {this.props.menu && (
            <div className={styles.buttons}>
              <ResetButton
                className={styles.button}
                type="text"
                onClick={this.profileDrawerVM.open}
              >
                <img
                  className={styles.icon}
                  src={ICONS.profile}
                  alt="profile"
                />
              </ResetButton>

              <ResetButton
                className={styles.button}
                type="text"
                onClick={this.menuVM.open}
              >
                <img className={styles.icon} src={ICONS.menu} alt="profile" />
              </ResetButton>
            </div>
          )}
        </div>

        {this.props.menu && (
          <>
            <ProfileDrawer vm={this.profileDrawerVM} />

            <Menu vm={this.menuVM} />
          </>
        )}
      </>
    );
  }
}

MainHeader.propTypes = {
  menu: PropTypes.bool
};

MainHeader.defaultProps = {
  menu: false
};

export default MainHeader;
