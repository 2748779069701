import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import ResetButton from '../ResetButton';

// const items = [
//   {
//     icon: 'string',
//     label: 'string',
//     buttonProps: {},
//   }
// ];

@observer
class CustomPopover extends React.Component {
  renderContent = () => (
    <div className={styles.content}>
      {
        this.props.items.map((item) => {
          const { icon, label = '', ...ps } = item;
          const checkIcon = !!icon;

          return (
            <ResetButton
              className={styles.itemButton}
              type="text"
              key={label}
              {...ps}
            >
              {checkIcon && <img src={icon} alt="item" />}

              <div className={styles.label}>
                {label}
              </div>
            </ResetButton>
          );
        })
      }
    </div>
  );

  render() {
    const { items, content, ...ps } = this.props;

    return (
      <Popover {...ps} content={content || this.renderContent()}>
        <div>{this.props.children}</div>
      </Popover>
    );
  }
}

CustomPopover.propTypes = {
  trigger: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  items: PropTypes.array,
  content: PropTypes.node
};

CustomPopover.defaultProps = {
  trigger: 'click',
  placement: 'bottomLeft',
  children: null,
  items: [],
  content: null
};

export default CustomPopover;
