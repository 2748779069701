import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ICONS from 'src/constants/icons';
import styles from './styles.module.scss';

export const COUNTRIES = {
  usa: {
    value: 'usa',
    code: '1',
    icon: ICONS.usa
  }
};

@observer
class CountryCode extends React.Component {
  get icon() {
    return COUNTRIES[this.props.country]?.icon || '';
  }

  get code() {
    if (this.props.country && COUNTRIES[this.props.country]) {
      const country = COUNTRIES[this.props.country];

      return `+${country.code}`;
    }

    return '';
  }

  render() {
    return (
      <div className={styles.container}>
        <img className={styles.icon} src={this.icon} alt="country" />
        <div className={styles.code}>{this.code}</div>
      </div>
    );
  }
}

CountryCode.propTypes = {
  country: PropTypes.string
};

CountryCode.defaultProps = {
  country: COUNTRIES.usa.value
};

export default CountryCode;
