import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import ErrorServices from 'src/services/ErrorServices';
import UserService from 'src/services/UserService';

class AlertModalViewModel {
  @observable isAwait = false;

  modalVM = new ActionModalViewModel();

  context = null;

  changed = () => {};

  @computed
  get disabled() {
    return {
      confirm: this.isAwait
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  didMount = (context) => {
    this.context = context;
  };

  open = ({ changed = () => {} }) => {
    this.changed = changed;
    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  onConfirm = () => {
    this.putUserProfile();
  }

  @action
  putUserProfile = async () => {
    this.isAwait = true;

    const formData = new FormData();
    const autoAccept = true;
    const sendMessage = false;

    const profile = {
      shouldAutoAcceptReservations: autoAccept,
      shouldSendNewReservationMessage: sendMessage
    };

    formData.append('shouldAutoAcceptReservations', autoAccept);
    formData.append('shouldSendNewReservationMessage', sendMessage);

    try {
      await UserService.putUserProfile(formData);

      this.context.actions.updateProfile(profile);

      this.changed(profile);

      this.close();
    } catch (error) {
      const msg = ErrorServices.putUserProfile(error);

      console.log('putUserProfile', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default AlertModalViewModel;
