import dayjs from 'dayjs';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import i18n from 'src/i18n';
import { formatPrice } from 'src/constants/price';
import { RentalCalc, RENTAL_STATUS } from 'src/constants/rental/rental';
import { assemble } from 'src/constants/methods';
import ContactDrawerViewModel from 'src/components/ContactDrawer/viewModel';
import RentalItemViewModel from './viewModels/RentalItemViewModel';
import RentalStatusHistoriesDrawerViewModel from './components/RentalStatusHistoriesDrawer/viewModel';
import RentalPriceDrawerViewModel from './components/RentalPriceDrawer/viewModel';

class RentalDetailViewModel {
  @observable booking = null;
  @observable rental = null;

  changed = () => {};

  statusHistoriesDrawerVM = new RentalStatusHistoriesDrawerViewModel();
  contactDrawerVM = new ContactDrawerViewModel();
  rentalPriceDrawerVM = new RentalPriceDrawerViewModel();

  @computed
  get check() {
    return {
      earning: RentalCalc.checkShowRentalEarning({
        status: this.rental.status
      })
    };
  }

  @computed
  get land() {
    return this.rental.land;
  }

  @computed
  get id() {
    return this.rental.id;
  }

  @computed
  get createAt() {
    return dayjs(this.rental.createAt).format('MMM D, YYYY');
  }

  @computed
  get duration() {
    const format = 'MMM D, YYYY';
    const d = RentalCalc.duration({
      startAt: this.rental.startAt,
      endAt: this.rental.endAt,
      timezoneId: this.land.timezoneId
    });
    const unit = i18n.t('booking_detail_drawer_rental_duration_unit');
    const startAt = dayjs(this.rental.startAt)
      .tz(this.land.timezoneId)
      .format(format);
    const endAt = dayjs(this.rental.endAt)
      .tz(this.land.timezoneId)
      .format(format);

    return `(${d} ${unit}) ${startAt} - ${endAt}`;
  }

  @computed
  get rentalStatus() {
    return RENTAL_STATUS[this.rental.status].label;
  }

  @computed
  get consumer() {
    const firstName = this.rental.consumer.firstName;
    const lastName = this.rental.consumer.lastName;

    return assemble.name({ firstName, lastName });
  }

  @computed
  get price() {
    const fee = RentalCalc.earning({
      copies: this.rental.copies,
      profitRatio: this.rental.profitRatio,
      startAt: this.rental.startAt,
      endAt: this.rental.endAt,
      timezoneId: this.rental.timezoneId
    });

    console.log(this.rental);

    return formatPrice(fee, true);
  }

  @computed
  get shippings() {
    const address = assemble.address({
      country: this.land.country,
      state: this.land.state,
      county: this.land.county,
      street: this.land.street,
      postcode: this.land.postcode
    });

    return [
      {
        id: this.land.id,
        address
      }
    ];
  }

  @computed
  get items() {
    return this.rental.copies.map(
      (copy) =>
        new RentalItemViewModel({
          item: copy.item,
          itemVariety: copy.itemVariety,
          rental: this.rental
        })
    );
  }

  constructor(props) {
    this.booking = props.booking;
    this.rental = props.rental;
    this.changed = props.changed;

    makeObservable(this);
  }

  onStatus = () => {
    this.statusHistoriesDrawerVM.open(this.rental);
  };

  onContact = () => {
    this.contactDrawerVM.open(this.rental.consumer);
  };

  onRentalPrice = () => {
    this.rentalPriceDrawerVM.open(this.rental);
  };
}

export default RentalDetailViewModel;
