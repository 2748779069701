import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { LAND_MESSAGE_TYPES } from 'src/constants/land';
import { assemble } from 'src/constants/methods';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import ReplyModalViewModel from '../ReplyModal/viewModel';

class MessageDrawerViewModel {
  @observable profile = null;
  @observable type = null;
  @observable message = null;

  drawerVM = new ActionDrawerViewModel();
  replyModalVM = new ReplyModalViewModel();

  changed = () => {};

  @computed
  get check() {
    return {
      message: !!this.message,
      qa: this.type === LAND_MESSAGE_TYPES.qas,
      rating: this.type === LAND_MESSAGE_TYPES.ratings,
      reply: !!this.message?.reply?.trim()
    };
  }

  @computed
  get score() {
    return this.message.score || 0;
  }

  @computed
  get user() {
    return {
      name: assemble.name({
        firstName: this.message?.user.firstName,
        lastName: this.message?.user.lastName
      }),
      date: dayjs(this.message.createAt).format('YYYY/MM/DD'),
      content: this.message.message
    };
  }

  @computed
  get owner() {
    return {
      name: assemble.name({
        firstName: this.profile?.firstName,
        lastName: this.profile?.lastName
      }),
      date: dayjs(this.message.answerAt).format('YYYY/MM/DD'),
      content: this.message.reply
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.type = props.type;
  };

  @action
  didMount = (context) => {
    this.profile = context.state.profile;
  };

  @action
  open = ({ data, changed }) => {
    this.message = data;
    this.changed = changed;

    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  onReply = () => {
    this.replyModalVM.open({ message: this.message, changed: this.update });
  };

  @action
  update = async (data) => {
    this.message = { ...this.message, ...data };
    this.changed(this.message);
  };
}

export default MessageDrawerViewModel;
