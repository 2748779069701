import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';
import withProfile from 'src/components/withProfile';
import MainHeader from './components/MainHeader';
import styles from './styles.module.scss';


@observer
class MainLayout extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <MainHeader menu />

        <div className={styles.content}>
          <Outlet context={[this.props.profile]}>
            {this.props.children}
          </Outlet>
        </div>
      </div>
    );
  }
}

MainLayout.propTypes = {
  children: PropTypes.node,
  profile: PropTypes.object.isRequired
};

MainLayout.defaultProps = {
  children: null
};

export default withProfile(MainLayout, true);
