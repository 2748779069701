import optionStore from 'src/global/optionStore';

class BookingListItemDataModel {
  constructor(data) {
    this.id = data.id;
    this.status = data.status;

    // 爭議時間.
    this.disputedAt = data.disputedAt;
    // 是否有爭議.
    this.isDisputed = !!data.disputedAt;

    // 行程的開始日期.
    this.startAt = data.startAt;
    // 行程結束日期.
    this.endAt = data.endAt;
    // 訂單完成日期.
    this.completedAt = data.completedAt;
    // 訂單建立日期.
    this.createdAt = data.createdAt;
    // 訂單更新日期.
    this.updatedAt = data.updatedAt;

    // 預約的獵人.
    this.consumer = {
      firstName: data.consumer.firstName,
      lastName: data.consumer.lastName,
      displayName: data.consumer.displayName,
      // 獵人的身分證狀態.
      idCardStatus: data.consumer.idCardStatus,
      // 獵人的獵人執照狀態
      huntingLicenseStatus: data.consumer.huntingLicenseStatus,
      phone: data.consumer.phone,
      email: data.consumer.email
    };

    // 預約的聯絡人
    this.contact = {
      firstName: data.contact.firstName,
      lastName: data.contact.lastName,
      phone: data.contact.phone,
      email: data.contact.email
    };

    // 預約的獵場.
    const state = optionStore.states[data.land.state];
    const county = state?.counties?.[data.land.county] || '';
    this.land = {
      id: data.land.id,
      name: data.land.name,
      // 獵場所在國家.
      country: data.land.country,
      // 地區.
      state: state?.name || '',
      // 城市.
      county,
      // 街道.
      street: data.land.street,
      // 郵遞區號.
      postcode: data.land.postcode
    };

    // 預約被拒絕的原因.
    this.cancelReason = data.cancelReason;
  }
}

export default BookingListItemDataModel;
