import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';

class DescriptionViewModel {
  @observable land = null;
  @observable description = '';

  form = null;

  rules = [
    {
      required: true,
      message: FORM_INPUT_REQUIRED_MESSAGE,
      validator: (_, value) =>
        value.trim() ? Promise.resolve() : Promise.reject()
    }
  ];

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = (props) => {
    const description = props.land.description || this.description || '';

    this.land = props.land;
    this.form = props.form;

    this.description = description;

    this.form.setFieldsValue({ description });
  };

  @action
  temporaryStorage = () => {
    const res = this.form.getFieldsValue(true);
    this.description = res.description || '';
  }

  @action
  resetFields = () => {
    this.description = '';
  }

  validateFields = async () => {
    const res = await this.form.validateFields();
    return res;
  };
}

export default DescriptionViewModel;
