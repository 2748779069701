import PagesRoot from 'src/pages/index';
import { AppStateProvider } from 'src/stores';
import { ConfigProvider } from 'antd';
import { THEME } from 'src/constants/theme';
import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.app}>
      <AppStateProvider>
        <ConfigProvider theme={THEME}>
          <PagesRoot />
        </ConfigProvider>
      </AppStateProvider>
    </div>
  );
}

export default App;
