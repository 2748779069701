import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ResetButton from 'src/components/ResetButton';
import editIcon from 'src/assets/icon_profile_card_edit.svg';
import styles from './styles.module.scss';

@observer
class ProfileCard extends React.Component {
  render() {
    return this.props.edit ? (
      <ResetButton
        className={styles.container}
        type="text"
        onClick={this.props.onClick}
      >
        <div className={styles.label}>{this.props.label}</div>
        <div className={styles.content}>{this.props.content}</div>
        <div className={styles.edit}>
          <span>Edit</span>
          <img className={styles.icon} src={editIcon} alt="edit" />
        </div>
      </ResetButton>
    ) : (
      <div className={styles.container} type="text">
        <div className={styles.label}>{this.props.label}</div>
        <div className={styles.content}>{this.props.content}</div>
      </div>
    );
  }
}

ProfileCard.propTypes = {
  label: PropTypes.string,
  content: PropTypes.string,
  edit: PropTypes.bool,
  onClick: PropTypes.func
};

ProfileCard.defaultProps = {
  label: 'Label',
  content: 'Content',
  edit: false,
  onClick: () => {}
};

export default ProfileCard;
