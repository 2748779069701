import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import withRouter from 'src/components/withRouter';
import i18n from 'src/i18n';
import { LAND_STATUS } from 'src/constants/land';
import ResetButton from 'src/components/ResetButton';
import BasicCard from 'src/components/BasicCard';
import LinkButton from 'src/components/LinkButton';
import LaunchLand from 'src/components/LaunchLand';
import CreateLandDrawer from '../CreateLandDrawer';
import LandDetailDrawer from '../LandDetailDrawer';
import LandMessagesDrawer from '../LandMessagesDrawer';
import DeleteLandModal from '../DeleteLandModal';
import styles from './styles.module.scss';

@observer
class LandCard extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ router: props.router, deleted: props.deleted });
  }

  renderFooter = () => {
    switch (this.vm.status.value) {
      case LAND_STATUS.unlisted.value:
        return (
          <>
            <ResetButton
              className={styles.button}
              onClick={this.vm.onDetail}
              disabled={this.vm.disabled.footer}
            >
              {i18n.t('lands_page_land_card_detail_button_label')}
            </ResetButton>

            <ResetButton
              className={styles.button}
              type="primary"
              onClick={this.vm.onListed}
              disabled={this.vm.disabled.footer}
            >
              {i18n.t('lands_page_land_card_list_button_label')}
            </ResetButton>
          </>
        );

      case LAND_STATUS.suspension.value:
        return (
          <>
            <ResetButton
              className={styles.button}
              onClick={this.vm.onDetail}
              disabled={this.vm.disabled.footer}
            >
              {i18n.t('lands_page_land_card_detail_button_label')}
            </ResetButton>

            <ResetButton
              className={styles.button}
              onClick={this.vm.onAppeal}
              disabled={this.vm.disabled.footer}
            >
              {i18n.t('lands_page_land_card_appeal_button_label')}
            </ResetButton>
          </>
        );

      case LAND_STATUS.draft.value:
        return (
          <ResetButton
            className={styles.button}
            onClick={this.vm.onDraftEdit}
            disabled={this.vm.disabled.footer}
          >
            {i18n.t('lands_page_land_card_edit_button_label')}
          </ResetButton>
        );

      default:
        return (
          <ResetButton className={styles.button} onClick={this.vm.onDetail}>
            {i18n.t('lands_page_land_card_detail_button_label')}
          </ResetButton>
        );
    }
  };

  render() {
    return (
      <>
        <div className={styles.mainContainer}>
          <div className={styles.name}>{this.vm.name}</div>

          <BasicCard className={styles.container}>
            <div className={styles.header}>
              <div className={clsx([styles.status, this.vm.status.style])}>
                <img
                  className={styles.icon}
                  src={this.vm.status.icon}
                  alt="status"
                />

                <span className={styles.text}>{this.vm.status.label}</span>
              </div>

              <div className={styles.state}>{this.vm.state}</div>
            </div>

            <div className={styles.divider} />

            <div className={styles.content}>
              <div className={styles.photo}>
                {this.vm.check.photo && (
                  <img
                    className={styles.img}
                    src={this.vm.photo}
                    alt="photo1"
                  />
                )}
              </div>

              <div className={styles.counts}>
                <div className={styles.item}>
                  <div className={styles.label}>
                    {i18n.t('lands_page_land_card_booking_count_label')}
                  </div>

                  {this.vm.check.bookingCount ? (
                    <LinkButton onClick={this.vm.onToBookingPage}>
                      {this.vm.bookingCount}
                    </LinkButton>
                  ) : (
                    <div className={styles.value}>{this.vm.bookingCount}</div>
                  )}
                </div>

                <div className={styles.item}>
                  <div className={styles.label}>
                    {i18n.t('lands_page_land_card_qa_count_label')}
                  </div>

                  {this.vm.check.qaCount ? (
                    <LinkButton onClick={this.vm.onQas}>
                      {this.vm.qa.count}
                    </LinkButton>
                  ) : (
                    <div className={styles.value}>{this.vm.qa.count}</div>
                  )}
                </div>

                <div className={styles.item}>
                  <div className={styles.label}>
                    {i18n.t('lands_page_land_card_rating_count_label')}
                  </div>

                  {this.vm.check.ratingCount ? (
                    <LinkButton onClick={this.vm.onRatings}>
                      {this.vm.rating.count}
                    </LinkButton>
                  ) : (
                    <div className={styles.value}>{this.vm.rating.count}</div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.footer}>
              {this.vm.check.delete && (
                <ResetButton
                  className={styles.button}
                  danger
                  ghost
                  onClick={this.vm.onDelete}
                >
                  {i18n.t('lands_page_land_card_delete_button_label')}
                </ResetButton>
              )}

              {this.renderFooter()}
            </div>
          </BasicCard>
        </div>

        <CreateLandDrawer vm={this.vm.createLandDrawerVM} />

        <LandDetailDrawer vm={this.vm.landDetailDrawerVM} />

        <LandMessagesDrawer vm={this.vm.messagesDrawerVM} />

        <DeleteLandModal vm={this.vm.deleteLandModalVM} />

        <LaunchLand vm={this.vm.launchLandVM} />
      </>
    );
  }
}

LandCard.propTypes = {
  vm: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  deleted: PropTypes.func
};

LandCard.defaultProps = {
  deleted: () => {}
};

export default withRouter(LandCard);
