import React from 'react';
import i18n from 'src/i18n';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import QrCodeModal from '../qrcode';
import styles from './styles.module.scss';

class PopularLand extends React.Component {
  modalVM = new ActionModalViewModel()

  render() {
    return (
      <>
        <div className={styles.block21}>
          <div style={{ paddingTop: 64, alignItems: 'center' }}>
            <h1 className={styles.hero_title}>
              {i18n.t('find_hunt_page_lands_title')}
            </h1>
          </div>

          <div className={styles.land_box}>
            <div className={styles.land_image_box}>
              <img
                src={require('src/assets/findHunt/land_thumbnail1.jpg')}
                alt=""
                className={styles.land_image_layout}
                onClick={this.modalVM.open}
              />
            </div>

            <div className={styles.land_image_box}>
              <img
                src={require('src/assets/findHunt/land_thumbnail2.jpg')}
                alt=""
                className={styles.land_image_layout}
                onClick={this.modalVM.open}
              />
            </div>

            <div className={styles.land_image_box}>
              <img
                src={require('src/assets/findHunt/land_thumbnail3.jpg')}
                alt=""
                className={styles.land_image_layout}
                onClick={this.modalVM.open}
              />
            </div>
          </div>
        </div>

        <QrCodeModal vm={this.modalVM} />
      </>
    );
  }
}

export default PopularLand;
