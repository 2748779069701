import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { diff } from 'deep-diff';
import i18n from 'src/i18n';
import { LAND_STATUS, getLandStatusStyle } from 'src/constants/land';
import { assemble } from 'src/constants/methods';
import LandEditDrawerViewModel, { PAGES } from '../LandEditDrawer/viewModel';
import LandMeetDrawerViewModel from '../LandMeetDrawer/viewModel';

const SHOW_PHOTOS_LENGTH = 3;

class LandDetailViewModel {
  @observable land = null;

  landEditDrawerVM = new LandEditDrawerViewModel();
  landMeetDrawerVM = new LandMeetDrawerViewModel()

  changed = async () => {};

  @computed
  get status() {
    const status = LAND_STATUS[this.land.status];

    return {
      value: status.value,
      label: status.label,
      style: getLandStatusStyle(status.value)
    };
  }

  @computed
  get photos() {
    const leftover = this.land.photos.length - SHOW_PHOTOS_LENGTH + 1;

    return {
      leftover: `+${leftover}`,
      srcs: this.land.photos.slice(0, SHOW_PHOTOS_LENGTH).map((item) => {
        return {
          id: item.id,
          src: item.src
        };
      })
    };
  }

  @computed
  get name() {
    return this.land.name;
  }

  @computed
  get acres() {
    return this.land.acres;
  }

  @computed
  get species() {
    const label = i18n.t('land_detail_drawer_detail_page_species_label');

    return {
      label: `${this.land.species.length} ${label}`
    };
  }

  @computed
  get description() {
    return this.land.description;
  }

  @computed
  get address() {
    return assemble.address({
      country: this.land.county,
      state: this.land.state,
      county: this.land.county,
      street: this.land.street,
      postcode: this.land.postcode
    });
  }

  @computed
  get meet() {
    if (this.land.meetState) {
      return assemble.meetAddress({
        postcode: this.land.meetPostcode,
        street: this.land.meetStreet,
        city: this.land.meetCity,
        state: this.land.meetState
      });
    }

    return i18n.t('land_detail_drawer_null_item_placeholder');
  }

  @computed
  get rules() {
    return (
      this.land.rules || i18n.t('land_detail_drawer_null_item_placeholder')
    );
  }

  @computed
  get finalBookingDate() {
    const label = i18n.t('land_detail_drawer_detail_page_final_booking_date_label_2');
    const date = this.land.finalBookingDate ? dayjs(this.land.finalBookingDate).tz(this.land.timezoneId).format('MMM DD, YYYY') : '';
    const timezoneOffset = this.land.timezoneOffset.split(':')[0];


    return this.land.finalBookingDate
      ? `${label} ${date} (UTC${timezoneOffset})`
      : i18n.t('land_detail_drawer_null_item_placeholder');
  }

  constructor(props) {
    this.land = props.land;
    this.changed = props.changed;

    makeObservable(this);
  }

  @action
  didUpdate = (props, preProps) => {
    const diffLand = diff(props.land, preProps.land);

    if (diffLand) {
      this.land = props.land;
    }
  };

  onStatus = () => {
    this.landEditDrawerVM.toPage(PAGES.status);
    this.landEditDrawerVM.open({ land: this.land, changed: this.changed });
  };

  onPhotos = () => {
    this.landEditDrawerVM.toPage(PAGES.photos);
    this.landEditDrawerVM.open({ land: this.land, changed: this.changed });
  };

  onBasicInfo = () => {
    this.landEditDrawerVM.toPage(PAGES.basicInfo);
    this.landEditDrawerVM.open({ land: this.land, changed: this.changed });
  };

  onDescription = () => {
    this.landEditDrawerVM.toPage(PAGES.description);
    this.landEditDrawerVM.open({ land: this.land, changed: this.changed });
  };

  onPosition = () => {
    this.landEditDrawerVM.toPage(PAGES.position);
    this.landEditDrawerVM.open({ land: this.land, changed: this.changed });
  };

  onMeet = () => {
    this.landMeetDrawerVM.open({ land: this.land, changed: this.changed });
  };

  onRules = () => {
    this.landEditDrawerVM.toPage(PAGES.rules);
    this.landEditDrawerVM.open({ land: this.land, changed: this.changed });
  };

  onFinalBookingDate = () => {
    this.landEditDrawerVM.toPage(PAGES.finalBookingDate);
    this.landEditDrawerVM.open({ land: this.land, changed: this.changed });
  };
}

export default LandDetailViewModel;
