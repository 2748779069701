import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import optionStore from 'src/global/optionStore';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';

class BasicInfoViewModel {
  @observable land = null;

  @observable name = null;
  @observable acres = null;
  @observable species = [];

  form = null;

  @computed
  get options() {
    const species = optionStore.infos?.species || {};

    return {
      species: Object.keys(species).map((key) => {
        return {
          label: species[key].name,
          value: key
        };
      })
    };
  }

  get rules() {
    const required = {
      required: true,
      message: FORM_INPUT_REQUIRED_MESSAGE
    };

    return {
      name: [required],
      acres: [required],
      species: [required]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.land = props.land;
    this.form = props.form;
  };

  didMount = () => {
    const checkAcres = !!this.land.acres;
    const checkSpecies = !!this.land.species?.length;

    const name = this.land.name || this.name || null;
    const acres = checkAcres ? String(this.land.acres) : (this.acres || null);
    const species = checkSpecies
      ? this.land.species.map((item) => item.id)
      : this.species;

    this.form.setFieldsValue({
      name,
      acres,
      species
    });
  };

  @action
  temporaryStorage = () => {
    const res = this.form.getFieldsValue(true);

    this.name = res.name || null;
    this.acres = res.acres || null;
    this.species = res.species || [];
  };

  @action
  resetFields = () => {
    this.name = null;
    this.acres = null;
    this.species = [];
  };

  validateFields = async () => {
    const res = await this.form.validateFields();
    return { ...res, species: res.species || [] };
  };
}

export default BasicInfoViewModel;
