import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import { BUSINESS_DAY_KEY_FORMAT } from 'src/constants/calendar';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import CalendarServices from 'src/services/CalendarServices';
import ErrorServices from 'src/services/ErrorServices';

class CalendarBatchTagModalViewModel {
  @observable land = false;
  @observable isAwait = false;

  changed = async () => {};

  form = null;

  modalVM = new ActionModalViewModel();

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      footer: this.isAwait
    };
  }

  @computed
  get rules() {
    return {
      required: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form }) => {
    this.form = form;
  };

  @action
  open = ({ land, changed = async () => {} }) => {
    this.land = land;
    this.changed = changed;

    this.form.resetFields();

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  @action
  onDelete = async () => {
    this.isAwait = true;

    await this.deleteTagAPI();
    await this.changed();

    runInAction(() => {
      this.isAwait = false;
      this.close();
    });
  };

  @action
  onAdd = async () => {
    this.isAwait = true;

    await this.deleteTagAPI();
    await this.postCalendarLandTagsAPI();
    await this.changed();

    runInAction(() => {
      this.isAwait = false;
      this.close();
    });
  };

  @action
  postCalendarLandTagsAPI = async () => {
    try {
      const data = await this.form.validateFields();
      const startAt = data.dates[0].format(BUSINESS_DAY_KEY_FORMAT);
      const endAt = data.dates[1].format(BUSINESS_DAY_KEY_FORMAT);

      await CalendarServices.postCalendarLandTags({
        id: this.land.id,
        startAt,
        endAt,
        tags: [data.tag]
      });
    } catch (error) {
      const msg = ErrorServices.postCalendarLandTags(error);
      console.log('postCalendarLandTags', msg);
    }
  };

  @action
  deleteTagAPI = async () => {
    try {
      const data = await this.form.validateFields();
      const startAt = data.dates[0].format(BUSINESS_DAY_KEY_FORMAT);
      const endAt = data.dates[1].format(BUSINESS_DAY_KEY_FORMAT);

      await CalendarServices.deleteCalendarLandTags({
        id: this.land.id,
        startAt,
        endAt,
        tags: [data.tag]
      });
    } catch (error) {
      const msg = ErrorServices.deleteCalendarLandTags(error);
      console.log('deleteCalendarLandTags', msg);
    }
  }
}

export default CalendarBatchTagModalViewModel;
