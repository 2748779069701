import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';

function ContinueButton(props) {
  return (
    <div className={styles.container}>
      <Button
        type="text"
        style={{ borderRadius: props.borderRadius }}
        className={clsx([
          styles.button,
          props.disabled && styles.disabled
        ])}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.text}
      </Button>
    </div>
  );
}

ContinueButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  borderRadius: PropTypes.number
};

ContinueButton.defaultProps = {
  text: '按鈕',
  onClick: () => {},
  disabled: false,
  borderRadius: 5
};

export default ContinueButton;
