import i18n from 'src/i18n';

export const PACKAGE_TYPES = {
  daily: {
    value: 'daily',
    label: i18n.t('package_type_daily')
  },
  weekly: {
    value: 'weekly',
    label: i18n.t('package_type_weekly')
  },
  monthly: {
    value: 'monthly',
    label: i18n.t('package_type_monthly')
  },
  perPackage: {
    value: 'perPackage',
    label: i18n.t('package_type_per_package')
  },
  perPerson: {
    value: 'perPerson',
    label: i18n.t('package_type_per_person')
  },
  allInclusive: {
    value: 'allInclusive',
    label: i18n.t('package_type_all_inclusive')
  }
};
