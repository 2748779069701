import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import i18n from 'src/i18n';
import ICONS from 'src/constants/icons';
import withRouter from 'src/components/withRouter';
import ResetButton from 'src/components/ResetButton';
import ResetTable from 'src/components/ResetTable';
import LinkButton from 'src/components/LinkButton';
import PackageDrawer from 'src/components/PackageDrawer';
import LandAddPackageModal from '../LandAddPackageModal';
import LandPackagesViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class LandPackages extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new LandPackagesViewModel({
      land: props.land,
      changed: props.changed,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.inited ? (
      <>
        <div className={styles.container}>
          <div className={styles.description}>
            {i18n.t('land_detail_drawer_packages_page_description')}
          </div>

          {this.vm.check.packages ? (
            <ResetButton
              className={styles.createButton}
              type="primary"
              onClick={this.vm.onAddPackage}
              disabled={this.vm.disabled.add}
            >
              {i18n.t(
                'land_detail_drawer_packages_page_add_package_button_label'
              )}
            </ResetButton>
          ) : (
            <ResetButton
              className={styles.navigateButton}
              onClick={this.vm.toPackagePage}
            >
              {i18n.t('land_detail_drawer_packages_page_placeholder')}
            </ResetButton>
          )}

          {this.vm.check.packages ? (
            <ResetTable data={this.vm.tablePackages}>
              <Table.Column
                title={i18n.t(
                  'land_detail_drawer_packages_page_table_package_label'
                )}
                render={(item) => (
                  <LinkButton
                    onClick={() => this.vm.onPackage(item)}
                    disabled={this.vm.disabled.delete}
                  >
                    {item.name}
                  </LinkButton>
                )}
              />

              <Table.Column
                width={100}
                title={i18n.t(
                  'land_detail_drawer_packages_page_table_package_label'
                )}
                align="center"
                render={(item) => (
                  <ResetButton
                    type="text"
                    onClick={() => this.vm.onDelete(item)}
                    disabled={this.vm.disabled.delete}
                  >
                    <img src={ICONS.delete} alt="delete" />
                  </ResetButton>
                )}
              />
            </ResetTable>
          ) : null }
        </div>

        <LandAddPackageModal vm={this.vm.addPackageModalVM} />

        <PackageDrawer vm={this.vm.packageDrawerVM} />
      </>
    ) : null;
  }
}

LandPackages.propTypes = {
  land: PropTypes.object.isRequired,
  changed: PropTypes.func,
  router: PropTypes.object.isRequired
};

LandPackages.defaultProps = {
  changed: () => {}
};

export default withRouter(LandPackages);
