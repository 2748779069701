import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { uuidv4 } from 'src/utils';
import { ROUTES, PARAMS } from 'src/constants/routes';
import {
  PAYOUT_TYPES,
  PAYOUT_STATUS
} from 'src/constants/payout';
import { routerMethods } from 'src/constants/methods';
import { formatPrice } from 'src/constants/price';
import PayoutServices from 'src/services/PayoutServices';
import ErrorServices from 'src/services/ErrorServices';
import PayoutViewModel from './viewModels/PayoutViewModel';

class BookingPayoutsViewModel {
  @observable paids = []; // 已支付
  @observable payables = []; // 尚未支付
  @observable statistics = {
    paid: { startAt: null, endAt: null, total: '0' },
    payable: { startAt: null, endAt: null, total: '0' }
  };

  @observable inited = false;

  router = null;

  @computed
  get total() {
    return {
      data: [
        {
          id: uuidv4(),
          paid: formatPrice(this.statistics.paid.total, true),
          payable: formatPrice(this.statistics.payable.total, true)
        }
      ]
    };
  }

  @computed
  get payouts() {
    // 排序

    const items = this.paids.concat(this.payables);

    items.sort((a, b) => {
      if (b.formats.timestamp === a.formats.timestamp) {
        return b.id - a.id;
      }

      return b.formats.timestamp - a.formats.timestamp;
    });

    // 新增索引

    const object = {};

    items.forEach((item) => {
      const check = !!object[item.formats.dateKey];

      if (check) {
        object[item.formats.dateKey].unshift(item);
      } else {
        object[item.formats.dateKey] = [item];
      }
    });

    Object.values(object).forEach((payouts) => {
      if (payouts.length > 1) {
        payouts.forEach((payout, i) => payout.setIndex(i + 1));
      }
    });

    return items;
  }

  constructor(props) {
    this.router = props.router;
    makeObservable(this);
  }

  didMount = async () => {
    await Promise.all([
      this.getPayoutPaidAPI(),
      this.getPayoutPayableAPI(),
      this.getPayoutStatisticsAPI()
    ]);

    runInAction(() => {
      this.inited = true;
    });
  };

  onPaid = (payout) => {
    const route = `/${ROUTES.payoutDetails.value}`;
    const search = routerMethods.createSearch({
      [PARAMS.payoutType]: PAYOUT_TYPES.booking,
      [PARAMS.payoutStatus]: PAYOUT_STATUS.paid,
      [PARAMS.payoutId]: payout.id
    });

    this.router.navigate({ pathname: route, search });
  };

  onPayable = (payout) => {
    const route = `/${ROUTES.payoutDetails.value}`;
    const search = routerMethods.createSearch({
      [PARAMS.payoutType]: PAYOUT_TYPES.booking,
      [PARAMS.payoutStatus]: PAYOUT_STATUS.payable,
      [PARAMS.payoutId]: payout.id
    });

    this.router.navigate({ pathname: route, search });
  };

  onPaidAll = () => {
    const route = `/${ROUTES.payoutDetails.value}`;
    const search = routerMethods.createSearch({
      [PARAMS.payoutType]: PAYOUT_TYPES.booking,
      [PARAMS.payoutStatus]: PAYOUT_STATUS.paid
    });

    this.router.navigate({ pathname: route, search });
  };

  onPayableAll = () => {
    const route = `/${ROUTES.payoutDetails.value}`;
    const search = routerMethods.createSearch({
      [PARAMS.payoutType]: PAYOUT_TYPES.booking,
      [PARAMS.payoutStatus]: PAYOUT_STATUS.payable
    });

    this.router.navigate({ pathname: route, search });
  };

  getPayoutPaidAPI = async () => {
    try {
      const res = await PayoutServices.getPayoutPaid({ year: undefined });

      runInAction(() => {
        this.paids = res.data.map((item) => new PayoutViewModel(item));
      });
    } catch (error) {
      const msg = ErrorServices.getPayoutPaid(error);
      console.log('getPayoutPaid', msg);
    }
  };

  getPayoutPayableAPI = async () => {
    try {
      const res = await PayoutServices.getPayoutPayable({ year: undefined });

      runInAction(() => {
        this.payables = res.data.map((item) => new PayoutViewModel(item));
      });
    } catch (error) {
      const msg = ErrorServices.getPayoutPayable(error);
      console.log('getPayoutPayable', msg);
    }
  };

  getPayoutStatisticsAPI = async () => {
    try {
      const res = await PayoutServices.getPayoutStatistics({
        landId: undefined
      });

      runInAction(() => {
        this.statistics = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getPayoutStatistics(error);
      console.log('getPayoutStatistics', msg);
    }
  };
}

export default BookingPayoutsViewModel;
