import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';

class ActionDrawerViewModel {
  @observable isOpen = false;

  constructor() {
    makeObservable(this);
  }

  @action
  open = () => {
    this.isOpen = true;
  };

  @action
  close = () => {
    this.isOpen = false;
  };
}

export default ActionDrawerViewModel;
