import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DatePicker } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class ResetDatePicker extends React.Component {
  render() {
    const { className, ...ps } = this.props;

    return (
      <DatePicker className={clsx([styles.datePicker, className])} {...ps} />
    );
  }
}

ResetDatePicker.propTypes = {
  className: PropTypes.string
};

ResetDatePicker.defaultProps = {
  className: ''
};

export default ResetDatePicker;
