import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import i18n from 'src/i18n';

class PricesViewModel {
  @observable land = null;

  @observable basicFee = null;
  @observable basicHunters = null;
  @observable extraHunters = null;
  @observable extraFee = null;

  // 編輯獵場時需要用到, land edit drawer
  @observable hasChange = false;

  form = null;

  @computed
  get disabled() {
    return {
      change: !this.hasChange,
      extraFee: !this.extraHunters
    };
  }

  get rules() {
    const required = {
      required: true,
      message: FORM_INPUT_REQUIRED_MESSAGE
    };

    return {
      required: [required],
      basicFee: [
        required,
        {
          validator: (_, value) =>
            value === '0' ? Promise.reject() : Promise.resolve(),
          message: i18n.t('create_land_drawer_price_page_no_input_zero')
        }
      ],
      basicHunters: [
        required,
        {
          validator: (_, value) =>
            value === 0 ? Promise.reject() : Promise.resolve(),
          message: i18n.t('create_land_drawer_price_page_no_input_zero')
        }
      ]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.land = props.land;
    this.form = props.form;

    this.hasChange = false;
  };

  @action
  didMount = () => {
    this.hasChange = false;

    const basicFee = this.land.basicFee || this.basicFee || null;
    const extraFee = this.land.extraFee || this.extraFee || null;
    const basicHunters = this.land.basicHunters || this.basicHunters || null;
    const extraHunters = this.land.extraHunters || this.extraHunters || null;

    this.basicFee = basicFee;
    this.extraFee = extraFee;
    this.basicHunters = basicHunters;
    this.extraHunters = extraHunters;

    this.form.setFieldsValue({
      basicFee,
      extraFee,
      basicHunters,
      extraHunters
    });

    console.log('Prices', 'this', this);
  };

  @action
  onExtraHuntersChange = (val) => {
    this.extraHunters = val;
    this.hasChange = true;

    if (val === 0) {
      this.form.setFieldsValue({
        extraFee: '0'
      });
    }
  };

  @action
  onOtherChange = () => {
    this.hasChange = true;
  };

  @action
  temporaryStorage = () => {
    const res = this.form.getFieldsValue(true);

    this.basicFee = res.basicFee;
    this.basicHunters = res.basicHunters;
    this.extraHunters = res.extraHunters;
    this.extraFee = res.extraFee;
  };

  @action
  resetFields = () => {
    this.basicFee = null;
    this.basicHunters = null;
    this.extraHunters = null;
    this.extraFee = null;
  };

  validateFields = async () => {
    const res = await this.form.validateFields();
    return res;
  };
}

export default PricesViewModel;
