import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Waypoint } from 'react-waypoint';
import PageHeader from 'src/components/PageHeader';
import i18n from 'src/i18n';
import ResetSelect from 'src/components/ResetSelect';
import PageContainer from 'src/components/PageContainer';
import PageCardsContainer from 'src/components/PageCardsContainer';
import NoLandLabel from 'src/components/NoLandLabel';
import FilterButton from 'src/components/FilterButton';
import RentalsFilterDrawer from './components/RentalsFilterDrawer';
import RentalCard from './components/RentalCard';
import RentalsPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class RentalsPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new RentalsPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.inited ? (
      <>
        <PageContainer>
          <PageHeader back label={i18n.t('routes_rentals_label')} />

          {this.vm.check.lands ? (
            <>
              <div className={styles.form}>
                <ResetSelect
                  className={styles.landsSelect}
                  placeholder={i18n.t(
                    'rentals_page_filter_land_select_placeholder'
                  )}
                  placeholderSolid
                  options={this.vm.options.lands}
                  value={this.vm.landId}
                  onChange={this.vm.onLandIdChange}
                  allowClear
                  disabled={this.vm.disabled.filter}
                  showSearch
                  optionFilterProp="label"
                />

                <div className={styles.filters}>
                  <FilterButton
                    label={i18n.t('rentals_page_filter_button_label')}
                    onClick={this.vm.onFilter}
                    disabled={this.vm.disabled.filter}
                  />

                  <ResetSelect
                    className={styles.filterStatus}
                    placeholder={i18n.t(
                      'rentals_page_filter_select_placeholder'
                    )}
                    placeholderSolid
                    options={this.vm.options.status}
                    value={this.vm.status}
                    onChange={this.vm.onStatusChange}
                    allowClear
                    disabled={this.vm.disabled.filter}
                    showSearch
                    optionFilterProp="label"
                  />
                </div>
              </div>

              <PageCardsContainer
                paddingBottom
                onWaypointEnter={this.vm.onWaypointEnter}
              >
                {this.vm.rentals.map((vm) => (
                  <RentalCard vm={vm} key={vm.id} />
                ))}
              </PageCardsContainer>
            </>
          ) : (
            <NoLandLabel />
          )}
        </PageContainer>

        <RentalsFilterDrawer vm={this.vm.filterDrawerVM} />
      </>
    ) : null;
  }
}

RentalsPage.propTypes = {};

RentalsPage.defaultProps = {};

export default RentalsPage;
