import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import i18n from 'src/i18n';
import { CREATE_LAND_PAGES, LAND_STATUS } from 'src/constants/land';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import FactoryViewModel from './components/Factory/viewModel';
import AddressViewModel from './components/Address/viewModel';
import PositionViewModel, {
  POSITION_TYPES
} from './components/Position/viewModel';
import BasicInfoViewModel from './components/BasicInfo/viewModel';
import PricesViewModel from './components/Prices/viewModel';
import PhotosViewModel from './components/Photos/viewModel';
import DescriptionViewModel from './components/Description/viewModel';

class CreateLandDrawerViewModel {
  @observable landId = null;
  @observable land = null;
  @observable page = CREATE_LAND_PAGES.address.value;

  @observable inited = false;
  @observable isAwait = false;

  drawerVM = new ActionDrawerViewModel();

  factoryVM = new FactoryViewModel(this);

  addressVM = new AddressViewModel();
  positionVM = new PositionViewModel();
  basicInfoVM = new BasicInfoViewModel();
  pricesVM = new PricesViewModel();
  photosVM = new PhotosViewModel();
  descriptionVM = new DescriptionViewModel();

  created = () => {};
  change = () => {};
  changed = () => {};

  @computed
  get steps() {
    const pages = Object.values(CREATE_LAND_PAGES);
    const active = pages.findIndex((item) => item.value === this.page);

    return {
      steps: pages.length,
      step: active
    };
  }

  // 判斷 position component 使用地址定位, 還是已經有座標可以定位
  @computed
  get positionType() {
    const check = !!this.land?.position;
    return check ? POSITION_TYPES.position : POSITION_TYPES.address;
  }

  @computed
  get disabled() {
    return {
      address: !!this.land?.country?.trim(),
      back: this.isAwait,
      next: this.isAwait,
      done: this.isAwait
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = async ({
    landId = null,
    created = () => {},
    change = () => {},
    changed = () => {}
  }) => {
    this.inited = false;
    this.landId = landId;

    this.created = created;
    this.change = change;
    this.changed = changed;

    if (this.landId) {
      await this.getLandDetailAPI(this.checkDraft);
    } else {
      this.page = CREATE_LAND_PAGES.address.value;
    }

    runInAction(() => {
      this.inited = true;
      this.drawerVM.open();
    });
  };

  @action
  close = () => {
    this.landId = null;
    this.land = null;
    this.isAwait = false;

    this.basicInfoVM.resetFields();
    this.pricesVM.resetFields();
    this.photosVM.resetFields();
    this.descriptionVM.resetFields();

    this.drawerVM.close();
  };

  @action
  checkDraft = () => {
    const checkAddress = !this.land.country?.trim();
    const checkPosition = !this.land.position?.length;
    const checkInfo = !this.land.name?.trim();
    const checkPrice = !this.land.basicFee?.trim();
    const checkPhotos = !this.land.photos.length;
    const checkDescription = !this.land.description?.trim();

    if (checkAddress) {
      this.page = CREATE_LAND_PAGES.address.value;
      console.log('草稿, 缺地址');
    } else if (checkPosition) {
      this.page = CREATE_LAND_PAGES.position.value;
      console.log('草稿, 缺定位');
    } else if (checkInfo) {
      this.page = CREATE_LAND_PAGES.basicInfo.value;
      console.log('草稿, 缺基本資訊');
    } else if (checkPrice) {
      this.page = CREATE_LAND_PAGES.price.value;
      console.log('草稿, 缺價格');
    } else if (checkPhotos) {
      this.page = CREATE_LAND_PAGES.photos.value;
      console.log('草稿, 缺照片');
    } else if (checkDescription) {
      this.page = CREATE_LAND_PAGES.description.value;
      console.log('草稿, 缺描述');
    }
  };

  @action
  setPage = (val) => {
    this.page = val;
  };

  @action
  updateLand = (data) => {
    const land = { ...this.land, ...data };

    this.land = { ...land };

    this.change({ ...land });
  };

  @action
  loading = () => {
    this.isAwait = true;
  };

  @action
  completed = () => {
    this.isAwait = false;
  };

  createLand = async (data, callBack = () => {}) => {
    await this.postCreateLandAPI(data, callBack);
    await this.getLandDetailAPI();
  };

  @action
  postCreateLandAPI = async (data, callBack = () => {}) => {
    this.isAwait = true;

    try {
      const res = await LandServices.postLandCreate(data);

      runInAction(() => {
        this.landId = res.data.id;

        // 只有 id, 需要再使用 get detail 拿詳細資料
        this.land = { ...data, ...res.data };

        this.created();

        callBack();
      });
    } catch (error) {
      const msg = ErrorServices.postLandCreate(error);

      console.log('postLandCreate', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  putUpdateLandInfoAPI = async (data, callBack = () => {}) => {
    this.isAwait = true;

    try {
      await LandServices.putUpdateLandInfo({
        ...data,
        id: this.land.id
      });
      const newData = { ...data };

      // 特別處理, 因為 api 需要的資料與回傳的資料不同, 在 basicInfo 傳來的資料是 [id, id, id, ...], api 的資料是 [{}, {}, {}], 需要轉換
      if (this.page === CREATE_LAND_PAGES.basicInfo.value) {
        newData.species = data.species.map((item) => ({ id: item }));
      }

      this.updateLand({ ...newData });

      await callBack();
    } catch (error) {
      const msg = ErrorServices.getLandDetail(error);

      console.log('getLandDetail', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  // 草稿 detail
  @action
  getLandDetailAPI = async (callBack = () => {}) => {
    this.isAwait = true;

    try {
      const res = await LandServices.getLandDetail({ id: this.landId });

      runInAction(() => {
        this.landId = res.data.id;
        this.land = res.data;

        callBack();

        console.log(res.data);
      });
    } catch (error) {
      const msg = ErrorServices.getLandDetail(error);

      console.log('getLandDetail', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  putLandStatusAPI = async () => {
    try {
      const status = LAND_STATUS.unlisted.value;

      await LandServices.putLandStatus({
        id: this.land.id,
        status
      });

      this.updateLand({ status });

      await this.changed({ ...this.land });
      await this.created();

      this.close();
    } catch (error) {
      const mag = ErrorServices.putLandStatus(error);

      console.log(mag);
    }
  };
}

export default CreateLandDrawerViewModel;
