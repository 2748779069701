import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import { BUSINESS_DAY_KEY_FORMAT, getBatchDates } from 'src/constants/calendar';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';
import configStore from 'src/global/configStore';

class CalendarBatchAvailableModalViewModel {
  @observable land = false;
  @observable isAwait = false;

  changed = async () => {};

  form = null;

  modalVM = new ActionModalViewModel();

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      footer: this.isAwait
    };
  }

  @computed
  get rules() {
    return {
      required: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ]
    };
  }

  @computed
  get minDate() {
    return dayjs().startOf().add(configStore.bookingAfterDays, 'day');
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form }) => {
    this.form = form;
  };

  @action
  open = ({ land, changed = async () => {} }) => {
    this.land = land;
    this.changed = changed;

    this.form.resetFields();

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  onUnAvailable = () => {
    this.putLandBusinessDayAPI(false);
  };

  onAvailable = () => {
    this.putLandBusinessDayAPI(true);
  };

  @action
  putLandBusinessDayAPI = async (isAvailable) => {
    this.isAwait = true;

    try {
      // 當前月份.
      const data = await this.form.validateFields();
      const dates = getBatchDates({
        startAt: data.dates[0],
        endAt: data.dates[1]
      });
      const days = dates.map((item) => ({ date: item, isAvailable }));

      await LandServices.putLandBusinessDay({
        id: this.land.id,
        days
      });

      await this.changed();

      this.close();
    } catch (error) {
      const msg = ErrorServices.putLandBusinessDay(error);
      console.log('putLandBusinessDay', msg, error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default CalendarBatchAvailableModalViewModel;
