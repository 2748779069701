import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ResetFormItem from 'src/components/ResetFormItem';
import styles from './styles.module.scss';

@observer
class Note extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ booking: props.booking, form: props.form });
  }

  render() {
    return (
      <div className={styles.container}>
        <Form className={styles.form} form={this.vm.form}>
          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('booking_edit_drawer_note_page_note_label')}
            </div>

            <ResetFormItem name="note" rules={this.vm.rules.note}>
              <Input.TextArea
                size="large"
                maxLength={500}
                showCount
                autoSize={{ minRows: 6, maxRows: 12 }}
                placeholder={i18n.t('booking_edit_drawer_note_page_note_placeholder')}
              />
            </ResetFormItem>
          </div>
        </Form>
      </div>
    );
  }
}

Note.propTypes = {
  vm: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

Note.defaultProps = {};

export default withForm(Note);
