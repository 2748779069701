import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import i18n from 'src/i18n';
import { CARDS_LIMIT } from 'src/constants/config';
import { routerMethods } from 'src/constants/methods';
import { PARAMS } from 'src/constants/routes';
import { getPayoutDetailType } from 'src/constants/payout';
import ErrorServices from 'src/services/ErrorServices';
import PayoutServices from 'src/services/PayoutServices';
import BookingCardViewModel from './components/BookingCard/viewModel';

class BookingsViewModel {
  @observable payoutId = null;
  @observable landId = null;

  @observable date = '';
  @observable bookings = [];

  @observable isAwait = false;

  anchor = undefined;

  @computed
  get disabled() {
    return {
      landId: this.isAwait
    };
  }

  @computed
  get labels() {
    const checkDate = !!this.date.trim();
    const date = dayjs(this.date).format('MMM YYYY');
    const label = i18n.t('payout_details_page_booking_subtitle_label');

    return {
      subtitle: checkDate ? `${date} ${label}` : ''
    };
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    const type = routerMethods.getSearch(props.router, PARAMS.payoutType);
    const status = routerMethods.getSearch(props.router, PARAMS.payoutStatus);

    // 如果是 null, 代表要看全部的意思
    const payoutId = routerMethods.getSearch(props.router, PARAMS.payoutId);

    this.type = getPayoutDetailType({ type, status });
    this.payoutId = payoutId;

    console.log('booking type', this.type);
  };

  @action
  onLandIdChange = (val) => {
    this.landId = val;
    this.reset();
  };

  @action
  onWaypointEnter = async () => {
    if (!this.isAwait && this.anchor !== null) {
      this.isAwait = true;

      await this.getPayoutBookingsAPI();

      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  @action
  reset = async () => {
    this.isAwait = true;

    this.bookings = [];
    this.anchor = undefined;

    await this.getPayoutBookingsAPI();

    runInAction(() => {
      this.isAwait = false;
    });
  };

  // 根據 payout 和 land 讀取 bookings
  getPayoutBookingsAPI = async () => {
    try {
      const res = await PayoutServices.getPayoutBookings({
        limit: CARDS_LIMIT,
        anchor: this.anchor,
        id: this.payoutId,
        land: this.landId || undefined
      });

      runInAction(() => {
        const data = res.data.map(
          (item) =>
            new BookingCardViewModel({
              booking: item,
              date: res.date
            })
        );

        this.date = res.date;
        this.bookings = [...this.bookings, ...data];
        this.anchor = res.anchor;
      });
    } catch (error) {
      const msg = ErrorServices.getPayoutBookings(error);
      console.log('getPayoutBookings', msg);
    }
  };
}

export default BookingsViewModel;
