import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import i18n from 'src/i18n';
import ICONS from '../icons';
import { formatPrice } from '../price';
import styles from './styles.module.scss';

export const LAND_STATUS = {
  unlisted: {
    value: 'unlisted',
    label: i18n.t('land_status_unlisted')
  },

  draft: {
    value: 'draft',
    label: i18n.t('land_status_draft')
  },

  listed: {
    value: 'listed',
    label: i18n.t('land_status_listed')
  },

  suspension: {
    value: 'suspension',
    label: i18n.t('land_status_suspension')
  }
};

export const CREATE_LAND_PAGES = {
  address: {
    value: 'address',
    label: i18n.t('create_land_drawer_address_page_title')
  },

  position: {
    value: 'position',
    label: i18n.t('create_land_drawer_position_page_title')
  },

  basicInfo: {
    value: 'basicInfo',
    label: i18n.t('create_land_drawer_basic_info_page_title')
  },

  price: {
    value: 'price',
    label: i18n.t('create_land_drawer_price_page_title')
  },

  photos: {
    value: 'photos',
    label: i18n.t('create_land_drawer_photo_page_title')
  },

  description: {
    value: 'description',
    label: i18n.t('create_land_drawer_description_page_title')
  }
};

export class SpecialDateCalc {
  /**
   * 將 0 ~ 100 和 type 轉為 0 ~ 2
   * @param {string} val 0 ~ 100
   * @param {boolean} type true 增加, false 減少
   */
  static toRatio(val, type = true) {
    const ratio = new BigNumber(val).dividedBy(100);

    // 折扣需要反過來處理處理
    // 範例: val = 20, type = false, 代表是 8 折, 回傳值應該是 0.8
    return type
      ? ratio.plus(1).toString()
      : new BigNumber(1).minus(ratio).abs().toString();
  }

  /**
   * 將 ratio(0 ~ 2) 轉為百分比
   * @param {string} val
   */
  static toPercentage = (val) => {
    const ratio = new BigNumber(val);

    // 折扣需要反過來處理
    // 範例: val = 0.8 代表是 8 折, 回傳值應該是 20, 因為 ui 要顯示 20% off
    return ratio.isGreaterThanOrEqualTo(1)
      ? ratio.multipliedBy(100).minus(100).toString()
      : new BigNumber(100).minus(ratio.multipliedBy(100)).toString();
  };

  // 判斷是不是加價
  static ratioIsPlus = (val) => {
    const ratio = new BigNumber(val);
    return ratio.isGreaterThanOrEqualTo(1);
  };

  // 依照 ui 需求格式化
  static toFormat = ({
    startAt,
    endAt,
    ratio,
    basicFee = '0',
    extraFee = '0',
    timezoneId = 'America/New_York'
  }) => {
    const start = dayjs(startAt).tz(timezoneId).format('YYYY/MM/DD');
    const end = dayjs(endAt).tz(timezoneId).format('YYYY/MM/DD');
    const ratioPercentage = SpecialDateCalc.toPercentage(ratio);
    const isPlus = SpecialDateCalc.ratioIsPlus(ratio);
    const unit = isPlus
      ? i18n.t('land_edit_drawer_special_dates_plus_label')
      : i18n.t('land_edit_drawer_special_dates_off_label');

    const dateLabel = `${start} - ${end} (${ratioPercentage}% ${unit})`;

    const basicFeeLabel = i18n.t(
      'land_edit_drawer_special_dates_basic_fee_label'
    );
    const basicFeeFormat = formatPrice(
      new BigNumber(basicFee).multipliedBy(ratio)
    );

    const extraFeeLabel = i18n.t(
      'land_edit_drawer_special_dates_extra_fee_label'
    );
    const extraFeeFormat = formatPrice(
      new BigNumber(extraFee).multipliedBy(ratio)
    );

    return {
      date: dateLabel,
      basicFee: `${basicFeeLabel} US$${basicFeeFormat}`,
      extraFee: `${extraFeeLabel} US$${extraFeeFormat}`
    };
  };
}

export const formatDiscount = {
  /**
   * @param {string} val 0 ~ 100
   */
  toRatio: (val) => {
    const ratio = new BigNumber(val).minus(100).abs();
    return ratio.dividedBy(100).abs().toString();
  },

  toPercentage: (val) => {
    const ratio = new BigNumber(val).minus(1).abs();
    return ratio.multipliedBy(100).toString();
  }
};

export const DISCOUNTS = {
  30: {
    value: '30',
    label: i18n.t('land_edit_drawer_discounts_page_discounts_30_dates_label')
  },
  60: {
    value: '60',
    label: i18n.t('land_edit_drawer_discounts_page_discounts_60_dates_label')
  },
  90: {
    value: '90',
    label: i18n.t('land_edit_drawer_discounts_page_discounts_90_dates_label')
  },
  180: {
    value: '180',
    label: i18n.t('land_edit_drawer_discounts_page_discounts_180_dates_label')
  },
  240: {
    value: '240',
    label: i18n.t('land_edit_drawer_discounts_page_discounts_240_dates_label')
  }
};

export const LAND_MESSAGE_TYPES = {
  ratings: 'ratings',
  qas: 'qas'
};

export const getLandStatusStyle = (status) => {
  switch (status) {
    case LAND_STATUS.unlisted.value:
      return styles.unlisted;

    case LAND_STATUS.listed.value:
      return styles.listed;

    case LAND_STATUS.suspension.value:
      return styles.suspension;

    default:
      return '';
  }
};

export const getLandStatusIcon = (status) => {
  switch (status) {
    case LAND_STATUS.unlisted.value:
      return ICONS.warning;

    case LAND_STATUS.listed.value:
      return ICONS.tickGreen;

    case LAND_STATUS.suspension.value:
      return ICONS.warningRed;

    case LAND_STATUS.draft.value:
      return ICONS.warningBlack;

    default:
      return ICONS.stopBlack;
  }
};

export const APPEAL_EMAIL = '';

export const toLocalTimeZone = (date) => {
  const localTZ = dayjs.tz.guess();
  return dayjs(date).tz(localTZ);
};

// 寬高比最低限制
// 算式: 小 * MIN_PHOTO_RATIO >= 大
// note: 2024/06/06 為了讓手機拍攝的照片可以上傳, 調整限制的比例
export const MIN_PHOTO_RATIO = 2.5;

// 照片最少要幾張
export const LAND_PHOTOS_MIN_LENGTH = 5;
