import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ImageCompressor from 'js-image-compressor/dist/image-compressor.min';
import { message } from 'antd';
import i18n from 'src/i18n';
import { MIN_PHOTO_RATIO, LAND_PHOTOS_MIN_LENGTH } from 'src/constants/land';
import LandServices from 'src/services/LandServices';
import GoogleServices from 'src/services/GoogleServices';
import PhotoViewModel, { PHOTO_SOURCE } from './viewModels/Photo';

class PhotosViewModel {
  @observable land = null;
  @observable photos = [];
  @observable isChange = false;

  @observable awaits = [];

  @computed
  get check() {
    return {
      photos: this.photos.length >= LAND_PHOTOS_MIN_LENGTH,
      showRequired: this.photos.length === 0,
      showTable: this.photos.length !== 0
    };
  }

  @computed
  get disabled() {
    return {
      upload: !!this.awaits.length
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = (props) => {
    const checkPhotos = !!props.land.photos.length;

    this.land = props.land;
    this.photos = checkPhotos
      ? props.land.photos.map((item) => PhotoViewModel.fromServer(item))
      : this.photos;

    this.isChange = false;
  };

  // 限制圖片的最大寬高
  resizeFile = (file) => {
    return new Promise((resolve) => {
      const ins = new ImageCompressor({
        file,
        quality: 1,
        maxWidth: 1920,
        maxHeight: 1920,
        success: (result) => {
          resolve(result);
        }
      });
    });
  };

  // 檢查圖片寬高
  checkRatio = async (file) => {
    const resizeFile = await this.resizeFile(file);
    const bigValue
      = resizeFile.width > resizeFile.height
        ? resizeFile.width
        : resizeFile.height;
    const smallValue
      = resizeFile.width === bigValue ? resizeFile.height : resizeFile.width;
    const res = smallValue * MIN_PHOTO_RATIO >= bigValue;

    return res;
  };

  onBeforeUpload = async (file) => {
    this.setLoading();

    // 檢查照片寬高比例.
    const validRatio = await this.checkRatio(file);

    if (!validRatio) {
      message.error(
        i18n.t('create_land_drawer_photos_page_photo_ratio_error'),
        5
      );

      this.setCompleted();

      return false;
    }

    return true;
  };

  @action
  onUpload = async (event) => {
    // 壓縮圖片.
    const file = await this.resizeFile(event.file);
    // 將檔案下載為圖片, 顯示到 ui.
    const fileReader = new FileReader();

    fileReader.addEventListener('load', (e) => this.onFileReaderLoad(e, file));
    fileReader.readAsDataURL(file);

    console.log('上傳的檔案', file);
  };

  @action
  onFileReaderLoad = (event, file) => {
    const src = event.target.result;
    const size = event.total;

    this.photos = [
      ...this.photos,
      PhotoViewModel.fromUpload({ src, size, file })
    ];

    this.setCompleted();

    console.log('上傳的照片', event);
  };

  @action
  onDragEnd = (e) => {
    if (e.change) {
      this.photos = e.data;
      this.isChange = true;
    }
  };

  @action
  onPhotoDelete = (id) => {
    const photo = this.photos.find((item) => item.id === id);

    this.photos = this.photos.filter((item) => item.id !== id);

    // 刪除已經存在 server 的照片
    if (photo.source === PHOTO_SOURCE.server) {
      this.isChange = true;
    }
  };

  @action
  setLoading = () => {
    this.awaits.push(true);
  }

  @action
  setCompleted = () => {
    this.awaits.pop();
  }

  @action
  resetFields = () => {
    this.photos = [];
  };

  @action
  validateFields = async () => {
    if (!this.check.photos) {
      message.error(i18n.t('create_land_drawer_photos_page_description'), 5);
      throw new Error('photos length error');
    }

    const uploadPhotos = this.photos.filter(
      (vm) => vm.source === PHOTO_SOURCE.upload
    );

    // 有上傳照片
    if (uploadPhotos.length) {
      // 拿 google 雲端的網址
      const res = await LandServices.postGetUploadPhotosUrl({
        id: this.land.id,
        photos: uploadPhotos.map((photo) => photo.resForGoogleLink)
      });
      // 上傳到 google
      await Promise.all(
        uploadPhotos.map((vm, i) => {
          const link = res.data[i];
          const data = vm.resForGoogleUpload;

          return GoogleServices.putGoogleStorage({
            url: link.url,
            mimetype: data.mimetype,
            size: data.size,
            file: data.file
          });
        })
      );

      // 上傳完了, 更新 vm
      uploadPhotos.forEach((vm, i) =>
        vm.sourceToServer({ id: res.data[i].id }));
    }

    if (uploadPhotos.length || this.isChange) {
      // 告訴 server 上傳完成
      await LandServices.putUpdatePhotos({
        id: this.land.id,
        photos: this.photos.map((vm) => vm.resForServer)
      });
    }

    // 更新獵場 detail
    return { photos: this.photos.map((vm) => vm.resForLandDetail) };
  };
}

export default PhotosViewModel;
