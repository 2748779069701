import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import styles from './styles.module.scss';

@observer
class BasicCard extends React.Component {
  render() {
    const { className, border, children, ...ps } = this.props;

    return (
      <div
        className={clsx([styles.container, border && styles.border, className])}
        {...ps}
      >
        {children}
      </div>
    );
  }
}

BasicCard.propTypes = {
  className: PropTypes.string,
  border: PropTypes.bool,
  children: PropTypes.node
};

BasicCard.defaultProps = {
  className: '',
  border: false,
  children: null
};

export default BasicCard;
