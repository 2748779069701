import i18n from 'src/i18n';
import AppButtons from './components/AppButtons';
import styles from './styles.module.scss';


function App() {
  return (
    <section className={styles.box1}>
      <div className={styles.row2}>
        <div className={styles.row2__item}>
          <div className={styles.wrapper12}>
            <h1 className={styles.hero_title2}>
              {i18n.t('find_hunt_page_function_title')}
            </h1>

            <h4 className={styles.highlights2}>
              {i18n.t('find_hunt_page_function_content')}
            </h4>
          </div>

          <AppButtons media="small" />
        </div>

        <div className={styles.row2__spacer} />

        <div className={styles.row2__item}>
          <img
            className={styles.image7}
            src={require('src/assets/findHunt/system.png')}
            alt="alt text"
          />
        </div>

        <div className={styles.row2__spacer} />

        <AppButtons media="big" />
      </div>
    </section>
  );
}

export default App;
