class PayoutStatisticsDataModel {
  constructor(props) {
    this.paid = {
      startAt: props.paid.startAt,
      endAt: props.paid.endAt,
      total: props.paid.total
    };

    this.payable = {
      startAt: props.payable.startAt,
      endAt: props.payable.endAt,
      total: props.payable.total
    };
  }
}

export default PayoutStatisticsDataModel;
