import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ROUTES } from 'src/constants/routes';
import PageHeader from 'src/components/PageHeader';
import ResetButton from 'src/components/ResetButton';
import ResetTabs from 'src/components/ResetTabs';
import PageContainer from 'src/components/PageContainer';
import PayoutsPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class PayoutsPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new PayoutsPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.inited ? (
      <PageContainer className={styles.container}>
        <PageHeader back label={ROUTES.payouts.label} />

        <div className={styles.stripe}>
          <ResetButton
            className={styles.button}
            type={this.vm.labels.spriteButtonType}
            ghost={this.vm.labels.spriteButtonGhost}
            onClick={this.vm.onStripe}
            disabled={this.vm.disabled.stripe}
          >
            {this.vm.labels.stripeLabel}
          </ResetButton>
        </div>

        <div className={styles.tabs}>
          <ResetTabs items={this.vm.pages} size="large" tabFull tabPadding />
        </div>
      </PageContainer>
    ) : null;
  }
}

PayoutsPage.propTypes = {};

PayoutsPage.defaultProps = {};

export default PayoutsPage;
