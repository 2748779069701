import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import i18n from 'src/i18n';
import withRouter from 'src/components/withRouter';
import Loading from 'src/components/Loading';
import ResendButton from 'src/components/ResendButton';
import VerifyCode from 'src/components/VerifyCode';
import ContinueButton from '../ContinueButton/index';
import VerifyViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new VerifyViewModel({
      router: props.router,
      profile: props.profile,
      email: props.email,
      toLogin: props.toLogin,
      close: props.close
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentWillUnmount() {
    this.vm.unMount();
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.verifyCodeContainer}>
            <VerifyCode
              onChangeCode={this.vm.onChangeCode}
              showError={this.vm.showError}
            />
          </div>

          {/* 重新寄驗證信. */}
          <div className={styles.itemContainer}>
            <ResendButton
              type="link"
              vm={this.vm.resendButtonVM}
              onClick={this.vm.onResend}
              label={i18n.t('find_hunt_resend_button_label')}
              unit={i18n.t('find_hunt_resend_unit')}
            />
          </div>

          <div className={styles.itemContainer}>
            <ContinueButton
              text={i18n.t('btn_continue')}
              onClick={this.vm.onContinue}
              disabled={this.vm.disabledContinue}
            />
          </div>
        </div>

        {this.vm.isAwait && <Loading />}
      </div>
    );
  }
}

Verify.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object,
  email: PropTypes.string,
  toLogin: PropTypes.func,
  close: PropTypes.func
};

Verify.defaultProps = {
  profile: null,
  email: '',
  toLogin: () => {},
  close: () => {}
};

export default withRouter(Verify);
