class GetUploadPhotosUrlDataModel {
  constructor(data) {
    // 檔案名稱.
    this.id = data.id;

    // 上傳至 google 的網址.
    this.url = data.url;
  }
}

export default GetUploadPhotosUrlDataModel;
