import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'src/i18n';
import ICONS from 'src/constants/icons';
import { CHECK_BOOKING_LINKS } from 'src/constants/config';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

@observer
class Footer extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.trademark}>
          {i18n.t('check_booking_page_booking_page_trademark')}
        </div>

        <div className={styles.icons}>
          <a
            className={styles.link}
            href={CHECK_BOOKING_LINKS.instagram}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.icon}
              src={ICONS.instagram}
              alt="instagram"
            />
          </a>

          <a
            className={styles.link}
            href={CHECK_BOOKING_LINKS.facebook}
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.icon} src={ICONS.facebook} alt="facebook" />
          </a>

          <a
            className={styles.link}
            href={CHECK_BOOKING_LINKS.twitter}
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.icon} src={ICONS.twitter} alt="instagram" />
          </a>

          <a
            className={styles.link}
            href={CHECK_BOOKING_LINKS.youtube}
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.icon} src={ICONS.youtube} alt="youtube" />
          </a>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
