import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ICONS from 'src/constants/icons';
import ResetButton from 'src/components/ResetButton';
import clsx from 'clsx';
import styles from './styles.module.scss';

@observer
class ActionDrawerCard extends React.Component {
  render() {
    const checkLabel = !!this.props.label;
    const checkChildren = !!this.props.children;

    return (
      <ResetButton
        className={styles.container}
        type="text"
        onClick={this.props.onClick}
      >
        <div className={styles.mainContent}>
          {checkLabel && (
            <div
              className={clsx([
                styles.label,
                this.props.next && styles.hasNext
              ])}
            >
              {this.props.label}
            </div>
          )}

          {checkChildren && (
            <div className={styles.content}>{this.props.children}</div>
          )}
        </div>

        {this.props.next && (
          <div className={styles.next}>
            <img src={ICONS.drawerNext} alt="next" />
          </div>
        )}
      </ResetButton>
    );
  }
}

ActionDrawerCard.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array
  ]),
  next: PropTypes.bool,
  onClick: PropTypes.func
};

ActionDrawerCard.defaultProps = {
  label: '',
  children: null,
  next: false,
  onClick: () => {}
};

export default ActionDrawerCard;
