import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetInput from 'src/components/ResetInput';
import ResetInputNumber from 'src/components/ResetInputNumber';
import ResetButton from 'src/components/ResetButton';
import ResendButton from 'src/components/ResendButton';
import CountryCode from 'src/components/CountryCode';
import { PAGES } from './viewModel';
import styles from './styles.module.scss';

@observer
class PhoneModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ form: props.form });
  }

  renderFooter = () => {
    switch (this.vm.page) {
      case PAGES.inputPhone:
        return (
          <div className={styles.footer}>
            <ResetButton className={styles.button} onClick={this.vm.close}>
              {i18n.t('phone_modal_cancel_button_label')}
            </ResetButton>

            <ResetButton
              className={styles.button}
              type="primary"
              onClick={this.vm.onNext}
            >
              {i18n.t('phone_modal_next_button_label')}
            </ResetButton>
          </div>
        );

      case PAGES.verifyCode:
        return (
          <div className={styles.footer}>
            <ResendButton
              className={styles.button}
              vm={this.vm.resendButtonVM}
              onClick={this.vm.onResend}
              label={i18n.t('phone_modal_resend_button_label')}
              unit={i18n.t('phone_modal_resend_unit')}
            />

            <ResetButton
              className={styles.button}
              type="primary"
              onClick={this.vm.onConfirm}
            >
              {i18n.t('phone_modal_verify_button_label')}
            </ResetButton>
          </div>
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        <Form
          className={styles.container}
          form={this.vm.form}
          disabled={this.vm.disabled.form}
        >
          {this.vm.check.inputPhone && (
            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('phone_modal_phone_label')}
              </div>

              <ResetFormItem name="phone" rules={this.vm.rules.phone}>
                <ResetInputNumber
                  className={styles.phoneInput}
                  size="large"
                  addonBefore={<CountryCode />}
                  controls={false}
                  precision={0}
                  stringMode
                  placeholder={i18n.t('phone_modal_phone_placeholder')}
                  onChange={this.vm.onPhoneChange}
                />
              </ResetFormItem>
            </div>
          )}

          {this.vm.check.verifyCode && (
            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('phone_modal_verify_label')}
              </div>

              <ResetFormItem name="code" rules={this.vm.rules.verifyCode}>
                <ResetInput
                  placeholder={i18n.t('phone_modal_verify_placeholder')}
                />
              </ResetFormItem>
            </div>
          )}

          <div className={styles.description}>{this.vm.description}</div>

          {this.renderFooter()}
        </Form>
      </ActionModal>
    );
  }
}

PhoneModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

PhoneModal.defaultProps = {};

export default withForm(PhoneModal);
