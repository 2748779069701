import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { assemble } from 'src/constants/methods';
import { formatPrice } from 'src/constants/price';
import BookingDetailDrawerViewModel from 'src/components/BookingDetailDrawer/viewModel';

class BookingRecordCardViewModel {
  @observable record = null;

  detailDrawerVM = new BookingDetailDrawerViewModel();

  @computed
  get id() {
    return this.record.id;
  }

  @computed
  get labels() {
    const consumer = assemble.name({
      firstName: this.record.consumer.firstName,
      lastName: this.record.consumer.lastName
    });

    const startAt = dayjs(this.record.startAt).format('MMM D, YYYY');
    const endAt = dayjs(this.record.endAt).format('MMM D, YYYY');

    return {
      date: dayjs(this.record.date).format('MMM D, YYYY'),
      amount: formatPrice(this.record.amount, true),
      consumer,
      duration: `${startAt} - ${endAt}`,
      landName: this.record.land.name
    };
  }

  constructor(props) {
    this.record = props;
    makeObservable(this);
  }

  onDetailDrawer = () => {
    this.detailDrawerVM.openBooking({ bookingId: this.record.bookingId });
  }
}

export default BookingRecordCardViewModel;
