class BookingStatisticsDataModel {
  constructor(data) {
    this.arrivingSoon = data.arrivingSoon;

    this.current = data.current;

    this.next14days = data.next14days;

    this.ready2finish = data.ready2finish;

    this.requests = data.requests;

    this.total = data.total;
  }
}

export default BookingStatisticsDataModel;
