import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';

class RulesViewModel {
  @observable land = null;

  form = null;

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = ({ land, form }) => {
    this.land = land;
    this.form = form;

    this.form.setFieldsValue({ rules: this.land.rules || '' });
  }


  validateFields = async () => {
    const res = await this.form.validateFields();
    return res;
  }
}

export default RulesViewModel;
