import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import ErrorServices from 'src/services/ErrorServices';
import LandServices from 'src/services/LandServices';
import i18n from 'src/i18n';
import LandDetail from './components/LandDetail';
import LandPrices from './components/LandPrices';
import LandPackages from './components/LandPackages';

const PAGES = {
  detail: {
    value: 'detail',
    label: i18n.t('land_detail_drawer_detail_page_title')
  },
  fees: {
    value: 'fees',
    label: i18n.t('land_detail_drawer_fees_page_title')
  },
  packages: {
    value: 'packages',
    label: i18n.t('land_detail_drawer_packages_page_title')
  }
};


class LandDetailDrawerViewModel {
  @observable landId = null;
  @observable land = null;
  @observable inited = false;
  @observable isAwait = false;

  drawerVM = new ActionDrawerViewModel();

  changed = () => {};

  openEnd = () => {};
  closeEnd = () => {};

  @computed
  get pages() {
    return [
      {
        key: PAGES.detail.value,
        label: PAGES.detail.label,
        children: (
          <LandDetail land={this.land} changed={this.getLandDetailAPI} />
        )
      },
      {
        key: PAGES.fees.value,
        label: PAGES.fees.label,
        children: (
          <LandPrices land={this.land} changed={this.getLandDetailAPI} />
        )
      },
      {
        key: PAGES.packages.value,
        label: PAGES.packages.label,
        children: (
          <LandPackages land={this.land} changed={this.getLandDetailAPI} />
        )
      }
    ];
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({
    landId = null,
    openEnd = () => {},
    closeEnd = () => {},
    changed = () => {}
  }) => {
    this.landId = landId;

    this.changed = changed;
    this.openEnd = openEnd;
    this.closeEnd = closeEnd;

    this.drawerVM.open();

    this.getLandDetailAPI();
  };

  @action
  close = () => {
    this.inited = false;
    this.closeEnd(this.land);
    this.drawerVM.close();
  };

  @action
  getLandDetailAPI = async () => {
    this.isAwait = true;

    try {
      const res = await LandServices.getLandDetail({ id: this.landId });

      runInAction(() => {
        this.landId = res.data.id;
        this.land = res.data;
        this.inited = true;
      });
    } catch (error) {
      const msg = ErrorServices.getLandDetail(error);
      console.log('getLandDetail', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default LandDetailDrawerViewModel;
