import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Waypoint } from 'react-waypoint';
import ActionDrawer from 'src/components/ActionDrawer';
import ActionDrawerHeader from 'src/components/ActionDrawer/components/ActionDrawerHeader';
import styles from './styles.module.scss';
import Message from './components/Message';

@observer
class LandMessagesDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
        <div className={styles.container}>
          <ActionDrawerHeader content={this.vm.header} paddingBottom />

          <div className={styles.messages}>
            {this.vm.data.map((vm) => (
              <Message
                vm={vm}
                type={this.vm.type}
                key={vm.id}
              />
            ))}

            <Waypoint onEnter={this.vm.onWaypointEnter} />
          </div>
        </div>
      </ActionDrawer>
    );
  }
}

LandMessagesDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

LandMessagesDrawer.defaultProps = {};

export default LandMessagesDrawer;
