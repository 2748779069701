import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { LAND_STATUS, getLandStatusStyle } from 'src/constants/land';
import i18n from 'src/i18n';

class StatusViewModel {
  @observable land = null;
  @observable status = false;

  @computed
  get switchLabel() {
    return this.status ? LAND_STATUS.listed.label : LAND_STATUS.unlisted.label;
  }

  @computed
  get statusStyle() {
    const status = this.status
      ? LAND_STATUS.listed.value
      : LAND_STATUS.unlisted.value;
    const style = getLandStatusStyle(status);

    return style;
  }

  @computed
  get description() {
    return this.status
      ? i18n.t('land_edit_drawer_status_page_status_listed_description')
      : i18n.t('land_edit_drawer_status_page_status_unlisted_description');
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = ({ land }) => {
    this.land = land;
    this.status = land.status === LAND_STATUS.listed.value;
  };

  @action
  setStatus = (val) => {
    this.status = val;
  };

  @action
  onStatusChange = (val) => {
    this.status = val;
  };

  validateFields = () => {
    return {
      status: this.status
        ? LAND_STATUS.listed.value
        : LAND_STATUS.unlisted.value
    };
  };
}

export default StatusViewModel;
