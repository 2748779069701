import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ICONS from 'src/constants/icons';
import styles from './styles.module.scss';

@observer
class CardContent extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.date}>{this.props.date}</div>

          <div className={styles.amount}>
            <span>{this.props.amount}</span>
            <img src={ICONS.drawerNext} alt="detail" />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.item}>{this.props.consumer}</div>
          <div className={styles.item}>{this.props.duration}</div>
          <div className={styles.item}>{this.props.landName}</div>
        </div>
      </div>
    );
  }
}

CardContent.propTypes = {
  date: PropTypes.string,
  amount: PropTypes.string,
  consumer: PropTypes.string,
  duration: PropTypes.string,
  landName: PropTypes.string
};

CardContent.defaultProps = {
  date: '',
  amount: '',
  consumer: '',
  duration: '',
  landName: ''
};

export default CardContent;
