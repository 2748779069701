import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { CARDS_LIMIT } from 'src/constants/config';
import {
  RENTAL_FILTER_STATUS,
  getRentalFilters
} from 'src/constants/rental/rental';
import LandServices from 'src/services/LandServices';
import RentalServices from 'src/services/RentalServices';
import ErrorServices from 'src/services/ErrorServices';
import RentalCardViewModel from './components/RentalCard/viewModel';
import RentalsFilterDrawerViewModel from './components/RentalsFilterDrawer/viewModel';

class RentalsPageViewModel {
  @observable lands = [];
  @observable rentals = [];

  @observable inited = false;
  @observable isAwait = false;

  @observable landId = null;

  @observable filters = {
    status: undefined,
    isPackingCompleted: undefined,
    search: undefined,
    date: undefined
  };

  limit = 40;
  anchor = undefined;

  filterDrawerVM = new RentalsFilterDrawerViewModel();

  @computed
  get check() {
    return {
      lands: !!this.lands.length
    };
  }

  @computed
  get disabled() {
    return {
      filter: this.isAwait
    };
  }

  @computed
  get options() {
    return {
      lands: this.lands.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      }),
      status: Object.values(RENTAL_FILTER_STATUS)
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  didMount = async () => {
    await Promise.all([this.getLandListAPI(), this.postRentalOrdersAPI()]);

    runInAction(() => {
      this.inited = true;
    });
  };

  @action
  onLandIdChange = (val) => {
    this.landId = val || null;

    this.reset();
  };

  @action
  onStatusChange = (val) => {
    const filters = getRentalFilters(val);

    this.status = val || null;
    this.filters = { ...this.filters, ...filters };

    this.reset();
  };

  onFilter = () => {
    this.filterDrawerVM.open({
      confirm: this.onFilterDrawerConfirm,
      startAt: this.filters.date?.startAt || null,
      endAt: this.filters.date?.endAt || null,
      keyword: this.filters.search?.keyword || null
    });
  };

  @action
  onFilterDrawerConfirm = (data) => {
    this.filters.search = data.search;
    this.filters.date = data.date;

    this.reset();

    console.log('filter drawer', data);
  };

  onWaypointEnter = () => {
    if (!this.isAwait && this.anchor === undefined) {
      this.postRentalOrdersAPI();
    }
  };

  @action
  reset = () => {
    this.rentals = [];
    this.anchor = undefined;

    this.postRentalOrdersAPI();
  };

  @action
  getLandListAPI = async () => {
    try {
      const res = await LandServices.getLandList();

      runInAction(() => {
        this.lands = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getLandList(error);
      console.log('getLandList', msg);
    }
  };

  @action
  postRentalOrdersAPI = async () => {
    this.isAwait = true;

    try {
      const res = await RentalServices.getRentalsList({
        limit: CARDS_LIMIT,
        anchor: this.anchor,
        landId: this.landId || undefined,
        statuses: this.filters.status,
        isPackingCompleted: this.filters.isPackingCompleted,
        search: this.filters.search,
        date: this.filters.date
      });

      runInAction(() => {
        const data = res.data.map((item) => new RentalCardViewModel(item));

        this.rentals = [...this.rentals, ...data];
        this.anchor = res.anchor;
      });
    } catch (error) {
      const msg = ErrorServices.getRentalsList(error);

      console.log('RentalsPageViewModel', 'getRentalsList', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default RentalsPageViewModel;
