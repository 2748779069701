import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ActionDrawer from '../ActionDrawer';
import ActionDrawerHeader from '../ActionDrawer/components/ActionDrawerHeader';
import ActionDrawerItem from '../ActionDrawer/components/ActionDrawerItem';
import styles from './styles.module.scss';

@observer
class ContactDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
        <div className={styles.container}>
          <ActionDrawerHeader
            content={i18n.t('contact_drawer_header')}
            paddingBottom
          />

          <ActionDrawerItem
            label={i18n.t('contact_drawer_name_label')}
            divider
          >
            {this.vm.name}
          </ActionDrawerItem>

          <ActionDrawerItem
            label={i18n.t('contact_drawer_email_label')}
            divider
          >
            {this.vm.email}
          </ActionDrawerItem>

          <ActionDrawerItem
            label={i18n.t('contact_drawer_phone_label')}
          >
            {this.vm.phone}
          </ActionDrawerItem>
        </div>
      </ActionDrawer>
    );
  }
}

ContactDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

ContactDrawer.defaultProps = {};

export default ContactDrawer;
