// 獵場列表的 item.
class LandListItemDataModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;

    // 獵場照縮圖.
    this.photos = data.photos.map((item) => {
      return {
        id: item.id,
        priority: item.priority,
        size: item.size,
        src: item.src
      };
    }) || [];

    this.photo = this.photos[0];

    // 獵場狀態.
    this.status = data.status;

    // 獵場描述.
    this.description = data.description;

    this.rules = data.rules;

    // 獵場定位.
    this.position = data.position;

    // 獵場所在國家.
    this.country = data.country;

    // 獵場所在地區.
    this.state = data.state;

    // 獵場所在城市.
    this.county = data.county || '';

    // 獵場所在街道.
    this.street = data.street || '';

    // 獵場的郵遞區號.
    this.postcode = data.postcode;

    // 獵場大小.
    this.acres = data.acres;

    // 獵場基本收費.
    this.basicFee = data.basicFee;
    // 獵場基本人數.
    this.basicHunters = data.basicHunters;

    // 獵場額外費用.
    this.extraFee = data.extraFee;
    // 獵場額外人數.
    this.extraHunters = data.extraHunters;

    // 獵場最後預定的時間.
    this.finalBookingDate = data.finalBookingDate;

    // 獵場時區 id.
    this.timezoneId = data.timezoneId;
    // 獵場時區計算.
    this.timezoneOffset = data.timezoneOffset;

    // 獵場預定次數.
    this.bookingsCount = data.bookingsCount;

    // 獵場評分資料.
    this.rating = {
      // 平均分數.
      avg: data.rating.avg,
      count: data.rating.count
    };
    // 地主未讀的評分數.
    this.unreadRatingsCount = data.unreadRatingsCount;

    // 獵場問答次數.
    this.qaCount = data.qaCount;
    // 獵場尚未回答的次數.
    this.unreadQACount = data.unreadQACount;

    // 獵場的獵物.
    this.species = data.species?.map((item) => {
      return {
        id: item.id,
        category: item.category,
        priority: item.priority
      };
    }) || [];

    // 有沒有看過條款
    this.termsAgreedIn = data.termsAgreedIn;
  }
}

export default LandListItemDataModel;
