import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ResetButton from 'src/components/ResetButton';
import BasicCard from 'src/components/BasicCard';
import BookingDetailDrawer from 'src/components/BookingDetailDrawer';
import CardContent from '../../../CardContent';
import styles from './styles.module.scss';

@observer
class RentalCard extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <>
        <ResetButton
          className={styles.button}
          type="text"
          onClick={this.vm.onDetailDrawer}
        >
          <BasicCard>
            <CardContent
              date={this.vm.labels.date}
              amount={this.vm.labels.amount}
              consumer={this.vm.labels.consumer}
              duration={this.vm.labels.duration}
              landName={this.vm.labels.landName}
            />
          </BasicCard>
        </ResetButton>

        <BookingDetailDrawer vm={this.vm.detailDrawerVM} />
      </>
    );
  }
}

RentalCard.propTypes = {
  vm: PropTypes.object.isRequired
};

RentalCard.defaultProps = {};

export default RentalCard;
