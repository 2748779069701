import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

@observer
class FeeCollapseFooter extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.item1}>
          {this.props.item1}
        </div>

        <div className={styles.item2}>
          {this.props.item2}
        </div>
      </div>
    );
  }
}

FeeCollapseFooter.propTypes = {
  item1: PropTypes.string,
  item2: PropTypes.string
};

FeeCollapseFooter.defaultProps = {
  item1: '',
  item2: ''
};

export default FeeCollapseFooter;
