import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import LinkButton from 'src/components/LinkButton';
import styles from './styles.module.scss';

@observer
class Placeholder extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <LinkButton onClick={this.props.onClick}>
            {i18n.t('lands_page_placeholder_link_button_label')}
          </LinkButton>

          &nbsp;

          <span>{i18n.t('lands_page_placeholder')}</span>
        </div>
      </div>
    );
  }
}

Placeholder.propTypes = {
  onClick: PropTypes.func
};

Placeholder.defaultProps = {
  onClick: () => {}
};

export default Placeholder;
