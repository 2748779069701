import { request, getHost } from './utils';

export const getCalendarLandTags = ({ token, id, date }) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/backstage/calendar/land/${id}/tags`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },
    params: {
      date
    }
  };

  return request(options);
};

export const postCalendarLandTags = ({ token, id, startAt, endAt, tags }) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/backstage/calendar/land/${id}/tags`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },
    data: {
      startAt,
      endAt,
      tags
    }
  };

  return request(options);
};

export const deleteCalendarLandTags = ({ token, id, startAt, endAt, tags }) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/v1/backstage/calendar/land/${id}/tags`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },
    data: {
      startAt,
      endAt,
      tags
    }
  };

  return request(options);
};

export const getCalendarLandTagsSearch = ({ token, id, date }) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/backstage/calendar/land/${id}/tags/search`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },
    params: {
      keyword: date
    }
  };

  return request(options);
};
