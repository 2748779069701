import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { ROUTES } from 'src/constants/routes';
import LandServices from 'src/services/LandServices';
import PackageServices from 'src/services/PackageServices';
import ErrorServices from 'src/services/ErrorServices';
import PackageDrawerViewModel from 'src/components/PackageDrawer/viewModel';
import LandAddPackageModalViewModel from '../LandAddPackageModal/viewModel';

class LandPackagesViewModel {
  @observable land = null;
  @observable packages = [];
  @observable inited = false;
  @observable isAwait = false;

  changed = () => {};

  router = null;

  addPackageModalVM = new LandAddPackageModalViewModel();
  packageDrawerVM = new PackageDrawerViewModel();

  @computed
  get tablePackages() {
    return this.land.packages.map((item) => {
      return {
        id: item.id,
        name: item.data.name,
        package: item.data
      };
    });
  }

  @computed
  get disabled() {
    return {
      add: this.isAwait,
      delete: this.isAwait
    };
  }

  @computed
  get check() {
    return {
      packages: !!this.packages.length
    };
  }

  constructor(props) {
    this.land = props.land;
    this.changed = props.changed;
    this.router = props.router;

    makeObservable(this);
  }

  didMount = async () => {
    await this.getPackagesAPI();

    runInAction(() => {
      this.inited = true;
    });
  };

  @action
  didUpdate = (props, preProps) => {
    const diffLand = diff(props.land, preProps.land);

    if (diffLand) {
      this.land = props.land;
    }
  };

  onAddPackage = () => {
    this.addPackageModalVM.open({
      land: this.land,
      packages: this.packages,
      changed: this.changed
    });
  };

  onPackage = (item) => {
    this.packageDrawerVM.openView(item.package);
  }

  onDelete = (item) => {
    this.deleteLandPackageAPI(item.id);
  };

  toPackagePage = () => {
    const route = `/${ROUTES.packages.value}`;
    this.router.navigate(route);
  }

  @action
  getPackagesAPI = async () => {
    this.isAwait = true;

    try {
      const res = await PackageServices.getPackageList();

      runInAction(() => {
        this.packages = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getPackageList(error);
      console.log('getPackageList', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  deleteLandPackageAPI = async (packageId) => {
    this.isAwait = true;

    try {
      await LandServices.deleteLandPackage({
        id: this.land.id,
        pid: packageId
      });

      await this.changed();
    } catch (error) {
      const msg = ErrorServices.deleteLandPackage(error);
      console.log('deleteLandPackage', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default LandPackagesViewModel;
