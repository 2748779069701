import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import i18n from 'src/i18n';
import { PAYOUT_DETAIL_TYPES, getPayoutDetailType } from 'src/constants/payout';
import { formatPrice } from 'src/constants/price';
import { routerMethods } from 'src/constants/methods';
import { PARAMS } from 'src/constants/routes';
import RentalServices from 'src/services/RentalServices';
import ErrorServices from 'src/services/ErrorServices';
import RentalCardViewModel from './components/RentalCard/viewModel';

class RentalsViewModel {
  @observable type = null;

  @observable landId = null;
  @observable payoutId = null;

  @observable rentals = [];

  @observable statistics = {
    paid: {
      startAt: null,
      endAt: null,
      total: '0'
    },
    payable: {
      startAt: null,
      endAt: null,
      total: '0'
    }
  };

  @observable inited = false;
  @observable isAwait = false;

  anchor = undefined;

  @computed
  get disabled() {
    return {
      landId: this.isAwait
    };
  }

  @computed
  get date() {
    switch (this.type) {
      case PAYOUT_DETAIL_TYPES.rentalPaid: {
        const check = !!this.rentals?.[0];
        return check ? dayjs(this.rentals[0].completedAt).format('YYYY') : '';
      }

      case PAYOUT_DETAIL_TYPES.rentalPayable: {
        const check
          = !!this.statistics.payable.startAt && !!this.statistics.payable.endAt;

        if (check) {
          const startAt = dayjs(this.statistics.payable.startAt).format('YYYY');
          const endAt = dayjs(this.statistics.payable.endAt).format('YYYY');

          return `${startAt} - ${endAt}`;
        }

        return '';
      }

      default:
        return '';
    }
  }

  /**
   * paid 前端可以算
   * payable 需要 call api
   */
  @computed
  get total() {
    const paid = this.rentals.reduce(
      (t, i) => t.plus(i.profit),
      new BigNumber(0)
    );

    switch (this.type) {
      case PAYOUT_DETAIL_TYPES.rentalPaid:
        return formatPrice(paid, true);

      case PAYOUT_DETAIL_TYPES.rentalPayable:
        return formatPrice(this.statistics.payable.total, true);

      default:
        return '';
    }
  }

  @computed
  get labels() {
    const subTitleLabel = i18n.t('payout_details_page_rental_subtitle_label');

    return {
      subTitle: `${this.date} ${subTitleLabel} ${this.total}`
    };
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    const type = routerMethods.getSearch(props.router, PARAMS.payoutType);
    const status = routerMethods.getSearch(props.router, PARAMS.payoutStatus);
    const payoutId = routerMethods.getSearch(props.router, PARAMS.payoutId);

    this.type = getPayoutDetailType({ type, status });
    this.payoutId = payoutId;
  };

  didMount = async () => {
    await this.reset();

    runInAction(() => {
      this.inited = true;
    });
  };

  @action
  onLandIdChange = (val) => {
    this.landId = val;
    this.reset();
  };

  @action
  reset = async () => {
    this.rentals = [];
    this.anchor = undefined;

    switch (this.type) {
      case PAYOUT_DETAIL_TYPES.rentalPaid:
        await this.getPayoutPaidRentalsAPI();
        break;

      case PAYOUT_DETAIL_TYPES.rentalPayable:
        await Promise.all([
          this.getPayoutPayableRentalsAPI(),
          this.getRentalPayoutStatisticsAPI()
        ]);
        break;

      default:
        break;
    }
  };

  onWaypointEnter = () => {
    if (
      this.inited
      && !this.isAwait
      && this.anchor !== null
      && this.type === PAYOUT_DETAIL_TYPES.rentalPayable
    ) {
      this.getPayoutPayableRentalsAPI();
    }
  };

  @action
  getPayoutPaidRentalsAPI = async () => {
    this.isAwait = true;

    try {
      const res = await RentalServices.getPayoutPaidRentals({
        id: this.payoutId,
        land: this.landId || undefined
      });

      runInAction(() => {
        this.rentals = res.data.map((item) => new RentalCardViewModel(item));
      });
    } catch (error) {
      const msg = ErrorServices.getPayoutPaidRentals(error);
      console.log('getPayoutPaidRentals', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  getPayoutPayableRentalsAPI = async () => {
    this.isAwait = true;

    try {
      const res = await RentalServices.getPayoutPayableRentals({
        land: this.landId || undefined,
        anchor: this.anchor
      });

      runInAction(() => {
        this.rentals = res.data.map((item) => new RentalCardViewModel(item));
        this.anchor = res.anchor;
      });
    } catch (error) {
      const msg = ErrorServices.getPayoutPayableRentals(error);
      console.log('getPayoutPayableRentals', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  getRentalPayoutStatisticsAPI = async () => {
    try {
      const res = await RentalServices.getRentalPayoutStatistics({
        landId: this.landId || undefined
      });

      runInAction(() => {
        this.statistics = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getRentalPayoutStatistics(error);
      console.log('getRentalPayoutStatistics', msg);
    }
  };
}

export default RentalsViewModel;
