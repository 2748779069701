import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import { ROUTES } from 'src/constants/routes';
import PageHeader from 'src/components/PageHeader';
import ResetButton from 'src/components/ResetButton';
import PackageDrawer from 'src/components/PackageDrawer';
import PageContainer from 'src/components/PageContainer';
import PageCardsContainer from 'src/components/PageCardsContainer';
import PackageCard from './components/PackageCard';
import Placeholder from './components/Placeholder';
import PackagesPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class PackagesPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new PackagesPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.inited ? (
      <>
        <PageContainer>
          <PageHeader back label={ROUTES.packages.label} />

          <div className={styles.create}>
            <ResetButton
              className={styles.button}
              type="primary"
              onClick={this.vm.onCreate}
              disabled={this.vm.disabled.create}
            >
              {i18n.t('packages_page_create_package_button_label')}
            </ResetButton>
          </div>

          {this.vm.check.packages ? (
            <PageCardsContainer paddingBottom>
              {this.vm.packages.map((vm) => (
                <PackageCard vm={vm} deleted={this.vm.deleted} key={vm.id} />
              ))}
            </PageCardsContainer>
          ) : (
            <Placeholder onClick={this.vm.onCreate} />
          )}
        </PageContainer>

        <PackageDrawer vm={this.vm.packageDrawerVM} />
      </>
    ) : null;
  }
}

PackagesPage.propTypes = {};

PackagesPage.defaultProps = {};

export default PackagesPage;
