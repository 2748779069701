import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import ICONS from 'src/constants/icons';
import withRouter from '../withRouter';
import ResetButton from '../ResetButton';
import styles from './styles.module.scss';

@observer
class PageHeader extends React.Component {
  onBack = () => {
    if (this.props.onBack) {
      this.props.onBack();
    } else {
      this.props.router.navigate(-1);
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <div
          className={clsx([styles.back, this.props.back && styles.translateX])}
        >
          {this.props.back && (
            <ResetButton
              className={styles.backButton}
              align="center"
              type="text"
              onClick={this.onBack}
            >
              <img src={this.props.backIcon} alt="back" />
            </ResetButton>
          )}

          <div className={styles.title}>{this.props.label}</div>
        </div>

        {this.props.children}
      </div>
    );
  }
}

PageHeader.propTypes = {
  label: PropTypes.string,
  back: PropTypes.bool,
  backIcon: PropTypes.string,
  onBack: PropTypes.func,
  children: PropTypes.node,
  router: PropTypes.object.isRequired
};

PageHeader.defaultProps = {
  label: '',
  back: false,
  backIcon: ICONS.back,
  onBack: null,
  children: null
};

export default withRouter(PageHeader);
