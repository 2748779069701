import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import clsx from 'clsx';
import ResetButton from 'src/components/ResetButton';
import styles from './styles.module.scss';

@observer
class ActionModalFooter extends React.Component {
  @computed
  get cancelProps() {
    const { className, children, ...ps } = this.props.cancelProps;

    return {
      className,
      children,
      ps: { ...ps }
    };
  }

  @computed
  get confirmProps() {
    const { className = '', children = '', ...ps } = this.props.confirmProps;

    return {
      className,
      children,
      ps: { ...ps }
    };
  }

  render() {
    return (
      <div className={clsx([styles.container, this.props.className])}>
        <ResetButton
          className={clsx([styles.button, this.cancelProps.className])}
          {...this.cancelProps.ps}
        >
          {this.cancelProps.children}
        </ResetButton>

        <ResetButton
          className={clsx([styles.button, this.confirmProps.className])}
          type="primary"
          {...this.confirmProps.ps}
        >
          {this.confirmProps.children}
        </ResetButton>
      </div>
    );
  }
}

ActionModalFooter.propTypes = {
  className: PropTypes.string,
  cancelProps: PropTypes.object,
  confirmProps: PropTypes.object
};

ActionModalFooter.defaultProps = {
  className: '',
  cancelProps: {},
  confirmProps: {}
};

export default ActionModalFooter;
