import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import RentalServices from 'src/services/RentalServices';
import ErrorServices from 'src/services/ErrorServices';
import ParcelViewModel from './components/Parcel/viewModel';

class ShippingViewModel {
  @observable booking = null;
  @observable rental = null;

  @observable parcels = [];

  @observable inited = false;
  @observable isAwait = false;

  @computed
  get check() {
    return {
      parcels: !!this.parcels.length
    };
  }

  constructor(props) {
    this.booking = props.booking;
    this.rental = props.rental;

    makeObservable(this);
  }

  didMount = async () => {
    await this.getRentalParcelsAPI();

    runInAction(() => {
      this.inited = true;
    });
  }

  @action
  didUpdate = (props, preProps) => {
    const diffBooking = diff(props.booking, preProps.booking);
    const diffRental = diff(props.rental, preProps.rental);

    if (props.booking && diffBooking) {
      this.booking = props.booking;
    }

    if (props.rental && diffRental) {
      this.rental = props.rental;
    }
  }

  @action
  getRentalParcelsAPI = async () => {
    this.isAwait = true;

    try {
      const res = await RentalServices.getRentalParcels({ id: this.rental.id });

      runInAction(() => {
        this.parcels = res.data.map((item) => new ParcelViewModel(item));
      });
    } catch (error) {
      const msg = ErrorServices.getRentalParcels(error);

      console.log('ParcelsViewModel', 'getRentalParcels', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

}

export default ShippingViewModel;
