class RentalCopyDetailDataModel {
  constructor(props) {
    this.id = props.id;

    this.warehouse = {
      id: props.warehouse.id
    };

    this.itemVariety = {
      id: props.itemVariety.id,
      name: props.itemVariety.name,
      thumbnail: props.itemVariety.thumbnail,
      plan: props.itemVariety.plan,
      count: props.itemVariety.count,
      price: props.itemVariety.price,
      deposit: props.itemVariety.deposit,
      damageWaiver: props.itemVariety.damageWaiver
    };

    this.item = {
      id: props.item.id,
      name: props.item.name,
      shippingFee: props.item.shippingFee,
      description: props.item.description
    };

    this.rental = {
      id: props.rental.id,
      createdAt: props.rental.createdAt
    };
  }
}

export default RentalCopyDetailDataModel;
