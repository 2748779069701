import {
  StyleSheet
} from '@react-pdf/renderer';

export const container = StyleSheet.create({
  page: {
    padding: '48 40'
  }
});

export const table = StyleSheet.create({
  table: {
    marginBottom: 27,
    borderBottom: '1 solid #dedede',
    display: 'flex',
    flexDirection: 'row'
  },

  label: {
    padding: '13 0',
    backgroundColor: '#fafafa',
    borderBottom: '1 solid #dedede'
  },

  labelText: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center'
  },

  content: {
    padding: '13 16'
  },

  contentText: {
    fontSize: 14,
    textAlign: 'center'
  },

  land: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 190
  },

  address: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 135
  },

  host: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 150
  },

  booking: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 135
  },

  renter: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 150
  }
});

export const parcel = StyleSheet.create({
  parcel: {
    paddingBottom: 10,
    border: '1 solid #D9D9D9'
  },

  header: {
    padding: '12 22',
    backgroundColor: '#D9D9D9'
  },

  title: {
    fontSize: 14
  },

  labels: {
    padding: '12 22'
  },

  label: {
    fontSize: 14,
    color: '#666666'
  },

  shippingLabel: {
    paddingBottom: 10
  }
});

export const items = StyleSheet.create({
  table: {
    padding: '0 22'
  },

  labels: {
    backgroundColor: '#fafafa',
    borderBottom: '1 solid #dedede',
    display: 'flex',
    flexDirection: 'row'
  },

  label: {
    padding: '12 0'
  },

  labelText: {
    fontSize: 14,
    textAlign: 'center'
  },

  contents: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid #dedede'
  },

  content: {
    padding: '35 16'
  },

  contentText: {
    fontSize: 14,
    textAlign: 'center'
  },

  name: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 216
  },

  variation: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 210
  },

  rental: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 130
  },

  quantity: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 120
  }
});
