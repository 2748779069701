import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetSelect from 'src/components/ResetSelect';
import styles from './styles.module.scss';

@observer
class LandAddPackageModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ form: props.form });
  }

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        <div className={styles.container}>
          <Form
            className={styles.form}
            form={this.vm.form}
            disabled={this.vm.disabled.form}
          >
            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('create_land_package_modal_package_label')}
              </div>

              <ResetFormItem name="package" rules={this.vm.rules.package}>
                <ResetSelect
                  options={this.vm.options.packages}
                  placeholder={i18n.t(
                    'create_land_package_modal_package_placeholder'
                  )}
                />
              </ResetFormItem>
            </div>
          </Form>

          <ActionModalFooter
            cancelProps={{
              onClick: this.vm.close,
              children: i18n.t('create_land_package_modal_cancel_button_label')
            }}
            confirmProps={{
              onClick: this.vm.onConfirm,
              disabled: this.vm.disabled.footer,
              children: i18n.t('create_land_package_modal_confirm_button_label')
            }}
          />
        </div>
      </ActionModal>
    );
  }
}

LandAddPackageModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

LandAddPackageModal.defaultProps = {};

export default withForm(LandAddPackageModal);
