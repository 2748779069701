import dayjs from 'dayjs';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import {
  RENTALS_FILTER_DATE_TYPES,
  RENTALS_FILTER_KEYWORD_TYPES
} from 'src/constants/rental/rental';

class RentalsFilterDrawerViewModel {
  @observable dates = null;

  @observable keywordType = RENTALS_FILTER_KEYWORD_TYPES.visitorName.value;
  @observable keyword = '';

  confirm = () => {};

  drawerVM = new ActionDrawerViewModel();

  @computed
  get check() {
    return {
      dates: !!this.dates,
      keyword: !!this.keyword.trim(),
      search: !!this.dates || !!this.keyword.trim()
    };
  }

  @computed
  get options() {
    return {
      keywordTypes: Object.values(RENTALS_FILTER_KEYWORD_TYPES)
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({ confirm, startAt, endAt, keyword }) => {
    this.confirm = confirm;

    if (startAt && endAt) {
      this.dates = [dayjs(startAt), dayjs(endAt)];
    } else {
      this.dates = null;
    }

    if (keyword) {
      this.keyword = keyword;
    } else {
      this.keyword = '';
    }

    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  @action
  reset = () => {
    this.dates = null;

    this.keywordType = RENTALS_FILTER_KEYWORD_TYPES.visitorName.value;
    this.keyword = '';
  };

  @action
  onDatesChange = (val) => {
    this.dates = val;
  };

  @action
  onKeywordTypeChange = (value) => {
    this.keywordType = value;
  };

  @action
  onKeywordChange = (event) => {
    this.keyword = event.target.value;
  };

  onConfirm = () => {
    const checkKeyword = !!this.keyword?.trim();
    const checkDates = !!this.dates;
    const data = {};

    data.search = checkKeyword
      ? {
        type: this.keywordType,
        keyword: this.keyword.trim()
      }
      : undefined;

    data.date = checkDates
      ? {
        type: RENTALS_FILTER_DATE_TYPES.rentalDate.value,
        startAt: this.dates[0].valueOf() || null,
        endAt: this.dates[1].valueOf() || null
      }
      : undefined;

    this.confirm(data);

    this.close();
  };
}

export default RentalsFilterDrawerViewModel;
