import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { message } from 'antd';
import BigNumber from 'bignumber.js';
import { diff } from 'deep-diff';
import i18n from 'src/i18n';
import { formatPrice } from 'src/constants/price';
import { SpecialDateCalc } from 'src/constants/land';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';

class SpecialDatesViewModel {
  @observable land = null;
  @observable specialDates = [];
  @observable isAwait = false;

  form = null;

  changed = () => {};

  addModalVM = new ActionModalViewModel();

  @computed
  get disabled() {
    return {
      delete: this.isAwait,
      form: this.isAwait,
      confirm: this.isAwait
    };
  }

  @computed
  get basicFee() {
    const label = i18n.t(
      'land_edit_drawer_special_dates_page_main_label_basic_fee_label'
    );
    const price = formatPrice(this.land.basicFee);

    return `${label} US$${price}`;
  }

  @computed
  get extraFee() {
    const label = i18n.t(
      'land_edit_drawer_special_dates_page_main_label_extra_fee_label'
    );
    const check = !!this.land.extraHunters;
    const price = check ? formatPrice(this.land.extraFee) : '';
    const notSet = i18n.t('land_detail_drawer_null_item_placeholder');

    return check ? `${label} ${price}` : `${label} ${notSet}`;
  }

  @computed
  get rules() {
    const required = {
      required: true,
      message: FORM_INPUT_REQUIRED_MESSAGE
    };

    return {
      date: [required],
      ratio: [
        required,
        {
          message: FORM_INPUT_REQUIRED_MESSAGE,
          validator: (_, v) =>
            v === '0' ? Promise.reject() : Promise.resolve()
        }
      ]
    };
  }

  @computed
  get dates() {
    return this.specialDates.map((item) => {
      const format = SpecialDateCalc.toFormat({
        startAt: item.startAt,
        endAt: item.endAt,
        ratio: item.ratio,
        basicFee: this.land.basicFee,
        extraFee: this.land.extraFee,
        timezoneId: this.land.timezoneId
      });

      return {
        id: item.id,
        date: format.date,
        basicFee: format.basicFee,
        extraFee: format.extraFee
      };
    });
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = ({ land, form, changed }) => {
    this.land = land;
    this.specialDates = land.specialDates;

    this.form = form;

    this.changed = changed;
  };

  @action
  didUpdate = (props, preProps) => {
    const diffLand = diff(props?.land, preProps?.land);

    if (props?.land && diffLand) {
      this.land = props.land;
    }
  };

  onAdd = () => {
    this.form.resetFields();
    this.form.setFieldsValue({
      type: true
    });

    this.addModalVM.open();
  };

  onDelete = (id) => {
    this.deleteLandSpecialDateAPI(id);
  };

  onModalConfirm = () => {
    this.postLandSpecialDateAPI();
  };

  @action
  postLandSpecialDateAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();
      const reverseRatio = SpecialDateCalc.toRatio(data.ratio, data.type);

      const res = await LandServices.postLandSpecialDate({
        id: this.land.id,
        startAt: data.date[0].format('YYYY-MM-DD'),
        endAt: data.date[1].format('YYYY-MM-DD'),
        ratio: reverseRatio
      });

      await this.changed();

      this.addModalVM.close();
      runInAction(() => {
        this.specialDates = [...this.specialDates, res.data];
      });
    } catch (error) {
      const msg = ErrorServices.postLandSpecialDate(error);

      message.error(msg, 5);

      console.log('postLandSpecialDate', error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  // 刪除特殊日期定價.
  @action
  deleteLandSpecialDateAPI = async (sid) => {
    this.isAwait = true;

    try {
      await LandServices.deleteLandSpecialDate({
        id: this.land.id,
        sid
      });

      await this.changed();

      runInAction(() => {
        this.specialDates = this.specialDates.filter((item) => item.id !== sid);
      });
    } catch (error) {
      const msg = ErrorServices.deleteLandSpecialDate(error);

      console.log('deleteLandSpecialDate', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default SpecialDatesViewModel;
