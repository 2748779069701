import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import dayjs from 'dayjs';
import i18n from 'src/i18n';
import {
  BOOKING_STATUS,
  BookingCalc,
  checkBookingCancel,
  checkShowBookingEarning,
  getBookingStatus
} from 'src/constants/booking/booking';
import { assemble } from 'src/constants/methods';
import { formatPrice } from 'src/constants/price';
import BookingServices from 'src/services/BookingServices';
import ErrorServices from 'src/services/ErrorServices';
import ContactDrawerViewModel from 'src/components/ContactDrawer/viewModel';
import PackageDrawerViewModel from 'src/components/PackageDrawer/viewModel';
import BookingPriceDrawerViewModel from './components/BookingPriceDrawer/viewModel';
import BookingCancelModalViewModel from './components/BookingCancelModal/viewModel';
import BookingEditDrawerViewModel from './components/BookingEditDrawer/viewModel';

class BookingDetailViewModel {
  @observable booking = null;
  @observable isAwait = false;

  changed = () => {};
  statusChanged = () => {};
  landFeeChanged = () => {};

  priceDrawerVM = new BookingPriceDrawerViewModel();
  contactDrawerVM = new ContactDrawerViewModel();
  packageDrawerVM = new PackageDrawerViewModel();
  bookingCancelModalVM = new BookingCancelModalViewModel();
  editDrawerVM = new BookingEditDrawerViewModel();

  @computed
  get check() {
    return {
      isRequesting: this.booking.status === BOOKING_STATUS.requesting.value,
      cancel: checkBookingCancel({
        status: this.booking.status,
        startAt: this.booking.startAt,
        timezoneId: this.booking.land.timezoneId,
        disputedAt: this.booking.disputedAt
      }),
      packages: !!this.booking.packages.length,
      payouts: !!this.booking.payouts.length,
      earning: checkShowBookingEarning({
        status: this.booking.status,
        refundRatio: this.booking.refundRatio
      })
    };
  }

  @computed
  get disabled() {
    return {
      accept: this.isAwait,
      reject: this.isAwait
    };
  }

  @computed
  get note() {
    return (
      this.booking.note
      || i18n.t('booking_detail_drawer_booking_details_placeholder')
    );
  }

  @computed
  get id() {
    return this.booking.id;
  }

  @computed
  get status() {
    const valueStatus = BOOKING_STATUS[this.booking.status];
    const labelStatus = getBookingStatus({
      status: this.booking.status,
      startAt: this.booking.startAt,
      endAt: this.booking.endAt,
      disputedAt: this.booking.disputedAt
    });

    return {
      value: valueStatus.value,
      label: labelStatus.label
    };
  }

  @computed
  get land() {
    return this.booking.land.name;
  }

  @computed
  get duration() {
    const startAt = this.booking.startAt;
    const endAt = this.booking.endAt;
    const timezoneId = this.booking.land.timezoneId;

    const start = dayjs(this.booking.startAt).format('MMM D, YYYY');
    const end = dayjs(this.booking.endAt).format('MMM D, YYYY');
    const dates = BookingCalc.duration({ startAt, endAt, timezoneId });
    const unit = i18n.t('booking_detail_drawer_booking_duration_unit');

    return `(${dates} ${unit}) ${start} - ${end}`;
  }

  @computed
  get hunters() {
    const hunters = this.booking.hunters;
    const unit = i18n.t('booking_detail_drawer_booking_hunters_unit');

    return `${hunters} ${unit}`;
  }

  @computed
  get contact() {
    return assemble.name({
      firstName: this.booking.contact.firstName,
      lastName: this.booking.contact.lastName
    });
  }

  @computed
  get packages() {
    return this.booking.packages;
  }

  @computed
  get price() {
    const total = BookingCalc.earning({
      price: this.booking.price,
      refundRatio: this.booking.refundRatio
    });

    return formatPrice(total, true);
  }

  @computed
  get payouts() {
    const payableTotal = BookingCalc.payableTotalFromBookingDetail({
      price: BookingCalc.earning({
        price: this.booking.price,
        refundRatio: this.booking.refundRatio
      }),
      endAt: this.booking.endAt,
      payouts: this.booking.payouts
    });

    const payouts = this.booking.payouts.map((item) => {
      const price = formatPrice(item.amount, true);
      const date = dayjs(item.date).format('YYYY/MM/DD');
      const label = i18n.t(
        'booking_detail_drawer_booking_payout_paid_item_label'
      );

      return {
        price,
        label: `${label} ${date}`
      };
    });

    return {
      payableTotal: `~${formatPrice(payableTotal, true)}`,
      payouts
    };
  }

  constructor(props) {
    this.booking = props.booking;

    this.changed = props.changed;
    this.statusChanged = props.statusChanged;
    this.landFeeChanged = props.landFeeChanged;

    makeObservable(this);
  }

  @action
  didUpdate = (props, preProps) => {
    const diffBooking = !!diff(props.booking, preProps.booking);

    if (diffBooking) {
      this.booking = props.booking;
    }
  };

  onNote = () => {
    this.editDrawerVM.toNotePage();
    this.editDrawerVM.open({ booking: this.booking, changed: this.canceled });
  };

  onAccept = () => {
    this.putBookingAcceptAPI();
  };

  onReject = () => {
    this.putBookingRejectAPI();
  };

  onContact = () => {
    this.contactDrawerVM.open(this.booking.contact);
  };

  onPackage = (item) => {
    this.packageDrawerVM.openView(item);
  };

  onPrice = () => {
    this.priceDrawerVM.open({ booking: this.booking });
  };

  canceled = async () => {
    await this.changed();
    await this.statusChanged();
  };

  onCancel = () => {
    this.bookingCancelModalVM.open({
      booking: this.booking,
      changed: this.canceled
    });
  };

  @action
  putBookingAcceptAPI = async () => {
    this.isAwait = true;

    try {
      await BookingServices.putBookingAccept({ id: this.id });
      await this.changed();
      await this.statusChanged();
    } catch (error) {
      const msg = ErrorServices.putBookingAccept(error);
      console.log('putBookingAccept', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  putBookingRejectAPI = async () => {
    this.isAwait = true;

    try {
      await BookingServices.putBookingReject({ id: this.id });
      await this.changed();
      await this.statusChanged();
    } catch (error) {
      const msg = ErrorServices.putBookingReject(error);
      console.log('putBookingReject', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default BookingDetailViewModel;
