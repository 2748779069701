class RentalDetailDataModel {
  constructor(props) {
    this.id = props.id;

    this.bookingId = props.bookingId;

    this.status = props.status;

    this.startAt = props.startAt;
    this.endAt = props.endAt;

    this.isPackingCompleted = props.isPackingCompleted;

    this.price = props.price;

    this.profitRatio = props.profitRatio;

    this.profit = props.profit;

    this.totalDeposit = props.totalDeposit;

    this.totalDamageWaiver = props.totalDamageWaiver;

    this.refund = props.refund;

    this.refundRatio = props.refundRatio;

    this.report = props.report;

    this.disputedAt = props.disputedAt;

    this.cancelledAt = props.cancelledAt;

    this.completedAt = props.completedAt;

    this.packageCount = props.packageCount;

    this.copies = props.copies;

    this.trade = {
      status: props.trade.status,
      email: props.trade.email,
      currency: props.trade.currency,
      amount: props.trade.amount,
      platformServiceFee: props.trade.platformServiceFee,
      tax: props.trade.tax
    };

    this.land = {
      id: props.land.id,
      name: props.land.name,
      position: props.land.position,
      country: props.land.country,
      state: props.land.state,
      county: props.land.county || '',
      street: props.land.street,
      postcode: props.land.postcode,
      timezoneId: props.land.timezoneId
    };

    this.consumer = {
      id: props.consumer.id,
      firstName: props.consumer.firstName,
      lastName: props.consumer.lastName,
      displayName: props.consumer.displayName,
      gender: props.consumer.gender,
      email: props.consumer.email,
      phone: props.consumer.phone
    };

    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }
}

export default RentalDetailDataModel;
