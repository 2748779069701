import API from 'src/apis';
import PackageDataModel from 'src/models/response/PackageDataModel';
import Auth from './auth';

class PackageServices {
  // 讀取帳號所有的行程.
  static async getPackageList() {
    const res = await API.packageAPI.getPackageList({
      token: Auth.getToken()
    });
    const list = res.data?.list || [];

    return {
      data: list.map((item) => new PackageDataModel(item))
    };
  }

  // 新增帳號的行程.
  static async postPackageCreate({ name, type, price, description }) {
    const res = await API.packageAPI.postPackageCreate({
      token: Auth.getToken(),
      name,
      type,
      price,
      description
    });

    return {
      data: res.data
    };
  }

  // 更新帳號的行程.
  static async putPackage({ id, name, type, price, description }) {
    const res = await API.packageAPI.putPackage({
      token: Auth.getToken(),
      id,
      name,
      type,
      price,
      description
    });

    return {
      data: res.data
    };
  }

  // 刪除帳號的行程.
  static async deletePackage({ id }) {
    const res = await API.packageAPI.deletePackage({
      token: Auth.getToken(),
      id
    });

    return {
      data: res.data
    };
  }
}

export default PackageServices;
