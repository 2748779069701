import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ICONS from 'src/constants/icons';
import clsx from 'clsx';
import ResetButton from '../ResetButton';
import styles from './styles.module.scss';

@observer
class FilterButton extends React.Component {
  render() {
    const { className, icon, label, ...ps } = this.props;

    return (
      <ResetButton className={clsx([className, styles.button])} {...ps}>
        <img className={styles.icon} src={icon} alt="filter" />
        <div className={styles.label}>{label}</div>
      </ResetButton>
    );
  }
}

FilterButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  align: PropTypes.string
};

FilterButton.defaultProps = {
  className: '',
  icon: ICONS.bookingsFilter,
  label: '',
  type: 'black',
  align: 'center'
};

export default FilterButton;
