import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import optionStore from 'src/global/optionStore';
import {
  FORM_INPUT_REQUIRED_MESSAGE,
  DEACTIVATE_ACCOUNT_REASON
} from 'src/constants/config';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';
import { ROUTES } from 'src/constants/routes';

export const PAGES = {
  description: 'description',
  form: 'form',
  confirm: 'confirm'
};

class DeactivateAccountModalViewModel {
  @observable page = PAGES.description;
  @observable profile = null;
  @observable isAwait = null;

  state = '';
  reason = '';

  form = null;

  actions = null;

  router = null;

  modalVM = new ActionModalViewModel();

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      footer: this.isAwait
    };
  }

  @computed
  get rules() {
    return {
      state: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ],
      reason: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ]
    };
  }

  @computed
  get options() {
    return {
      states: Object.keys(optionStore.states).map((key) => {
        return {
          value: key,
          label: optionStore.states[key].name
        };
      }),
      reasons: Object.values(DEACTIVATE_ACCOUNT_REASON)
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form, router }) => {
    this.form = form;
    this.router = router;
  };

  didMount = (context) => {
    this.actions = context.actions;
  };

  @action
  open = ({ profile }) => {
    this.page = PAGES.description;
    this.profile = profile;
    this.form.resetFields();

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  @action
  onBack = () => {
    switch (this.page) {
      case PAGES.form:
        this.page = PAGES.description;
        break;

      case PAGES.confirm:
        this.page = PAGES.form;
        break;

      default:
        break;
    }
  };

  @action
  onNext = async () => {
    switch (this.page) {
      case PAGES.description:
        this.page = PAGES.form;
        break;

      case PAGES.form: {
        try {
          const data = await this.form.validateFields();

          runInAction(() => {
            this.state = data.state;
            this.reason = data.reason;

            this.page = PAGES.confirm;
          });
        } catch (error) {
          console.log(error);
        }

        break;
      }

      default:
        break;
    }
  };

  onDelete = () => {
    this.postDeleteAccountRequestAPI();
  }

  @action
  postDeleteAccountRequestAPI = async () => {
    this.isAwait = true;

    try {
      await UserService.postDeleteAccountRequest({
        deleteReside: this.state,
        deleteReason: this.reason
      });

      await this.postUserLogoutAPI();
    } catch (error) {
      const msg = ErrorServices.postDeleteAccountRequest(error);
      console.log('postDeleteAccountRequest', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  postUserLogoutAPI = async () => {
    try {
      await UserService.postUserLogout();

      this.close();

      this.actions.updateProfile(null);

      this.router.navigate(`/${ROUTES.client.value}`, { replace: true });
    } catch (error) {
      const msg = ErrorServices.postUserLogout(error);
      console.log('postUserLogout', msg);
    }
  };

}

export default DeactivateAccountModalViewModel;
