import { makeObservable, observable, action, computed } from 'mobx';
import i18n from 'src/i18n';
import { VERIFY_CODE_RESET_SECOND } from 'src/constants/config';

class ResendButtonViewModel {
  @observable second = 0;
  timer = null;

  @computed
  get check() {
    return {
      second: this.second === 0
    };
  }

  @computed
  get disabled() {
    return !!this.second;
  }

  constructor() {
    makeObservable(this);
  }

  // 開啟排程.
  @action
  setTimer = () => {
    this.clearTimer();

    this.second = VERIFY_CODE_RESET_SECOND;
    this.timer = setInterval(this.reduceSecond, 1000);
  };

  // 清除排程.
  @action
  clearTimer = () => {
    this.second = 0;
    this.timer = clearInterval(this.timer);
  };

  // 倒數.
  @action
  reduceSecond = () => {
    this.second -= 1;

    if (this.second === 0) {
      this.clearTimer();
    }
  };
}

export default ResendButtonViewModel;
