import LandSpecieDataModel from './LandSpecieDataModel';
import LandSpecialDateDataModel from './LandSpecialDateDataModel';
import LandDiscountDataModel from './LandDiscountDataModel';
import PackageDataModel from './PackageDataModel';

class LandDetailDataModel {
  constructor(data) {
    this.id = data.id;

    this.name = data.name;

    // 描述.
    this.description = data.description;

    // 規則.
    this.rules = data.rules;

    // 定位.
    this.position = data.position;

    // 國家.
    this.country = data.country;
    // 地區.
    this.state = data.state;
    // 城市.
    this.county = data.county || '';
    // 街道.
    this.street = data.street || '';
    // 郵遞區號.
    this.postcode = data.postcode;

    // 獵場的狀態.
    this.status = data.status;

    // 獵場大小.
    this.acres = data.acres;

    // 基本費用.
    this.basicFee = data.basicFee;
    // 額外費用.
    this.extraFee = data.extraFee;

    // 基本的獵人.
    this.basicHunters = data.basicHunters;
    // 額外的獵人.
    this.extraHunters = data.extraHunters;

    // 最後的預日期.
    this.finalBookingDate = data.finalBookingDate;

    // 曝光度.
    this.rank = data.rank;

    // 時區 id.
    this.timezoneId = data.timezoneId;
    // 時區偏移.
    this.timezoneOffset = data.timezoneOffset;

    // 獵場的獵物種類.
    this.species = data.species.map((item) => new LandSpecieDataModel(item));

    // 獵場照片.
    this.photos = data.photos.map((item) => {
      return {
        id: item.id,
        priority: item.priority,
        size: item.size,
        src: item.src
      };
    });

    // 待補充.
    this.posts = data.posts;

    // 已經擁有的行程.
    this.packages = data.packages.map((item) => {
      return {
        id: item.id,
        data: new PackageDataModel(item.presetPackage)
      };
    });

    // 特別日定價.
    this.specialDates = data.specialDates.map((item) => {
      return new LandSpecialDateDataModel(item);
    });

    // 長期預約的折扣.
    this.discounts = data.discounts.map((item) => {
      return new LandDiscountDataModel(item);
    });

    // 待補充.
    this.isFavorite = data.isFavorite;

    // 評分.
    this.rating = data.rating;

    this.meetCity = data.meetCity;
    this.meetPostcode = data.meetPostcode;
    this.meetState = data.meetState;
    this.meetStreet = data.meetStreet;

    this.meetPosition = data.meetPosition;

    this.meetDescription = data.meetDescription;

    // 有沒有看過條款
    this.termsAgreedIn = data.termsAgreedIn;
  }
}

export default LandDetailDataModel;
