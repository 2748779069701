import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ActionDrawer from 'src/components/ActionDrawer';
import ResetTabs from 'src/components/ResetTabs';
import styles from './styles.module.scss';

@observer
class LandDetailDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
        <div className={styles.container}>
          {this.vm.inited ? (
            <ResetTabs tabFull items={this.vm.pages} size="large" />
          ) : null}
        </div>
      </ActionDrawer>
    );
  }
}

LandDetailDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

LandDetailDrawer.defaultProps = {};

export default LandDetailDrawer;
