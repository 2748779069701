import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ICONS from 'src/constants/icons';
import { ROUTES } from 'src/constants/routes';
import withRouter from 'src/components/withRouter';
import PageHeader from 'src/components/PageHeader';
import ResetButton from 'src/components/ResetButton';
import ResetSelect from 'src/components/ResetSelect';
import PageContainer from 'src/components/PageContainer';
import PageCardsContainer from 'src/components/PageCardsContainer';
import CreateLandDrawer from './components/CreateLandDrawer';
import Placeholder from './components/Placeholder';
import LandsPageViewModel from './viewModel';
import styles from './styles.module.scss';
import LandCard from './components/LandCard';

@observer
class LandsPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new LandsPageViewModel({ router: props.router });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.inited ? (
      <>
        <PageContainer>
          <PageHeader label={ROUTES.lands.label} />

          <div className={styles.function}>
            <ResetButton
              className={styles.createButton}
              type="primary"
              onClick={this.vm.onCreate}
              disabled={this.vm.disabled.create}
            >
              <img className={styles.icon} src={ICONS.add} alt="add" />

              <div className={styles.label}>
                {i18n.t('lands_page_add_land_button_label')}
              </div>
            </ResetButton>

            <div className={styles.filter}>
              <ResetSelect
                className={styles.select}
                allowClear
                options={this.vm.options.lands}
                value={this.vm.landId}
                onChange={this.vm.onLandIdChange}
                disabled={this.vm.disabled.filter}
                placeholder={i18n.t('lands_page_select_land_all_land_label')}
                placeholderSolid
                showSearch
                optionFilterProp="label"
              />
            </div>
          </div>

          {this.vm.check.lands ? (
            <PageCardsContainer className={styles.cards} paddingBottom>
              {this.vm.filterLands.map((vm) => (
                <LandCard vm={vm} deleted={this.vm.deleted} key={vm.id} />
              ))}
            </PageCardsContainer>
          ) : (
            <Placeholder onClick={this.vm.onCreate} />
          )}
        </PageContainer>

        <CreateLandDrawer vm={this.vm.createLandDrawerVM} />
      </>
    ) : null;
  }
}

LandsPage.propTypes = {
  router: PropTypes.object.isRequired
};

LandsPage.defaultProps = {};

export default withRouter(LandsPage);
