import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ROUTES } from 'src/constants/routes';
import ICONS from 'src/constants/icons';
import i18n from 'src/i18n';
import withRouter from 'src/components/withRouter';
import ResetSelect from 'src/components/ResetSelect';
import PageHeader from 'src/components/PageHeader';
import FilterButton from 'src/components/FilterButton';
import BookingCard from 'src/components/BookingCard';
import PageContainer from 'src/components/PageContainer';
import PageCardsContainer from 'src/components/PageCardsContainer';
import NoLandLabel from 'src/components/NoLandLabel';
import CustomPopover from 'src/components/CustomPopover';
import ResetButton from 'src/components/ResetButton';
import BookingSettingDrawer from './components/BookingSettingDrawer';
import BookingsFilterDrawer from './components/BookingsFilterDrawer';
import BookingsPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class BookingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new BookingsPageViewModel({ router: props.router });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  renderContent = () => {
    return this.vm.inited ? (
      <>
        <PageContainer>
          <PageHeader back label={ROUTES.bookings.label}>
            {this.vm.check.lands && (
              <CustomPopover items={this.vm.popoverItems}>
                <ResetButton type="text">
                  <img
                    className={styles.moreIcon}
                    src={ICONS.more}
                    alt="more"
                  />
                </ResetButton>
              </CustomPopover>
            )}
          </PageHeader>

          {this.vm.check.lands ? (
            <>
              <div className={styles.filters}>
                <div className={styles.item1}>
                  <ResetSelect
                    className={styles.landsSelect}
                    allowClear
                    options={this.vm.options.lands}
                    value={this.vm.filter.landId}
                    onChange={this.vm.onLandIdChange}
                    disabled={this.vm.disabled.filter}
                    placeholder={i18n.t(
                      'bookings_page_filter_select_land_placeholder'
                    )}
                    placeholderSolid
                    showSearch
                    optionFilterProp="label"
                  />
                </div>

                <div className={styles.item2}>
                  <FilterButton
                    label={i18n.t('bookings_page_filter_button_label')}
                    onClick={this.vm.onFilterDrawer}
                    disabled={this.vm.disabled.filter}
                  />

                  <ResetSelect
                    className={styles.statusSelect}
                    allowClear
                    options={this.vm.options.filterStatus}
                    value={this.vm.filterStatus}
                    onChange={this.vm.onFilterStatusChange}
                    disabled={this.vm.disabled.filter}
                    placeholder={this.vm.labels.allBookings}
                    placeholderSolid
                    showSearch
                    optionFilterProp="label"
                  />
                </div>
              </div>

              <PageCardsContainer
                paddingBottom
                onWaypointEnter={this.vm.onWaypointEnter}
              >
                {this.vm.bookings.map((vm) => (
                  <BookingCard vm={vm} key={vm.id} />
                ))}
              </PageCardsContainer>
            </>
          ) : (
            <NoLandLabel />
          )}
        </PageContainer>

        <BookingSettingDrawer vm={this.vm.bookingSettingDrawerVM} />

        <BookingsFilterDrawer vm={this.vm.filterDrawerVM} />
      </>
    ) : null;
  };

  render() {
    return this.vm.inited ? this.renderContent() : null;
  }
}

BookingsPage.propTypes = {
  router: PropTypes.object.isRequired
};

BookingsPage.defaultProps = {};

export default withRouter(BookingsPage);
