import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { diff } from 'deep-diff';
import i18n from 'src/i18n';
import {
  BUSINESS_DAY_KEY_FORMAT,
  isBookingAfterDays,
  isLessFinalBookingDate
} from 'src/constants/calendar';
import { assemble } from 'src/constants/methods';
import configStore from 'src/global/configStore';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import BookingDetailDrawerViewModel from 'src/components/BookingDetailDrawer/viewModel';
import calendarStore from '../../modules/CalendarStore';
import TagModalViewModel from '../TagModal/viewModel';

class BusinessDayDrawerViewModel {
  @observable land = null;

  // 這是 BusinessDayCardViewModel
  @observable businessDay = null;
  @observable available = false;

  @observable isAwait = false;

  drawerVM = new ActionDrawerViewModel();
  tagModalVM = new TagModalViewModel();
  bookingDetailDrawerVM = new BookingDetailDrawerViewModel();

  @computed
  get check() {
    const booking = !!this.businessDay?.booking;
    const isAfter = isBookingAfterDays(this.businessDay?.date);
    const isLessFinal = isLessFinalBookingDate({
      date: this.businessDay?.date,
      finalBookingDate: this.land?.finalBookingDate
    });

    return {
      show: !!this.land && !!this.businessDay,
      booking: !!this.businessDay?.booking,
      isAfter,
      isLessFinal,
      statusSwitch: !booking && isAfter && isLessFinal
    };
  }

  @computed
  get disabled() {
    return {
      status: this.isAwait,
      nextDate: this.isAwait,
      prevDate: this.isAwait,
      addTag: this.isAwait
    };
  }

  @computed
  get labels() {
    let status = this.businessDay.isAvailable
      ? i18n.t('calendar_page_business_day_status_available')
      : i18n.t('calendar_page_business_day_status_un_available');

    if (!this.check.isLessFinal) {
      status = i18n.t('calendar_page_business_day_status_final');
    }

    let booking = '';

    if (this.check.booking) {
      const bookingId = this.businessDay.booking.id;
      const consumer = this.businessDay.booking.consumer;
      const name = assemble.name({
        firstName: consumer.firstName,
        lastName: consumer.lastName
      });

      booking = `${bookingId} - ${name}`;

      status = i18n.t('calendar_page_business_day_status_booking');
    }

    return {
      date: dayjs(this.businessDay.date).format('MMM D, YYYY'),
      status,
      isAvailable: this.businessDay.isAvailable,
      booking
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.land = props.land;
    this.businessDay = props.businessDay;
  };

  @action
  didUpdate = (props, preProps) => {
    const diffLand = diff(props.land, preProps.land);
    const diffBusinessDay = diff(props.businessDay, preProps.businessDay);

    if (diffLand) {
      this.land = props.land;
    }

    if (diffBusinessDay) {
      this.businessDay = props.businessDay;
    }
  };

  @action
  open = () => {
    this.drawerVM.open();
  };

  @action
  close = () => {
    this.drawerVM.close();
  };

  @action
  onAvailableChange = (val) => {
    this.putLandBusinessDayAPI(val);
  };

  onBooking = () => {
    this.bookingDetailDrawerVM.openBooking({
      bookingId: this.businessDay.booking?.id,
      statusChanged: calendarStore.refresh,
      landFeeChanged: calendarStore.refresh
    });
  };

  onTagCreate = () => {
    this.tagModalVM.openCreate({
      land: this.land,
      businessDay: this.businessDay
    });
  };

  onTagEdit = (tag) => {
    this.tagModalVM.openEdit({
      land: this.land,
      businessDay: this.businessDay,
      tag
    });
  };

  @action
  putLandBusinessDayAPI = async (isAvailable) => {
    this.isAwait = true;

    try {
      // 當前月份.
      const date = dayjs(this.businessDay.date).format(BUSINESS_DAY_KEY_FORMAT);

      await LandServices.putLandBusinessDay({
        id: this.land.id,
        days: [
          {
            date,
            isAvailable
          }
        ]
      });

      this.businessDay.update({ isAvailable });
    } catch (error) {
      const msg = ErrorServices.putLandBusinessDay(error);
      console.log('putLandBusinessDay', msg, error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default BusinessDayDrawerViewModel;
