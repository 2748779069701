import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';
import clsx from 'clsx';
import './reset.scss';

@observer
class ResetTabs extends React.Component {
  render() {
    const { className, tabFull, tabPadding, ...ps } = this.props;

    return (
      <Tabs
        className={clsx([
          'resetTabs',
          tabFull && 'tabFull',
          tabPadding && 'tabPadding',
          className
        ])}
        {...ps}
      />
    );
  }
}

ResetTabs.propTypes = {
  className: PropTypes.string,
  tabFull: PropTypes.bool,
  tabPadding: PropTypes.bool
};

ResetTabs.defaultProps = {
  className: '',
  tabFull: false,
  tabPadding: false
};

export default ResetTabs;
