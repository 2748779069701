import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';

class LandAddPackageModalViewModel {
  @observable land = null;
  @observable packages = [];
  @observable isAwait = false;

  changed = () => {};

  modalVM = new ActionModalViewModel();

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      footer: this.isAwait
    };
  }

  @computed
  get options() {
    const landPackages = this.land?.packages || [];
    const filterPackages = this.packages.filter(
      (item) =>
        !landPackages.find((lItem) => item.id === lItem.data.id)
    );

    return {
      packages: filterPackages.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      })
    };
  }

  @computed
  get rules() {
    return {
      package: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form }) => {
    this.form = form;
  };

  @action
  open = ({ land, packages, changed }) => {
    this.land = land;
    this.packages = packages;
    this.changed = changed;

    this.form.resetFields();

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  onConfirm = () => {
    this.postLandPackageAPI();
  };

  @action
  postLandPackageAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();

      await LandServices.postLandPackage({
        id: this.land.id,
        pid: data.package
      });

      await this.changed();

      this.close();
    } catch (error) {
      const msg = ErrorServices.postLandPackage(error);
      console.log('postLandPackage', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default LandAddPackageModalViewModel;
