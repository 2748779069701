import React from 'react';
import PropTypes from 'prop-types';
import { AppStateContext } from 'src/stores';
import withRouter from 'src/components/withRouter';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';

const withProfile = (WrappedComponent, isRequired = false) => {
  class WithProfileHOC extends React.Component {
    static contextType = AppStateContext;
    static propTypes = {
      router: PropTypes.object.isRequired
    };

    constructor(props) {
      super(props);
      this.state = { isAwait: true };
    }

    async componentDidMount() {
      this.getUserProfileAPI();
    }

    setAwait = (value) => {
      this.setState(() => {
        return { isAwait: value };
      });
    };

    getUserProfileAPI = async () => {
      try {
        const res = await UserService.getUserProfile();

        // 更新 profile.
        this.context.actions.updateProfile(res.data);

        // getProfile api 讀取結束.
        this.setAwait(false);
      } catch (error) {
        const msg = ErrorServices.getUserProfile(error);

        // 登出.
        this.postUserLogoutAPI();

        console.log('getUserProfile', msg);
      }
    };

    postUserLogoutAPI = async () => {
      try {
        // 登出 + remove token.
        const res = await UserService.postUserLogout();
      } catch (error) {
        const msg = ErrorServices.postUserLogout(error);

        console.log('postUserLogout', msg);
      } finally {
        requestAnimationFrame(() => {
          // 刪除 profile.
          this.context.actions.updateProfile(null);

          if (isRequired) {
            // 回到首頁.
            this.props.router?.navigate('/', { replace: true });
          } else {
            // getProfile api 讀取結束.
            this.setAwait(false);
          }
        });
      }
    };

    render() {
      const { router, ...ps } = this.props;
      const profile = this.context.state.profile;

      return (isRequired && !profile) || this.state.isAwait ? null : (
        <WrappedComponent {...ps} profile={profile} />
      );
    }
  }

  return withRouter(WithProfileHOC);
};

export default withProfile;
