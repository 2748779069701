import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ResetButton from 'src/components/ResetButton';
import PackageDrawer from 'src/components/PackageDrawer';
import BasicCard from 'src/components/BasicCard';
import styles from './styles.module.scss';

@observer
class PackageCard extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ deleted: props.deleted });
  }

  render() {
    return (
      <>
        <BasicCard className={styles.container}>
          <div className={styles.header}>
            <div className={styles.type}>{this.vm.type}</div>

            <div className={styles.price}>{this.vm.price}</div>
          </div>

          <div className={styles.divider} />

          <div className={styles.description}>{this.vm.description}</div>

          <div className={styles.footer}>
            <ResetButton
              className={styles.detailButton}
              onClick={this.vm.onDetail}
            >
              {i18n.t('packages_page_package_card_detail_button_label')}
            </ResetButton>
          </div>
        </BasicCard>

        <PackageDrawer vm={this.vm.packageDrawerVM} />
      </>
    );
  }
}

PackageCard.propTypes = {
  vm: PropTypes.object.isRequired,
  deleted: PropTypes.func
};

PackageCard.defaultProps = {
  deleted: () => {}
};

export default PackageCard;
