import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ActionDrawer from 'src/components/ActionDrawer';
import ActionDrawerHeader from 'src/components/ActionDrawer/components/ActionDrawerHeader';
import ActionDrawerItem from 'src/components/ActionDrawer/components/ActionDrawerItem';
import styles from './styles.module.scss';

@observer
class RentalStatusHistoriesDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
        <div className={styles.container}>
          <ActionDrawerHeader
            content={i18n.t('rental_status_histories_drawer_header')}
            paddingBottom
          />

          <div className={styles.histories}>
            {this.vm.mapHistories.map((item, i) => {
              const last = this.vm.mapHistories.length === i + 1;
              const divider = !last;

              return (
                <ActionDrawerItem
                  label={item.label}
                  key={item.id}
                  divider={divider}
                >
                  {item.value}
                </ActionDrawerItem>
              );
            })}
          </div>
        </div>
      </ActionDrawer>
    );
  }
}

RentalStatusHistoriesDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

RentalStatusHistoriesDrawer.defaultProps = {};

export default RentalStatusHistoriesDrawer;
