import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import {
  FORM_INPUT_REQUIRED_MESSAGE,
  FORM_INPUT_FORMAT_ERROR_MESSAGE,
  PASSWORD_LENGTH,
  REGEXPS
} from 'src/constants/config';
import { message } from 'antd';
import { getHost } from 'src/apis/utils';
import i18n from 'src/i18n';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';

class LoginViewModel {
  @observable isAwait = false;

  form = null;
  context = null;

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      confirm: this.isAwait,
      others: this.isAwait
    };
  }

  @computed
  get rules() {
    const required = {
      required: true,
      message: FORM_INPUT_REQUIRED_MESSAGE
    };

    return {
      email: [
        required,
        {
          pattern: REGEXPS.email,
          message: FORM_INPUT_FORMAT_ERROR_MESSAGE
        }
      ],
      password: [
        required,
        {
          min: PASSWORD_LENGTH,
          message: FORM_INPUT_FORMAT_ERROR_MESSAGE
        }
      ]
    };
  }

  constructor(props) {
    this.form = props.form;
    makeObservable(this);
  }

  didMount = (context) => {
    this.context = context;
  };

  onConfirm = () => {
    this.postUserLogin();
  };

  onFacebook = () => {
    const host = getHost();
    const api = `${host}/api/v1/facebook/login`;

    // https://dev-7ddd5a31.kavava.com/api/v1/facebook/login?redirect=http://localhost:3000/booking/7CuS2h
    const link = `${api}?redirect=${window.location.href}`;

    window.location.replace(link);
  };

  onApple = () => {
    const host = getHost();
    const api = `${host}/api/v1/apple/login`;
    const link = `${api}?redirect=${window.location.href}`;

    window.location.replace(link);
  };

  @action
  postUserLogin = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();
      const res = await UserService.postUserLogin({
        email: data.email,
        password: data.password
      });

      this.context.actions.updateProfile(res.data);
    } catch (error) {
      const msg = ErrorServices.postUserLogin(error);

      console.log(msg);

      switch (error.response?.status) {
        case 401:
        case 404: {
          const content = i18n.t('post_user_login_404_content');

          message.error(content, 5);

          break;
        }


        default:
      }
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default LoginViewModel;
