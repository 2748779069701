import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import withRouter from 'src/components/withRouter';
import MainHeader from 'src/components/MainLayout/components/MainHeader';
import ResetButton from 'src/components/ResetButton';
import Footer from '../Footer';
import BookingViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class Booking extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new BookingViewModel({
      router: props.router,
      toLogin: props.toLogin
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.check.show ? (
      <div className={styles.container}>
        <div className={styles.basicContainer}>
          <MainHeader menu />
        </div>

        <div className={styles.basicContainer}>
          <div className={styles.landName}>{this.vm.land.name}</div>
        </div>

        <div className={styles.basicContainer}>
          <div className={styles.section}>
            <div className={styles.title}>
              <div className={styles.label}>
                {i18n.t('check_booking_page_booking_page_booking_detail_label')}
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('check_booking_page_booking_page_booking_id_label')}
              </div>

              <div className={styles.content}>{this.vm.labels.bookingId}</div>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t(
                  'check_booking_page_booking_page_booking_start_at_label'
                )}
              </div>

              <div className={styles.content}>{this.vm.labels.startAt}</div>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('check_booking_page_booking_page_booking_end_at_label')}
              </div>

              <div className={styles.content}>{this.vm.labels.endAt}</div>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t(
                  'check_booking_page_booking_page_booking_duration_label'
                )}
              </div>

              <div className={styles.content}>{this.vm.labels.duration}</div>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t(
                  'check_booking_page_booking_page_booking_hunters_label'
                )}
              </div>

              <div className={styles.content}>{this.vm.labels.hunters}</div>
            </div>
          </div>
        </div>

        {this.vm.check.packages && (
          <div className={styles.basicContainer}>
            <div className={styles.section}>
              <div className={styles.title}>
                <div className={styles.label}>
                  {i18n.t(
                    'check_booking_page_booking_page_booking_packages_label'
                  )}
                </div>

                <div className={styles.content}>
                  {i18n.t(
                    'check_booking_page_booking_page_booking_packages_unit'
                  )}
                </div>
              </div>

              {this.vm.packages.map((item) => (
                <div className={styles.item} key={item.id}>
                  <div className={styles.label}>{item.name}</div>
                  <div className={styles.content}>{item.count}</div>
                </div>
              ))}
            </div>
          </div>
        )}

        {this.vm.check.rental && (
          <div className={styles.basicContainer}>
            <div className={styles.section}>
              <div className={styles.title}>
                <div className={styles.label}>
                  {i18n.t('check_booking_page_booking_page_rental_items_label')}
                </div>

                <div className={styles.content}>
                  {i18n.t('check_booking_page_booking_page_rental_items_unit')}
                </div>
              </div>

              {this.vm.items.map((item) => (
                <div className={styles.item} key={item.id}>
                  <div className={styles.label}>{item.name}</div>
                  <div className={styles.content}>{item.count}</div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className={styles.basicContainer}>
          <div className={styles.section}>
            <div className={styles.title}>
              {i18n.t('check_booking_page_booking_page_booking_earning_label')}
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('check_booking_page_booking_page_booking_price_label')}
              </div>

              <div className={styles.content}>
                {this.vm.labels.bookingPrice}
              </div>
            </div>

            {this.vm.check.rental && (
              <div className={styles.item}>
                <div className={styles.label}>
                  {i18n.t(
                    'check_booking_page_booking_page_rental_profit_label'
                  )}
                </div>

                <div className={styles.content}>
                  {this.vm.labels.rentalProfit}
                </div>
              </div>
            )}

            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('check_booking_page_booking_page_total_label')}
              </div>

              <div className={styles.content}>{this.vm.labels.total}</div>
            </div>
          </div>
        </div>

        <div className={styles.space} />

        {this.vm.check.requesting ? (
          <div className={styles.basicContainer}>
            <div className={styles.requestingFooter}>
              <ResetButton
                className={styles.button}
                type="primary"
                onClick={this.vm.onAccepted}
                disabled={this.vm.disabled.actions}
              >
                {i18n.t(
                  'check_booking_page_booking_page_accepted_button_label'
                )}
              </ResetButton>

              <ResetButton
                className={styles.button}
                onClick={this.vm.onReject}
                disabled={this.vm.disabled.actions}
              >
                {i18n.t('check_booking_page_booking_page_reject_button_label')}
              </ResetButton>
            </div>
          </div>
        ) : (
          <div className={styles.basicContainer}>
            <div className={styles.status}>
              <ResetButton className={styles.button} disabled>
                {this.vm.labels.status}
              </ResetButton>
            </div>
          </div>
        )}

        <div className={styles.basicContainer}>
          <Footer />
        </div>
      </div>
    ) : null;
  }
}

Booking.propTypes = {
  router: PropTypes.object.isRequired,
  toLogin: PropTypes.func.isRequired
};

Booking.defaultProps = {};

export default withRouter(Booking);
