import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import BigNumber from 'bignumber.js';
import i18n from 'src/i18n';
import { BookingCalc } from 'src/constants/booking/booking';
import { formatPrice } from 'src/constants/price';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';

const defItem = {
  label: '',
  value: '',
  items: []
};

class BookingPriceDrawerViewModel {
  @observable booking = null;

  drawerVM = new ActionDrawerViewModel();

  @computed
  get check() {
    const hunters = BookingCalc.hunters({
      hunters: this.booking?.hunters || 0,
      basicHunters: this.booking?.basicHunters || 0
    });

    let checkDiscount = false;

    if (this.booking?.discounts?.length) {
      const discount = BookingCalc.checkDiscount({
        startAt: this.booking.startAt,
        endAt: this.booking.endAt,
        timezoneId: this.booking.land.timezoneId,
        discounts: this.booking.discounts
      });

      checkDiscount = discount;
    }

    return {
      booking: !!this.booking,
      extraHunters: !!hunters.extra,
      packages: !!this.booking?.packages.length,
      discount: checkDiscount,
      refund: BookingCalc.checkRefundEarning(this.booking?.refundRatio || 1)
    };
  }

  @computed
  get dates() {
    return this.check.booking
      ? BookingCalc.duration({
        startAt: this.booking.startAt,
        endAt: this.booking.endAt,
        timezoneId: this.booking.land.timezoneId
      })
      : 0;
  }

  @computed
  get hunters() {
    return this.check.booking
      ? BookingCalc.hunters({
        hunters: this.booking.hunters,
        basicHunters: this.booking.basicHunters
      })
      : { basic: 0, extra: 0 };
  }

  @computed
  get basicFee() {
    if (this.check.booking) {
      const fees = BookingCalc.basicFee({
        startAt: this.booking.startAt,
        endAt: this.booking.endAt,
        basicFee: this.booking.basicFee,
        specialDates: this.booking.specialDates,
        timezoneId: this.booking.land.timezoneId
      });
      const dateLabel = i18n.t('booking_price_drawer_date_unit');
      const total = formatPrice(fees.total);

      return {
        label: i18n.t('booking_price_drawer_basic_fee_label'),
        value: `$ ${total}`,
        items: Object.keys(fees.items).map((key) => {
          const days = fees.items[key];
          const label = `$${key} x ${days} ${dateLabel}`;
          const value = formatPrice(new BigNumber(key).multipliedBy(days));

          return {
            label,
            value: `$ ${value}`
          };
        })
      };
    }

    return defItem;
  }

  @computed
  get extraFee() {
    if (this.check.booking && this.check.extraHunters) {
      const hunters = BookingCalc.hunters({
        hunters: this.booking.hunters,
        basicHunters: this.booking.basicHunters
      });
      const fees = BookingCalc.extraFee({
        startAt: this.booking.startAt,
        endAt: this.booking.endAt,
        specialDates: this.booking.specialDates,
        extraFee: this.booking.extraFee,
        hunters: this.booking.hunters,
        basicHunters: this.booking.basicHunters,
        timezoneId: this.booking.land.timezoneId
      });
      const formatTotal = formatPrice(fees.total);
      const huntersUnitLabel = i18n.t('booking_price_drawer_hunters_unit');
      const dateUnitLabel = i18n.t('booking_price_drawer_date_unit');

      return {
        label: i18n.t('booking_price_drawer_extra_fee_label'),
        value: `$ ${formatTotal}`,
        items: Object.keys(fees.items).map((key) => {
          const days = fees.items[key];

          // 額外獵人一個人多少錢
          const fee = new BigNumber(key).dividedBy(hunters.extra);

          const label = `$${fee} x ${hunters.extra} ${huntersUnitLabel} x ${days} ${dateUnitLabel}`;
          const value = formatPrice(new BigNumber(key).multipliedBy(days));

          return {
            label,
            value: `$ ${value}`
          };
        })
      };
    }

    return defItem;
  }

  @computed
  get packages() {
    if (this.check.booking) {
      const total = BookingCalc.packages(this.booking.packages);
      const formatTotal = formatPrice(total);

      return {
        label: i18n.t('booking_price_drawer_packages_label'),
        value: `$ ${formatTotal}`,
        items: this.booking.packages.map((item) => {
          const fee = new BigNumber(item.price).multipliedBy(item.count);
          const formatFee = formatPrice(fee);

          return {
            label: `${item.name} x ${item.count}`,
            value: `$ ${formatFee}`
          };
        })
      };
    }

    return defItem;
  }

  @computed
  get discount() {
    if (this.check.booking && this.check.discount) {
      const basicFees = BookingCalc.basicFee({
        startAt: this.booking.startAt,
        endAt: this.booking.endAt,
        basicFee: this.booking.basicFee,
        specialDates: this.booking.specialDates,
        timezoneId: this.booking.land.timezoneId
      });

      const extraFees = BookingCalc.extraFee({
        startAt: this.booking.startAt,
        endAt: this.booking.endAt,
        hunters: this.booking.hunters,
        basicHunters: this.booking.basicHunters,
        extraFee: this.booking.extraFee,
        specialDates: this.booking.specialDates,
        timezoneId: this.booking.land.timezoneId
      });

      const total = new BigNumber(basicFees.total)
        .plus(extraFees.total)
        .toString();

      const discount = BookingCalc.discount({
        price: total,
        startAt: this.booking.startAt,
        endAt: this.booking.endAt,
        discounts: this.booking.discounts
      });
      const FormatAmount = formatPrice(discount.amount);
      const ratio = new BigNumber(discount.reduceRatio)
        .multipliedBy(100)
        .toString();
      const day = discount.item.name;
      const label = i18n.t('booking_price_drawer_discount_label');
      const unitLabel = i18n.t('booking_price_drawer_discount_unit');

      return {
        label: i18n.t('booking_price_drawer_long_term_discount_label'),
        value: `-$ ${FormatAmount}`,
        items: [
          {
            label: `${label} ${day} ${unitLabel}`,
            value: `${ratio}% off`
          }
        ]
      };
    }

    return defItem;
  }

  @computed
  get refund() {
    const refund = BookingCalc.refund({
      price: this.booking.price,
      refundRatio: this.booking.refundRatio || 0
    });

    const ratio = new BigNumber(refund.ratio).multipliedBy(100).toString();

    const formaTotal = formatPrice(refund.amount);

    const label = i18n.t('booking_price_drawer_refund_label');
    const description = i18n.t('booking_price_drawer_refund_description');
    const content = i18n.t('booking_price_drawer_refund_content');
    const unit = i18n.t('booking_price_drawer_refund_unit');

    return {
      label: `${label}`,
      value: `-$ ${formaTotal}`,
      items: [
        {
          label: description,
          value: `${content} ${ratio}${unit}`
        }
      ]
    };
  }

  @computed
  get total() {
    const t = this.check.booking
      ? BookingCalc.earning({
        price: this.booking.price,
        refundRatio: this.booking.refundRatio
      })
      : '0';
    const formaTotal = formatPrice(t);

    return {
      label: i18n.t('booking_price_drawer_total_label'),
      value: `$ ${formaTotal}`
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({ booking }) => {
    this.booking = booking;
    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };
}

export default BookingPriceDrawerViewModel;
