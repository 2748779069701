import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed
} from 'mobx';
import moment from 'moment';
import { PASSWORD_LENGTH, SOURCE_TYPES, REGEXPS } from 'src/constants/config';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';
import { diff } from 'deep-diff';
import i18n from 'src/i18n';

class SignUpViewModel {
  @observable profile = null;
  @observable email = '';
  @observable showEmailErrorMessage = false;
  @observable password = '';
  // 用戶名字.
  @observable firstName = '';
  @observable lastName = '';
  // 用戶生日.
  @observable birthday = '';
  // 性別.
  @observable gender = null;
  // 異步等待.
  @observable isAwait = false;
  // 接收新聞.
  @observable getNews = false;

  @computed
  get genderOptions() {
    return [
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
      { label: 'Others', value: 'others' }
    ];
  }

  // 你是不是第三方登入.
  @computed
  get isOutsideLogin() {
    switch (this.profile?.source) {
      case SOURCE_TYPES.facebook:
      case SOURCE_TYPES.apple:
        return true;

      default:
        return false;
    }
  }

  @computed
  get emailMessage() {
    if (this.showEmailErrorMessage) {
      return i18n.t('find_hunt_page_sign_up_email_error_message');
    }

    return i18n.t('find_hunt_page_sign_up_email_desc');
  }

  @computed
  get verifyEmail() {
    // email 登入在上一頁已經驗證過 email, 故這頁不用.
    // 是第三方登入.
    if (this.isOutsideLogin) {
      const re = new RegExp(REGEXPS.email);

      return re.test(this.email);
    }

    return true;
  }

  @computed
  get verifyPassword() {
    // email 登入, 需要在這頁輸入密碼.
    // 不是第三方登入.
    if (!this.isOutsideLogin) {
      return this.password.length >= PASSWORD_LENGTH;
    }

    return true;
  }

  // 鎖定下一步.
  @computed
  get disabledContinue() {
    return (
      this.isAwait
      || !this.firstName
      || !this.lastName
      || !this.birthday
      || !this.gender
      || !this.verifyEmail
      || !this.verifyPassword
    );
  }

  constructor(props) {
    this.email = props.email;
    this.props = props;
    this.profile = props.profile;

    console.log('sign up', 'profile', 'constructor', props.profile);

    makeObservable(this);
  }

  // 保留 context 的 action.
  didMount = (context) => {
    this.contextActions = context.actions;
  };

  @action
  didUpdate = (props, preProps) => {
    const checkProfile = diff(props.profile, preProps.profile);

    if (checkProfile) {
      this.profile = props.profile;

      console.log('sign up', 'profile', 'didUpdate', props.profile);
    }
  };

  @action
  onChangeFirstName = (event) => {
    this.firstName = event.target.value;

    console.log('sign up', 'profile', this.profile);
    console.log('sign up', 'isOutsideLogin', this.isOutsideLogin);
  };

  @action
  onChangeLastName = (event) => {
    this.lastName = event.target.value;
  };

  @action
  onChangeBirthday = (mIns) => {
    const formatBirthday = mIns.format('YYYY-MM-DD');

    this.birthday = formatBirthday;

    console.log('Birthday', formatBirthday);
  };

  // 禁止選擇的日期.
  disabledDate = (mIns) => {
    return mIns.valueOf() > moment().valueOf();
  }

  @action
  onChangeGender = (value) => {
    this.gender = value;

    console.log('gender', value);
  };

  @action
  onChangeEmail = (event) => {
    const value = event.target.value;
    this.email = value;

    console.log('email', value);
  };

  @action
  onChangePassword = (event) => {
    const value = event.target.value;
    this.password = value;

    console.log('password', value);
  };

  @action
  onChangeGetNews = (checked) => {
    this.getNews = checked;

    console.log('getNews', checked);
  };

  onContinue = () => {
    if (this.isOutsideLogin) {
      this.putUserProfileAPI();
    } else {
      this.postUserSignUpAPI();
    }
  };

  // 註冊.
  @action
  postUserSignUpAPI = async () => {
    // 異步等待中.
    this.isAwait = true;

    try {
      const password = this.props.hasEmail ? this.password : undefined;
      const getNews = !this.getNews;

      const res = await UserService.postUserSignUp({
        email: this.props.email,
        password,
        firstName: this.firstName,
        lastName: this.lastName,
        birthday: this.birthday,
        gender: this.gender,
        shouldMarketing: getNews
      });

      // 觸發上層的 didUpdate.
      this.contextActions.updateProfile(res.data);
    } catch (error) {
      const msg = ErrorServices.postUserSignUp(error);

      console.log('postUserSignUp', msg);
    } finally {
      runInAction(() => {
        // 異步結束.
        this.isAwait = false;
      });
    }
  };

  createFormData = () => {
    const form = new FormData();
    const getNews = !this.getNews;

    form.append('email', this.email);
    form.append('firstName', this.firstName);
    form.append('lastName', this.lastName);
    form.append('birthday', this.birthday);
    form.append('gender', this.gender);
    form.append('shouldMarketing', getNews);

    return form;
  };

  // 已經使用第三方登入, 開始填寫資料.
  @action
  putUserProfileAPI = async () => {
    this.isAwait = true;
    this.showEmailErrorMessage = false;

    try {
      const formData = this.createFormData();
      const res = await UserService.putUserProfile(formData);

      // 觸發上層的 didUpdate.
      this.contextActions.updateProfile(res.data);
    } catch (error) {
      const msg = ErrorServices.putUserProfile(error);

      runInAction(() => {
        switch (error.response?.status) {
          case 409:
            this.showEmailErrorMessage = true;
            break;

          default:
        }
      });

      console.log('putUserProfile', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default SignUpViewModel;
