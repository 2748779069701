import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import i18n from 'src/i18n';
import styles from './styles.module.scss';
import { PAYOUT_STATUS } from '../payout';

export const BOOKING_STATUS = {
  // 接受的訂單.
  accepted: {
    value: 'accepted',
    label: i18n.t('booking_status_accepted')
  },
  // 拒絕的訂單.
  declined: {
    value: 'declined',
    label: i18n.t('booking_status_declined')
  },
  // 請求中的訂單.
  requesting: {
    value: 'requesting',
    label: i18n.t('booking_status_requesting')
  },
  // 結束的訂單.
  completed: {
    value: 'completed',
    label: i18n.t('booking_status_completed')
  },
  // 取消的訂單.
  canceledByHunter: {
    value: 'canceledByHunter',
    label: i18n.t('booking_status_canceled_by_hunter')
  },
  canceledByOwner: {
    value: 'canceledByOwner',
    label: i18n.t('booking_status_canceled_by_owner')
  },
  canceledByAdmin: {
    value: 'canceledByAdmin',
    label: i18n.t('booking_status_canceled_by_admin')
  },
  canceledBySystem: {
    value: 'canceledBySystem',
    label: i18n.t('booking_status_canceled_by_system')
  },
  // 進行中的訂單, 不在後端的狀態中
  ongoing: {
    value: 'ongoing',
    label: i18n.t('booking_status_ongoing')
  },
  // 爭議訂單, 不在後端的狀態中
  disputed: {
    value: 'disputed',
    label: i18n.t('booking_status_disputed')
  }
};

export const BOOKING_FILTER_STATUS = {
  currentlyHosting: {
    value: 'currentlyHosting',
    label: i18n.t('booking_filters_currently_hosting')
  },
  arrivingSoon: {
    value: 'arrivingSoon',
    label: i18n.t('booking_filters_arriving_soon')
  },
  readyToFinish: {
    value: 'readyToFinish',
    label: i18n.t('booking_filters_ready_to_finish')
  },
  next14Days: {
    value: 'next14Days',
    label: i18n.t('booking_filters_next_14_days')
  },
  requesting: {
    value: BOOKING_STATUS.requesting.value,
    label: i18n.t('booking_filters_requesting')
  },
  accepted: {
    value: BOOKING_STATUS.accepted.value,
    label: i18n.t('booking_filters_accepted')
  },
  declined: {
    value: BOOKING_STATUS.declined.value,
    label: i18n.t('booking_filters_declined')
  },
  completed: {
    value: BOOKING_STATUS.completed.value,
    label: i18n.t('booking_filters_completed')
  },
  disputed: {
    value: BOOKING_STATUS.disputed.value,
    label: i18n.t('booking_filters_disputed')
  },
  canceledByHunter: {
    value: BOOKING_STATUS.canceledByHunter.value,
    label: i18n.t('booking_filters_canceled_by_hunter')
  },
  canceledByOwner: {
    value: BOOKING_STATUS.canceledByOwner.value,
    label: i18n.t('booking_filters_canceled_by_owner')
  },
  canceledByAdmin: {
    value: BOOKING_STATUS.canceledByAdmin.value,
    label: i18n.t('booking_filters_canceled_by_admin')
  },
  canceledBySystem: {
    value: BOOKING_STATUS.canceledBySystem.value,
    label: i18n.t('booking_filters_canceled_by_system')
  }
};

// bookings filters 組合 statistics(後端統計)
export const bookingFilterStatusAndStatisticsToArray = (statistics) => {
  return Object.values(BOOKING_FILTER_STATUS).map((item) => {
    let count = null;

    switch (item.value) {
      case BOOKING_FILTER_STATUS.currentlyHosting.value: {
        count = statistics.current || 0;
        break;
      }

      case BOOKING_FILTER_STATUS.arrivingSoon.value: {
        count = statistics.arrivingSoon || 0;
        break;
      }

      case BOOKING_FILTER_STATUS.next14Days.value: {
        count = statistics.next14days || 0;
        break;
      }

      case BOOKING_FILTER_STATUS.readyToFinish.value: {
        count = statistics.ready2finish || 0;
        break;
      }

      default:
        break;
    }

    return {
      value: item.value,
      label: count === null ? item.label : `${item.label} (${count})`
    };
  });
};

export const BOOKING_FILTER_DATES = {
  range: 'range',
  overlap: 'overlap',
  start: 'start',
  end: 'end'
};

export const getBookingsFilter = (status) => {
  switch (status) {
    // 特殊的選項

    // 這個會篩出 ongoing
    case BOOKING_FILTER_STATUS.currentlyHosting.value: {
      const todayIns = dayjs().startOf('date');

      return {
        status: BOOKING_STATUS.accepted.value,
        dateType: BOOKING_FILTER_DATES.overlap,
        startAt: todayIns.toISOString(),
        endAt: todayIns.endOf('date').toISOString()
      };
    }

    case BOOKING_FILTER_STATUS.arrivingSoon.value: {
      const todayIns = dayjs().startOf('date');
      const tomorrowIns = dayjs().endOf('date').add(1, 'days');

      return {
        status: BOOKING_STATUS.accepted.value,
        dateType: BOOKING_FILTER_DATES.start,
        startAt: todayIns.toISOString(),
        endAt: tomorrowIns.toISOString()
      };
    }

    case BOOKING_FILTER_STATUS.readyToFinish.value: {
      const todayIns = dayjs().startOf('date');

      return {
        status: BOOKING_STATUS.accepted.value,
        dateType: BOOKING_FILTER_DATES.end,
        startAt: todayIns.toISOString(),
        endAt: todayIns.endOf('date').toISOString()
      };
    }

    case BOOKING_FILTER_STATUS.next14Days.value: {
      const startAtIns = dayjs().startOf('date');
      const endAtIns = dayjs().endOf('date').add(14, 'day');

      return {
        status: BOOKING_STATUS.accepted.value,
        dateType: BOOKING_FILTER_DATES.start,
        startAt: startAtIns.toISOString(),
        endAt: endAtIns.toISOString()
      };
    }

    // 通常的訂單狀態

    case BOOKING_STATUS.requesting.value: {
      return {
        status: BOOKING_STATUS.requesting.value,
        dateType: null,
        startAt: null,
        endAt: null
      };
    }

    case BOOKING_STATUS.accepted.value: {
      return {
        status: BOOKING_STATUS.accepted.value,
        dateType: null,
        startAt: null,
        endAt: null
      };
    }

    case BOOKING_STATUS.declined.value: {
      return {
        status: BOOKING_STATUS.declined.value,
        dateType: null,
        startAt: null,
        endAt: null
      };
    }

    case BOOKING_STATUS.completed.value: {
      return {
        status: BOOKING_STATUS.completed.value,
        dateType: null,
        startAt: null,
        endAt: null
      };
    }

    case BOOKING_STATUS.disputed.value: {
      return {
        status: BOOKING_STATUS.disputed.value,
        dateType: null,
        startAt: null,
        endAt: null
      };
    }

    case BOOKING_STATUS.canceledByHunter.value: {
      return {
        status: BOOKING_STATUS.canceledByHunter.value,
        dateType: null,
        startAt: null,
        endAt: null
      };
    }

    case BOOKING_STATUS.canceledByOwner.value: {
      return {
        status: BOOKING_STATUS.canceledByOwner.value,
        dateType: null,
        startAt: null,
        endAt: null
      };
    }

    case BOOKING_STATUS.canceledByAdmin.value: {
      return {
        status: BOOKING_STATUS.canceledByAdmin.value,
        dateType: null,
        startAt: null,
        endAt: null
      };
    }

    case BOOKING_STATUS.canceledBySystem.value: {
      return {
        status: BOOKING_STATUS.canceledBySystem.value,
        dateType: null,
        startAt: null,
        endAt: null
      };
    }

    default: {
      return {
        landId: null,
        status: null,
        dateType: null,
        startAt: null,
        endAt: null
      };
    }
  }
};

// 計算 special date 的費用
function getSpecialDateFees({
  sourceFee,
  startAt,
  endAt,
  timezoneId,
  specialDates
}) {
  // 每天的時間戳
  const times = BookingCalc.timestamps({ startAt, endAt, timezoneId });
  const items = {};

  times.forEach((time) => {
    // 當天是不是特殊價格
    const findSpecialDate = specialDates.find(
      (item) => item.startAt <= time && time <= item.endAt
    );
    const ratio = findSpecialDate ? findSpecialDate.ratio : 1;
    const fee = new BigNumber(sourceFee).multipliedBy(ratio);
    const key = `${fee}`;
    const value = items[key];

    items[key] = value === undefined ? 1 : value + 1;
  });

  const total = Object.keys(items).reduce((t, fee) => {
    const count = items[fee];
    const price = new BigNumber(fee).multipliedBy(count);

    return t.plus(price);
  }, new BigNumber(0));

  return {
    total: total.toString(),
    items
  };
}

export class BookingCalc {
  static duration({ startAt, endAt, timezoneId = 'Asia/Taipei' }) {
    const start = dayjs(startAt).tz(timezoneId).startOf('date');
    const end = dayjs(endAt).tz(timezoneId).endOf('date');
    const dates = end.diff(start, 'day') + 1;

    return dates;
  }

  static timestamps({ startAt, endAt, timezoneId = 'Asia/Taipei' }) {
    const dates = BookingCalc.duration({ startAt, endAt, timezoneId });

    return Array.from({ length: dates }).map((_, i) =>
      dayjs(startAt).tz(timezoneId).add(i, 'day').valueOf());
  }

  static hunters({ hunters, basicHunters }) {
    return {
      basic: hunters > basicHunters ? basicHunters : hunters,
      extra: hunters > basicHunters ? hunters - basicHunters : 0
    };
  }

  static basicFee({
    startAt,
    endAt,
    basicFee,
    specialDates,
    timezoneId = 'Asia/Taipei'
  }) {
    const fees = getSpecialDateFees({
      sourceFee: basicFee,
      startAt,
      endAt,
      specialDates,
      timezoneId
    });

    return {
      total: fees.total,
      items: fees.items
    };
  }

  static extraFee({
    startAt,
    endAt,
    specialDates,
    hunters,
    basicHunters,
    extraFee,
    timezoneId = 'Asia/Taipei'
  }) {
    const hs = BookingCalc.hunters({ hunters, basicHunters });
    // extraFee 每天額外獵人的費用
    const dateFee = new BigNumber(extraFee).multipliedBy(hs.extra);
    const fees = getSpecialDateFees({
      sourceFee: dateFee,
      startAt,
      endAt,
      specialDates,
      timezoneId
    });

    return {
      total: fees.total,
      items: fees.items
    };
  }

  static packages(packages = []) {
    const total = packages.reduce((t, item) => {
      const fee = new BigNumber(item.price).multipliedBy(item.count);
      return t.plus(fee);
    }, new BigNumber(0));

    return total.toString();
  }

  static checkRefundEarning(refundRatio) {
    return refundRatio && new BigNumber(refundRatio).isLessThan(1);
  }

  static checkDiscount({
    startAt,
    endAt,
    timezoneId = 'Asia/Taipei',
    discounts = []
  }) {
    if (!discounts.length) {
      return false;
    }

    const duration = BookingCalc.duration({ startAt, endAt, timezoneId });

    const [longTermDiscount, ...ps] = discounts.filter(
      (item) => parseInt(item.name, 10) <= duration
    );

    return !!longTermDiscount;
  }

  static discount({
    price,
    startAt,
    endAt,
    timezoneId = 'Asia/Taipei',
    discounts = []
  }) {
    const days = BookingCalc.duration({ startAt, endAt, timezoneId });

    const [longTermDiscount, ...ps] = discounts
      .filter((item) => parseInt(item.name, 10) <= days)
      .sort((a, b) => Number(a.discount) - Number(b.discount));

    const ratio = longTermDiscount
      ? new BigNumber(longTermDiscount.discount).minus(1).abs()
      : new BigNumber(1);

    return {
      // 折扣了多少錢
      amount: longTermDiscount
        ? new BigNumber(price).multipliedBy(ratio).toString()
        : '0',
      reduceRatio: longTermDiscount ? ratio.toString() : '0',
      item: longTermDiscount || null
    };
  }

  static total({
    basicFee,
    extraFee,
    hunters,
    basicHunters,
    startAt,
    endAt,
    specialDates,
    packages,
    discounts,
    timezoneId
  }) {
    const basicFees = BookingCalc.basicFee({
      basicFee,
      startAt,
      endAt,
      specialDates,
      timezoneId
    });
    const extraFees = BookingCalc.extraFee({
      hunters,
      basicHunters,
      extraFee,
      startAt,
      endAt,
      specialDates,
      timezoneId
    });

    const packagesTotal = BookingCalc.packages(packages);

    const dayTotal = new BigNumber(basicFees.total).plus(extraFees.total);

    const discount = BookingCalc.discount({
      price: dayTotal,
      startAt,
      endAt,
      discounts,
      timezoneId
    });

    const discountBefore = dayTotal.plus(packagesTotal).toString();
    const discountAfter = dayTotal
      .minus(discount.amount)
      .plus(packagesTotal)
      .toString();

    return {
      discountBefore,
      discountAfter
    };
  }

  // 印出期間每天的最後一分鍾, 用於測試訂單結算
  static logDurationEveryDayEndOfDate({ startAt, endAt, timezoneId }) {
    const duration = BookingCalc.duration({ startAt, endAt, timezoneId });

    Array.from({ length: duration }).forEach((_, i) => {
      const label = `第 ${i + 1} 天`;
      const start = dayjs(startAt).tz(timezoneId);
      const date = start.add(i, 'day').endOf('day').toISOString();

      console.log(label, date);
    });
  }

  // 沒有付款的款項總計
  static payableTotalFromBookingDetail({ price, endAt, payouts }) {
    const endAts = payouts
      .map((item) => dayjs(item.endAt).valueOf())
      .sort((a, b) => b - a);
    const total = payouts.reduce(
      (t, i) => t.minus(i.amount),
      new BigNumber(price)
    );

    const checkEndAt = endAt === endAts[0];

    return checkEndAt ? 0 : total.toString();
  }

  // 退款給獵人多少錢
  // refundRatio: '1' or '0.7'
  static refund = ({ price, refundRatio }) => {
    const ratio = refundRatio || '0';

    return {
      amount: new BigNumber(price).multipliedBy(ratio).toString(),
      ratio
    };
  };

  // 僅使用後端給的費用乘以退款比例
  static earning({ price, refundRatio }) {
    const ratio = refundRatio ? new BigNumber(refundRatio).minus(1).abs() : 1;
    const total = new BigNumber(price);

    return total.multipliedBy(ratio).toString();
  }
}

export const getBookingStatusStyle = (status) => {
  switch (status) {
    case BOOKING_STATUS.accepted.value:
      return styles.accepted;

    case BOOKING_STATUS.requesting.value:
      return styles.requesting;

    default:
      return '';
  }
};

export const checkBookingCancel = ({
  status,
  startAt,
  timezoneId,
  disputedAt
}) => {
  if (status !== BOOKING_STATUS.accepted.value) {
    return false;
  }

  if (disputedAt) {
    return false;
  }

  const today = dayjs().tz(timezoneId).startOf('day')
    .valueOf();

  if (today >= startAt) {
    return false;
  }

  return true;
};

// note: 新的需求, 訂單拒絕或取消時, 不應該顯示訂單的收入
export const checkShowBookingEarning = ({ status, refundRatio }) => {
  const checkRefundEarning = BookingCalc.checkRefundEarning(refundRatio);

  if (checkRefundEarning) {
    return true;
  }

  switch (status) {
    case BOOKING_STATUS.declined.value:
    case BOOKING_STATUS.canceledByAdmin.value:
    case BOOKING_STATUS.canceledByHunter.value:
    case BOOKING_STATUS.canceledByOwner.value:
    case BOOKING_STATUS.canceledBySystem.value: {
      return false;
    }

    default:
      return true;
  }
};

// 判斷訂單的狀態.
export const getBookingStatus = ({ status, disputedAt, startAt, endAt }) => {
  // 爭議訂單.
  if (disputedAt) {
    return BOOKING_STATUS.disputed;
  }

  // 暫時不用
  // const today = dayjs().valueOf();

  // if (
  //   startAt <= today
  //   && today <= endAt
  //   && status === BOOKING_STATUS.accepted.value
  // ) {
  //   return BOOKING_STATUS.ongoing;
  // }

  return BOOKING_STATUS[status];
};

export const BOOKING_CANCEL_REASONS = {
  reason1: {
    value: 'reason1',
    label: i18n.t('booking_cancel_reason_1')
  },
  reason2: {
    value: 'reason2',
    label: i18n.t('booking_cancel_reason_2')
  },
  reason3: {
    value: 'reason3',
    label: i18n.t('booking_cancel_reason_3')
  },
  reason4: {
    value: 'reason4',
    label: i18n.t('booking_cancel_reason_4')
  },
  reason5: {
    value: 'reason5',
    label: i18n.t('booking_cancel_reason_5')
  }
};

// const provider = {
//   channels: [
//     // 最好有一個不會改變的頻道做為預設頻道, 非預設頻道會被系統隱藏, 需要手動打開才能顯示
//     {
//       id: 'default-channel',
//       name: 'channel name',
//       description: 'channel description',

//       // Bitmap 格式的圖片
//       logo: 'img url',

//       // 點擊頻道後開啟 app 的哪一頁, 這個範例是開啟首頁
//       intentUri: 'intent:#Intent;component=com.ptstv/.MainActivity;end',

//       programs: [
//         {
//           // 根據需求可能會不同
//           // 如果希望打開後直接播放影片, 則是 episode id
//           // 如果希望打開後進入節目介紹頁, 則是 program id
//           id: 'string',
//           // TYPE_MOVIE = 0
//           // TYPE_TV_SERIES = 1
//           // TYPE_TV_SEASON = 2
//           // TYPE_TV_EPISODE = 3
//           // TYPE_CLIP = 4
//           // TYPE_EVENT = 5
//           // TYPE_CHANNEL = 6
//           // TYPE_TRACK = 7
//           // TYPE_ALBUM = 8
//           // TYPE_ARTIST = 9
//           // TYPE_PLAYLIST = 10
//           // TYPE_STATION = 11
//           // TYPE_GAME = 12
//           type: 'int',
//           title: 'string',
//           description: 'string',
//           // 節目圖片
//           posterArtUri: '',
//           // 圖片比例, 如果可以就統一使用一種
//           // 16:9 = 0
//           // 3:2 = 1
//           // 4:3 = 2
//           // 1:1 = 3
//           // 2:3 = 4
//           // 1: 1.441 = 5
//           posterArtAspectRatio: 'int',
//           // 根據需求帶入不同的資料和開啟不同的頁面
//           intentUri: 'intent:#Intent;component=com.ptstv/.MainActivity;S.page=player;S.episodeId=12345;end'
//         }
//       ]
//     }
//   ]
// };
