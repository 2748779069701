import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import clsx from 'clsx';
import GoogleMapReact from 'google-map-react';
import { Input, Form } from 'antd';
import { GOOGLE_KEY } from 'src/constants/config';
import { capitalization } from 'src/constants/methods';
import GoogleServices from 'src/services/GoogleServices';
import i18n from 'src/i18n';
import markerIcon from 'src/assets/icon_map_marker.svg';
import inputLatLngDescIcon from 'src/assets/icon_input_lat_lng_desc.svg';
import ResetFormItem from 'src/components/ResetFormItem';
import styles from './styles.module.scss';

const MAP_KEYS = {
  key: GOOGLE_KEY,
  language: 'en',
  region: 'us'
};

const MAP_OPTIONS = {
  dragMap: {
    // 關閉全螢幕按鈕.
    fullscreenControl: false
  },

  inputMap: {
    fullscreenControl: false,
    gestureHandling: 'none'
  }
};

export const POSITION_TYPES = {
  address: 'address',
  position: 'position'
};

class PositionViewModel {
  @observable type = POSITION_TYPES.address;
  @observable land = null;

  @observable defaultLat = 0;
  @observable defaultLng = 0;

  @observable lat = 0;
  @observable lng = 0;

  @observable showDragDesc = true;
  @observable inited = false;

  @observable latLng = '';
  @observable latLngAwait = false;

  form = null;

  dragMap = null;

  @computed
  get disabled() {
    return {
      latLng: this.latLngAwait
    };
  }

  @computed
  get center() {
    return {
      new: {
        lat: this.lat,
        lng: this.lng
      },
      default: {
        lat: this.defaultLat,
        lng: this.defaultLng
      }
    };
  }

  @computed
  get items() {
    return [
      {
        key: 'drag',
        label: i18n.t('create_land_drawer_position_page_tabs_drag_map_label'),
        children: (
          <div className={styles.dragMap}>
            <div className={styles.description}>
              {i18n.t(
                'create_land_drawer_position_page_tabs_drag_map_description'
              )}
            </div>

            <div className={styles.map}>
              <GoogleMapReact
                bootstrapURLKeys={MAP_KEYS}
                options={MAP_OPTIONS.dragMap}
                defaultZoom={14}
                defaultCenter={this.center.default}
              // 使用 google map methods
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={this.onDragMapLoaded}
                onDragEnd={this.onMapDragEnd}
              />

              <img className={styles.marker} src={markerIcon} alt="mark" />

              <div
                className={clsx([
                  styles.dragDesc,
                  this.showDragDesc && styles.show
                ])}
              >
                {i18n.t(
                  'create_land_drawer_position_page_tabs_drag_map_drag_description'
                )}
              </div>
            </div>
          </div>
        )
      },
      {
        key: 'input',
        label: i18n.t('create_land_drawer_position_page_tabs_input_map_label'),
        children: (
          <div className={styles.inputLatLng}>
            <div className={styles.inputContainer}>
              <div className={styles.label}>
                <div className={styles.content}>
                  {i18n.t(
                    'create_land_drawer_position_page_tabs_input_map_lat_lng_desc'
                  )}
                </div>

                <a
                  href="https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop&oco=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={inputLatLngDescIcon} alt="desc" />
                </a>
              </div>

              <Form form={this.form} disabled={this.disabled.latLng}>
                <ResetFormItem
                  name="latLng"
                  rules={this.latLngRules}
                  errorMessageAbsolute
                >
                  <Input.Search onSearch={this.onLatLngSearch} />
                </ResetFormItem>
              </Form>
            </div>

            <div className={styles.mapContainer}>
              <GoogleMapReact
                bootstrapURLKeys={MAP_KEYS}
                options={MAP_OPTIONS.inputMap}
                defaultZoom={14}
                center={this.center.new}
              />

              <img className={styles.marker} src={markerIcon} alt="mark" />
            </div>
          </div>
        )
      }
    ];
  }

  get latLngRules() {
    return [
      {
        required: true
      },
      {
        pattern: /^([-]?\d+(\.\d+)?), ?([-]?\d+(\.\d+)?)$/,
        message: i18n.t(
          'create_land_drawer_position_page_tabs_input_map_input_format_error'
        )
      }
    ];
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.type = props.type;
    this.land = props.land;
    this.form = props.form;

    this.showDragDesc = true;

    this.inited = false;
  };

  @action
  didMount = () => {
    switch (this.type) {
      case POSITION_TYPES.address: {
        this.getGeocodingAPI();
        break;
      }

      case POSITION_TYPES.position: {
        // 順序: 經緯度
        const lng = this.land.position[0];
        const lat = this.land.position[1];

        this.firstSetLanLng({ lat, lng });

        this.inited = true;

        console.log('已經有經緯度', this.land.position);
        break;
      }

      default:
        break;
    }
  };

  @action
  firstSetLanLng = ({ lat, lng }) => {
    // 地圖預設經緯度.
    this.defaultLat = lat;
    this.defaultLng = lng;

    // 地圖操作使用的經緯度.
    this.lat = lat;
    this.lng = lng;

    const latLng = `${lat}, ${lng}`;

    this.latLng = latLng;

    this.form.setFieldsValue({
      latLng
    });
  };

  onDragMapLoaded = ({ map, maps }) => {
    this.dragMap = map;

    console.log('可拖移的地圖', map);
  };

  @action
  onMapDragEnd = (event) => {
    const lat = event.center.lat();
    const lng = event.center.lng();

    this.lat = lat;
    this.lng = lng;

    // 隱藏拖放介紹.
    this.showDragDesc = false;

    console.log('經緯度', lat, lng);
  };

  @action
  onLatLngChange = (event) => {
    this.latLng = event.target.value;
  };

  @action
  onLatLngSearch = async () => {
    this.latLngAwait = true;

    try {
      const res = await this.form.validateFields();
      // 順序: 緯經度(因 ui 說明所以是反的)
      const latLng = res.latLng.split(',').map((item) => Number(item));

      runInAction(() => {
        this.lat = latLng[0];
        this.lng = latLng[1];

        this.dragMap.setCenter({ lat: this.lat, lng: this.lng });

        console.log(res);
      });
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.latLngAwait = false;
    });
  };

  // 使用地址獲取經緯度.
  @action
  getGeocodingAPI = async () => {
    try {
      // 街道
      const street = this.land.street;
      // 城市.
      const county = this.land.county
        .split('_')
        .map((item) => {
          return capitalization(item);
        })
        .join(' ');

      // 地區
      const state = capitalization(this.land.state);
      // 郵遞區號.
      const postcode = this.land.postcode;
      // 給 api 的資資料.
      const address = `${street} ${county} ${state} ${postcode}`;

      console.log('使用地址搜尋經緯度', address);

      const res = await GoogleServices.getGeocoding({
        key: GOOGLE_KEY,
        // us, 美國.
        // 查詢縮寫, https://en.wikipedia.org/wiki/Country_code_top-level_domain
        region: 'us',
        address
      });

      runInAction(() => {
        const lat = res.data.location.lat;
        const lng = res.data.location.lng;

        this.firstSetLanLng({ lat, lng });

        this.inited = true;
      });
    } catch (error) {
      console.log('getGeocoding', error);
    }
  };

  validateFields = async () => {
    return {
      // 順序: 經緯度
      position: [this.lng, this.lat]
    };
  };
}

export default PositionViewModel;
