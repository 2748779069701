import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import i18n from 'src/i18n';
import { ITEM_STATUS } from 'src/constants/rental/rental';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import ErrorServices from 'src/services/ErrorServices';
import RentalServices from 'src/services/RentalServices';

class ReportItemModalViewModel {
  @observable rental = null;
  @observable parcel = null;
  @observable item = null;

  @observable isAwait = null;

  changed = async () => {};

  modalVM = new ActionModalViewModel();
  alertVM = new ActionModalViewModel();

  form = null;

  @computed
  get ids() {
    return this.item?.itemIds || [];
  }

  @computed
  get check() {
    return {
      item: !!this.item
    };
  }

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      footer: this.isAwait
    };
  }

  @computed
  get options() {
    return {
      status: Object.values(ITEM_STATUS)
    };
  }

  @computed
  get rules() {
    const required = {
      required: true,
      message: FORM_INPUT_REQUIRED_MESSAGE
    };

    return {
      count: [
        required,
        {
          validator: (_, value) =>
            value === '0' ? Promise.reject() : Promise.resolve()
        }
      ],
      status: [required]
    };
  }

  @computed
  get deconstruct() {
    const itemCopy = this.item.itemCopy; // 後端複製的資料
    const itemVariety = itemCopy.itemVariety; // 租借物品的款式
    const itemSource = itemVariety.item; // 租借的物品

    return {
      itemCopy,
      itemVariety,
      itemSource
    };
  }

  @computed
  get labels() {
    const label1 = i18n.t('parcel_restore_alert_desc_1');
    const label2 = i18n.t('parcel_restore_alert_desc_2');

    return {
      alert: `${label1} "${this.deconstruct.itemSource.name}" ${label2}`
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form }) => {
    this.form = form;
  };

  @action
  open = ({ parcel, item, changed = async () => {} }) => {
    this.parcel = parcel;
    this.item = item;

    this.form.setFieldsValue({
      count: 1,
      status: item.status || null,
      report: item.report || ''
    });

    this.changed = changed;

    this.modalVM.open();

    console.log('ReportItemModal', 'parcel', parcel);
    console.log('ReportItemModal', 'item', item);
  }

  close = () => {
    this.form.resetFields();
    this.modalVM.close();
  };

  @action
  onModalConfirm = async () => {
    try {
      const res = await this.form.validateFields();

      if (res.status !== this.item.status) {
        this.alertVM.open();
      } else {
        this.putRentalParcelItemAPI();
      }
    } catch (error) {
      console.log(error);
    }
  };

  onAlertConfirm = () => {
    this.putRentalParcelItemAPI();
  }

  @action
  putRentalParcelItemAPI = async () => {
    this.isAwait = true;

    try {
      const res = await this.form.validateFields();

      await RentalServices.putRentalParcelItem({
        parcelId: this.parcel.id,
        itemId: this.item.id,
        status: res.status,
        report: res.report?.trim() || ''
      });

      await this.changed();

      this.close();
    } catch (error) {
      const msg = ErrorServices.putRentalParcelItem(error);
      console.log('putRentalParcelItem', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default ReportItemModalViewModel;
