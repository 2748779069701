import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import styles from './styles.module.scss';

@observer
class Steps extends React.Component {
  get steps() {
    return Array.from({ length: this.props.steps }).map((_, i) => i);
  }

  render() {
    return (
      <div className={styles.container}>
        {this.steps.map((item, i) => {
          const key = JSON.stringify(item);
          const active = this.props.step >= i;

          return (
            <div
              className={clsx([styles.step, active && styles.active])}
              key={key}
            />
          );
        })}
      </div>
    );
  }
}

Steps.propTypes = {
  steps: PropTypes.number,
  step: PropTypes.number
};

Steps.defaultProps = {
  steps: 6,
  step: 0
};

export default Steps;
