import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { BookingCalc } from 'src/constants/booking/booking';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import BookingServices from 'src/services/BookingServices';
import ErrorServices from 'src/services/ErrorServices';
import RentalServices from 'src/services/RentalServices';
import i18n from 'src/i18n';
import BookingDetail from './components/BookingDetail';
import RentalDetail from './components/RentalDetail';
import Shipping from './components/Shipping';

const PAGES = {
  booking: 'booking',
  rental: 'rental',
  shipping: 'shipping'
};

class BookingDetailDrawerViewModel {
  @observable defaultPage = null;

  @observable bookingId = null;
  @observable rentalId = null;

  @observable booking = null;
  @observable rental = null;

  @observable page = PAGES.booking;

  @observable inited = false;

  drawerVM = new ActionDrawerViewModel();

  opened = () => {};
  closed = () => {};

  bookingChanged = () => {};
  rentalChanged = () => {};

  bookingStatusChanged = () => {}; // 在月曆進入 booking 會用到
  landFeeChanged = () => {}; // 在月曆進入 booking 再進入 land 改費用會用到

  @computed
  get check() {
    return {
      show: this.inited && !!this.booking,
      booking: !!this.booking,
      rental: !!this.rental,
      rentalId: !!this.rentalId
    };
  }

  @computed
  get pages() {
    const items = [];

    if (this.check.booking) {
      const booking = {
        key: PAGES.booking,
        label: i18n.t('booking_detail_drawer_booking_tab_label'),
        children: (
          <BookingDetail
            booking={this.booking}
            changed={this.getBookingDetailAPI}
            statusChanged={this.bookingStatusChanged}
            landFeeChanged={this.landFeeChanged}
          />
        )
      };

      items.push(booking);
    }

    if (this.check.rental) {
      const rental = {
        key: PAGES.rental,
        label: i18n.t('booking_detail_drawer_rental_tab_label'),
        children: (
          <RentalDetail
            booking={this.booking}
            rental={this.rental}
            changed={this.getRentalDetailAPI}
          />
        )
      };

      const shipping = {
        key: PAGES.shipping,
        label: i18n.t('booking_detail_drawer_shipping_tab_label'),
        children: <Shipping booking={this.booking} rental={this.rental} />
      };

      items.push(rental, shipping);
    }

    return items;
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  openBooking = async ({
    bookingId = '',
    opened = () => {},
    closed = () => {},
    changed = () => {},
    statusChanged = () => {},
    landFeeChanged = () => {}
  }) => {
    this.defaultPage = PAGES.booking;

    this.bookingId = bookingId;
    this.opened = opened;
    this.closed = closed;

    this.bookingChanged = changed;
    this.bookingStatusChanged = statusChanged;
    this.landFeeChanged = landFeeChanged;

    this.opened();

    this.drawerVM.open();

    await this.getBookingDetailAPI();

    if (this.check.rentalId) {
      await this.getRentalDetailAPI();
    }

    runInAction(() => {
      this.inited = true;
    });
  };

  @action
  openRental = async ({
    rentalId = '',
    opened = () => {},
    closed = () => {},
    changed = () => {}
  }) => {
    this.defaultPage = PAGES.rental;

    this.rentalId = rentalId;
    this.opened = opened;
    this.closed = closed;

    this.rentalChanged = changed;

    this.opened();

    this.drawerVM.open();

    await this.getRentalDetailAPI();
    await this.getBookingDetailAPI();

    runInAction(() => {
      this.inited = true;
    });
  };

  @action
  openShipping = async ({
    rentalId = '',
    opened = () => {},
    closed = () => {},
    changed = () => {}
  }) => {
    this.defaultPage = PAGES.shipping;

    this.rentalId = rentalId;
    this.opened = opened;
    this.closed = closed;

    this.rentalChanged = changed;

    this.opened();

    this.drawerVM.open();

    await this.getRentalDetailAPI();
    await this.getBookingDetailAPI();

    runInAction(() => {
      this.inited = true;
    });
  };

  @action
  close = () => {
    this.closed();
    this.drawerVM.close();

    this.page = PAGES.booking;
    this.inited = false;

    this.booking = null;
    this.bookingId = null;

    this.rental = null;
    this.rentalId = null;
  };

  @action
  onTabClick = (key) => {
    this.page = key;
  };

  @action
  toBooking = () => {
    this.page = PAGES.booking;
  };

  @action
  toRental = () => {
    this.page = PAGES.rental;
  };

  @action
  toShipping = () => {
    this.page = PAGES.shipping;
  };

  @action
  getBookingDetailAPI = async () => {
    try {
      const res = await BookingServices.getBookingDetail({
        id: this.bookingId
      });

      this.bookingChanged(res.data);

      runInAction(() => {
        const booking = res.data;

        this.booking = booking;

        this.bookingId = booking.id;
        this.rentalId = booking.rentalId;

        BookingCalc.logDurationEveryDayEndOfDate({
          startAt: booking.startAt,
          endAt: booking.endAt,
          timezoneId: booking.land.timezoneId
        });
      });
    } catch (error) {
      const msg = ErrorServices.getBookingDetail(error);

      console.log('getBookingDetail', msg);
    }
  };

  @action
  getRentalDetailAPI = async () => {
    try {
      const res = await RentalServices.getRentalDetail({
        id: this.rentalId
      });

      this.rentalChanged(res.data);

      runInAction(() => {
        this.rental = res.data;

        this.rentalId = res.data.id;
        this.bookingId = res.data.bookingId;
      });
    } catch (error) {
      const msg = ErrorServices.getRentalDetail(error);

      console.log('getRentalDetail', msg);
    }
  };
}

export default BookingDetailDrawerViewModel;
