import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { FORM_INPUT_REQUIRED_MESSAGE, GENDERS } from 'src/constants/config';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import ErrorServices from 'src/services/ErrorServices';
import UserService from 'src/services/UserService';

class GenderModalViewModel {
  @observable profile = null;
  @observable isAwait = false;

  modalVM = new ActionModalViewModel();

  form = null;

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      confirm: this.isAwait
    };
  }

  get rules() {
    return {
      gender: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ]
    };
  }

  get options() {
    return {
      genders: Object.values(GENDERS)
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form }) => {
    this.form = form;
  };

  @action
  open = ({ profile, changed }) => {
    this.profile = profile;
    this.changed = changed;

    this.form.setFieldsValue({
      gender: profile.gender || null
    });

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  onConfirm = () => {
    this.putUserProfileAPI();
  };

  @action
  putUserProfileAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();

      const bodyFormData = new FormData();

      bodyFormData.append('gender', data.gender);

      const res = await UserService.putUserProfile(bodyFormData);

      await this.changed(res.data);

      this.close();
    } catch (error) {
      const msg = ErrorServices.putUserProfile(error);

      console.log('putUserProfile', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default GenderModalViewModel;
