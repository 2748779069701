import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DatePicker } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class ResetRangePicker extends React.Component {
  render() {
    const { className, ...ps } = this.props;

    return (
      <DatePicker.RangePicker
        className={clsx([styles.datePicker, className])}
        {...ps}
      />
    );
  }
}

ResetRangePicker.propTypes = {
  className: PropTypes.string
};

ResetRangePicker.defaultProps = {
  className: ''
};

export default ResetRangePicker;
