import { makeObservable, observable, action, computed } from 'mobx';
import { uuidv4 } from 'src/utils';

export const PHOTO_SOURCE = {
  upload: 'upload',
  server: 'server'
};

class PhotoViewModel {
  source = null;

  // ui 需要
  @observable id = null;
  @observable src = null;

  // server 需要
  size = null;
  mimetype = null;
  file = null;

  @computed
  get resForGoogleLink() {
    return {
      size: this.size,
      mimetype: this.file?.type || null
    };
  }

  @computed
  get resForGoogleUpload() {
    return {
      size: this.size,
      mimetype: this.file?.type || null,
      file: this.file
    };
  }

  @computed
  get resForServer() {
    return {
      name: this.id,
      size: this.size
    };
  }

  @computed
  get resForLandDetail() {
    return {
      id: this.id,
      src: this.src,
      size: this.size
    };
  }

  constructor(data) {
    this.source = data.source;

    // 來自 server 的照片有 id
    this.id = data.id || uuidv4();

    this.src = data.src;
    this.size = data.size;
    this.file = data.file || null;

    makeObservable(this);
  }

  @action
  sourceToServer = ({ id }) => {
    this.source = PHOTO_SOURCE.server;

    this.id = id;
    this.mimetype = null;
    this.file = null;
  };

  static fromUpload(data) {
    return new PhotoViewModel({
      source: PHOTO_SOURCE.upload,
      src: data.src,
      size: data.size,
      file: data.file
    });
  }

  static fromServer(data) {
    return new PhotoViewModel({ ...data, source: PHOTO_SOURCE.server });
  }
}

export default PhotoViewModel;
