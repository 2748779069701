// 獵物.

class LandSpecieDataModel {
  constructor(data) {
    this.id = data.id;

    this.category = data.category;

    this.priority = data.priority;
  }
}

export default LandSpecieDataModel;
