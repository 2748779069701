import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetInput from 'src/components/ResetInput';
import styles from './styles.module.scss';

@observer
class DisplayNameModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ form: props.form });
  }

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        <div className={styles.container}>
          <Form form={this.vm.form} disabled={this.vm.disabled.form}>
            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('display_name_modal_display_name_label')}
              </div>

              <ResetFormItem
                name="displayName"
                rules={this.vm.rules.displayName}
                errorMessageAbsolute
              >
                <ResetInput maxLength={100} />
              </ResetFormItem>
            </div>
          </Form>

          <ActionModalFooter
            cancelProps={{
              onClick: this.vm.close,
              children: i18n.t('display_name_modal_cancel_button_label')
            }}
            confirmProps={{
              onClick: this.vm.onConfirm,
              disabled: this.vm.disabled.confirm,
              children: i18n.t('display_name_modal_confirm_button_label')
            }}
          />
        </div>
      </ActionModal>
    );
  }
}

DisplayNameModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

DisplayNameModal.defaultProps = {};

export default withForm(DisplayNameModal);
