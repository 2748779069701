import dayjs from 'dayjs';
import { makeObservable, observable, computed, action } from 'mobx';
import { LAND_MESSAGE_TYPES } from 'src/constants/land';
import MessageDrawerViewModel from '../MessageDrawer/viewModel';

class MessageViewModel {
  @observable type = null;
  @observable message = null;

  messageDrawerVM = new MessageDrawerViewModel();

  @computed
  get check() {
    return {
      qa: this.type === LAND_MESSAGE_TYPES.qas,
      rating: this.type === LAND_MESSAGE_TYPES.ratings,
      reply: !!this.message.reply?.trim()
    };
  }

  @computed
  get id() {
    return this.message.id;
  }

  @computed
  get content() {
    return this.message.message;
  }

  @computed
  get createAt() {
    return dayjs(this.message.createAt).format('YYYY/MM/DD');
  }

  @computed
  get score() {
    return this.message.score || 0;
  }

  constructor(props) {
    this.message = props;
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.type = props.type;
  };

  onClick = () => {
    this.messageDrawerVM.open({ data: this.message, changed: this.update });
  };

  @action
  update = (data) => {
    this.message = { ...this.message, ...data };
  };
}

export default MessageViewModel;
