import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import ResetButton from 'src/components/ResetButton';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class LinkButton extends React.Component {
  render() {
    const { className, type, children, ...ps } = this.props;

    return (
      <ResetButton
        className={clsx(['linkButton', className])}
        type={type}
        {...ps}
      >
        <div className={styles.link}>{children}</div>
      </ResetButton>
    );
  }
}

LinkButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node
};

LinkButton.defaultProps = {
  className: '',
  type: 'link',
  children: null
};

export default LinkButton;
