import optionStore from 'src/global/optionStore';

class PayoutBookingDataModel {
  constructor(data) {
    this.id = data.id;
    this.status = data.status;

    this.startAt = data.startAt;
    this.endAt = data.endAt;
    // 爭議時間.
    this.disputedAt = data.disputedAt;
    // 完成時間.
    this.completedAt = data.completedAt;

    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;

    // 預約的獵人.
    this.consumer = {
      id: data.consumer.id,
      firstName: data.consumer.firstName,
      lastName: data.consumer.lastName,
      displayName: data.consumer.displayName,
      idCardStatus: data.consumer.idCardStatus,
      huntingLicenseStatus: data.consumer.huntingLicenseStatus
    };

    // 預約的獵場.
    const state = optionStore.states[data.land.state];
    const county = state.counties[data.land.county] || '';
    this.land = {
      id: data.land.id,
      name: data.land.name,
      country: data.land.country,
      state: data.land.state,
      county,
      street: data.land.street,
      postcode: data.land.postcode
    };

    // 預約的交易資料.
    this.trade = {
      currency: data.trade.currency,
      // 預約的費用.
      amount: data.trade.amount,
      // 預約的服務費.
      platformServiceFee: data.trade.platformServiceFee
    };

    this.payout = {
      id: data.payout.id,
      status: data.payout.status,
      amount: data.payout.amount,
      startAt: data.payout.startAt,
      endAt: data.payout.endAt
    };
  }
}

export default PayoutBookingDataModel;
