import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import qs from 'qs';
import Papa from 'papaparse';
import { PapaConfig } from './config';
import { BOOKING_STATUS } from './booking/booking';

// 大駝峰命名法, 所有的單字字首大寫.
export function upperCamelCase(string) {
  const re = /^[a-zA-Z]{1}/g;
  const splitString = string
    .trim()
    .split(' ')
    .filter((item) => !!item);
  const mapString = splitString.map((item) => {
    return item.replace(re, (s) => {
      return s.toUpperCase();
    });
  });
  const resString = mapString.join(' ');

  return resString;
}

// 字首大寫.
export function capitalization(string) {
  const re = /^[a-zA-Z]{1}/g;

  return string.replace(re, (s) => {
    return s.toUpperCase();
  });
}

export const routerMethods = {
  // 幫你產 route search.
  createSearch: (data) => {
    const params = qs.stringify(data);

    return `?${params}`;
  },

  // 幫你讀 route search, 沒有會返回 null.
  getSearch: (router, key) => {
    return router.searchParams[0]?.get(key);
  },

  createHash: (data) => {
    return qs.stringify(data);
  },

  getHash: (router, key) => {
    const hash = router.location?.hash?.slice(1) || '';
    const data = qs.parse(hash);

    return key ? data[key] : data;
  },

  // 刪除 hash.
  removeHash: (router) => {
    const route = router.location.pathname;

    // router.navigate(route, { replace: true });
    // window.location.hash = '';
    window.history.replaceState(null, null, ' ');
  }
};

// 匯出 csv.
export const downloadCSV = (data, fileName = 'export') => {
  // 將 json 轉為 csv 格式.
  const csv = Papa.unparse(data, PapaConfig.csv.export);
  const file = new Blob(['\ufeff', csv], { type: 'text/csv' });
  const element = document.createElement('a');

  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.csv`;
  element.click();
};

export const download = (name, ref) => {
  const element = document.createElement('a');

  element.href = ref;
  element.target = '_blank';

  element.download = name;

  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);

  // FileSaver.saveAs(ref, name);
};

export const assemble = {
  address: ({
    postcode = '',
    street = '',
    country = '',
    state = '',
    county = ''
  }) => {
    let res = '';

    if (postcode) {
      res = postcode;
    }

    if (street) {
      res = `${res} ${street}`;
    }

    if (county) {
      res = `${res} ${county}`;
    }

    if (state) {
      res = `${res}, ${state}`;
    }

    if (country) {
      res = `${res}, ${country}`;
    }

    return res;
  },

  meetAddress: ({ postcode = '', street = '', city = '', state = '' }) => {
    let res = '';

    if (postcode) {
      res = postcode;
    }

    if (street) {
      res = `${res} ${street}`;
    }

    if (city) {
      res = `${res} ${city}`;
    }

    if (state) {
      res = `${res}, ${state}`;
    }

    return res;
  },

  name: (data = {}) => {
    const firstName = data.firstName || '';
    const lastName = data.lastName || '';

    return `${firstName} ${lastName}`;
  },

  size: (data) => {
    const { width, height, length, distanceUnit, weight, massUnit } = data;
    let size = `(W ${width} x H ${height} x L ${length} ${distanceUnit})`;

    if (weight) {
      size = `${size} - ${weight} ${massUnit}`;
    }

    return size;
  }
};

// 將 parcel 的狀態轉為 rental 的狀態
// 判斷 item 可不可以 report issue
// export const toRentalStatus = (parcel) => {
//   let rentalStatus = null;

//   switch (parcel.status) {
//     case `${PARCEL_STATUS.unknown},${PARCEL_STATUS.unknown}`: {
//       rentalStatus = RENTAL_ORDER_STATUS.waitingForShipping.value;
//       break;
//     }

//     case `${PARCEL_STATUS.preTransit},${PARCEL_STATUS.unknown}`: {
//       rentalStatus = RENTAL_ORDER_STATUS.onTheWayToLand.value;
//       break;
//     }

//     case `${PARCEL_STATUS.transit},${PARCEL_STATUS.unknown}`: {
//       rentalStatus = RENTAL_ORDER_STATUS.onTheWayToLand.value;
//       break;
//     }

//     case `${PARCEL_STATUS.delivered},${PARCEL_STATUS.unknown}`: {
//       rentalStatus = RENTAL_ORDER_STATUS.deliveredLand.value;
//       break;
//     }

//     case `${PARCEL_STATUS.delivered},${PARCEL_STATUS.preTransit}`: {
//       rentalStatus = RENTAL_ORDER_STATUS.onTheWayToWarehouse.value;
//       break;
//     }

//     case `${PARCEL_STATUS.delivered},${PARCEL_STATUS.transit}`: {
//       rentalStatus = RENTAL_ORDER_STATUS.OnTheWayToWarehouse.value;
//       break;
//     }

//     case `${PARCEL_STATUS.delivered},${PARCEL_STATUS.delivered}`: {
//       rentalStatus = RENTAL_ORDER_STATUS.inspecting.value;
//       break;
//     }

//     default: {
//       rentalStatus = RENTAL_ORDER_STATUS.unknown.value;
//       break;
//     }
//   }

//   if (
//     rentalStatus === RENTAL_ORDER_STATUS.deliveredLand.value
//     && !!parcel.signature
//   ) {
//     return RENTAL_ORDER_STATUS.pickedUp.value;
//   }

//   return rentalStatus;
// };
