import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ActionModal from 'src/components/ActionModal';
import ActionModalAlertContainer from 'src/components/ActionModal/components/ActionModalAlertContainer';
import ActionModalDetailWarning from 'src/components/ActionModal/components/ActionModalDetailWarning';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import styles from './styles.module.scss';

@observer
class PriceAlertModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        <ActionModalAlertContainer>
          <ActionModalDetailWarning
            title={i18n.t('land_edit_drawer_price_alert_modal_title')}
            content={i18n.t('land_edit_drawer_price_alert_modal_content')}
          />

          <ActionModalFooter
            cancelProps={{
              onClick: this.vm.close,
              children: i18n.t(
                'land_edit_drawer_price_alert_modal_cancel_button_label'
              )
            }}
            confirmProps={{
              onClick: this.vm.onConfirm,
              children: i18n.t(
                'land_edit_drawer_price_alert_modal_confirm_button_label'
              ),
              disabled: this.vm.disabled.confirm
            }}
          />

        </ActionModalAlertContainer>
      </ActionModal>
    );
  }
}

PriceAlertModal.propTypes = {
  vm: PropTypes.object.isRequired
};

PriceAlertModal.defaultProps = {};

export default PriceAlertModal;
