import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import i18n from 'src/i18n';
import ActionDrawerItem from 'src/components/ActionDrawer/components/ActionDrawerItem';
import CustomLi from 'src/components/CustomLi';
import LinkButton from 'src/components/LinkButton';
import ResetButton from 'src/components/ResetButton';
import ContactDrawer from 'src/components/ContactDrawer';
import ResetTable from 'src/components/ResetTable';
import PackageDrawer from 'src/components/PackageDrawer';
import ActionDrawerCard from 'src/components/ActionDrawer/components/ActionDrawerCard';
import BookingPriceDrawer from './components/BookingPriceDrawer';
import BookingCancelModal from './components/BookingCancelModal';
import BookingEditDrawer from './components/BookingEditDrawer';
import BookingDetailViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class BookingDetail extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new BookingDetailViewModel({
      booking: props.booking,
      changed: props.changed,
      statusChanged: props.statusChanged,
      landFeeChanged: props.landFeeChanged
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderRequestingButtons = () => (
    <div className={styles.requestingButtons}>
      <ResetButton
        className={styles.button}
        type="primary"
        onClick={this.vm.onAccept}
        disabled={this.vm.disabled.accept}
      >
        {i18n.t('booking_detail_drawer_booking_accept_button_label')}
      </ResetButton>

      <ResetButton
        className={styles.button}
        type="reject"
        onClick={this.vm.onReject}
        disabled={this.vm.disabled.reject}
      >
        {i18n.t('booking_detail_drawer_booking_reject_button_label')}
      </ResetButton>
    </div>
  );

  render() {
    return (
      <>
        <div className={styles.container}>
          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_booking_note_label')}
            divider
          >
            <ActionDrawerCard
              label={this.vm.note}
              next
              onClick={this.vm.onNote}
            />
          </ActionDrawerItem>

          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_booking_id_label')}
            divider
          >
            {this.vm.id}
          </ActionDrawerItem>

          <div className={styles.item}>
            <ActionDrawerItem
              label={i18n.t('booking_detail_drawer_booking_status_label')}
            >
              {this.vm.status.label}
            </ActionDrawerItem>

            {this.vm.check.isRequesting && this.renderRequestingButtons()}
          </div>

          <div className={styles.divider} />

          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_booking_land_label')}
            divider
          >
            {this.vm.land}
          </ActionDrawerItem>

          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_booking_duration_label')}
            divider
          >
            {this.vm.duration}
          </ActionDrawerItem>

          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_booking_hunters_label')}
            divider
          >
            {this.vm.hunters}
          </ActionDrawerItem>

          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_booking_contact_label')}
            divider
          >
            <LinkButton onClick={this.vm.onContact}>
              {this.vm.contact}
            </LinkButton>
          </ActionDrawerItem>

          {this.vm.check.packages && (
            <ActionDrawerItem
              label={i18n.t('booking_detail_drawer_booking_package_label')}
              divider
            >
              <ResetTable data={this.vm.packages}>
                <Table.Column
                  width={270}
                  title={i18n.t(
                    'booking_detail_drawer_booking_package_table_item_label'
                  )}
                  render={(item) => (
                    <LinkButton onClick={() => this.vm.onPackage(item)}>
                      {item.name}
                    </LinkButton>
                  )}
                />

                <Table.Column
                  width={60}
                  title={i18n.t(
                    'booking_detail_drawer_booking_package_table_qty_label'
                  )}
                  dataIndex="count"
                  align="center"
                />
              </ResetTable>
            </ActionDrawerItem>
          )}

          {this.vm.check.earning && (
            <ActionDrawerItem
              label={i18n.t('booking_detail_drawer_booking_earning_label')}
            >
              <div className={styles.prices}>
                <LinkButton onClick={this.vm.onPrice}>
                  {this.vm.price}
                </LinkButton>

                {this.vm.check.payouts && (
                  <>
                    <div className={styles.space} />

                    <CustomLi>
                      <div className={styles.payable}>
                        <div className={styles.label}>
                          {i18n.t(
                            'booking_detail_drawer_booking_payout_payable_total_label'
                          )}
                        </div>
                        &nbsp;
                        <div className={styles.price}>
                          {this.vm.payouts.payableTotal}
                        </div>
                      </div>
                    </CustomLi>

                    {this.vm.payouts.payouts.map((item) => (
                      <CustomLi key={item.label}>
                        <div className={styles.payout}>
                          <div className={styles.price}>{item.price}</div>
                          &nbsp;
                          <div className={styles.label}>{item.label}</div>
                        </div>
                      </CustomLi>
                    ))}
                  </>
                )}
              </div>
            </ActionDrawerItem>
          )}

          {this.vm.check.cancel && (
            <div className={styles.cancel}>
              <ResetButton
                className={styles.button}
                type="primary"
                ghost
                danger
                onClick={this.vm.onCancel}
              >
                {i18n.t('booking_detail_drawer_booking_cancel_button_label')}
              </ResetButton>
            </div>
          )}
        </div>

        <BookingPriceDrawer vm={this.vm.priceDrawerVM} />

        <ContactDrawer vm={this.vm.contactDrawerVM} />

        <PackageDrawer vm={this.vm.packageDrawerVM} />

        <BookingCancelModal vm={this.vm.bookingCancelModalVM} />

        <BookingEditDrawer vm={this.vm.editDrawerVM} />
      </>
    );
  }
}

BookingDetail.propTypes = {
  booking: PropTypes.object.isRequired,
  changed: PropTypes.func,
  statusChanged: PropTypes.func,
  landFeeChanged: PropTypes.func
};

BookingDetail.defaultProps = {
  changed: () => {},
  statusChanged: () => {},
  landFeeChanged: () => {}
};

export default BookingDetail;
