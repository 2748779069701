import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import i18n from 'src/i18n';
import { BookingCalc } from 'src/constants/booking/booking';
import { RentalCalc, ITEM_PLANS } from 'src/constants/rental/rental';
import { formatPrice } from 'src/constants/price';
import configStore from 'src/global/configStore';
import CustomLi from 'src/components/CustomLi';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import BigNumber from 'bignumber.js';
import styles from './styles.module.scss';

class RentalPriceDrawerViewModel {
  @observable rental = null;

  drawerVM = new ActionDrawerViewModel();

  @computed
  get check() {
    return {
      rental: !!this.rental
    };
  }

  @computed
  get items() {
    if (this.check.rental) {
      const copiesObject = {};

      this.rental.copies.forEach((copy) => {
        const item = copiesObject[copy.item.id];

        if (item) {
          item.items.push(copy.itemVariety);
        } else {
          copiesObject[copy.item.id] = {
            id: copy.item.id,
            name: copy.item.name,
            items: [copy.itemVariety]
          };
        }
      });

      // this.getItem 有包裝 html 到 object 內
      return Object.values(copiesObject).map((item) => this.getItem(item));
    }

    return [];
  }

  @computed
  get duration() {
    if (this.check.rental) {
      return BookingCalc.duration({
        startAt: this.rental.startAt,
        endAt: this.rental.endAt,
        timezoneId: this.rental.land.timezoneId
      });
    }

    return 0;
  }

  @computed
  get total() {
    if (this.check.rental) {
      const fee = RentalCalc.earning({
        copies: this.rental.copies,
        profitRatio: this.rental.profitRatio,
        startAt: this.rental.startAt,
        endAt: this.rental.endAt,
        timezoneId: this.rental.timezoneId
      });
      const profit = formatPrice(fee);

      return `$ ${profit}`;
    }

    return '';
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = (rental) => {
    this.rental = rental;
    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  getItem = (item) => {
    const total = item.items.reduce((t, i) => {
      const itemTotal = new BigNumber(i.price)
        .multipliedBy(i.count)
        .multipliedBy(this.duration)
        .multipliedBy(configStore.rentalProfitRatio);

      return t.plus(itemTotal);
    }, new BigNumber(0));
    const formatTotal = formatPrice(total);

    return {
      key: item.id,
      label: item.name,
      value: `$ ${formatTotal}`,
      items: item.items.map((variety) => this.getItemVariety(variety))
    };
  };

  getItemVariety = (itemVariety) => {
    const ratio = new BigNumber(configStore.rentalProfitRatio).multipliedBy(
      100
    );
    const plan = ITEM_PLANS[itemVariety.plan];

    const priceLabel = i18n.t(
      'rental_price_drawer_items_table_item_variety_price_label'
    );

    const durationUnitLabel = i18n.t('rental_price_drawer_items_duration_unit');

    // 這邊是將算是拆開, 故不需要計算
    const price = formatPrice(itemVariety.price);

    // 租借費用 * 數量 * 天數 * 抽成
    const label = `${priceLabel} US$ ${price} x ${itemVariety.count} x ${this.duration} ${durationUnitLabel} x ${ratio}%`;

    return {
      key: itemVariety.name,
      label: (
        <div className={styles.itemVariety}>
          <CustomLi>{itemVariety.name}</CustomLi>
          <CustomLi>{plan.label}</CustomLi>
          <CustomLi>{label}</CustomLi>
        </div>
      )
    };
  };
}

export default RentalPriceDrawerViewModel;
