import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import './reset.scss';

function ActionModal(props) {
  const { vm, wrapClassName, ...ps } = props;

  return (
    <Modal
      open={vm.isOpen}
      wrapClassName={clsx(['action-modal', wrapClassName])}
      {...ps}
    >
      {props.children}
    </Modal>
  );
}

ActionModal.propTypes = {
  vm: PropTypes.object.isRequired,
  wrapClassName: PropTypes.string,
  title: PropTypes.node,
  footer: PropTypes.node,
  centered: PropTypes.bool,
  maskClosable: PropTypes.bool,
  width: PropTypes.number,
  destroyOnClose: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ActionModal.defaultProps = {
  wrapClassName: '',
  title: null,
  footer: null,
  centered: true,
  maskClosable: false,
  width: 580,
  destroyOnClose: true,
  children: null
};

export default observer(ActionModal);
