import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isIOS, isAndroid } from 'react-device-detect';

const appleStoreUrl = 'https://apps.apple.com/tw/app/kavava/id1668711137';
const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.develo.kavava&pli=1';

export default function DeepLinkRedirectPage() {
  const navigate = useNavigate();

  useEffect(() => {
    if (isIOS) {
      window.location.replace(appleStoreUrl);
      return;
    }
    if (isAndroid) {
      window.location.replace(googlePlayUrl);
      return;
    }

    navigate('/client');

  }, [navigate]);

  return (
    <div />
  );
}
