/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Checkbox } from 'antd';
import clsx from 'clsx';
import i18n from 'src/i18n';
import { BOOKING_STATUS } from 'src/constants/booking/booking';
import ResetButton from 'src/components/ResetButton';
import BookingDetailDrawer from '../BookingDetailDrawer';
import BasicCard from '../BasicCard';
import styles from './styles.module.scss';

@observer
class BookingCard extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  renderDefaultFooter = () => (
    <div className={styles.footer}>
      <ResetButton className={styles.button} onClick={this.vm.onDetail}>
        {i18n.t('booking_card_detail_button_label')}
      </ResetButton>
    </div>
  );

  renderFooter = () => {
    switch (this.vm.status.value) {
      case BOOKING_STATUS.requesting.value: {
        return this.props.batch ? (
          <div className={styles.footer}>
            <label
              className={styles.select}
              htmlFor={this.vm.id}
              onClick={this.vm.onSelectClick}
            >
              <Checkbox
                className={styles.checkBox}
                id={this.vm.id}
                checked={this.vm.selected}
                onChange={this.vm.onSelectedChange}
              />

              <img
                className={styles.icon}
                src={this.vm.icons.select}
                alt="select"
              />
            </label>
          </div>
        ) : (
          <div className={styles.footer}>
            <ResetButton
              className={styles.button}
              onClick={this.vm.onReject}
              disabled={this.vm.disabled.reject}
            >
              {i18n.t('booking_card_reject_button_label')}
            </ResetButton>

            <ResetButton
              className={styles.button}
              type="primary"
              onClick={this.vm.onAccept}
              disabled={this.vm.disabled.accept}
            >
              {i18n.t('booking_card_accept_button_label')}
            </ResetButton>
          </div>
        );
      }

      case BOOKING_STATUS.accepted.value: {
        return this.vm.check.contact ? (
          <div className={styles.footer}>
            <ResetButton className={styles.button} onClick={this.vm.onEmail}>
              {i18n.t('booking_card_email_button_label')}
            </ResetButton>

            <ResetButton className={styles.button} onClick={this.vm.onPhone}>
              {i18n.t('booking_card_phone_button_label')}
            </ResetButton>
          </div>
        ) : (
          this.renderDefaultFooter()
        );
      }

      default:
        return this.renderDefaultFooter();
    }
  };

  render() {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.land}>{this.vm.land.name}</div>

          <BasicCard className={styles.card} onClick={this.vm.onDetail}>
            <div className={styles.header}>
              <div className={clsx([styles.status, this.vm.status.style])}>
                {this.vm.status.label}
              </div>

              <div className={styles.id}>{this.vm.id}</div>
            </div>

            <div className={styles.content}>
              <div className={styles.item}>
                <div className={styles.label}>
                  {i18n.t('booking_card_start_at_label')}
                </div>

                <div className={styles.text}>{this.vm.startAt}</div>
              </div>

              <div className={styles.item}>
                <div className={styles.label}>
                  {i18n.t('booking_card_end_at_label')}
                </div>

                <div className={styles.text}>{this.vm.endAt}</div>
              </div>

              <div className={styles.item}>
                <div className={styles.label}>
                  {i18n.t('booking_card_consumer_label')}
                </div>

                <div className={styles.text}>{this.vm.consumer}</div>
              </div>
            </div>

            {this.renderFooter()}
          </BasicCard>
        </div>

        <BookingDetailDrawer vm={this.vm.detailDrawerVM} />
      </>
    );
  }
}

BookingCard.propTypes = {
  vm: PropTypes.object.isRequired,
  batch: PropTypes.bool
};

BookingCard.defaultProps = {
  batch: false
};

export default BookingCard;
