import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed
} from 'mobx';
import i18n from 'src/i18n';
import { getHost } from 'src/apis/utils';
import { ROUTES } from 'src/constants/routes';
import { REGEXPS, SOURCE_TYPES } from 'src/constants/config';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';

class CheckViewModel {
  @observable email = '';
  @observable isAwait = false;
  @observable showErrorMessage = false;
  @observable errorMessage = '';

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  @computed
  get disabledContinue() {
    const re = new RegExp(REGEXPS.email);

    return !re.test(this.email);
  }

  @action
  onChangeEmail = (event) => {
    const value = event.target.value;
    this.email = value;
  };

  onLoginEmail = () => {
    this.postExistsAPI();
  };

  // 使用 facebook 登入.
  onLoginFacebook = () => {
    const host = getHost();
    const link = `${host}/api/v1/facebook/login?redirect=${host}/${ROUTES.lands.value}`;

    window.location.replace(link);
  };

  // 使用 apple 登入.
  onLoginApple = () => {
    const host = getHost();
    const link = `${host}/api/v1/apple/login?redirect=${host}/${ROUTES.lands.value}`;

    window.location.replace(link);
  };

  // 檢查 email 有沒有註冊.
  @action
  postExistsAPI = async () => {
    // 異步等待中.
    this.isAwait = true;
    this.showErrorMessage = false;
    this.errorMessage = '';

    try {
      const res = await UserService.postUserExists({ email: this.email });
      const source = res.data.source;

      runInAction(() => {
        switch (source) {
          case SOURCE_TYPES.facebook:
            this.showErrorMessage = true;
            this.errorMessage = i18n.t(
              'find_hunt_page_check_email_error_from_facebook'
            );
            break;

          case SOURCE_TYPES.apple:
            this.showErrorMessage = true;
            this.errorMessage = i18n.t(
              'find_hunt_page_check_email_error_from_apple'
            );
            break;

          default:
            // 保存 email.
            this.props.setEmail(this.email);
            // email 有註冊, 前往登入.
            this.props.toLogin();
        }
      });
    } catch (error) {
      const status = error.response?.status;
      const msg = ErrorServices.postUserExists(error);

      switch (status) {
        // email 尚未註冊.
        case 404: {
          // 保存 email.
          this.props.setEmail(this.email);

          this.props.toSignUp();

          console.log('postExists', msg);
          break;
        }

        // 其他錯誤.
        default: {
          console.log('postExists', msg, error);
        }
      }
    } finally {
      runInAction(() => {
        // 異步結束.
        this.isAwait = true;
      });
    }
  };
}

export default CheckViewModel;
