import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { assemble } from 'src/constants/methods';
import ActionDrawerViewModel from '../ActionDrawer/viewModel';

class ContactDrawerViewModel {
  @observable user = null;

  drawerVM = new ActionDrawerViewModel();

  @computed
  get check() {
    return {
      user: !!this.user
    };
  }

  @computed
  get name() {
    if (this.check.user) {
      return assemble.name({
        firstName: this.user.firstName,
        lastName: this.user.lastName
      });
    }

    return '';
  }

  @computed
  get email() {
    if (this.check.user) {
      return this.user.email || '';
    }

    return '';
  }

  @computed
  get phone() {
    if (this.check.user) {
      return this.user.phone || '';
    }

    return '';
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = (user) => {
    this.user = user;
    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };
}

export default ContactDrawerViewModel;
