import API from 'src/apis';
import GeocodingDataModel from 'src/models/response/GeocodingDataModel';

class GoogleServices {
  // 使用地址獲得經緯度.
  static async getGeocoding({ key, region, address }) {
    const res = await API.google.getGeocoding({ key, region, address });

    return {
      data: new GeocodingDataModel(res.data)
    };
  }

  // 使用地址獲得經緯度.
  static async putGoogleStorage({ id, uid, url, mimetype, size, file }) {
    const res = await API.google.putGoogleStorage({
      url,
      mimetype,
      size,
      file
    });

    return {
      data: { id, uid, size, mimetype }
    };
  }
}

export default GoogleServices;
