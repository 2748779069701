class RentalPayoutRecordDataModel {
  constructor(props) {
    this.id = props.id;

    this.startAt = props.startAt;

    this.endAt = props.endAt;

    this.payoutAt = props.payoutAt;

    this.amount = props.amount;

    this.consumer = {
      id: props.consumer.id,
      firstName: props.consumer.firstName,
      lastName: props.consumer.lastName,
      displayName: props.consumer.displayName
    };

    this.land = {
      id: props.land.id,
      name: props.land.name
    };
  }
}

export default RentalPayoutRecordDataModel;
