import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Parcel from './components/Parcel';
import NoShippingsLabel from './components/NoShippingsLabel';
import ShippingViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class Shipping extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ShippingViewModel({
      booking: props.booking,
      rental: props.rental
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderContent = () => {
    return this.vm.check.parcels ? (
      <div className={styles.container}>
        {this.vm.parcels.map((vm) => (
          <Parcel vm={vm} rental={this.vm.rental} key={vm.id} />
        ))}
      </div>
    ) : (
      <NoShippingsLabel />
    );
  };

  render() {
    return this.vm.inited ? this.renderContent() : null;
  }
}

Shipping.propTypes = {
  booking: PropTypes.object.isRequired,
  rental: PropTypes.object.isRequired
};

Shipping.defaultProps = {};

export default Shipping;
