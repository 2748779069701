import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, InputNumber } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetInputNumber from 'src/components/ResetInputNumber';
import styles from './styles.module.scss';

@observer
class Prices extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ land: props.land, form: props.form });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.container}>
        <Form className={styles.form} form={this.vm.form}>
          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_price_page_basic_fee_label')}
            </div>

            <ResetFormItem
              name="basicFee"
              rules={this.vm.rules.basicFee}
              errorMessageAbsolute
            >
              <ResetInputNumber
                className={styles.inputNumber}
                step="10"
                min={0}
                stringMode
                placeholder={i18n.t(
                  'create_land_drawer_price_page_basic_fee_placeholder'
                )}
                prefix={<div className={styles.feeUnit}>US$</div>}
                controls={false}
                onChange={this.vm.onOtherChange}
              />
            </ResetFormItem>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_price_page_basic_hunters_label')}
            </div>

            <ResetFormItem
              name="basicHunters"
              rules={this.vm.rules.basicHunters}
              errorMessageAbsolute
            >
              <ResetInputNumber
                className={styles.inputNumber}
                step="1"
                min={0}
                precision={0}
                placeholder={i18n.t(
                  'create_land_drawer_price_page_basic_hunters_placeholder'
                )}
                customStepButton
                onChange={this.vm.onOtherChange}
              />
            </ResetFormItem>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_price_page_extra_hunters_label')}
            </div>

            <ResetFormItem
              name="extraHunters"
              rules={this.vm.rules.required}
              errorMessageAbsolute
            >
              <ResetInputNumber
                className={styles.inputNumber}
                step="1"
                min={0}
                precision={0}
                placeholder={i18n.t(
                  'create_land_drawer_price_page_extra_hunters_placeholder'
                )}
                onChange={this.vm.onExtraHuntersChange}
                customStepButton
              />
            </ResetFormItem>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_price_page_extra_fee_label')}
            </div>

            <ResetFormItem
              name="extraFee"
              rules={this.vm.rules.required}
              errorMessageAbsolute
            >
              <ResetInputNumber
                className={styles.inputNumber}
                step="10"
                min={0}
                stringMode
                placeholder={i18n.t(
                  'create_land_drawer_price_page_extra_fee_placeholder'
                )}
                prefix={<div className={styles.feeUnit}>US$</div>}
                disabled={this.vm.disabled.extraFee}
                controls={false}
                onChange={this.vm.onOtherChange}
              />
            </ResetFormItem>
          </div>
        </Form>
      </div>
    );
  }
}

Prices.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

Prices.defaultProps = {};

export default withForm(Prices);
