import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Select, Switch } from 'antd';
import clsx from 'clsx';
import { AppStateContext } from 'src/stores';
import i18n from 'src/i18n';
import { PDF_LINK } from 'src/constants/config';
import ResetDatePicker from 'src/components/ResetDatePicker';
import FormInput from '../FormInput';
import ContinueButton from '../ContinueButton/index';
import SignUpViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class SignUp extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = new SignUpViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.context);
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderEmail = () => {
    if (this.vm.isOutsideLogin) {
      return (
        <div className={styles.itemContainer}>
          <FormInput
            type="email"
            name="email"
            placeholder={i18n.t('find_hunt_page_sign_up_email_placeholder')}
            value={this.vm.email}
            onChange={this.vm.onChangeEmail}
            hasBorder
          />

          <div className={styles.infoContainer}>
            <div
              className={clsx([
                styles.info2,
                this.vm.showEmailErrorMessage && styles.colorRed
              ])}
            >
              {this.vm.emailMessage}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.itemContainer}>
        <div className={styles.emailContainer}>
          <div className={styles.borderContainer}>
            <div className={styles.placeholder}>
              {i18n.t('find_hunt_page_sign_up_email_placeholder')}
            </div>

            <div className={styles.email}>{this.props.email}</div>
          </div>

          <div className={styles.infoContainer}>
            <div className={styles.info1}>
              {i18n.t('find_hunt_page_sign_up_email_desc')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPasswordInput = () => {
    return (
      <div className={styles.itemContainer}>
        <FormInput
          type="password"
          name="password"
          placeholder={i18n.t('find_hunt_page_sign_up_password_placeholder')}
          value={this.vm.password}
          onChange={this.vm.onChangePassword}
          hasBorder
          errorMessage={i18n.t(
            'find_hunt_page_sign_up_password_invalid_error_message'
          )}
          showErrorMessage={!this.vm.verifyPassword}
        />
      </div>
    );
  };

  render() {
    return (
      <div id="signUp" className={styles.container}>
        <div className={styles.itemContainer}>
          <div className={styles.firstNameBorder}>
            <FormInput
              name="firstName"
              placeholder={i18n.t(
                'find_hunt_page_sign_up_first_name_placeholder'
              )}
              value={this.vm.firstName}
              onChange={this.vm.onChangeFirstName}
            />
          </div>

          <div className={styles.lastNameBorder}>
            <FormInput
              name="lastName"
              placeholder={i18n.t(
                'find_hunt_page_sign_up_last_name_placeholder'
              )}
              value={this.vm.lastName}
              onChange={this.vm.onChangeLastName}
            />
          </div>

          <div className={styles.infoContainer}>
            <div className={styles.info1}>
              {i18n.t('find_hunt_page_sign_up_name_desc')}
            </div>
          </div>
        </div>

        <div className={styles.itemContainer}>
          <div className={styles.heightItem}>
            <ResetDatePicker
              className={clsx([styles.fullContainer, styles.datePicker])}
              onChange={this.vm.onChangeBirthday}
              placeholder={i18n.t(
                'find_hunt_page_sign_up_birth_date_placeholder'
              )}
              disabledDate={this.vm.disabledDate}
              inputReadOnly
            />
          </div>

          <div className={styles.infoContainer}>
            <div className={styles.info1}>
              {i18n.t('find_hunt_page_sign_up_birth_date_desc')}
            </div>
          </div>
        </div>

        <div className={styles.itemContainer}>
          <div className={styles.heightItem}>
            <Select
              className={styles.fullContainer}
              options={this.vm.genderOptions}
              value={this.vm.gender}
              onChange={this.vm.onChangeGender}
              placeholder={i18n.t('find_hunt_page_sign_up_gender_placeholder')}
            />
          </div>
        </div>

        {/* 依照登入方式顯示不同的 email. */}
        {this.renderEmail()}

        {/* 使用 email 登入需要輸入密碼. */}
        {!this.vm.isOutsideLogin && this.renderPasswordInput()}

        <div className={styles.itemContainer}>
          <div className={styles.info2}>
            {i18n.t('find_hunt_page_sign_up_terms_desc_1')}

            <a
              href={PDF_LINK.privatePolicy}
              target="_blank"
              className={styles.pdfLink}
              rel="noreferrer"
            >
              {i18n.t('find_hunt_page_sign_up_terms_desc_2')}
            </a>
          </div>
        </div>

        <div className={styles.itemContainer}>
          <ContinueButton
            text={i18n.t('find_hunt_page_sign_up_continue_btn')}
            onClick={this.vm.onContinue}
            disabled={this.vm.disabledContinue}
          />
        </div>

        <div className={styles.itemContainer}>
          <div className={styles.info2}>
            {i18n.t('find_hunt_page_sign_up_notification_desc')}
          </div>
        </div>

        <div className={styles.messagesContainer}>
          <div className={styles.info3}>
            {i18n.t('find_hunt_page_sign_up_marketing_desc')}
          </div>

          <div className={styles.switchContainer}>
            <Switch
              checked={this.vm.getNews}
              onChange={this.vm.onChangeGetNews}
            />
          </div>
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  profile: PropTypes.object,
  email: PropTypes.string,
  close: PropTypes.func,
  hasEmail: PropTypes.bool,
  toVerify: PropTypes.func
};

SignUp.defaultProps = {
  profile: null,
  email: '',
  hasEmail: false,
  toVerify: () => {}
};

export default SignUp;
