import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import withRouter from 'src/components/withRouter';
import ActionModal from 'src/components/ActionModal';
import ModalTitle from 'src/pages/FindHuntPage/components/Authenticate/components/ModalTitle';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class QrCodeModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ActionModal
        vm={this.props.vm}
        wrapClassName="qrCodeModal"
        width={500}
        closeIcon={false}
      >
        <ModalTitle title="" close={this.props.vm.close} />

        <div className={styles.modalContainer}>
          <img src={require('src/assets/findHunt/kavavaqr.jpg')} />

          <div className={styles.font}>
            Install KAVAVA APP forMore information
          </div>
        </div>
      </ActionModal>
    );
  }
}

QrCodeModal.propTypes = {
  vm: PropTypes.object.isRequired
};

QrCodeModal.defaultProps = {
};

export default withRouter(QrCodeModal);
