import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { PACKAGE_TYPES } from 'src/constants/package';
import { formatPrice } from 'src/constants/price';
import PackageDrawerViewModel from 'src/components/PackageDrawer/viewModel';

class PackageCardViewModel {
  @observable package = null;

  packageDrawerVM = new PackageDrawerViewModel();

  deleted = () => {};

  @computed
  get id() {
    return this.package.id;
  }

  @computed
  get type() {
    return PACKAGE_TYPES[this.package.type].label;
  }

  @computed
  get price() {
    const p = formatPrice(this.package.price);
    return `US$${p}`;
  }

  @computed
  get description() {
    return this.package.description;
  }

  constructor(props) {
    this.package = props;
    makeObservable(this);
  }

  init = (props) => {
    this.deleted = props.deleted;
  };

  @action
  update = (data) => {
    this.package = { ...this.package, ...data };
  };

  onDetail = () => {
    this.packageDrawerVM.openEdit({
      data: this.package,
      changed: this.update,
      deleted: this.deleted
    });
  };
}

export default PackageCardViewModel;
