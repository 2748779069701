import { request, getHost } from './utils';

// 新增獵場, 設定地址.
export const postLandCreate = ({ token, street, county, state, postcode }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/backstage/land/create`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      street,
      county,
      state,
      postcode
    }
  };

  return request(options);
};

// 讀取指定的獵場資料.
export const getLandDetail = ({ token, id }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/land/${id}/detail`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 更新指定的獵場資料的基本資料.
export const putUpdateLandInfo = ({
  token,
  id,
  name,
  description,
  rules,
  street,
  county,
  state,
  postcode,
  position,
  acres,
  species,
  basicFee,
  extraFee,
  basicHunters,
  extraHunters,
  finalBookingDate,
  meetStreet,
  meetState,
  meetCity,
  meetPostcode,
  meetPosition,
  meetDescription,
  agreeTerms
}) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/backstage/land/${id}/info`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      name,
      description,
      rules,
      street,
      county,
      state,
      postcode,
      position,
      acres,
      species,
      basicFee,
      extraFee,
      basicHunters,
      extraHunters,
      finalBookingDate,
      meetStreet,
      meetState,
      meetCity,
      meetPostcode,
      meetPosition,
      meetDescription,
      agreeTerms
    }
  };

  return request(options);
};

// 獲取上傳照片需要的網址.
export const postGetUploadPhotosUrl = ({ token, id, photos }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/backstage/land/${id}/photos`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      photos
    }
  };

  return request(options);
};

// 將上傳完成的照片告訴 server.
export const putUpdatePhotos = ({ token, id, photos }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/backstage/land/${id}/photos`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      photos
    }
  };

  return request(options);
};

// 讀取所有獵場.
export const getLandList = ({ token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/land/list`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 更新獵場狀態.
export const putLandStatus = ({ token, id, status }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/backstage/land/${id}/status`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      status
    }
  };

  return request(options);
};

// 刪除獵場的草稿.
export const deleteLandDraft = ({ token, id }) => {
  const options = {
    method: 'delete',

    url: `${getHost()}/api/v1/backstage/land/${id}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 讀取獵場特別日收費列表.
export const getLandSpecialDateList = ({ token, id }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/land/${id}/special-date`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 新增獵場特別日收費.
export const postLandSpecialDate = ({ token, id, startAt, endAt, ratio }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/backstage/land/${id}/special-date`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      startAt,
      endAt,
      ratio
    }
  };

  return request(options);
};

// 刪除獵場特別日收費.
export const deleteLandSpecialDate = ({ token, id, sid }) => {
  const options = {
    method: 'delete',

    url: `${getHost()}/api/v1/backstage/land/${id}/special-date/${sid}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 新增獵場長期預約折扣.
export const postLandDiscount = ({ token, id, type, name, discount }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/backstage/land/${id}/discount`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      type,
      name,
      discount
    }
  };

  return request(options);
};

// 刪除獵場長期預約折扣.
export const deleteLandDiscount = ({ token, id, did }) => {
  const options = {
    method: 'delete',

    url: `${getHost()}/api/v1/backstage/land/${id}/discount/${did}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 讀取獵場長期折扣列表.
export const getLandDiscountList = ({ token, id }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/land/${id}/discount`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 新增獵場的行程.
export const postLandPackage = ({ token, id, presetPackageId, count }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/backstage/land/${id}/package`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      presetPackageId,
      count
    }
  };

  return request(options);
};

// 刪除獵場的行程.
export const deleteLandPackage = ({ token, id, pid }) => {
  const options = {
    method: 'delete',

    url: `${getHost()}/api/v1/backstage/land/${id}/package/${pid}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 讀取獵場的行程.
export const getLandPackageList = ({ token, id }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/land/${id}/package`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 讀取獵場提問列表.
export const getLandQAList = ({ token, id, limit, anchor }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/land/${id}/qa`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// 讀取獵場評分列表.
export const getLandRatingList = ({ token, id, limit, anchor }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/land/${id}/rating`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// 讀取獵場某月的每天是否開啟.
export const getLandBusinessDay = ({ token, id, date }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/land/${id}/business-day`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      date
    }
  };

  return request(options);
};

// 讀取獵場某月的每天是否開啟.
export const putLandBusinessDay = ({ token, id, days }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/backstage/land/${id}/business-day`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      days
    }
  };

  return request(options);
};

export const deleteLand = ({ token, landId }) => {
  const options = {
    method: 'delete',

    url: `${getHost()}/api/v1/backstage/land/${landId}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};
