import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import i18n from 'src/i18n';
import iconApple from 'src/assets/findHunt/icon_apple.png';
import iconFacebook from 'src/assets/findHunt/icon_facebook.png';
import FormInput from '../FormInput';
import ContinueButton from '../ContinueButton/index';
import CheckViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class Check extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CheckViewModel(props);
  }

  // 第三方登入按鈕.
  renderOutsideLoginButton({ onClick = () => {}, icon, alt, content }) {
    return (
      <Button className={styles.outsideLoginButton} onClick={onClick}>
        <div className={styles.flexContainer}>
          <div className={styles.iconContainer}>
            <img src={icon} alt={alt} className={styles.icon} />
          </div>

          <div className={styles.buttonContent}>{content}</div>

          <div className={styles.iconContainer} />
        </div>
      </Button>
    );
  }

  render() {
    return (
      <div id="check" className={styles.container}>
        <div className={styles.itemContainer}>
          <FormInput
            name="email"
            placeholder={i18n.t('find_hunt_page_check_email_placeholder')}
            value={this.vm.email}
            onChange={this.vm.onChangeEmail}
            type="email"
            hasBorder
            borderRadius={20}
            showErrorMessage={this.vm.showErrorMessage}
            errorMessage={this.vm.errorMessage}
          />
        </div>

        <div className={styles.itemContainer}>
          <ContinueButton
            text={i18n.t('find_hunt_page_check_email_btn')}
            onClick={this.vm.onLoginEmail}
            disabled={this.vm.disabledContinue}
            borderRadius={20}
          />
        </div>

        <div className={styles.itemContainer}>
          <div className={styles.hr}>
            <div className={styles.hrText}>
              {i18n.t('find_hunt_page_check_divider')}
            </div>
          </div>
        </div>

        {/* facebook 登入. */}
        <div className={styles.itemContainer}>
          {this.renderOutsideLoginButton({
            icon: iconFacebook,
            onClick: this.vm.onLoginFacebook,
            alt: 'facebook',
            content: i18n.t('find_hunt_page_check_facebook_btn')
          })}
        </div>

        {/* apple 登入. */}
        <div className={styles.itemContainer}>
          {this.renderOutsideLoginButton({
            icon: iconApple,
            onClick: this.vm.onLoginApple,
            alt: 'apple',
            content: i18n.t('find_hunt_page_check_apple_btn')
          })}
        </div>
      </div>
    );
  }
}

Check.propTypes = {
  setEmail: PropTypes.func.isRequired,
  toLogin: PropTypes.func.isRequired,
  toSignUp: PropTypes.func.isRequired
};

Check.defaultProps = {};

export default Check;
