import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ICONS from 'src/constants/icons';
import ResetButton from 'src/components/ResetButton';
import BasicCard from 'src/components/BasicCard';
import CustomRate from '../CustomRate';
import MessageDrawer from '../MessageDrawer';
import styles from './styles.module.scss';

@observer
class Message extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      type: props.type
    });
  }

  render() {
    return (
      <>
        <BasicCard className={styles.container} border>
          {this.vm.check.reply && (
            <img
              className={styles.replyIcon}
              src={ICONS.replyFull}
              alt="reply"
            />
          )}

          <ResetButton
            className={styles.button}
            type="text"
            onClick={this.vm.onClick}
          >
            <div className={styles.createAt}>{this.vm.createAt}</div>

            {this.vm.check.rating && (
              <CustomRate value={this.vm.score} disabled />
            )}

            <div className={styles.content}>{this.vm.content}</div>
          </ResetButton>
        </BasicCard>

        <MessageDrawer vm={this.vm.messageDrawerVM} type={this.props.type} />
      </>
    );
  }
}

Message.propTypes = {
  vm: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

Message.defaultProps = {};

export default Message;
