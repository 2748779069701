import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import cn from 'classnames';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import Footer from './components/Footer';
import Header from './components/Header';
import Games from './components/Games';
import PopularLand from './components/PopularLand';
import App from './components/App';
import styles from './styles.module.scss';

@observer
class FindHuntPage extends React.Component {
  render() {
    return (
      <div id="findHunt">
        <div className={cn(styles.root, 'wallpaper-p2')}>
          <Header profile={this.props.profile} />

          <Games />

          <PopularLand />

          <App />

          <Footer />
        </div>
      </div>
    );
  }
}

FindHuntPage.propTypes = {
  profile: PropTypes.object
};

FindHuntPage.defaultProps = {
  profile: null
};

export default withProfile(withRouter(FindHuntPage), false);
