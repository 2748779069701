class LandDiscountDataModel {
  constructor(data) {
    this.id = data.id;

    this.type = data.type;

    this.name = data.name;

    this.discount = data.discount;
  }
}

export default LandDiscountDataModel;
