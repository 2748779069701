import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { AppStateContext } from 'src/stores';
import { Switch } from 'antd';
import i18n from 'src/i18n';
import ActionDrawer from 'src/components/ActionDrawer';
import ActionDrawerHeader from 'src/components/ActionDrawer/components/ActionDrawerHeader';
import AlertModal from './components/AlertModal';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class BookingSettingDrawer extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  componentDidMount() {
    this.vm.didMount(this.context);
  }

  render() {
    return (
      <>
        <ActionDrawer
          vm={this.vm.drawerVM}
          rootClassName="bookingSettingDrawer"
          width={375}
          onClose={this.vm.close}
        >
          <div className={styles.container}>
            <ActionDrawerHeader
              content={i18n.t('bookings_page_booking_setting_drawer_title')}
            />

            <div className={styles.item}>
              <div className={styles.itemLabel}>
                <div className={styles.label}>
                  {i18n.t(
                    'bookings_page_booking_setting_drawer_auto_accept_label'
                  )}
                </div>

                <Switch
                  checked={this.vm.autoAcceptBooking}
                  onChange={this.vm.onAutoAcceptBookingChange}
                  disabled={this.vm.disabled.autoAcceptBooking}
                />
              </div>

              <div className={styles.content}>
                {this.vm.labels.autoAcceptBooking}
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.itemLabel}>
                <div className={styles.label}>
                  {i18n.t('bookings_page_booking_setting_drawer_message_label')}
                </div>

                <Switch
                  checked={this.vm.newReservationMessage}
                  onChange={this.vm.onNewReservationMessageChange}
                  disabled={this.vm.disabled.newReservationMessage}
                />
              </div>

              <div className={styles.content}>
                {this.vm.labels.newReservationMessage}
              </div>
            </div>
          </div>

          <AlertModal vm={this.vm.alertModalVM} />
        </ActionDrawer>
      </>
    );
  }
}

BookingSettingDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

BookingSettingDrawer.defaultProps = {};

export default BookingSettingDrawer;
