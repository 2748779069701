import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { observer } from 'mobx-react';

import iconClose from 'src/assets/findHunt/icon_close.png';

import styles from './styles.module.scss';

function ModalTitle(props) {
  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <Button className={styles.button} onClick={props.close} ghost>
          <img
            className={styles.iconClose}
            src={iconClose}
            alt="close"
          />
        </Button>
      </div>

      <div className={styles.titleContainer}>
        <div className={styles.title}>{props.title}</div>
      </div>

      <div className={styles.buttonContainer} />
    </div>
  );
}

ModalTitle.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func.isRequired
};

ModalTitle.defaultProps = {
  title: '你沒有傳入標題'
};

export default observer(ModalTitle);
