class LandSpecialDateDataModel {
  constructor(data) {
    this.id = data.id;

    this.startAt = data.startAt;

    this.endAt = data.endAt;

    // 收費比率.
    this.ratio = data.ratio;
  }
}

export default LandSpecialDateDataModel;
