import { makeObservable, observable, action, computed } from 'mobx';

class CalendarStore {
  refresh = () => {};

  businessDaysChanged = () => {}

  tagsChanged = async () => {}

  setRefresh = (changed = () => {}) => {
    this.refresh = changed;
  };

  setBusinessDaysChanged = (changed = () => {}) => {
    this.businessDaysChanged = changed;
  };

  setTagsChanged = (changed = () => {}) => {
    this.tagsChanged = changed;
  };
}

const calendarStore = new CalendarStore();

export default calendarStore;
