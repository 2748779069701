import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import i18n from 'src/i18n';
import ErrorServices from 'src/services/ErrorServices';
import UserService from 'src/services/UserService';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import AlertModalViewModel from './components/AlertModal/viewModel';

class BookingSettingDrawerViewModel {
  @observable data = null;
  @observable autoAcceptBooking = false;
  @observable newReservationMessage = false;
  @observable isAwait = false;

  drawerVM = new ActionDrawerViewModel();
  alertModalVM = new AlertModalViewModel();

  context = null;

  @computed
  get labels() {
    return {
      autoAcceptBooking: this.autoAcceptBooking
        ? i18n.t(
          'bookings_page_booking_setting_drawer_auto_accept_opened_content'
        )
        : i18n.t(
          'bookings_page_booking_setting_drawer_auto_accept_closed_content'
        ),
      newReservationMessage: this.newReservationMessage
        ? i18n.t('bookings_page_booking_setting_drawer_message_opened_content')
        : i18n.t('bookings_page_booking_setting_drawer_message_closed_content')
    };
  }

  @computed
  get disabled() {
    return {
      autoAcceptBooking: this.isAwait || !this.newReservationMessage,
      newReservationMessage: this.isAwait
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  didMount = (context) => {
    this.context = context;

    this.autoAcceptBooking = context.state.profile.shouldAutoAcceptReservations;
    this.newReservationMessage
      = context.state.profile.shouldSendNewReservationMessage;
  };

  open = () => {
    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  @action
  onAutoAcceptBookingChange = (value) => {
    this.autoAcceptBooking = value;
    this.putUserProfile();
  };

  @action
  onNewReservationMessageChange = (value) => {
    if (!this.autoAcceptBooking && !value) {
      this.alertModalVM.open({ changed: this.update });
    } else {
      this.newReservationMessage = value;
      this.putUserProfile();
    }
  };

  @action
  update = ({
    shouldAutoAcceptReservations,
    shouldSendNewReservationMessage
  }) => {
    this.autoAcceptBooking = shouldAutoAcceptReservations;
    this.newReservationMessage = shouldSendNewReservationMessage;
  };

  @action
  putUserProfile = async () => {
    this.isAwait = true;

    const formData = new FormData();

    formData.append('shouldAutoAcceptReservations', this.autoAcceptBooking);
    formData.append(
      'shouldSendNewReservationMessage',
      this.newReservationMessage
    );

    try {
      const res = await UserService.putUserProfile(formData);

      this.context.actions.updateProfile({
        shouldAutoAcceptReservations: this.autoAcceptReservations,
        shouldSendNewReservationMessage: this.newReservationMessage
      });
    } catch (error) {
      const msg = ErrorServices.putUserProfile(error);

      console.log('putUserProfile', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default BookingSettingDrawerViewModel;
