import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { AppStateContext } from 'src/stores';
import i18n from 'src/i18n';
import withRouter from 'src/components/withRouter';
import ActionDrawer from 'src/components/ActionDrawer';
import ResetButton from 'src/components/ResetButton';
import ProfileCard from './components/ProfileCard';
import IdCardModal from './components/IdCardModal';
import DisplayNameModal from './components/DisplayNameModal';
import GenderModal from './components/GenderModal';
import PhoneModal from './components/PhoneModal';
import DeactivateAccountModal from './components/DeactivateAccountModal';
import styles from './styles.module.scss';

@observer
class ProfileDrawer extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ router: props.router });
  }

  componentDidMount() {
    this.vm.didMount(this.context);
  }

  componentDidUpdate() {
    this.vm.didUpdate(this.context);
  }

  render() {
    return this.vm.check.profile ? (
      <>
        <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
          <div className={styles.container}>
            <div className={styles.cards}>
              <ProfileCard
                label={i18n.t('profile_drawer_profile_card_display_name_label')}
                content={this.vm.displayName}
                edit
                onClick={this.vm.onDisplayName}
              />

              <ProfileCard
                label={i18n.t('profile_drawer_profile_card_legal_name_label')}
                content={this.vm.legalName}
              />

              <ProfileCard
                label={i18n.t('profile_drawer_profile_card_gender_label')}
                content={this.vm.gender}
                edit={this.vm.edit.gender}
                onClick={this.vm.onGender}
              />

              <ProfileCard
                label={i18n.t('profile_drawer_profile_card_birth_date_label')}
                content={this.vm.birthday}
              />

              <ProfileCard
                label={i18n.t('profile_drawer_profile_card_email_label')}
                content={this.vm.email}
              />

              <ProfileCard
                label={i18n.t('profile_drawer_profile_card_phone_number_label')}
                content={this.vm.phone}
                edit
                onClick={this.vm.onPhone}
              />

              <ProfileCard
                label={i18n.t('profile_drawer_profile_card_id_card_label')}
                content={this.vm.idCard}
                edit={this.vm.edit.idCard}
                onClick={this.vm.onIdCard}
              />
            </div>

            <div className={styles.footer}>
              <div className={styles.logout}>
                <ResetButton
                  className={styles.button}
                  onClick={this.vm.onLogout}
                  disabled={this.vm.disabled.logout}
                >
                  {i18n.t('profile_drawer_logout_button_label')}
                </ResetButton>
              </div>

              <div className={styles.deactivate}>
                <ResetButton
                  type="text"
                  className={styles.button}
                  onClick={this.vm.onDeactivateAccount}
                >
                  <span className={styles.underline}>
                    {i18n.t('profile_drawer_deactivate_account_button_label')}
                  </span>
                </ResetButton>
              </div>
            </div>
          </div>
        </ActionDrawer>

        <DisplayNameModal vm={this.vm.displayNameModalVM} />

        <GenderModal vm={this.vm.genderModalVM} />

        <IdCardModal vm={this.vm.idCardModalVM} />

        <PhoneModal vm={this.vm.phoneModalVM} />

        <DeactivateAccountModal vm={this.vm.deactivateAccountModalVM} />
      </>
    ) : null;
  }
}

ProfileDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

ProfileDrawer.defaultProps = {};

export default withRouter(ProfileDrawer);
