import { makeObservable, observable, action } from 'mobx';

class OptionStore {
  // data.
  @observable states = null;
  @observable infos = null;

  // keys.
  statesKey = 'states';
  infosKey = 'infos';

  constructor() {
    makeObservable(this);
  }

  @action
  update = (key, value) => {
    this[key] = value;
  };
}

const optionStore = new OptionStore();

export default optionStore;
