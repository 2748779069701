import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { Waypoint } from 'react-waypoint';
import i18n from 'src/i18n';
import withRouter from 'src/components/withRouter';
import ResetTable from 'src/components/ResetTable';
import ResetButton from 'src/components/ResetButton';
import LinkButton from 'src/components/LinkButton';
import RentalPayoutsViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class RentalPayouts extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new RentalPayoutsViewModel({
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.inited ? (
      <div className={styles.container}>
        <div className={styles.scrollY}>
          <div className={styles.totalsTable}>
            <ResetTable data={this.vm.totals}>
              <Table.Column
                width={500}
                title={i18n.t('payouts_page_rental_tab_paid_total_label')}
                align="center"
                render={(item) => (
                  <LinkButton onClick={this.vm.onPaidTotal}>
                    {item.paid}
                  </LinkButton>
                )}
              />

              <Table.Column
                width={500}
                title={i18n.t('payouts_page_rental_tab_payable_total_label')}
                align="center"
                render={(item) => (
                  <LinkButton onClick={this.vm.onPayableTotal}>
                    {item.payable}
                  </LinkButton>
                )}
              />
            </ResetTable>
          </div>

          <div className={styles.payoutsTable}>
            <ResetTable data={this.vm.paids}>
              <Table.Column
                width={500}
                title={i18n.t('payouts_page_rental_tab_payout_date_label')}
                align="center"
                render={(vm) => (
                  <div className={styles.date}>{vm.labels.date}</div>
                )}
              />

              <Table.Column
                width={500}
                title={i18n.t('payouts_page_rental_tab_payout_label')}
                align="center"
                render={(vm) => (
                  <LinkButton onClick={() => this.vm.onPaid(vm)}>
                    {vm.labels.amount}
                  </LinkButton>
                )}
              />
            </ResetTable>
          </div>

          <Waypoint onEnter={this.vm.onWaypointEnter} />
        </div>
      </div>
    ) : null;
  }
}

RentalPayouts.propTypes = {
  router: PropTypes.object.isRequired
};

RentalPayouts.defaultProps = {};

export default withRouter(RentalPayouts);
