import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class ResetInput extends React.Component {
  render() {
    const { className, countAbsolute, ...ps } = this.props;

    return (
      <Input
        className={clsx([
          styles.input,
          countAbsolute && 'countAbsolute',
          className
        ])}
        {...ps}
      />
    );
  }
}

ResetInput.propTypes = {
  className: PropTypes.string,
  countAbsolute: PropTypes.bool
};

ResetInput.defaultProps = {
  className: '',
  countAbsolute: false
};

export default ResetInput;
