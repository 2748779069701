import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import withRouter from 'src/components/withRouter';
import PageCardsContainer from 'src/components/PageCardsContainer';
import Back from '../Back';
import Header from '../Header';
import LandsSelect from '../LandsSelect';
import BookingCard from './components/BookingCard';
import BookingsViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class Bookings extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new BookingsViewModel({ router: props.router });
  }

  render() {
    return (
      <>
        <Back />

        <Header subtitle={this.vm.labels.subtitle} />

        <LandsSelect
          value={this.vm.landId}
          onChange={this.vm.onLandIdChange}
          disabled={this.vm.disabled.landId}
          showSearch
          optionFilterProp="label"
        />

        <PageCardsContainer
          paddingBottom
          onWaypointEnter={this.vm.onWaypointEnter}
        >
          {this.vm.bookings.map((vm) => (
            <BookingCard vm={vm} key={vm.id} />
          ))}
        </PageCardsContainer>
      </>
    );
  }
}

Bookings.propTypes = {
  router: PropTypes.object.isRequired
};

Bookings.defaultProps = {};

export default withRouter(Bookings);
