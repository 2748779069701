import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table } from 'antd';
import ICONS from 'src/constants/icons';
import ResetTable from '../ResetTable';
import styles from './styles.module.scss';

const Row = ({ children, style, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    // 讀取 table rowKey 的值
    id: props['data-row-key']
  });

  const newStyle = {
    ...style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    ),
    transition,
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999
      }
      : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={newStyle} {...attributes}>
      {React.Children.map(children, (child) => {
        // 將拖移按鈕站位符替換為圖片和加入拖移功能
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <div
                className={styles.drag}
                ref={setActivatorNodeRef}
                {...listeners}
              >
                <img
                  className={styles.icon}
                  src={ICONS.dragTableButton}
                  alt="drag"
                />
              </div>
            )
          });
        }

        return child;
      })}
    </tr>
  );
};

@observer
class DragTable extends React.Component {
  onDragEnd = ({ active, over }) => {
    const data = this.props.data.map((item) => item);
    const change = active.id !== over?.id;
    const res = { change, data };

    if (change) {
      const activeIndex = data.findIndex((i) => i.id === active.id);
      const overIndex = data.findIndex((i) => i.id === over?.id);

      res.data = arrayMove(data, activeIndex, overIndex);
    }

    this.props.onDragEnd(res);
  };

  render() {
    const { children, data, onDragEnd, ...ps } = this.props;

    return (
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={this.onDragEnd}
      >
        <SortableContext
          // rowKey array
          items={data.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          <ResetTable data={data} components={{ body: { row: Row } }} {...ps}>
            {/* 拖移按鈕的站位符號 */}
            <Table.Column key="sort" title="Order" align="center" />

            {children}
          </ResetTable>
        </SortableContext>
      </DndContext>
    );
  }
}

DragTable.propTypes = {
  children: PropTypes.node,
  data: PropTypes.array,
  onDragEnd: PropTypes.func
};

DragTable.defaultProps = {
  children: null,
  data: [],
  onDragEnd: () => {}
};

export default DragTable;
