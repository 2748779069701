import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ICONS from 'src/constants/icons';
import styles from './styles.module.scss';

@observer
class ActionModalWarning extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <img src={this.props.icon} alt="warning" className={styles.icon} />
        <div className={styles.label}>{this.props.content}</div>
      </div>
    );
  }
}

ActionModalWarning.propTypes = {
  icon: PropTypes.string,
  content: PropTypes.string
};

ActionModalWarning.defaultProps = {
  icon: ICONS.warning,
  content: ''
};

export default ActionModalWarning;
