import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import i18n from 'src/i18n';
import { PAYOUT_STATUS } from 'src/constants/payout';
import { PARAMS } from 'src/constants/routes';
import { routerMethods } from 'src/constants/methods';
import ErrorServices from 'src/services/ErrorServices';
import PayoutServices from 'src/services/PayoutServices';
import BookingRecordCardViewModel from './components/BookingRecordCard/viewModel';

class BookingPayoutRecordsViewModel {
  @observable status = null;

  @observable landId = null;
  @observable bookings = [];

  @observable statistics = {
    paid: {
      total: '0',
      startAt: null,
      endAt: null
    },
    payable: {
      total: '0',
      startAt: null,
      endAt: null
    }
  };

  @observable inited = false;
  @observable isAwait = false;

  anchor = null;

  @computed
  get disabled() {
    return {
      landId: this.isAwait
    };
  }

  @computed
  get subtitle() {
    const data = this.statistics[this.status];

    if (data && data.startAt && data.endAt) {
      const label = i18n.t('payout_details_page_booking_subtitle_label');
      const startAt = dayjs(data.startAt).format('YYYY');
      const endAt = dayjs(data.endAt).format('YYYY');

      return `${startAt} - ${endAt} ${label}`;
    }

    return '';
  }

  constructor(props) {
    this.status = routerMethods.getSearch(props.router, PARAMS.payoutStatus);
    makeObservable(this);
  }

  didMount = async () => {
    await this.reset();

    runInAction(() => {
      this.inited = true;
    });
  };

  @action
  onLandIdChange = (val) => {
    this.landId = val;
    this.reset();
  };

  @action
  onWaypointEnter = async () => {
    if (this.inited && !this.isAwait && this.anchor !== null) {
      this.isAwait = true;

      await this.switchAPI();

      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  @action
  reset = async () => {
    this.isAwait = true;

    this.bookings = [];
    this.anchor = undefined;

    await Promise.all([this.getPayoutStatisticsAPI(), this.switchAPI()]);

    runInAction(() => {
      this.isAwait = false;
    });
  };

  switchAPI = async () => {
    switch (this.status) {
      case PAYOUT_STATUS.paid:
        await this.getPayoutPaidRecordsAPI();
        break;

      case PAYOUT_STATUS.payable:
        await this.getPayoutPayableRecordsAPI();
        break;

      default:
        break;
    }
  };

  getPayoutStatisticsAPI = async () => {
    try {
      const res = await PayoutServices.getPayoutStatistics({
        landId: this.landId || undefined
      });

      runInAction(() => {
        this.statistics = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getPayoutStatistics(error);
      console.log('getPayoutStatistics', msg);
    }
  };

  getPayoutPaidRecordsAPI = async () => {
    try {
      const res = await PayoutServices.getPayoutPaidRecords({
        landId: this.landId || undefined,
        anchor: this.anchor
      });

      runInAction(() => {
        const data = res.data.map(
          (item) => new BookingRecordCardViewModel(item)
        );

        this.bookings = [...this.bookings, ...data];
        this.anchor = res.anchor;
      });
    } catch (error) {
      const msg = ErrorServices.getPayoutPaidRecords(error);
      console.log('getPayoutPaidRecords', msg);
    }
  };

  getPayoutPayableRecordsAPI = async () => {
    try {
      const res = await PayoutServices.getPayoutPayableRecords({
        landId: this.landId || undefined,
        anchor: this.anchor
      });

      runInAction(() => {
        const data = res.data.map(
          (item) => new BookingRecordCardViewModel(item)
        );

        this.bookings = [...this.bookings, ...data];
        this.anchor = res.anchor;
      });
    } catch (error) {
      const msg = ErrorServices.getPayoutPayableRecords(error);
      console.log('getPayoutPayableRecords', msg);
    }
  };
}

export default BookingPayoutRecordsViewModel;
