class ConfigDataModel {
  constructor(props) {
    this.version = props.version;

    this.timezone = props.timezone;

    this.bookingAfterDays = props.bookingAfterDays;

    this.platformServiceFeeRatio = props.platformServiceFeeRatio;

    this.taxRate = props.taxRate;

    this.emailAuthLimit = props.emailAuthLimit;

    this.phoneAuthLimit = props.phoneAuthLimit;

    this.resetPasswordLimit = props.resetPasswordLimit;

    this.bookingTokenExpires = props.bookingTokenExpires;

  }
}

export default ConfigDataModel;
