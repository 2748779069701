import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ErrorServices from 'src/services/ErrorServices';
import PackageServices from 'src/services/PackageServices';
import PackageDrawerViewModel from 'src/components/PackageDrawer/viewModel';
import PackageCardViewModel from './components/PackageCard/viewModel';

class PackagesPageViewModel {
  @observable packages = [];
  @observable inited = false;
  @observable isAwait = false;

  packageDrawerVM = new PackageDrawerViewModel();

  @computed
  get check() {
    return {
      packages: !!this.packages.length
    };
  }

  @computed
  get disabled() {
    return {
      create: this.isAwait
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  didMount = async () => {
    await this.getPackageListAPI();

    runInAction(() => {
      this.inited = true;
    });
  }

  onCreate = () => {
    this.packageDrawerVM.openCreate({ created: this.getPackageListAPI });
  }

  deleted = async () => {
    await this.getPackageListAPI();
  }

  @action
  getPackageListAPI = async () => {
    this.isAwait = true;

    try {
      const res = await PackageServices.getPackageList();

      runInAction(() => {
        this.packages = res.data.map((item) => new PackageCardViewModel(item));
      });
    } catch (error) {
      const msg = ErrorServices.getLandList(error);

      console.log('getPackageList', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

}

export default PackagesPageViewModel;
