import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import { ROUTES } from 'src/constants/routes';
import { VERIFY_CODE_LENGTH, SOURCE_TYPES } from 'src/constants/config';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';
import ResendButtonViewModel from 'src/components/ResendButton/viewModel';

class VerifyViewModel {
  @observable profile = null;
  @observable isAwait = false;
  @observable code = '';
  @observable showErrorMessage = false;
  // 倒數計時按鈕.
  resendButtonVM = new ResendButtonViewModel();

  @computed
  get showError() {
    return this.showErrorMessage || this.code.length !== VERIFY_CODE_LENGTH;
  }

  @computed
  get disabledContinue() {
    return this.code.length !== VERIFY_CODE_LENGTH;
  }

  @computed
  get isOutsideLogin() {
    switch (this.profile?.source) {
      case SOURCE_TYPES.facebook:
      case SOURCE_TYPES.apple:
        return true;

      default:
        return false;
    }
  }

  constructor(props) {
    this.profile = props.profile;
    this.props = props;

    makeObservable(this);

    console.log('verify', 'profile', this.profile);
  }

  didMount = () => {
    // 開啟排程.
    this.resendButtonVM.setTimer();
  };

  unMount = () => {
    // 刪除排程.
    this.resendButtonVM.clearTimer();
  };

  @action
  onChangeCode = (code) => {
    this.code = code;

    console.log('code', code);
  };

  // 重發驗證信.
  onResend = () => {
    this.postUserResendAuthEmailAPI();
  };

  // 驗證 email and code.
  onContinue = () => {
    this.postUserVerifyAPI();
  };

  // 重發驗證信.
  @action
  postUserResendAuthEmailAPI = async () => {
    this.isAwait = true;

    try {
      const res = await UserService.postUserResendAuthEmail({
        email: this.props.email
      });

      runInAction(() => {
        // 開啟排程.
        this.resendButtonVM.setTimer();

        this.isAwait = false;
      });
    } catch (error) {
      runInAction(() => {
        const label = ErrorServices.postUserVerify(error);

        this.isAwait = false;

        console.log('postUserVerify', error);
      });
    }
  };

  // 驗證 email and code.
  @action
  postUserVerifyAPI = async () => {
    this.isAwait = true;
    this.showErrorMessage = false;

    try {
      const res = await UserService.postUserVerify({ code: this.code });

      // 第三方登入完成驗證.
      if (this.isOutsideLogin) {
        const route = `/${ROUTES.reservations.value}`;

        // 前往後台.
        this.props.router.navigate(route, { replace: true });

        // 關閉彈窗.
        this.props.close();
      } else {
        // email 有註冊, 前往登入.
        this.props.toLogin();
      }
    } catch (error) {
      const msg = ErrorServices.postUserVerify(error);

      runInAction(() => {
        switch (error.response?.status) {
          case 404:
            this.showErrorMessage = true;
            break;

          default:
        }
      });

      console.log('postUserVerify', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default VerifyViewModel;
