import { request, getHost } from './utils';

// 讀取所有訂單.
export const postBookingList = ({
  token,
  limit,
  anchor,
  landId,
  status,
  dateType,
  startAt,
  endAt,
  keyword
}) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/backstage/booking/list`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      limit,
      anchor
    },

    data: {
      landId,
      status,
      dateType,
      startAt,
      endAt,
      keyword
    }
  };

  return request(options);
};

// 接受訂單.
export const putBookingAccept = ({
  token,
  id
}) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/backstage/booking/${id}/accept`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 拒絕訂單.
export const putBookingReject = ({
  token,
  id
}) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/backstage/booking/${id}/reject`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 訂單詳情.
export const getBookingDetail = ({
  token,
  id
}) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/booking/${id}/detail`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 刪除訂單.
export const putBookingCancel = ({
  token,
  id,
  reason
}) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/backstage/booking/${id}/cancel`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      reason
    }
  };

  return request(options);
};

// 訂單分類的數量.
export const getBookingStatistics = ({
  token,
  landId,
  timezone
}) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/booking/statistics`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      land: landId,
      timezone
    }
  };

  return request(options);
};

export const putBookingInfo = ({
  token,
  bookingId,
  note
}) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/backstage/booking/${bookingId}/info`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      note
    }
  };

  return request(options);
};
