import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import styles from './styles.module.scss';

@observer
class PageContainer extends React.Component {
  render() {
    const { className, children, ...ps } = this.props;

    return (
      <div className={clsx([styles.container, className])} {...ps}>
        {children}
      </div>
    );
  }
}

PageContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

PageContainer.defaultProps = {
  className: '',
  children: null
};

export default PageContainer;
