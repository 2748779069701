import API from 'src/apis';
import OptionStateDataModel from 'src/models/response/OptionStateDataModel';
import OptionInfoDataModel from 'src/models/response/OptionInfoDataModel';

class OptionServices {
  // 新增獵場, 編輯獵場, 地區城市選項.
  static async getOptionState() {
    const res = await API.option.getOptionState();

    return {
      data: new OptionStateDataModel(res.data.state)
    };
  }

  // 新增獵場, 編輯獵場, 獵物選項.
  static async getOptionInfo() {
    const res = await API.option.getOptionInfo();

    return {
      data: new OptionInfoDataModel(res.data)
    };
  }
}

export default OptionServices;
