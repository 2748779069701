import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeObservable, observable, action, computed } from 'mobx';
import i18n from 'src/i18n';
import { PARAMS } from 'src/constants/routes';
import { routerMethods } from 'src/constants/methods';
import { PAYOUT_DETAIL_TYPES, getPayoutDetailType } from 'src/constants/payout';
import withRouter from 'src/components/withRouter';
import styles from './styles.module.scss';

@observer
class Header extends React.Component {
  @observable type = null;

  @computed
  get content() {
    switch (this.type) {
      case PAYOUT_DETAIL_TYPES.bookingPaid:
        return i18n.t('payout_details_page_booking_paids_title');


      case PAYOUT_DETAIL_TYPES.bookingPayable:
        return i18n.t('payout_details_page_booking_payables_title');


      case PAYOUT_DETAIL_TYPES.rentalPaid:
        return i18n.t('payout_details_page_rental_paids_title');


      case PAYOUT_DETAIL_TYPES.rentalPayable:
        return i18n.t('payout_details_page_rental_payables_title');


      default:
        return '';
    }
  }

  constructor(props) {
    super(props);
    this.init({ router: props.router });
    makeObservable(this);
  }

  @action
  init = ({ router }) => {
    const type = routerMethods.getSearch(router, PARAMS.payoutType);
    const status = routerMethods.getSearch(router, PARAMS.payoutStatus);

    this.type = getPayoutDetailType({ type, status });
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          {this.content}
        </div>

        <div className={styles.subtitle}>
          {this.props.subtitle}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  router: PropTypes.object.isRequired,
  subtitle: PropTypes.string
};

Header.defaultProps = {
  subtitle: ''
};

export default withRouter(Header);
