import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import './reset.scss';

// 共用的 Drawer.
function ActionDrawer(props) {
  const { vm, children, ...ps } = props;

  return (
    <Drawer
      className="actionDrawer"
      open={vm.isOpen}
      {...ps}
    >
      {children}
    </Drawer>
  );
}

ActionDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  destroyOnClose: PropTypes.bool,
  maskClosable: PropTypes.bool
};

ActionDrawer.defaultProps = {
  width: 800,
  title: null,
  footer: null,
  children: null,
  destroyOnClose: true,
  maskClosable: false
};

export default observer(ActionDrawer);
