import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import withProfile from 'src/components/withProfile';
import Login from './components/Login';
import Booking from './components/Booking';
import CheckBookingPageViewModel, { PAGES } from './viewModel';
import styles from './styles.module.scss';

@observer
class CheckBookingPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CheckBookingPageViewModel({
      profile: props.profile
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderPage = () => {
    switch (this.vm.page) {
      case PAGES.login:
        return <Login />;

      case PAGES.booking:
        return <Booking toLogin={this.vm.toLogin} />;

      default:
        return null;
    }
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderPage()}
      </div>
    );
  }
}

CheckBookingPage.propTypes = {
  profile: PropTypes.object
};

CheckBookingPage.defaultProps = {
  profile: null
};

export default withProfile(CheckBookingPage);
