import React from 'react';
import { Form } from 'antd';

function withForm(Component) {
  return (props) => {
    const [form] = Form.useForm();

    return <Component form={form} {...props} />;
  };
}

export default withForm;
