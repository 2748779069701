import PropTypes from 'prop-types';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { APP } from 'src/constants/config';
import googlePlay from 'src/assets/findHunt/c3fdb93d086bb2d82fe76874d7fdc70c.png';
import appleStore from 'src/assets/findHunt/638dd47a5eff159a8ced4b2ee6f05cf8.png';
import styles from './styles.module.scss';

function AppButtons(props) {
  const style = props.media === 'big' ? styles.big : styles.small;

  return (
    <div className={clsx([styles.container, style])}>
      <div className={styles.title}>{i18n.t('find_hunt_page_apps_title')}</div>

      <div className={styles.buttons}>
        <a
          className={styles.button}
          href={APP.googlePlay}
          target="_blank"
          rel="noreferrer"
        >
          <img src={googlePlay} alt="googlePlay" className={styles.image} />
        </a>

        <a
          className={styles.button}
          href={APP.appStore}
          target="_blank"
          rel="noreferrer"
        >
          <img src={appleStore} alt="appleStore" className={styles.image} />
        </a>
      </div>
    </div>
  );
}

AppButtons.propTypes = {
  media: PropTypes.string
};

AppButtons.defaultProps = {
  media: 'big'
};

export default AppButtons;
