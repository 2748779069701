import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetButton from 'src/components/ResetButton';
import styles from './styles.module.scss';

@observer
class ReplyModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ type: props.type, form: props.form });
  }

  render() {
    return (
      <ActionModal
        vm={this.vm.modalVM}
        width={375}
        onCancel={this.vm.close}
      >
        <div className={styles.container}>
          <Form form={this.vm.form} disabled={this.vm.disabled.modal}>
            <ResetFormItem
              name="reply"
              rules={this.vm.rules.reply}
              errorMessageAbsolute
            >
              <Input.TextArea
                autoSize={{ minRows: 6, maxRows: 12 }}
                showCount
                maxLength={500}
                placeholder={i18n.t(
                  'land_messages_drawer_message_reply_placeholder'
                )}
              />
            </ResetFormItem>
          </Form>

          <ActionModalFooter
            cancelProps={{
              onClick: this.vm.close,
              children: i18n.t('land_messages_drawer_message_reply_cancel_button_label')
            }}
            confirmProps={{
              onClick: this.vm.onConfirm,
              disabled: this.vm.disabled.footer,
              children: i18n.t('land_messages_drawer_message_reply_confirm_button_label')
            }}
          />
        </div>
      </ActionModal>
    );
  }
}

ReplyModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

ReplyModal.defaultProps = {};

export default withForm(ReplyModal);
