import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class ResetButton extends React.Component {
  render() {
    const { className, type, align, children, ...ps } = this.props;
    const isCenter = align === 'center';
    const isReject = type === 'reject';
    const isText = type === 'text';
    const isBlack = type === 'black';

    return (
      <Button
        className={clsx(
          'resetButton',
          styles.button,
          isCenter && styles.center,
          isReject && styles.reject,
          isText && styles.text,
          isBlack && styles.black,
          className
        )}
        type={isReject ? 'default' : type}
        {...ps}
      >
        {children}
      </Button>
    );
  }
}

ResetButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  align: PropTypes.string
};

ResetButton.defaultProps = {
  children: null,
  className: '',
  type: 'default',
  align: ''
};

export default ResetButton;
