import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import i18n from 'src/i18n';
import ResetSelect from 'src/components/ResetSelect';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';
import styles from './styles.module.scss';

@observer
class LandsSelect extends React.Component {
  @observable lands = [];
  @observable isAwait = false;

  @computed
  get options() {
    return this.lands.map((item) => ({ value: item.id, label: item.name }));
  }

  @computed
  get disabled() {
    return this.isAwait || this.props.disabled;
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.getLandListAPI();
  }

  @action
  getLandListAPI = async () => {
    this.isAwait = true;

    try {
      const res = await LandServices.getLandList();

      runInAction(() => {
        this.lands = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getLandList(error);
      console.log('getLandList', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  render() {
    const {
      allowClear,
      placeholderSolid,
      placeholder,
      options,
      value,
      onChange,
      disabled,
      ...ps
    } = this.props;

    return (
      <div className={styles.container}>
        <ResetSelect
          allowClear={allowClear}
          placeholderSolid={placeholderSolid}
          placeholder={placeholder}
          options={this.options}
          value={value}
          onChange={onChange}
          disabled={this.disabled}
          {...ps}
        />
      </div>
    );
  }
}

LandsSelect.propTypes = {
  allowClear: PropTypes.bool,
  placeholderSolid: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

LandsSelect.defaultProps = {
  allowClear: true,
  placeholderSolid: true,
  placeholder: i18n.t('payout_details_page_select_land_placeholder'),
  options: [],
  value: null,
  onChange: () => {},
  disabled: false
};

export default LandsSelect;
