import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ActionDrawer from 'src/components/ActionDrawer';
import ActionDrawerHeader from 'src/components/ActionDrawer/components/ActionDrawerHeader';
import ResetInput from 'src/components/ResetInput';
import ResetButton from 'src/components/ResetButton';
import ResetSelect from 'src/components/ResetSelect';
import ResetRangePicker from 'src/components/ResetRangePicker';
import styles from './styles.module.scss';

@observer
class RentalsFilterDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
        <div className={styles.container}>
          <ActionDrawerHeader
            content={i18n.t('rentals_filter_drawer_title')}
            paddingBottom
          />

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('rentals_filter_drawer_date_label')}
            </div>

            <ResetRangePicker
              size="large"
              value={this.vm.dates}
              onChange={this.vm.onDatesChange}
              inputReadOnly
            />
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('rentals_filter_drawer_keyword_label')}
            </div>

            <ResetSelect
              options={this.vm.options.keywordTypes}
              value={this.vm.keywordType}
              onChange={this.vm.onKeywordTypeChange}
            />

            <ResetInput
              value={this.vm.keyword}
              onChange={this.vm.onKeywordChange}
              placeholder={i18n.t('rentals_filter_drawer_keyword_placeholder')}
            />
          </div>

          <div className={styles.footer}>
            <ResetButton
              className={styles.button}
              type="primary"
              onClick={this.vm.onConfirm}
            >
              {i18n.t('rentals_filter_drawer_confirm_button_label')}
            </ResetButton>
          </div>
        </div>
      </ActionDrawer>
    );
  }
}

RentalsFilterDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

RentalsFilterDrawer.defaultProps = {};

export default RentalsFilterDrawer;
