import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import withForm from 'src/components/withForm';
import { Form, Input } from 'antd';
import i18n from 'src/i18n';
import ResetFormItem from 'src/components/ResetFormItem';
import styles from './styles.module.scss';

@observer
class Rules extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ land: props.land, form: props.form });
  }

  render() {
    return (
      <div className={styles.container}>
        <Form form={this.vm.form}>
          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('land_edit_drawer_rules_page_rules_label')}
            </div>

            <div className={styles.input}>
              <ResetFormItem name="rules" rules={this.vm.rules}>
                <Input.TextArea
                  size="large"
                  maxLength={500}
                  showCount
                  autoSize={{ minRows: 6 }}
                  placeholder={i18n.t(
                    'land_edit_drawer_rules_page_rules_placeholder'
                  )}
                />
              </ResetFormItem>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

Rules.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

Rules.defaultProps = {};

export default withForm(Rules);
