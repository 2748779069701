import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import { AppStateContext } from 'src/stores';
import ActionModal from 'src/components/ActionModal';
import ActionModalDetailWarning from 'src/components/ActionModal/components/ActionModalDetailWarning';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ActionModalAlertContainer from 'src/components/ActionModal/components/ActionModalAlertContainer';
import styles from './styles.module.scss';

@observer
class AlertModal extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  componentDidMount() {
    this.vm.didMount(this.context);
  }

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        <ActionModalAlertContainer>
          <ActionModalDetailWarning
            title={i18n.t(
              'bookings_page_booking_setting_drawer_alert_title_label'
            )}
            content={i18n.t(
              'bookings_page_booking_setting_drawer_alert_content_label'
            )}
          />

          <ActionModalFooter
            cancelProps={{
              onClick: this.vm.close,
              children: i18n.t(
                'bookings_page_booking_setting_drawer_alert_cancel_button_label'
              )
            }}
            confirmProps={{
              onClick: this.vm.onConfirm,
              children: i18n.t(
                'bookings_page_booking_setting_drawer_alert_confirm_button_label'
              ),
              disabled: this.vm.disabled.confirm
            }}
          />
        </ActionModalAlertContainer>
      </ActionModal>
    );
  }
}

AlertModal.propTypes = {
  vm: PropTypes.object.isRequired
};

AlertModal.defaultProps = {};

export default AlertModal;
