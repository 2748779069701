import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table, Form, Select } from 'antd';
import i18n from 'src/i18n';
import ICONS from 'src/constants/icons';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetButton from 'src/components/ResetButton';
import ResetTable from 'src/components/ResetTable';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetInputNumber from 'src/components/ResetInputNumber';
import ResetRangePicker from 'src/components/ResetRangePicker';
import CustomLi from 'src/components/CustomLi';
import styles from './styles.module.scss';
import './reset.scss';

const ratios = (
  <ResetFormItem name="type">
    <Select
      className="specialDatesSelectTypes"
      style={{
        width: 100
      }}
    >
      <Select.Option value>
        {i18n.t('land_edit_drawer_special_add_modal_plus_label')}
      </Select.Option>

      <Select.Option value={false}>
        {i18n.t('land_edit_drawer_special_add_modal_off_label')}
      </Select.Option>
    </Select>
  </ResetFormItem>
);

@observer
class SpecialDates extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      land: props.land,
      form: props.form,
      changed: props.changed
    });
  }

  componentWillUnmount(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.mainLabel}>
            {i18n.t(
              'land_edit_drawer_special_dates_page_main_label_description'
            )}
          </div>

          <div className={styles.mainLabel}>
            <div>
              {i18n.t(
                'land_edit_drawer_special_dates_page_main_label_prices_label'
              )}
            </div>
            <CustomLi>{this.vm.basicFee}</CustomLi>
            <CustomLi>{this.vm.extraFee}</CustomLi>
          </div>

          <div className={styles.add}>
            <ResetButton
              className={styles.button}
              type="primary"
              onClick={this.vm.onAdd}
            >
              {i18n.t('land_edit_drawer_special_dates_page_add_button_label')}
            </ResetButton>
          </div>

          <div className={styles.table}>
            <ResetTable data={this.vm.dates}>
              <Table.Column
                title={i18n.t(
                  'land_edit_drawer_special_dates_page_table_plan_label'
                )}
                render={(item) => (
                  <div className={styles.labels}>
                    <div className={styles.label}>{item.date}</div>
                    <div className={styles.label}>{item.basicFee}</div>
                    <div className={styles.label}>{item.extraFee}</div>
                  </div>
                )}
              />

              <Table.Column
                width={50}
                align="center"
                title={i18n.t(
                  'land_edit_drawer_special_dates_page_table_delete_label'
                )}
                render={(item) => (
                  <ResetButton
                    className={styles.deleteButton}
                    type="text"
                    icon={<img src={ICONS.delete} alt="delete" />}
                    onClick={() => this.vm.onDelete(item.id)}
                    disabled={this.vm.disabled.delete}
                  />
                )}
              />
            </ResetTable>
          </div>
        </div>

        <ActionModal
          vm={this.vm.addModalVM}
          width={375}
          onCancel={this.vm.addModalVM.close}
        >
          <div className={styles.modalContainer}>
            <Form
              className={styles.form}
              form={this.vm.form}
              disabled={this.vm.disabled.form}
            >
              <div className={styles.item}>
                <div className={styles.label}>
                  {i18n.t(
                    'land_edit_drawer_special_add_modal_dates_ratio_label'
                  )}
                </div>

                <ResetFormItem
                  name="date"
                  rules={this.vm.rules.date}
                  errorMessageAbsolute
                >
                  <ResetRangePicker
                    className={styles.date}
                    size="large"
                    inputReadOnly
                  />
                </ResetFormItem>
              </div>

              <div className={styles.item}>
                <div className={styles.label}>
                  {i18n.t(
                    'land_edit_drawer_special_add_modal_dates_ratio_label'
                  )}
                </div>

                <ResetFormItem
                  name="ratio"
                  rules={this.vm.rules.ratio}
                  errorMessageAbsolute
                >
                  <ResetInputNumber
                    className={styles.input}
                    addonBefore={ratios}
                    step={10}
                    precision={0}
                    max={100}
                    min={0}
                    stringMode
                    customStepButton
                  />
                </ResetFormItem>
              </div>
            </Form>

            <ActionModalFooter
              cancelProps={{
                onClick: this.vm.addModalVM.close,
                children: i18n.t(
                  'land_edit_drawer_special_add_modal_dates_cancel_button_label'
                )
              }}
              confirmProps={{
                onClick: this.vm.onModalConfirm,
                disabled: this.vm.disabled.confirm,
                children: i18n.t(
                  'land_edit_drawer_special_add_modal_dates_confirm_button_label'
                )
              }}
            />
          </div>
        </ActionModal>
      </>
    );
  }
}

SpecialDates.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  changed: PropTypes.func
};

SpecialDates.defaultProps = {
  changed: () => {}
};

export default withForm(SpecialDates);
