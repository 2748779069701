import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Divider } from 'antd';
import styles from './styles.module.scss';

@observer
class ActionDrawerItem extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.item}>
          <div className={styles.label}>{this.props.label}</div>
          <div className={styles.content}>{this.props.children}</div>
        </div>

        {this.props.divider && <Divider className={styles.divider} />}
      </div>
    );
  }
}

ActionDrawerItem.propTypes = {
  label: PropTypes.string,
  divider: PropTypes.bool,
  children: PropTypes.node
};

ActionDrawerItem.defaultProps = {
  label: '',
  divider: false,
  children: null
};

export default ActionDrawerItem;
