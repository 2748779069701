import add from 'src/assets/icon_add.svg';

import deleteIcon from 'src/assets/icon_delete.svg';

import warning from 'src/assets/icon_warning.svg';
import warningBlack from 'src/assets/icon_warning_black.svg';
import warningRed from 'src/assets/icon_warning_red.svg';

import tickGreen from 'src/assets/icon_tick_green.svg';

import arrowLeft from 'src/assets/icon_arrow_left.svg';
import arrowRight from 'src/assets/icon_arrow_right.svg';

import print from 'src/assets/icon_print.svg';

import parcelLabelDownload from 'src/assets/icon_parcel_label_download.svg';
import parcelNote from 'src/assets/icon_parcel_note.svg';

import starFll from 'src/assets/icon_star_full.svg';
import starEmpty from 'src/assets/icon_star_empty.svg';

import profile from 'src/assets/icon_profile.svg';

import menu from 'src/assets/icon_menu.svg';
import menuLands from 'src/assets/icon_menu_lands.svg';
import menuBookings from 'src/assets/icon_menu_bookings.svg';
import menuPackages from 'src/assets/icon_menu_packages.svg';
import menuRentals from 'src/assets/icon_menu_rentals.svg';
import menuPayouts from 'src/assets/icon_menu_payouts.svg';
import menuCalendar from 'src/assets/icon_menu_calendar.svg';

import drawerNext from 'src/assets/icon_drawer_next.svg';

import addBusinessDayTag from 'src/assets/icon_add_business_day_tag.svg';

import back2 from 'src/assets/icon_back_2.svg';

import checked from 'src/assets/icon_check_box_checked.svg';
import unCheck from 'src/assets/icon_check_box_un_check.svg';

import bookingsFilter from 'src/assets/icon_bookings_filter.svg';
import bookingsSettingItem from 'src/assets/icon_more_item.svg';

import dragTableButton from 'src/assets/icon_drag_table_button.svg';

import stopBlack from 'src/assets/icon_stop_black.svg';

import feeCollapseShrink from 'src/assets/icon_fee_collapse_shrink.svg';
import feeCollapseGrow from 'src/assets/icon_fee_collapse_grow.svg';

import reply from 'src/assets/icon_reply.svg';
import replyFull from 'src/assets/icon_reply_full.svg';

import instagram from 'src/assets/icon_instagram.svg';
import facebook from 'src/assets/icon_facebook.svg';
import twitter from 'src/assets/icon_twitter.svg';
import youtube from 'src/assets/icon_youtube.svg';

import loginFacebook from 'src/assets/icon_login_facebook.svg';
import loginApple from 'src/assets/icon_login_apple.svg';

import usa from 'src/assets/icon_usa.svg';

import more from 'src/assets/icon_more.svg';

import calendarBatchAvailable from 'src/assets/icon_calendar_batch_available.svg';
import calendarBatchTag from 'src/assets/icon_calendar_batch_tag.svg';

import signature from 'src/assets/icon_signature.svg';

const ICONS = {
  add,
  delete: deleteIcon,

  warning,
  warningBlack,
  warningRed,

  tickGreen,

  arrowLeft,
  arrowRight,

  back: arrowLeft,
  next: arrowRight,

  back2,

  drawerNext,

  parcelNote,
  parcelLabelDownload,

  print,

  starFll,
  starEmpty,

  profile,

  menu,
  menuLands,
  menuBookings,
  menuPackages,
  menuRentals,
  menuPayouts,
  menuCalendar,

  addBusinessDayTag,

  selected: checked,
  unSelect: unCheck,

  bookingsFilter,
  bookingsSettingItem,

  dragTableButton,

  stopBlack,

  feeCollapseShrink,
  feeCollapseGrow,

  reply,
  replyFull,

  instagram,
  facebook,
  twitter,
  youtube,

  loginFacebook,
  loginApple,

  usa,

  more,

  calendarBatchAvailable,
  calendarBatchTag,

  signature
};

export default ICONS;
