import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import styles from './styles.module.scss';

@observer
class NoShippingsLabel extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          {i18n.t('booking_detail_drawer_shipping_tab_no_shippings_label')}
        </div>
      </div>
    );
  }
}

NoShippingsLabel.propTypes = {};

NoShippingsLabel.defaultProps = {};

export default NoShippingsLabel;
