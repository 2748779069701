import { request, getHost } from './utils';

export const getConfig = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/config`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };

  return request(options);
};
