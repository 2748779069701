import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Table } from 'antd';
import withForm from 'src/components/withForm';
import i18n from 'src/i18n';
import CustomLi from 'src/components/CustomLi';
import ResetButton from 'src/components/ResetButton';
import ResetTable from 'src/components/ResetTable';
import ICONS from 'src/constants/icons';
import ActionModal from 'src/components/ActionModal';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetSelect from 'src/components/ResetSelect';
import ResetInputNumber from 'src/components/ResetInputNumber';
import styles from './styles.module.scss';

@observer
class Discounts extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      land: props.land,
      changed: props.changed,
      form: props.form
    });
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.mainLabel}>
            {i18n.t('land_edit_drawer_discounts_page_main_label_description')}
          </div>

          <div className={styles.mainLabel}>
            <div>
              {i18n.t('land_edit_drawer_discounts_page_main_label_price_label')}
            </div>

            <CustomLi>{this.vm.basicFee}</CustomLi>

            <CustomLi>{this.vm.extraFee}</CustomLi>
          </div>

          {this.vm.checks.addButton && (
            <div className={styles.add}>
              <ResetButton
                className={styles.button}
                type="primary"
                onClick={this.vm.onAdd}
              >
                {i18n.t('land_edit_drawer_discounts_page_add_button_label')}
              </ResetButton>
            </div>
          )}

          <div className={styles.table}>
            <ResetTable data={this.vm.mapDiscounts}>
              <Table.Column
                title={i18n.t(
                  'land_edit_drawer_discounts_page_table_discount_label'
                )}
                render={(item) => (
                  <div className={styles.label}>{item.label}</div>
                )}
              />

              <Table.Column
                width={50}
                align="center"
                title={i18n.t(
                  'land_edit_drawer_discounts_page_table_delete_label'
                )}
                render={(item) => (
                  <ResetButton
                    className={styles.deleteButton}
                    type="text"
                    icon={<img src={ICONS.delete} alt="delete" />}
                    onClick={() => this.vm.onDelete(item.id)}
                    disabled={this.vm.disabled.delete}
                  />
                )}
              />
            </ResetTable>
          </div>
        </div>

        <ActionModal
          vm={this.vm.addModalVM}
          width={375}
          onCancel={this.vm.addModalVM.close}
        >
          <div className={styles.modalContainer}>
            <Form
              className={styles.form}
              form={this.vm.form}
              disabled={this.vm.disabled.form}
            >
              <div className={styles.item}>
                <div className={styles.label}>
                  {i18n.t(
                    'land_edit_drawer_discounts_add_modal_discount_label'
                  )}
                </div>

                <ResetFormItem
                  name="name"
                  rules={this.vm.rules.discount}
                  errorMessageAbsolute
                >
                  <ResetSelect
                    options={this.vm.options.discounts}
                    placeholder={i18n.t(
                      'land_edit_drawer_discounts_add_modal_discount_placeholder'
                    )}
                  />
                </ResetFormItem>
              </div>

              <div className={styles.item}>
                <div className={styles.label}>
                  {i18n.t('land_edit_drawer_discounts_add_modal_ratio_label')}
                </div>

                <ResetFormItem
                  name="ratio"
                  rules={this.vm.rules.ratio}
                  errorMessageAbsolute
                >
                  <ResetInputNumber
                    className={styles.input}
                    addonBefore={i18n.t(
                      'land_edit_drawer_discounts_add_modal_ratio_off_input_label'
                    )}
                    step={10}
                    precision={0}
                    max={100}
                    min={0}
                    size="large"
                    stringMode
                    customStepButton
                  />
                </ResetFormItem>
              </div>
            </Form>

            <ActionModalFooter
              cancelProps={{
                onClick: this.vm.addModalVM.close,
                children: i18n.t(
                  'land_edit_drawer_discounts_add_modal_cancel_button_label'
                )
              }}
              confirmProps={{
                onClick: this.vm.onModalConfirm,
                disabled: this.vm.disabled.confirm,
                children: i18n.t(
                  'land_edit_drawer_discounts_add_modal_container_button_label'
                )
              }}
            />
          </div>
        </ActionModal>
      </>
    );
  }
}

Discounts.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired,
  changed: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
};

Discounts.defaultProps = {};

export default withForm(Discounts);
