import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

@observer
class CustomLi extends React.Component {
  render() {
    return (<div className={styles.container}>{this.props.children}</div>);
  }
}

CustomLi.propTypes = {
  children: PropTypes.node
};

CustomLi.defaultProps = {
  children: null
};

export default CustomLi;
