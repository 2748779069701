import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import i18n from 'src/i18n';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';
import AddressViewModel from '../../../CreateLandDrawer/components/Address/viewModel';
import PositionViewModel, {
  POSITION_TYPES
} from '../../../CreateLandDrawer/components/Position/viewModel';
import DescriptionViewModel from '../../../CreateLandDrawer/components/Description/viewModel';

export const PAGES = {
  address: 'address',
  position: 'position',
  description: 'description'
};

class LandMeetDrawerViewModel {
  @observable page = PAGES.address;

  @observable land = null;

  @observable isAwait = false;

  // 決定 position 使用什麼定位
  @observable positionType = POSITION_TYPES.address;

  changed = async () => {};

  drawerVM = new ActionDrawerViewModel();

  addressVM = new AddressViewModel();
  positionVM = new PositionViewModel();
  descriptionVM = new DescriptionViewModel();

  @computed
  get check() {
    return {
      land: !!this.land
    };
  }

  @computed
  get steps() {
    const pages = Object.values(PAGES);

    return {
      steps: pages.length,
      step: pages.findIndex((item) => item === this.page)
    };
  }

  @computed
  get formatLand() {
    if (this.check.land) {
      return {
        id: this.land.id,
        state: this.land.meetState,
        county: this.land.meetCity,
        street: this.land.meetStreet,
        postcode: this.land.meetPostcode,
        position: this.land.meetPosition || null,
        description: this.land.meetDescription
      };
    }

    return {
      id: null,
      state: '',
      county: '',
      street: '',
      postcode: '',
      position: null,
      description: ''
    };
  }

  @computed
  get labels() {
    let title = '';

    switch (this.page) {
      case PAGES.address: {
        title = i18n.t('land_meet_drawer_address_page_title');
        break;
      }

      case PAGES.position: {
        title = i18n.t('land_meet_drawer_position_page_title');
        break;
      }

      case PAGES.description: {
        title = i18n.t('land_meet_drawer_description_page_title');
        break;
      }

      default:
        break;
    }

    return {
      title
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({ land, changed = async () => {} }) => {
    this.page = PAGES.address;

    this.land = land;
    this.changed = changed;

    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  @action
  toAddress = () => {
    this.page = PAGES.address;
  };

  @action
  toPosition = () => {
    this.page = PAGES.position;
  };

  @action
  toDescription = () => {
    this.page = PAGES.description;
  };

  onPrev = () => {
    switch (this.page) {
      case PAGES.position: {
        this.toAddress();
        break;
      }

      case PAGES.description: {
        this.toPosition();
        break;
      }

      default:
        break;
    }
  };

  @action
  onNext = async () => {
    this.isAwait = true;

    try {
      switch (this.page) {
        case PAGES.address: {
          const res = await this.addressVM.validateFields();
          const data = this.formatAddressToMeetAddress(res);

          // 判斷地址有沒有修改, 決定讓 position 使用哪種方式定位
          this.diffMeetAddress(data);

          await this.putUpdateLandInfoAPI(data, this.toPosition);
          break;
        }

        case PAGES.position: {
          // 在 position next 時, 表示更新過定位, 此時重置 positionType
          this.positionType = POSITION_TYPES.position;

          const res = await this.positionVM.validateFields();
          const data = { meetPosition: res.position };

          await this.putUpdateLandInfoAPI(data, this.toDescription);
          break;
        }

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  onDone = async () => {
    this.isAwait = true;

    try {
      const res = await this.descriptionVM.validateFields();
      const data = { meetDescription: res.description };

      await this.putUpdateLandInfoAPI(data, this.close);
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  formatAddressToMeetAddress = ({
    state = '',
    county = '',
    street = '',
    postcode = ''
  }) => {
    return {
      meetState: state,
      meetCity: county,
      meetStreet: street,
      meetPostcode: postcode
    };
  };

  @action
  diffMeetAddress = (data) => {
    // 沒有 meet 的 position 資料, 代表第一次設定
    if (!this.land.meetPosition) {
      this.positionType = POSITION_TYPES.address;
    } else {
      const meetAddress = {
        meetState: this.land.meetState,
        meetCity: this.land.meetCity,
        meetStreet: this.land.meetStreet,
        meetPostcode: this.land.meetPostcode
      };
      // 新舊地址比對
      const diffAddress = !!diff(data, meetAddress);

      this.positionType = diffAddress
        ? POSITION_TYPES.address
        : POSITION_TYPES.position;
    }
  };

  @action
  putUpdateLandInfoAPI = async (data, callBack = () => {}) => {
    try {
      await LandServices.putUpdateLandInfo({
        ...data,
        id: this.land.id
      });

      await this.changed(data);

      runInAction(() => {
        this.land = { ...this.land, ...data };
        callBack();
      });
    } catch (error) {
      const msg = ErrorServices.getLandDetail(error);
      console.log('getLandDetail', msg);
    }
  };
}

export default LandMeetDrawerViewModel;
