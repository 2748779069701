import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { AppStateContext } from 'src/stores';
import withRouter from 'src/components/withRouter';
import ActionModal from 'src/components/ActionModal';
import { PAGES } from './viewModel';
import ModalTitle from './components/ModalTitle';
import Check from './components/Check/index';
import SignUp from './components/SignUp';
import Verify from './components/Verify';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class Authenticate extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ router: props.router });
  }

  componentDidMount() {
    this.vm.didMount(this.context);
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  componentWillUnmount() {
    this.vm.close();
  }

  // 繪製頁面.
  renderModalPage = () => {
    switch (this.vm.page) {
      case PAGES.signUp:
        return (
          <SignUp
            profile={this.props.profile}
            email={this.vm.email}
            hasEmail={this.vm.hasEmail}
            toVerify={this.vm.toVerify}
          />
        );

      case PAGES.verify:
        return (
          <Verify
            profile={this.props.profile}
            toLogin={this.vm.toLogin}
            close={this.vm.close}
            email={this.vm.email}
          />
        );

      case PAGES.login:
        return (
          <Login
            email={this.vm.email}
            close={this.vm.close}
            toResetPassword={this.vm.toResetPassword}
          />
        );

      case PAGES.resetPassword:
        return (
          <ResetPassword
            email={this.vm.email}
            toLogin={this.vm.toLogin}
          />
        );

      case PAGES.check:
      default:
        return (
          <Check
            setEmail={this.vm.setEmail}
            toLogin={this.vm.toLogin}
            toSignUp={this.vm.toSignUp}
          />
        );
    }
  };

  render() {
    return (
      <ActionModal
        vm={this.vm.modalVM}
        wrapClassName="authenticate"
        width={740}
        closeIcon={false}
      >
        <div className={styles.modalContainer}>
          <ModalTitle
            title={this.vm.titleLabel}
            close={this.vm.onLogout}
          />

          <div className={styles.modalContent}>{this.renderModalPage()}</div>
        </div>
      </ActionModal>
    );
  }
}

Authenticate.propTypes = {
  profile: PropTypes.object,
  vm: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

Authenticate.defaultProps = {
  profile: null
};

export default withRouter(Authenticate);
