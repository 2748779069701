import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { pdf } from '@react-pdf/renderer';
import { uuidv4 } from 'src/utils';
import {
  RENTAL_STATUS,
  PARCEL_SHIPPING_STATUS
} from 'src/constants/rental/rental';
import { download } from 'src/constants/methods';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import ErrorServices from 'src/services/ErrorServices';
import RentalServices from 'src/services/RentalServices';
import ParcelItemViewModel from '../ParcelItem/viewModel';
import ParcelNoteModalViewModel from '../ParcelNoteModal/viewModel';
import ParcelPDF from '../ParcelPDF';
import SignatureModalViewModel from '../SignatureModal/viewModel';

class ParcelDetailDrawerViewModel {
  @observable rental = null;
  @observable parcelId = null;
  @observable parcel = null;

  changed = () => {};

  drawerVM = new ActionDrawerViewModel();
  noteModalVM = new ParcelNoteModalViewModel();
  signatureModalVM = new SignatureModalViewModel();

  @computed
  get check() {
    return {
      parcel: !!this.parcel,
      signature: !!this.parcel?.signature
    };
  }

  @computed
  get status() {
    return PARCEL_SHIPPING_STATUS[this.parcel.status] || RENTAL_STATUS.unknown;
  }

  @computed
  get labels() {
    return {
      shipping: this.parcel.label.trackingNumber,
      return: this.parcel.returnLabel.trackingNumber
    };
  }

  @computed
  get items() {
    return this.parcel.items.map((item) => new ParcelItemViewModel(item));
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({ rental, parcelId }) => {
    this.rental = rental;
    this.parcelId = parcelId;

    this.parcel = null;

    this.getRentalParcelDetailAPI();
    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  onSignature = () => {
    this.signatureModalVM.open({ parcel: this.parcel });
  }

  onNode = () => {
    this.noteModalVM.open({ parcel: this.parcel, changed: this.changed });
  };

  onLabelDownload = () => {
    const id = this.parcel.returnLabel.rateId;
    const name = `${id}.pdf`;
    const url = this.parcel.returnLabel.labelUrl;

    download(name, url);
  };

  onPDFDownload = async () => {
    const id = this.parcel.returnLabel.rateId;
    const name = `${id}.pdf`;
    const b = await pdf(
      <ParcelPDF rental={this.rental} parcel={this.parcel} />
    ).toBlob();
    const url = URL.createObjectURL(b);

    download(name, url);
  };

  @action
  changed = async () => {
    this.parcel = null;
    await this.getRentalParcelDetailAPI();
  };

  getRentalParcelDetailAPI = async () => {
    try {
      const res = await RentalServices.getRentalParcelDetail({
        id: this.parcelId
      });

      runInAction(() => {
        this.parcel = res.data;

        console.log('ParcelDetailDrawer', 'parcel', res.data);
      });
    } catch (error) {
      const msg = ErrorServices.apply(error);
      console.log('getRentalParcelDetail', msg);
    }
  };
}

export default ParcelDetailDrawerViewModel;
