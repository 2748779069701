import API from 'src/apis';
import Auth from './auth';

class RatingServices {
  // 更新獵場評分的回答.
  static async putRatingReply({ id, reply }) {
    const res = await API.rating.putRatingReply({
      token: Auth.getToken(),
      id,
      reply
    });

    return {
      data: res.data
    };
  }
}

export default RatingServices;
