export default class ProfileDataModel {
  static fromRes(data) {
    return new ProfileDataModel(data);
  }

  constructor(data) {
    this.id = data.id;

    // 分組.
    this.group = data.group;

    this.source = data.source;

    this.avatar = data.avatar;

    this.firstName = data.firstName;
    this.lastName = data.lastName;

    this.displayName = data.displayName;

    // 信箱.
    this.email = data.email;
    // email 狀態, 是否有驗證.
    this.emailStatus = data.emailStatus;

    this.gender = data.gender;

    this.phone = data.phone;

    this.birthday = data.birthday;

    this.idCard = data.idCard;
    this.idCardStatus = data.idCardStatus;

    this.huntingLicense = data.huntingLicense;
    this.huntingLicenseStatus = data.huntingLicenseStatus;

    // 是否行銷.
    this.shouldMarketing = data.shouldMarketing;

    // 地主自動接受預約.
    this.shouldAutoAcceptReservations = data.shouldAutoAcceptReservations;

    // 有新的預約時, 發送簡訊通知.
    this.shouldSendNewReservationMessage = data.shouldSendNewReservationMessage;

    // 帳號註冊的時間.
    this.createdAt = data.createdAt;
  }
}
