import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import configStore from 'src/global/configStore';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';

class FinalBookingDateViewModel {
 @observable land = null;
  form = null;

  @computed
  get minDate() {
    return dayjs().startOf('date').add(configStore.bookingAfterDays, 'date');
  }

  @computed
  get rules() {
    const required = {
      required: true,
      message: FORM_INPUT_REQUIRED_MESSAGE
    };

    return {
      finalBookingDate: [required]
    };
  }

  init = ({ land, form }) => {
    let finalBookingDate = null;

    if (land.finalBookingDate) {
      finalBookingDate = dayjs(land.finalBookingDate).tz(dayjs.tz.guess());
    } else {
      dayjs();
    }

    this.form = form;
    this.form.setFieldsValue({
      finalBookingDate
    });
  };

  validateFields = async () => {
    const res = await this.form.validateFields();

    return {
      finalBookingDate: res.finalBookingDate?.format('YYYY-MM-DD') || null
    };
  };
}

export default FinalBookingDateViewModel;
