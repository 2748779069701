import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { diff } from 'deep-diff';
import i18n from 'src/i18n';
import { GENDERS, VERIFIES } from 'src/constants/config';
import { ROUTES } from 'src/constants/routes';
import { assemble } from 'src/constants/methods';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import IdCardModalViewModel from './components/IdCardModal/viewModel';
import DisplayNameModalViewModel from './components/DisplayNameModal/viewModel';
import GenderModalViewModel from './components/GenderModal/viewModel';
import PhoneModalViewModel from './components/PhoneModal/viewModel';
import DeactivateAccountModalViewModel from './components/DeactivateAccountModal/viewModel';

const setPlaceHolder = i18n.t(
  'profile_drawer_profile_card_content_set_placeholder_label'
);
const noSetPlaceHolder = i18n.t(
  'profile_drawer_profile_card_content_no_set_placeholder_label'
);

class ProfileDrawerViewModel {
  @observable profile = null;
  @observable isAwait = false;

  router = null;

  actions = null;

  drawerVM = new ActionDrawerViewModel();
  idCardModalVM = new IdCardModalViewModel();
  displayNameModalVM = new DisplayNameModalViewModel();
  genderModalVM = new GenderModalViewModel();
  phoneModalVM = new PhoneModalViewModel();
  deactivateAccountModalVM = new DeactivateAccountModalViewModel()

  @computed
  get check() {
    return {
      profile: !!this.profile
    };
  }

  @computed
  get edit() {
    const checkIdCard = !!this.profile?.idCard;
    const idCardFailed = this.profile?.idCardStatus === VERIFIES.failed.value;

    return {
      gender: this.profile.gender === null,
      // 身分證只有未上傳或審核失敗, 才可以上傳
      idCard: !checkIdCard || idCardFailed
    };
  }

  @computed
  get disabled() {
    return {
      logout: this.isAwait
    };
  }

  @computed
  get displayName() {
    return this.profile.displayName;
  }

  @computed
  get legalName() {
    return assemble.name({
      firstName: this.profile.firstName,
      lastName: this.profile.lastName
    });
  }

  @computed
  get gender() {
    return this.profile.gender
      ? GENDERS[this.profile.gender].label
      : noSetPlaceHolder;
  }

  @computed
  get birthday() {
    return this.profile.birthday
      ? dayjs(this.profile.birthday).format('MMM DD, YYYY')
      : noSetPlaceHolder;
  }

  @computed
  get email() {
    return this.profile.email || noSetPlaceHolder;
  }

  @computed
  get phone() {
    return this.profile.phone || noSetPlaceHolder;
  }

  @computed
  get idCard() {
    switch (this.profile.idCardStatus) {
      case VERIFIES.failed.value:
        return VERIFIES.failed.label;

      case VERIFIES.pending.value:
        return VERIFIES.pending.label;

      case VERIFIES.verified.value:
        return VERIFIES.verified.label;

      default:
        return noSetPlaceHolder;
    }
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ router }) => {
    this.router = router;
  }

  @action
  didMount = (context) => {
    this.profile = context.state.profile;
    this.actions = context.actions;
  };

  @action
  didUpdate = (context) => {
    const diffProfile = diff(this.profile, context.state.profile);

    if (context.state.profile && diffProfile) {
      this.profile = context.state.profile;
    }
  }

  open = () => {
    this.drawerVM.open();
    this.getUserProfileAPI();
  };

  openPhone = () => {
    this.drawerVM.open();
    this.onPhone();
  }

  openIdCard = () => {
    this.drawerVM.open();
    this.onIdCard();
  }

  close = () => {
    this.drawerVM.close();
  };

  @action
  changed = async (profile) => {
    await this.getUserProfileAPI();
  };

  onDisplayName = () => {
    this.displayNameModalVM.open({
      profile: this.profile,
      changed: this.changed
    });
  };

  onGender = () => {
    this.genderModalVM.open({
      profile: this.profile,
      changed: this.changed
    });
  };

  onPhone = () => {
    this.phoneModalVM.open({ profile: this.profile, changed: this.changed });
  };

  onIdCard = () => {
    this.idCardModalVM.open({
      profile: this.profile,
      changed: this.changed
    });
  };

  onLogout = () => {
    this.postUserLogoutAPI();
  };

  onDeactivateAccount = () => {
    this.deactivateAccountModalVM.open({ profile: this.profile });
  }

  @action
  getUserProfileAPI = async () => {
    this.isAwait = true;

    try {
      const res = await UserService.getUserProfile();

      // 更新 profile.
      this.actions.updateProfile(res.data);

      runInAction(() => {
        this.profile = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getUserProfile(error);
      console.log('getUserProfile', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  postUserLogoutAPI = async () => {
    this.isAwait = true;

    try {
      await UserService.postUserLogout();

      this.actions.updateProfile(null);

      this.router.navigate(`/${ROUTES.client.value}`, { replace: true });
    } catch (error) {
      const msg = ErrorServices.postUserLogout(error);
      console.log('postUserLogout', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default ProfileDrawerViewModel;
