import API from 'src/apis';
import ConfigDataModel from 'src/models/response/ConfigDataModel';

class ConfigServices {
  static async getConfig() {
    const res = await API.config.getConfig();

    return {
      data: new ConfigDataModel(res.data)
    };
  }
}

export default ConfigServices;
