import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import { diff } from 'deep-diff';
import i18n from 'src/i18n';
import {
  BUSINESS_DAY_CARD_TAGS_MAX_LENGTH,
  isBookingAfterDays,
  isLessFinalBookingDate
} from 'src/constants/calendar';
import { assemble } from 'src/constants/methods';
import { formatPrice } from 'src/constants/price';

export const STATUS = {
  fee: 'fee',
  unAvailable: 'unAvailable',
  final: 'final',
  booking: 'booking'
};

class BusinessDayCardViewModel {
  @observable businessDay = null;
  @observable land = null;
  @observable tags = [];

  // BusinessDayCard 使用

  @computed
  get check() {
    const isAfter = isBookingAfterDays(this.businessDay.date);
    const isLessFinal = isLessFinalBookingDate({
      date: this.businessDay.date,
      finalBookingDate: this.land.finalBookingDate
    });

    return {
      tagsMore: this.tags.length > BUSINESS_DAY_CARD_TAGS_MAX_LENGTH,
      // 安全日期
      isAfter,
      isLessFinal,
      background: !isAfter || !isLessFinal
    };
  }

  @computed
  get labels() {
    const basicFee = new BigNumber(this.land.basicFee).multipliedBy(
      this.businessDay.ratio
    );

    let booking = '';

    if (this.businessDay.booking) {
      const bookingId = this.businessDay.booking.id;
      const consumer = this.businessDay.booking.consumer;
      const name = assemble.name({
        firstName: consumer.firstName,
        lastName: consumer.lastName
      });

      booking = `${bookingId} - ${name}`;
    }

    const tagsMore = this.tags.length - BUSINESS_DAY_CARD_TAGS_MAX_LENGTH;
    const tagsMoreLabel = i18n.t(
      'calendar_page_business_day_card_tags_more_label'
    );

    return {
      date: dayjs(this.businessDay.date).format('DD'),
      basicFee: `$${formatPrice(basicFee)}`,
      booking,
      tags: this.tags.slice(0, 2),
      tagsMore: `+${tagsMore} ${tagsMoreLabel}`
    };
  }

  @computed
  get status() {
    if (this.businessDay.booking) {
      return STATUS.booking;
    }

    if (!this.check.isLessFinal) {
      return STATUS.final;
    }

    /**
     * 需求: 不合法的日期如果是打開的需要顯示 fee
     * 不合法的日期: 不可以手動開啟或關閉的日期
     *
     * 值代表的意思:
     * isAvailable: 後端給的真實日期開關, 並且不合法的日期必定是 false
     * isManualOpened: 紀錄地主手動開關的結果, 初始值是 null
     *
     * 判斷條件:
     * 1. 合法的日期, 使用真實的開關 isAvailable
     * 2. 不合法的日期, 使用紀錄地主手動的值
     * isManualOpened = null or false or true
     * null and true = STATUS.fee 因為在預設中, 獵場每天都是打開的
     */
    const isManualOpened
      = this.businessDay.isManualOpened === null
        ? true
        : this.businessDay.isManualOpened;
    const flag = this.check.isAfter
      ? this.businessDay.isAvailable
      : isManualOpened;

    return flag ? STATUS.fee : STATUS.unAvailable;
  }

  @computed
  get dayIns() {
    return dayjs(this.businessDay.date).startOf('date');
  }

  // BusinessDayDrawer 使用

  @computed
  get date() {
    return this.businessDay.date;
  }

  @computed
  get isAvailable() {
    return this.businessDay.isAvailable;
  }

  @computed
  get booking() {
    return this.businessDay.booking;
  }

  constructor(props) {
    this.businessDay = props;
    makeObservable(this);
  }

  @action
  init = ({ land, tags }) => {
    this.land = land;
    this.tags = tags;
  };

  @action
  didUpdate = (props, preProps) => {
    const diffTags = diff(props.tags, preProps.tags);

    if (diffTags) {
      this.tags = props.tags;
    }
  };

  @action
  update = (data) => {
    this.businessDay = { ...this.businessDay, ...data };
  };
}

export default BusinessDayCardViewModel;
