import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ICONS from 'src/constants/icons';
import ActionDrawer from 'src/components/ActionDrawer';
import ActionDrawerItem from 'src/components/ActionDrawer/components/ActionDrawerItem';
import ResetButton from 'src/components/ResetButton';
import ParcelItem from '../ParcelItem';
import ParcelNoteModal from '../ParcelNoteModal';
import SignatureModal from '../SignatureModal';
import styles from './styles.module.scss';

@observer
class ParcelDetailDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <>
        <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
          {this.vm.check.parcel ? (
            <>
              <div className={styles.header}>
                {this.vm.check.signature && (
                  <ResetButton className={styles.button} type="text" onClick={this.vm.onSignature}>
                    <img src={ICONS.signature} alt="signature" />
                  </ResetButton>
                )}

                <ResetButton
                  className={styles.button}
                  type="text"
                  onClick={this.vm.onNode}
                >
                  <img src={ICONS.parcelNote} alt="note" />
                </ResetButton>

                <ResetButton
                  className={styles.button}
                  type="text"
                  onClick={this.vm.onLabelDownload}
                >
                  <img src={ICONS.parcelLabelDownload} alt="pdf" />
                </ResetButton>

                <ResetButton
                  className={styles.button}
                  type="text"
                  onClick={this.vm.onPDFDownload}
                >
                  <img src={ICONS.print} alt="print" />
                </ResetButton>
              </div>

              <div className={styles.container}>
                <ActionDrawerItem
                  label={i18n.t('parcel_detail_drawer_parcel_status_label')}
                  divider
                >
                  {this.vm.status.label}
                </ActionDrawerItem>

                <ActionDrawerItem
                  label={i18n.t('parcel_detail_drawer_parcel_shipping_label')}
                  divider
                >
                  {this.vm.labels.shipping}
                </ActionDrawerItem>

                <ActionDrawerItem
                  label={i18n.t('parcel_detail_drawer_parcel_return_label')}
                  divider
                >
                  {this.vm.labels.return}
                </ActionDrawerItem>

                <ActionDrawerItem
                  label={i18n.t('parcel_detail_drawer_parcel_items_label')}
                >
                  <div className={styles.items}>
                    {this.vm.items.map((vm) => (
                      <ParcelItem
                        vm={vm}
                        parcel={this.vm.parcel}
                        changed={this.vm.changed}
                        key={vm.key}
                      />
                    ))}
                  </div>
                </ActionDrawerItem>
              </div>
            </>
          ) : null}
        </ActionDrawer>

        <ParcelNoteModal vm={this.vm.noteModalVM} />

        <SignatureModal vm={this.vm.signatureModalVM} />
      </>
    );
  }
}

ParcelDetailDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

ParcelDetailDrawer.defaultProps = {};

export default ParcelDetailDrawer;
