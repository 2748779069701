import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ActionDrawer from 'src/components/ActionDrawer';
import ActionDrawerHeader from 'src/components/ActionDrawer/components/ActionDrawerHeader';
import FeeCollapse from 'src/components/FeeCollapse';
import FeeCollapseHeader from 'src/components/FeeCollapse/components/FeeCollapseHeader';
import FeeCollapseFooter from 'src/components/FeeCollapse/components/FeeCollapseFooter';
import styles from './styles.module.scss';

@observer
class BookingPriceDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
        {this.vm.booking ? (
          <div className={styles.container}>
            <ActionDrawerHeader
              paddingBottom
              content={i18n.t('booking_price_drawer_header')}
            />

            <div className={styles.table}>
              <div className={styles.desc}>
                {i18n.t('booking_price_drawer_desc')}
              </div>

              <FeeCollapseHeader
                item1={i18n.t('booking_price_drawer_item_label')}
                item2={i18n.t('booking_price_drawer_count_label')}
              />

              <FeeCollapse data={this.vm.basicFee} />

              {this.vm.check.extraHunters && (
                <FeeCollapse data={this.vm.extraFee} />
              )}

              {this.vm.check.discount && (
                <FeeCollapse data={this.vm.discount} />
              )}

              {this.vm.check.packages && (
                <FeeCollapse data={this.vm.packages} />
              )}

              {this.vm.check.refund && <FeeCollapse data={this.vm.refund} />}

              <FeeCollapseFooter
                item1={this.vm.total.label}
                item2={this.vm.total.value}
              />
            </div>
          </div>
        ) : null}
      </ActionDrawer>
    );
  }
}

BookingPriceDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

BookingPriceDrawer.defaultProps = {};

export default BookingPriceDrawer;
