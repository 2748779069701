import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Divider } from 'antd';
import clsx from 'clsx';
import i18n from 'src/i18n';
import ResetButton from 'src/components/ResetButton';
import BookingDetailDrawer from 'src/components/BookingDetailDrawer';
import BasicCard from 'src/components/BasicCard';
import styles from './styles.module.scss';

@observer
class RentalCard extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <>
        <BasicCard className={styles.container}>
          <div className={clsx([styles.status, this.vm.statusStyle])}>
            {this.vm.status.label}
          </div>

          <Divider className={styles.divider} dashed />

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('rental_card_start_at_label')}
            </div>

            <div className={styles.content}>{this.vm.startAt}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('rental_card_end_at_label')}
            </div>

            <div className={styles.content}>{this.vm.endAt}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('rental_card_consumer_label')}
            </div>

            <div className={styles.content}>{this.vm.consumer}</div>
          </div>

          <div className={styles.footer}>
            {this.vm.check.shipping && (
              <ResetButton
                className={styles.button}
                onClick={this.vm.onShipping}
              >
                {i18n.t('rental_card_shopping_button_label')}
              </ResetButton>
            )}

            <ResetButton className={styles.button} onClick={this.vm.onDetail}>
              {i18n.t('rental_card_detail_button_label')}
            </ResetButton>
          </div>
        </BasicCard>

        <BookingDetailDrawer vm={this.vm.detailDrawerVM} />
      </>
    );
  }
}

RentalCard.propTypes = {
  vm: PropTypes.object.isRequired
};

RentalCard.defaultProps = {};

export default RentalCard;
