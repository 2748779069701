import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import i18n from 'src/i18n';
import { PARAMS, ROUTES } from 'src/constants/routes';
import { routerMethods } from 'src/constants/methods';
import ICONS from 'src/constants/icons';
import {
  BOOKING_FILTER_DATES,
  bookingFilterStatusAndStatisticsToArray,
  getBookingsFilter
} from 'src/constants/booking/booking';
import LandServices from 'src/services/LandServices';
import BookingServices from 'src/services/BookingServices';
import ErrorServices from 'src/services/ErrorServices';
import BookingCardViewModel from 'src/components/BookingCard/viewModel';
import BookingSettingDrawerViewModel from './components/BookingSettingDrawer/viewModel';
import BookingsFilterDrawerViewModel from './components/BookingsFilterDrawer/viewModel';

class BookingsPageViewModel {
  @observable lands = [];
  @observable bookings = [];

  @observable filter = {
    landId: null,
    status: null,
    dateType: null,
    startAt: null,
    endAt: null,
    keyword: ''
  };

  @observable filterStatus = null;

  @observable statistics = {
    arrivingSoon: 0,
    current: 0,
    next14days: 0,
    ready2finish: 0,
    requests: 0,
    total: 0
  };

  @observable inited = false;
  @observable isAwait = false;

  anchor = undefined;

  bookingSettingDrawerVM = new BookingSettingDrawerViewModel();
  filterDrawerVM = new BookingsFilterDrawerViewModel();

  router = null;

  @computed
  get check() {
    return {
      lands: !!this.lands.length
    };
  }

  @computed
  get disabled() {
    return {
      filter: this.isAwait
    };
  }

  @computed
  get options() {
    return {
      lands: this.lands.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      }),
      filterStatus: bookingFilterStatusAndStatisticsToArray(this.statistics)
    };
  }

  get popoverItems() {
    return [
      {
        icon: ICONS.bookingsSettingItem,
        label: i18n.t('bookings_page_more_button_booking_setting_label'),
        onClick: this.bookingSettingDrawerVM.open
      },
      {
        icon: ICONS.bookingsSettingItem,
        label: i18n.t('bookings_page_more_button_booking_requests_label'),
        onClick: this.onToBatchBookings
      }
    ];
  }

  @computed
  get labels() {
    const allBookingsLabel = i18n.t('booking_filters_all_booking');
    const allBookingTotal = this.statistics?.total || 0;

    return {
      allBookings: `${allBookingsLabel} (${allBookingTotal})`
    };
  }

  constructor(props) {
    this.router = props.router;
    makeObservable(this);
  }

  @action
  didMount = async () => {
    const landId = routerMethods.getSearch(this.router, PARAMS.landId);

    if (landId) {
      this.filter.landId = Number(landId);
    }

    await Promise.all([
      this.getLandListAPI(),
      this.postBookingListAPI(),
      this.getBookingStatisticsAPI()
    ]);

    runInAction(() => {
      this.inited = true;
    });
  };

  onToBatchBookings = () => {
    this.router.navigate(`/${ROUTES.batchBookings.value}`);
  }

  onWaypointEnter = () => {
    if (!this.isAwait && this.anchor !== null) {
      this.postBookingListAPI();
    }
  };

  @action
  onLandIdChange = (val) => {
    this.filter.landId = val;
    this.reset();
  };

  onFilterDrawer = () => {
    this.filterDrawerVM.open({
      startAt: this.filter.startAt,
      endAt: this.filter.endAt,
      confirm: this.onFilterDrawerConfirm
    });
  };

  @action
  onFilterDrawerConfirm = async (data) => {
    this.filter.dateType = BOOKING_FILTER_DATES.overlap;
    this.filter.startAt = data.startAt;
    this.filter.endAt = data.endAt;
    this.filter.keyword = data.keyword;

    this.reset();
  };

  @action
  onFilterStatusChange = (val) => {
    const filter = getBookingsFilter(val);

    this.filter = { ...this.filter, ...filter };
    this.filterStatus = val;

    this.reset();
  };

  getFilters = async () => {
    const landId = this.filter.landId || undefined;
    const status = this.filter.status || undefined;

    const dateType = this.filter.dateType || undefined;

    const startAt = this.filter.startAt || undefined;
    const endAt = this.filter.endAt || undefined;

    const keyword = this.filter.keyword || undefined;

    return {
      landId,
      status,
      dateType,
      startAt,
      endAt,
      keyword
    };
  };

  @action
  reset = () => {
    this.bookings = [];
    this.anchor = undefined;
    this.postBookingListAPI();
    this.getBookingStatisticsAPI();
  };

  @action
  getLandListAPI = async () => {
    this.isAwait = true;

    try {
      const res = await LandServices.getLandList();

      runInAction(() => {
        this.lands = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getLandList(error);
      console.log('getLandList', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  postBookingListAPI = async () => {
    this.isAwait = true;

    try {
      const { landId, status, dateType, startAt, endAt, keyword }
        = await this.getFilters();
      const res = await BookingServices.postBookingList({
        anchor: this.anchor,
        landId,
        status,
        dateType,
        startAt,
        endAt,
        keyword
      });

      runInAction(() => {
        const bookings = res.data.map((item) => new BookingCardViewModel(item));

        this.bookings = this.bookings.concat(bookings);
        this.anchor = res.anchor;

        console.log('bookings', res.data);
      });
    } catch (error) {
      const msg = ErrorServices.postBookingList(error);

      console.log('postBookingList', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  getBookingStatisticsAPI = async () => {
    this.isAwait = true;

    try {
      const landId = this.filter.landId || undefined;
      const res = await BookingServices.getBookingStatistics({
        landId
      });

      runInAction(() => {
        this.statistics = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getBookingStatistics(error);

      console.log('getBookingStatistics', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default BookingsPageViewModel;
