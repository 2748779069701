import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';

class SignatureModalViewModel {
  @observable parcel = null;

  modalVM = new ActionModalViewModel();

  @computed
  get check() {
    return {
      parcel: !!this.parcel
    };
  }

  @computed
  get signature() {
    return this.parcel.signature || '';
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({ parcel }) => {
    this.parcel = parcel;
    this.modalVM.open();
  }

  @action
  close = () => {
    this.modalVM.close();
  }
}

export default SignatureModalViewModel;
