import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class ResetFormItem extends React.Component {
  render() {
    const { className, children, errorMessageAbsolute, ...ps } = this.props;

    return (
      <Form.Item
        className={clsx([
          errorMessageAbsolute && 'errorMessageAbsolute',
          styles.item,
          className
        ])}
        {...ps}
      >
        {children}
      </Form.Item>
    );
  }
}

ResetFormItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  errorMessageAbsolute: PropTypes.bool
};

ResetFormItem.defaultProps = {
  className: '',
  children: null,
  errorMessageAbsolute: false
};

export default ResetFormItem;
