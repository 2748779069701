import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { ROUTES } from 'src/constants/routes';
import AOS from 'aos';
import { isMobile } from 'react-device-detect';
import { AppStateContext } from 'src/stores';

import optionStore from 'src/global/optionStore';
import configStore from 'src/global/configStore';

import OptionServices from 'src/services/OptionServices';
import ConfigServices from 'src/services/ConfigServices';
import ErrorServices from 'src/services/ErrorServices';

import MainLayout from 'src/components/MainLayout';
import FindHuntPage from './FindHuntPage';
import LandsPage from './LandsPage';
import BookingsPage from './BookingsPage';
import BatchBookingsPage from './BatchBookingsPage';
import CheckBookingPage from './CheckBookingPage';
import PackagesPage from './PackagesPage';
import RentalsPage from './RentalsPage';
import PayoutsPage from './PayoutsPage';
import PayoutDetailsPage from './PayoutDetailsPage';
import CalendarPage from './CalendarPage';
import NotFoundPage from './NotFoundPage';

// > 20240620 added for deeplink
import DeepLinkRedirectPage from './DeepLinkRedirectPage';

const basename = `/${ROUTES.backstage.value}`;

@observer
class PagesRoot extends React.Component {
  static contextType = AppStateContext;

  @observable checkStates = false;
  @observable checkInfos = false;
  @observable checkConfig = false;

  @computed
  get show() {
    return this.checkStates && this.checkInfos && this.checkConfig;
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    Promise.all([
      this.getOptionStateAPI(),
      this.getOptionInfosAPI(),
      this.getConfigAPI()
    ]);

    setTimeout(() => {
      AOS.init({
        offset: isMobile ? 10 : 100
      });
      AOS.refresh();
    }, 1500);
  }

  // 讀取地址選項.
  getOptionStateAPI = async () => {
    try {
      const res = await OptionServices.getOptionState();

      optionStore.update(optionStore.statesKey, res.data);

      runInAction(() => {
        this.checkStates = true;
      });
    } catch (error) {
      const msg = ErrorServices.getOptionState(error);

      console.log('getOptionState', msg);
    }
  };

  // 讀取獵物選項.
  getOptionInfosAPI = async () => {
    try {
      const res = await OptionServices.getOptionInfo();

      optionStore.update(optionStore.infosKey, res.data);

      runInAction(() => {
        this.checkInfos = true;
      });
    } catch (error) {
      const msg = ErrorServices.getOptionInfo(error);

      console.log('getOptionInfo', msg);
    }
  };

  getConfigAPI = async () => {
    try {
      const res = await ConfigServices.getConfig();

      configStore.update(res.data);

      runInAction(() => {
        this.checkConfig = true;
      });
    } catch (error) {
      const msg = ErrorServices.getConfig(error);

      console.log('getConfig', msg);
    }
  };

  render() {
    return this.show ? (
      <BrowserRouter basename={basename}>
        <Routes>
          <Route
            path={ROUTES.client.value}
            element={<FindHuntPage context={this.context} />}
          />

          <Route
            path={`${ROUTES.post.value}/:id`}
            element={<DeepLinkRedirectPage />}
          />

          <Route element={<MainLayout context={this.context} />}>
            <Route
              path={ROUTES.lands.value}
              element={<LandsPage context={this.context} />}
            />

            <Route
              path={ROUTES.bookings.value}
              element={<BookingsPage context={this.context} />}
            />

            <Route
              path={ROUTES.batchBookings.value}
              element={<BatchBookingsPage context={this.context} />}
            />

            <Route
              path={ROUTES.packages.value}
              element={<PackagesPage context={this.context} />}
            />

            <Route
              path={ROUTES.rentals.value}
              element={<RentalsPage context={this.context} />}
            />

            <Route
              path={ROUTES.payouts.value}
              element={<PayoutsPage context={this.context} />}
            />

            <Route
              path={ROUTES.payoutDetails.value}
              element={<PayoutDetailsPage context={this.context} />}
            />

            <Route
              path={ROUTES.calendar.value}
              element={<CalendarPage context={this.context} />}
            />
          </Route>

          <Route
            path={ROUTES.checkBooking.value}
            element={<CheckBookingPage context={this.context} />}
          />

          {/* <Route element={<MainLayout context={this.context} />}>
            <Route
              path={ROUTES.reservations.value}
              element={<ReservationPage context={this.context} />}
            />

            <Route
              path={ROUTES.calendar.value}
              element={<CalendarPage context={this.context} />}
            />

            <Route
              path={ROUTES.properties.value}
              element={<PropertyPage context={this.context} />}
            />

            <Route
              path={ROUTES.packages.value}
              element={<PackagePage context={this.context} />}
            />

            <Route
              path={ROUTES.transactions.value}
              element={<TransactionPage context={this.context} />}
            />

            <Route
              path={ROUTES.account.value}
              element={<AccountPage context={this.context} />}
            />

            <Route
              path={ROUTES.rentals.value}
              element={<RentalsPage context={this.context} />}
            />

            <Route
              path={ROUTES.rentalPayouts.value}
              element={<RentalPayoutPage context={this.context} />}
            />
          </Route> */}

          {/* <Route
            path={`${ROUTES.booking.value}/:id`}
            element={<CheckBookingPage context={this.context} />}
          /> */}

          <Route path="/" element={<FindHuntPage context={this.context} />} />

          <Route path="*" element={<NotFoundPage context={this.context} />} />
        </Routes>
      </BrowserRouter>
    ) : null;
  }
}

export default PagesRoot;
