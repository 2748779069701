import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ResetButton from '../ResetButton';

// 倒數計時按鈕.
@observer
class ResendButton extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    const { vm, disabled, label, unit, ...ps } = this.props;

    return (
      <ResetButton
        {...ps}
        disabled={this.vm.disabled || disabled}
      >
        {this.vm.check.second ? label : `${label} (${this.vm.second}${unit})`}
      </ResetButton>
    );
  }
}

ResendButton.propTypes = {
  vm: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  unit: PropTypes.string
};

ResendButton.defaultProps = {
  disabled: false,
  label: '',
  unit: ''
};

export default ResendButton;
