import { makeObservable, observable, computed, action } from 'mobx';
import { diff } from 'deep-diff';
import { SOURCE_TYPES, EMAIL_STATUS } from 'src/constants/config';
import { PARAMS } from 'src/constants/routes';
import { routerMethods } from 'src/constants/methods';
import Auth from 'src/services/auth';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';
import i18n from 'src/i18n';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';

export const PAGES = {
  check: 'check',
  signUp: 'signUp',
  verify: 'verify',
  login: 'login',
  resetPassword: 'resetPassword'
};

class AuthenticateViewModel {
  @observable email = '';
  @observable page = PAGES.check;
  @observable profile = null;
  @observable source = null;

  modalVM = new ActionModalViewModel();

  // 有使用 email 登入.
  @computed
  get hasEmail() {
    return !!this.email;
  }

  // 有 profile.
  @computed
  get hasProfile() {
    return !!this.profile;
  }

  @computed
  get isOutsideLogin() {
    switch (this.source) {
      case SOURCE_TYPES.facebook:
      case SOURCE_TYPES.apple:
        return true;

      default:
        return false;
    }
  }

  @computed
  get titleLabel() {
    switch (this.page) {
      case PAGES.signUp:
        return i18n.t('find_hunt_page_sign_up_title');

      case PAGES.verify:
        return i18n.t('find_hunt_page_verify_title');

      case PAGES.login:
        return i18n.t('find_hunt_page_login_title');

      case PAGES.resetPassword:
        return i18n.t('find_hunt_page_reset_password_title');

      default:
        return i18n.t('find_hunt_page_check_title');
    }
  }

  constructor(props) {
    makeObservable(this);
  }

  init = (props) => {
    this.router = props.router;

    const login = routerMethods.getSearch(props.router, PARAMS.login);

    if (login) {
      this.open();
    }

    console.log('router', props.router, login);
  }

  @action
  didMount = (context) => {
    // 保存 profile.
    this.profile = context.state.profile;

    // context actions.
    this.contextActions = context.actions;

    // 檢查 profile.
    this.readProfile(context.state.profile);
  };

  @action
  didUpdate = (props, preProps) => {
    const checkProfile = diff(props.profile, preProps.profile);

    // profile 存在, 並且 profile 發生改變.
    if (props.profile && checkProfile) {
      // 保存 profile.
      this.profile = props.profile;

      // 檢查 profile.
      this.readProfile(props.profile);
    }
  };

  // 檢查 profile.
  // 1. withProfile didMount 時會更新 profile.
  // 2. signUp 時會更新 profile.
  // 3. login 時會 set token.
  readProfile = (profile) => {
    // profile 沒有輸入 email.
    if (profile && !profile?.email) {
      // 前往填資料.
      this.toSignUp();

      // 開啟彈窗.
      this.open();
    } else if (profile && profile.emailStatus === EMAIL_STATUS.pending) {
      // profile email 未驗證.
      this.toVerifyProfile(profile);
    } else if (profile && Auth.getToken()) {
      // 你的 profile 是完整的, 並且你有 token.
      // requestAnimationFrame(() => {
      //   this.router.navigate(`/${ROUTES.lands.value}`);
      // });
    }
  };

  @action
  open = () => {
    this.modalVM.open();
  };

  // 關閉彈窗.
  @action
  close = () => {
    this.modalVM.close();

    // 重置狀態.
    this.email = '';
    this.profile = null;
    this.page = PAGES.check;
  };

  @action
  setEmail = (email) => {
    this.email = email;
  };

  // 前往檢查 email.
  @action
  toCheck = () => {
    this.page = PAGES.check;
  };

  // 前往註冊.
  @action
  toSignUp = () => {
    this.page = PAGES.signUp;
  };

  // 前往驗證信箱.
  @action
  toVerify = () => {
    this.page = PAGES.verify;
  };

  // 前往驗證信箱, 檢查 email 驗證.
  // 1. signUp 時更新 profile.
  // 2. 應對重新整理.
  @action
  toVerifyProfile = (profile) => {
    this.email = profile.email;
    this.page = PAGES.verify;

    this.modalVM.open();
  };

  // 前往登入.
  @action
  toLogin = () => {
    this.page = PAGES.login;
  };

  // 前往重置密碼.
  @action
  toResetPassword = () => {
    this.page = PAGES.resetPassword;
  };

  // 登出.
  onLogout = () => {
    this.postUserLogoutAPI();
  };

  // 登出.
  postUserLogoutAPI = async () => {
    try {
      const res = await UserService.postUserLogout();
    } catch (error) {
      const msg = ErrorServices.postUserLogout(error);

      console.log('postUserLogout', msg);
    } finally {
      // 清除 context profile.
      this.contextActions.updateProfile(null);

      // 關閉彈窗.
      this.close();
    }
  };
}

export default AuthenticateViewModel;
