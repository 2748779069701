import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ResetButton from 'src/components/ResetButton';
import ActionModal from 'src/components/ActionModal';
import ActionModalWarning from 'src/components/ActionModal/components/ActionModalWarning';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ActionModalAlertContainer from 'src/components/ActionModal/components/ActionModalAlertContainer';
import BatchButtonViewModel, { BATCH_BUTTON_TYPE } from './viewModel';
import styles from './styles.module.scss';

@observer
class BatchButton extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new BatchButtonViewModel({
      bookings: props.bookings,
      type: props.type,
      changed: props.changed
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    const { disabled, ...ps } = this.props;

    return (
      <>
        <ResetButton
          className={styles.mainButton}
          type={this.vm.labels.type}
          onClick={this.vm.modalVM.open}
          disabled={disabled || this.vm.disabled.button}
        >
          {this.vm.labels.button}
        </ResetButton>

        <ActionModal
          vm={this.vm.modalVM}
          width={330}
          onCancel={this.vm.modalVM.close}
        >
          <ActionModalAlertContainer>
            <ActionModalWarning content={this.vm.labels.modalContent} />

            <ActionModalFooter
              cancelProps={{
                onClick: this.vm.modalVM.close,
                children: i18n.t(
                  'batch_bookings_page_batch_button_modal_cancel_button_label'
                )
              }}
              confirmProps={{
                danger: this.vm.labels.danger,
                onClick: this.vm.onConfirm,
                disabled: this.vm.disabled.modal,
                children: this.vm.labels.modalConfirm
              }}
            />
          </ActionModalAlertContainer>
        </ActionModal>
      </>
    );
  }
}

BatchButton.propTypes = {
  bookings: PropTypes.array,
  type: PropTypes.string,
  changed: PropTypes.func,
  disabled: PropTypes.bool
};

BatchButton.defaultProps = {
  bookings: [],
  type: BATCH_BUTTON_TYPE.accept,
  changed: () => {},
  disabled: false
};

export default BatchButton;
