import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import {
  RENTAL_STATUS,
  PARCEL_SHIPPING_STATUS
} from 'src/constants/rental/rental';
import ParcelDetailDrawerViewModel from '../ParcelDetailDrawer/viewModel';

class ParcelViewModel {
  @observable rental = null;
  @observable parcel = null;

  detailDrawerVM = new ParcelDetailDrawerViewModel();

  @computed
  get id() {
    return this.parcel.id;
  }

  @computed
  get name() {
    return this.parcel.name;
  }

  @computed
  get status() {
    return PARCEL_SHIPPING_STATUS[this.parcel.status] || RENTAL_STATUS.unknown;
  }

  constructor(props) {
    this.parcel = props;
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.rental = props.rental;
  };

  @action
  didUpdate = (props, preProps) => {
    const diffRental = diff(props.rental, preProps.rental);

    if (props.rental && diffRental) {
      this.rental = props.rental;
    }
  };

  onDetail = () => {
    this.detailDrawerVM.open({
      rental: this.rental,
      parcelId: this.parcel.id
    });
  };
}

export default ParcelViewModel;
