import { request, getHost } from './utils';

// 讀取帳號所有的行程.
export const getPackageList = ({ token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/package/list`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 新增帳號的行程.
export const postPackageCreate = ({
  token,
  name,
  type,
  price,
  description
}) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/backstage/package/create`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      name,
      type,
      price,
      description
    }
  };

  return request(options);
};

// 更新帳號的行程.
export const putPackage = ({
  token,
  id,
  name,
  type,
  price,
  description
}) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/backstage/package/${id}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      name,
      type,
      price,
      description
    }
  };

  return request(options);
};

// 刪除帳號的行程.
export const deletePackage = ({
  token,
  id
}) => {
  const options = {
    method: 'delete',

    url: `${getHost()}/api/v1/backstage/package/${id}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};
