import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import { LAND_MESSAGE_TYPES } from 'src/constants/land';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import QAServices from 'src/services/QAServices';
import RatingServices from 'src/services/RatingServices';
import ErrorServices from 'src/services/ErrorServices';
import dayjs from 'dayjs';

class ReplyModalViewModel {
  @observable type = null;
  @observable message = null;
  @observable isAwait = false;

  modalVM = new ActionModalViewModel();

  form = null;

  changed = () => {};

  @computed
  get check() {
    return {
      qa: this.type === LAND_MESSAGE_TYPES.qas,
      rating: this.type === LAND_MESSAGE_TYPES.ratings
    };
  }

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      footer: this.isAwait
    };
  }

  get rules() {
    return {
      reply: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.type = props.type;
    this.form = props.form;
  };

  @action
  open = ({ message, changed }) => {
    this.message = message;
    this.changed = changed;

    this.form.setFieldsValue({ reply: message.reply || '' });

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  onConfirm = () => {
    this.replyAPI();
  };

  @action
  replyAPI = async () => {
    this.isAwait = true;

    const api = this.check.qa
      ? QAServices.putQAReply
      : RatingServices.putRatingReply;
    const service = this.check.qa
      ? ErrorServices.putQAReply
      : ErrorServices.putRatingReply;

    try {
      const data = await this.form.validateFields();

      await api({ id: this.message.id, reply: data.reply });

      await this.changed({
        ...this.data,
        reply: data.reply,
        updatedAt: dayjs().valueOf()
      });

      this.modalVM.close();
    } catch (error) {
      const msg = service(error);
      console.log('replyAPI', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default ReplyModalViewModel;
