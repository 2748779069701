import { request, getHost } from './utils';

export const getUserProfile = (token) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/me/profile`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };
  return request(options);
};

// 檢查 email.
export const postUserExists = ({ email }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/exists`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data: {
      email
    }
  };
  return request(options);
};

// 註冊.
export const postUserSignUp = (data) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/signup`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data
  };
  return request(options);
};

// 註冊時, 重發驗證信.
export const postUserResendAuthEmail = ({ email }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/resend-auth-email`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data: {
      email
    }
  };
  return request(options);
};

// 驗證 email
export const postUserVerify = ({ token, code }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/auth-email`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      code
    }
  };
  return request(options);
};

// 登出.
export const postUserLogout = ({ token }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/logout`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };
  return request(options);
};

// 登入.
export const postUserLogin = ({ email, password }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/backstage/user/login`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data: {
      email,
      password
    }
  };
  return request(options);
};

// 更新會員資料.
export const putUserProfile = ({ token, data }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/user/profile`,

    headers: {
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data
  };
  return request(options);
};

// 驗證電話.
export const postUserResendAuthPhone = ({ token, phone }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/resend-auth-phone`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      phone
    }
  };
  return request(options);
};

// 更新電話.
export const putUserAuthPhone = ({ token, code, phone }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/user/auth-phone`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      code,
      phone
    }
  };
  return request(options);
};

// 讀取 stripe 資料.
export const getStripeInfo = ({ token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/stripe/info`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };
  return request(options);
};

// 前往註冊 stripe 帳號.
export const postStripeLink = ({ token }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/stripe/link`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 刪除帳號景求.
export const postDeleteAccountRequest = ({
  token,
  deleteReside,
  deleteReason
}) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/delete`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      deleteReside,
      deleteReason
    }
  };

  return request(options);
};

// 發送重置密碼驗證信.
export const postUserResendAuthResetPassword = ({
  email
}) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/resend-auth-reset-password`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data: {
      email
    }
  };

  return request(options);
};

// 重置密碼.
export const putUserAuthResetPassword = ({
  email, code, password
}) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/user/auth-reset-password`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data: {
      email, code, password
    }
  };

  return request(options);
};
