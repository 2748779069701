class OptionInfoDataModel {
  constructor(data) {
    this.accessory = data.accessory;

    // 狩獵經驗.
    this.huntingExperience = data.huntingExperience;

    // 狩獵月刊.
    this.huntingMonthly = data.huntingMonthly;

    // 狩獵類型.
    this.huntingType = data.huntingType;

    // 獵物, 物種分類.
    this.category = data.category;

    // 獵物, 不分類.
    this.species = data.species;

    // 待補充.
    this.tripsPerYear = data.tripsPerYear;

    // 武器種類.
    this.weapon = data.weapon;
  }
}

export default OptionInfoDataModel;
