import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetSelect from 'src/components/ResetSelect';
import ResetFormItem from 'src/components/ResetFormItem';
import styles from './styles.module.scss';

@observer
class BookingCancelModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ form: props.form });
  }

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        <div className={styles.container}>

          <div className={styles.description}>
            {i18n.t('booking_cancel_modal_description')}
          </div>

          <Form
            className={styles.form}
            form={this.vm.form}
            disabled={this.vm.disabled.form}
          >
            <ResetFormItem
              name="reason"
              rules={this.vm.rules.reason}
              errorMessageAbsolute
            >
              <ResetSelect
                options={this.vm.options.reasons}
                placeholder={i18n.t('booking_cancel_modal_reason_placeholder')}
              />
            </ResetFormItem>
          </Form>

          <ActionModalFooter
            className={styles.footer}
            cancelProps={{
              onClick: this.vm.close,
              children: i18n.t('booking_cancel_modal_cancel_button_label')
            }}
            confirmProps={{
              danger: true,
              onClick: this.vm.onConfirm,
              disabled: this.vm.disabled.footer,
              children: i18n.t('booking_cancel_modal_confirm_button_label')
            }}
          />
        </div>
      </ActionModal>
    );
  }
}

BookingCancelModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

BookingCancelModal.defaultProps = {};

export default withForm(BookingCancelModal);
