import { message } from 'antd';
import i18n from 'src/i18n';
// import errorModalViewModel from 'src/components/ErrorModal/viewModel';
import CustomError from './CustomError';

export default class ErrorServices {
  // 讀取用戶資料.
  static getUserProfile(error) {
    console.log('getUserProfile', error.response?.status);

    switch (error.response?.status) {
      // 帳號正在刪除.
      case 403: {
        const title = i18n.t('get_user_profile_403_title');
        const content = i18n.t('get_user_profile_403_content');

        // errorModalViewModel.open({ title, content });

        return content;
      }

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 檢查 email 有沒有註冊.
  static postUserExists(error) {
    switch (error.response?.status) {
      case 400:
        return 'email 錯誤，請重新確認';

      // 帳號正在刪除.
      case 403: {
        const title = i18n.t('post_user_exists_403_title');
        const content = i18n.t('post_user_exists_403_content');

        // errorModalViewModel.open({ title, content });

        return content;
      }

      case 404:
        return 'email 尚未註冊';

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 註冊.
  static postUserSignUp(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 註冊時, 重發驗證信.
  static postUserResendAuthEmail(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 驗證 email.
  static postUserVerify(error) {
    switch (error.response?.status) {
      case 404:
        return '驗證碼錯誤';

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 登出.
  static postUserLogout(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 登入.
  static postUserLogin(error) {
    switch (error.response?.status) {
      // 400, 密碼格式錯誤.
      // 401, 密碼輸入錯誤.
      // 404, 帳號錯誤.
      case 400:
      case 401:
        return i18n.t('find_hunt_page_login_password_error_message');
      case 403:
        return '帳號申請停用';
      case 404: {
        const content = i18n.t('post_user_login_404_content');

        return content;
      }
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新 profile.
  static putUserProfile(error) {
    switch (error.response?.status) {
      // email 已被使用.
      case 409:
        return i18n.t('find_hunt_page_sign_up_email_error_message');

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 驗證電話.
  static postUserResendAuthPhone(error) {
    switch (error.response?.status) {
      case 400: {
        const title = i18n.t('post_user_resend_auth_phone_400_title');
        const content = i18n.t('post_user_resend_auth_phone_400_content');

        // errorModalViewModel.open({ title, content });

        return content;
      }

      case 409: {
        const title = i18n.t('post_user_resend_auth_phone_409_title');
        const content = i18n.t('post_user_resend_auth_phone_409_content');

        // errorModalViewModel.open({ title, content });

        return content;
      }

      case 412: {
        const title = i18n.t('post_user_resend_auth_phone_412_title');
        const content = i18n.t('post_user_resend_auth_phone_412_content');

        // errorModalViewModel.open({ title, content });

        return content;
      }

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新電話.
  static putUserAuthPhone(error) {
    switch (error.response?.status) {
      case 404:
        return i18n.t('phone_modal_verification_code_error');

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取 stripe 資料.
  static getStripeInfo(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 前往註冊 stripe 帳號.
  static postStripeLink(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 申請刪除帳號.
  static postDeleteAccountRequest(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 發送重置密碼驗證信.
  static postUserResendAuthResetPassword(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 重置密碼.
  static putUserAuthResetPassword(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 新增獵場.
  static postLandCreate(error) {
    switch (error.response?.status) {
      case 400: {
        console.log(error.response);

        // 檢查錯誤訊息是寄件公司還是 server 給的
        const msg
          = error.response?.data?.data?.messages[0]?.text
          || error.response?.data?.message;

        message.error(msg, 5);

        console.log(msg);

        return msg;
      }

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取指定獵場.
  static getLandDetail(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新獵場資料..
  static putUpdateLandInfo(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 美國地區選項.
  static getOptionState(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 獵物選項.
  static getOptionInfo(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 獲取上傳照片需要的網址.
  static postGetUploadPhotosUrl(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新照片.
  static putUpdatePhotos(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取所有獵場.
  static getLandList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新獵場狀態.
  static putLandStatus(error) {
    switch (error.response?.status) {
      case 412:
        return i18n.t('put_land_status_listed_412');

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 刪除獵場草稿.
  static deleteLandDraft(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static deleteLand(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取獵場特別日收費列表.
  static getLandSpecialDateList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 新增獵場特別日收費.
  static postLandSpecialDate(error) {
    switch (error.response?.status) {
      case 409:
        return i18n.t('land_modal_special_date_overlapping_error_message');

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 刪除獵場特別日收費.
  static deleteLandSpecialDate(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 新增獵場長期預約折扣.
  static postLandDiscount(error) {
    switch (error.response?.status) {
      case 412:
        return i18n.t('land_edit_drawer_discounts_add_modal_post_land_discount_error_message_412');

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 刪除獵場長期預約折扣.
  static deleteLandDiscount(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取獵場長期折扣列表.
  static getLandDiscountList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 新增獵場的行程.
  static postLandPackage(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 刪除獵場的行程.
  static deleteLandPackage(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取獵場的行程.
  static getLandPackageList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取帳號所有的行程.
  static getPackageList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 新增帳號的行程.
  static postPackageCreate(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新帳號的行程.
  static putPackage(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 刪除帳號的行程.
  static deletePackage(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取獵場提問列表.
  static getLandQAList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新獵場提問的回答.
  static putQAReply(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取獵場評分列表.
  static getLandRatingList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新獵場評分的回答.
  static putRatingReply(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取獵場某月的每天是否開啟.
  static getLandBusinessDay(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 開啟或關閉獵場的日期.
  static putLandBusinessDay(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取訂單列表.
  static postBookingList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 接受訂單.
  static putBookingAccept(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 拒絕訂單.
  static putBookingReject(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 訂單詳情.
  // 403, 你沒有權限讀取這筆訂單.
  static getBookingDetail(error) {
    switch (error.response?.status) {
      case 403: {
        const title = i18n.t('get_booking_detail_403_title');
        const content = i18n.t('get_booking_detail_403_content');

        return content;
      }

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 取消訂單.
  static putBookingCancel(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 訂單分類數量.
  static getBookingStatistics(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static putBookingInfo(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 查詢已收款.
  static getPayoutPaid(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 查詢尚未收款.
  static getPayoutPayable(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // booking payout total
  static getPayoutStatistics(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取 booking payout paid 所有的紀錄
  static getPayoutPaidRecords(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getPayoutPayableRecords(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 使用 payout id 讀取 bookings.
  static getPayoutBookings(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取租賃列表.
  static getRentalsList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取租賃列表統計.
  static getRentalsStatistic(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getRentalDetail(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getRentalStatusHistory(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getRentalParcels(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getRentalParcelDetail(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static putUpdateRentalParcel(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static putRentalParcelItem(error) {
    switch (error.response?.status) {
      case 406: {
        return error.response.data.message;
      }

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getPayoutPaidDateList(error) {
    switch (error.response?.status) {
      case 406: {
        return error.response.data.message;
      }

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getPayoutPaidRentals(error) {
    switch (error.response?.status) {
      case 406: {
        return error.response.data.message;
      }

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getPayoutPayableRentals(error) {
    switch (error.response?.status) {
      case 406: {
        return error.response.data.message;
      }

      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getRentalCopyDetail(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getRentalPayoutStatistics(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getRentalPaidRecords(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 日曆 tag
  static getCalendarLandTags(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static postCalendarLandTags(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static deleteCalendarLandTags(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static getCalendarLandTagsSearch(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  static getConfig(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);
        return CustomError.fromError('其他錯誤').toString();
    }
  }
}
