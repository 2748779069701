import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import i18n from 'src/i18n';
import { ITEM_PLANS } from 'src/constants/rental/rental';
import ParcelPDFViewModel from './viewModel';
import { container, table, parcel, items } from './styles';

@observer
class ParcelPDF extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ParcelPDFViewModel({
      rental: props.rental,
      parcel: props.parcel
    });
  }

  renderItem = () => {
    return this.vm.parcel.items.map((item) => {
      const itemCopy = item.itemCopy;
      const itemVariety = itemCopy.itemVariety;
      const itemSource = itemVariety.item;

      return (
        <View style={items.contents} key={item.id}>
          <View style={{ ...items.content, ...items.name }}>
            <Text style={items.contentText}>{itemSource.name}</Text>
          </View>

          <View style={{ ...items.content, ...items.variation }}>
            <Text style={items.contentText}>{itemVariety.name}</Text>
          </View>

          <View style={{ ...items.content, ...items.rental }}>
            <Text style={items.contentText}>
              {ITEM_PLANS[itemCopy.plan].label}
            </Text>
          </View>

          <View style={{ ...items.content, ...items.quantity }}>
            <Text style={items.contentText}>1</Text>
          </View>
        </View>
      );
    });
  };

  render() {
    return (
      <Document>
        <Page size="A4" dpi={96} style={container.page}>
          <View style={table.table}>
            <View style={table.land}>
              <View style={table.label}>
                <Text style={table.labelText}>
                  {i18n.t(
                    'rental_drawer_parcel_page_parcel_pdf_land_name_label'
                  )}
                </Text>
              </View>

              <View style={table.content}>
                <Text style={table.contentText}>{this.vm.landName}</Text>
              </View>
            </View>

            <View style={table.address}>
              <View style={table.label}>
                <Text style={table.labelText}>
                  {i18n.t(
                    'rental_drawer_parcel_page_parcel_pdf_land_address_label'
                  )}
                </Text>
              </View>

              <View style={table.content}>
                <Text style={table.contentText}>{this.vm.landAddress}</Text>
              </View>
            </View>

            <View style={table.booking}>
              <View style={table.label}>
                <Text style={table.labelText}>
                  {i18n.t(
                    'rental_drawer_parcel_page_parcel_pdf_booking_id_label'
                  )}
                </Text>
              </View>

              <View style={table.content}>
                <Text style={table.contentText}>{this.vm.bookingId}</Text>
              </View>
            </View>

            <View style={table.renter}>
              <View style={table.label}>
                <Text style={table.labelText}>
                  {i18n.t(
                    'rental_drawer_parcel_page_parcel_pdf_consumer_label'
                  )}
                </Text>
              </View>

              <View style={table.content}>
                <Text style={table.contentText}>{this.vm.consumer}</Text>
              </View>
            </View>
          </View>

          <View style={parcel.parcel}>
            <View style={parcel.header}>
              <Text style={parcel.title}>{this.vm.parcelName}</Text>
            </View>

            <View style={parcel.labels}>
              <View style={parcel.shippingLabel}>
                <Text style={parcel.label}>{this.vm.shippingLabel}</Text>
              </View>

              <View style={parcel.returnLabel}>
                <Text style={parcel.label}>{this.vm.returnLabel}</Text>
              </View>
            </View>

            <View style={items.table}>
              <View style={items.labels}>
                <View style={{ ...items.label, ...items.name }}>
                  <Text style={items.labelText}>
                    {i18n.t(
                      'rental_drawer_parcel_page_parcel_pdf_item_name_label'
                    )}
                  </Text>
                </View>

                <View style={{ ...items.label, ...items.variation }}>
                  <Text style={items.labelText}>
                    {i18n.t(
                      'rental_drawer_parcel_page_parcel_pdf_variety_name_label'
                    )}
                  </Text>
                </View>

                <View style={{ ...items.label, ...items.rental }}>
                  <Text style={items.labelText}>
                    {i18n.t('rental_drawer_parcel_page_parcel_pdf_plan_label')}
                  </Text>
                </View>

                <View style={{ ...items.label, ...items.quantity }}>
                  <Text style={items.labelText}>
                    {i18n.t(
                      'rental_drawer_parcel_page_parcel_pdf_quantity_label'
                    )}
                  </Text>
                </View>
              </View>

              {this.renderItem()}
            </View>
          </View>
        </Page>
      </Document>
    );
  }
}

ParcelPDF.propTypes = {
  rental: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired
};

ParcelPDF.defaultProps = {};

export default ParcelPDF;
