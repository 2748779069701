import API from 'src/apis';
import ProfileDataModel from 'src/models/response/ProfileDataModel';
import StripeInfoDataModel from 'src/models/response/StripeInfoDataModel';
import StripeLinkDataModel from 'src/models/response/StripeLinkDataModel';
import { delay } from 'src/utils';
import Auth from './auth';

export default class UserService {
  static async getUserProfile() {
    const res = await API.user.getUserProfile(Auth.getToken());

    return {
      data: ProfileDataModel.fromRes(res.data.profile)
    };
  }

  // 檢查 email.
  static async postUserExists({ email }) {
    const res = await API.user.postUserExists({ email });

    return {
      data: {
        source: res.data.source
      }
    };
  }

  // 註冊.
  static async postUserSignUp(data) {
    const res = await API.user.postUserSignUp(data);

    // 保存 token.
    Auth.setToken(res.data.token);

    return {
      data: ProfileDataModel.fromRes(res.data.profile)
    };
  }

  // 註冊時, 重發驗證信.
  static async postUserResendAuthEmail({ email }) {
    const res = await API.user.postUserResendAuthEmail({ email });

    return {
      data: res.data
    };
  }

  // 驗證 email.
  static async postUserVerify({ code }) {
    const res = await API.user.postUserVerify({
      token: Auth.getToken(),
      code
    });

    return {
      data: res.data
    };
  }

  // 登出.
  static async postUserLogout() {
    const token = Auth.getToken();

    if (token) {
      const res = await API.user.postUserLogout({
        token: Auth.getToken()
      });

      // 刪除 token.
      Auth.removeToken();

      return {
        data: res.data
      };
    }

    return {
      data: 'not have token'
    };
  }

  // 登入.
  static async postUserLogin({ email, password }) {
    const res = await API.user.postUserLogin({
      email,
      password
    });

    // 保存 token.
    Auth.setToken(res.data.token);

    console.log('登入', 'token', res.data.token);

    return {
      data: ProfileDataModel.fromRes(res.data.profile)
    };
  }

  // 更新會員資料.
  static async putUserProfile(data) {
    const res = await API.user.putUserProfile({
      token: Auth.getToken(),
      data
    });

    return {
      data: ProfileDataModel.fromRes(res.data.profile)
    };
  }

  // 驗證電話.
  static async postUserResendAuthPhone({ phone }) {
    const res = await API.user.postUserResendAuthPhone({
      token: Auth.getToken(),
      phone
    });

    return {
      data: res.data
    };
  }

  // 更新電話.
  static async putUserAuthPhone({ code, phone }) {
    const res = await API.user.putUserAuthPhone({
      token: Auth.getToken(),
      code,
      phone
    });

    return {
      data: res.data
    };
  }

  // 讀取 stripe 資料.
  static async getStripeInfo() {
    const res = await API.user.getStripeInfo({ token: Auth.getToken() });

    return {
      data: new StripeInfoDataModel(res.data)
    };
  }

  // 前往註冊 stripe 帳號.
  static async postStripeLink() {
    const res = await API.user.postStripeLink({ token: Auth.getToken() });

    return {
      data: new StripeLinkDataModel(res.data)
    };
  }

  // 申請刪除帳號.
  static async postDeleteAccountRequest({ deleteReside, deleteReason }) {
    const res = await API.user.postDeleteAccountRequest({
      token: Auth.getToken(),
      deleteReside,
      deleteReason
    });

    return {
      data: res.data
    };
  }

  // 發送重置密碼驗證信.
  static async postUserResendAuthResetPassword({ email }) {
    const res = await API.user.postUserResendAuthResetPassword({
      email
    });

    return {
      data: res.data
    };
  }

  // 重置密碼.
  static async putUserAuthResetPassword({ email, password, code }) {
    const res = await API.user.putUserAuthResetPassword({
      email, password, code
    });

    return {
      data: res.data
    };
  }
}
