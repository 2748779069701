import { request, getHost } from './utils';

// 檢查 email.
export const putRatingReply = ({ token, id, reply }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/rating/${id}/reply`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      reply
    }
  };

  return request(options);
};
