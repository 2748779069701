import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import styles from './styles.module.scss';

@observer
class ActionDrawerHeader extends React.Component {
  render() {
    const { paddingBottom, ...ps } = this.props;

    return (
      <div
        className={clsx([
          styles.container,
          paddingBottom && styles.paddingBottom
        ])}
        {...ps}
      >
        {this.props.content}
      </div>
    );
  }
}

ActionDrawerHeader.propTypes = {
  paddingBottom: PropTypes.bool,
  content: PropTypes.string
};

ActionDrawerHeader.defaultProps = {
  paddingBottom: false,
  content: 'Action Drawer Title'
};

export default ActionDrawerHeader;
