import * as user from './user';
import * as land from './land';
import * as option from './option';
import * as google from './google';
// package 是保留字.
import * as packageAPI from './package';
import * as qa from './qa';
import * as rating from './rating';
import * as booking from './booking';
import * as payout from './payout';
import * as rentals from './rentals';
import * as calendar from './calendar';
import * as config from './config';

export default {
  user,
  land,
  option,
  google,
  packageAPI,
  qa,
  rating,
  booking,
  payout,
  rentals,
  calendar,
  config
};
