import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Popover } from 'antd';
import withRouter from 'src/components/withRouter';
import ResetButton from 'src/components/ResetButton';
import ActionDrawer from 'src/components/ActionDrawer';
import styles from './styles.module.scss';

@observer
class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ router: props.router });
  }

  render() {
    return (
      <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
        <div className={styles.container}>
          {this.vm.routes.map((item) => (
            <ResetButton
              key={item.value}
              type="text"
              className={styles.item}
              onClick={() => this.vm.onRouteClick(item)}
            >
              <img src={item.icon} alt="icon" className={styles.icon} />
              <div className={styles.label}>{item.label}</div>
            </ResetButton>
          ))}
        </div>
      </ActionDrawer>
    );
  }
}

Menu.propTypes = {
  vm: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

Menu.defaultProps = {};

export default withRouter(Menu);
