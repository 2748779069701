
import React from 'react';
import ReactDOM from 'react-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 48 }} />;
const body = document.body;

function Loading() {
  // TODO: @ian 滿版全黑怪怪的，理想上只擋 content，而 slider / header 不用擋
  return ReactDOM.createPortal(
    <div className={styles.container}>
      <div className={styles.background} />

      <div className={styles.content}>
        <Spin indicator={antIcon} />
      </div>
    </div>,
    body
  );
}

export default Loading;
