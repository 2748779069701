import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import { BOOKING_CANCEL_REASONS } from 'src/constants/booking/booking';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import BookingServices from 'src/services/BookingServices';
import ErrorServices from 'src/services/ErrorServices';
import { delay } from 'src/utils';

class BookingCancelModalViewModel {
  @observable booking = null;
  @observable isAwait = false;

  changed = () => {};

  form = null;

  modalVM = new ActionModalViewModel();

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      footer: this.isAwait
    };
  }

  @computed
  get rules() {
    return {
      reason: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ]
    };
  }

  @computed
  get options() {
    return {
      reasons: Object.values(BOOKING_CANCEL_REASONS)
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form }) => {
    this.form = form;
  };

  @action
  open = ({ booking, changed = () => {} }) => {
    this.booking = booking;
    this.changed = changed;

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  onConfirm = () => {
    this.putBookingCancelAPI();
  };

  @action
  putBookingCancelAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();

      await BookingServices.putBookingCancel({
        id: this.booking.id,
        reason: BOOKING_CANCEL_REASONS[data.reason].label
      });

      await this.changed();

      this.close();
    } catch (error) {
      const msg = ErrorServices.putBookingCancel(error);
      console.log('putBookingCancel', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default BookingCancelModalViewModel;
