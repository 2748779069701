import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import { ITEM_STATUS } from 'src/constants/rental/rental';
import ResetButton from 'src/components/ResetButton';
import ReportItemModal from '../ReportItemModal';
import styles from './styles.module.scss';

@observer
class ParcelItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ parcel: props.parcel, changed: props.changed });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.itemName}>
            {this.vm.deconstruct.itemSource.name}
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {this.vm.deconstruct.itemVariety.name}
            </div>

            <div className={styles.count}>{this.vm.labels.count}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {i18n.t('parcel_detail_drawer_parcel_item_status_label')}
            </div>

            <div className={styles.status}>{this.vm.status.label}</div>
          </div>

          {this.vm.check.report ? (
            <div className={styles.footer}>
              <ResetButton
                className={styles.button}
                align="center"
                onClick={this.vm.onReport}
              >
                {this.vm.labels.button}
              </ResetButton>
            </div>
          ) : null}
        </div>

        <ReportItemModal vm={this.vm.reportItemModalVM} />
      </>
    );
  }
}

ParcelItem.propTypes = {
  vm: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired,
  changed: PropTypes.func.isRequired
};

ParcelItem.defaultProps = {};

export default ParcelItem;
