class OptionStateDataModel {
  constructor(state) {
    const stateKeys = Object.keys(state);

    stateKeys.forEach((stateKey) => {
      this[stateKey] = {
        name: state[stateKey].name,
        counties: state[stateKey].counties
      };
    });
  }
}

export default OptionStateDataModel;
