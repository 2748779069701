import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import BookingCardViewModel from 'src/components/BookingCard/viewModel';
import {
  BOOKING_STATUS,
  bookingFilterStatusAndStatisticsToArray
} from 'src/constants/booking/booking';
import BookingServices from 'src/services/BookingServices';
import ErrorServices from 'src/services/ErrorServices';

class BatchBookingsPageViewModel {
  @observable bookings = [];
  @observable status = BOOKING_STATUS.requesting.value;

  @observable statistics = {
    arrivingSoon: 0,
    current: 0,
    next14days: 0,
    ready2finish: 0,
    requests: 0,
    total: 0
  };

  @observable inited = false;
  @observable isAwait = false;

  anchor = undefined;

  router = null

  @computed
  get checks() {
    return {
      bookings: !!this.bookings.length
    };
  }

  @computed
  get disabled() {
    return {
      filter: this.isAwait,
      footer: this.isAwait
    };
  }

  @computed
  get options() {
    return {
      status: bookingFilterStatusAndStatisticsToArray(this.statistics)
    };
  }

  constructor(props) {
    this.router = props.router;
    makeObservable(this);
  }

  didMount = async () => {
    await this.postBookingListAPI();

    runInAction(() => {
      this.inited = true;
    });
  }

  onBack = () => {
    this.router.navigate(-1);
  }

  @action
  onStatusChange = (val) => {
    this.status = val;
  }

  onWaypointEnter = () => {
    if (!this.isAwait && this.anchor !== null) {
      this.postBookingListAPI();
    }
  };

  reset = () => {
    this.bookings = [];
    this.anchor = undefined;

    this.postBookingListAPI();
  };

  @action
  postBookingListAPI = async () => {
    this.isAwait = true;

    try {
      const res = await BookingServices.postBookingList({
        anchor: this.anchor,
        status: BOOKING_STATUS.requesting.value
      });

      runInAction(() => {
        const bookings = res.data.map((item) => new BookingCardViewModel(item));

        this.bookings = this.bookings.concat(bookings);
        this.anchor = res.anchor;

        console.log('bookings', res.data);
      });
    } catch (error) {
      const msg = ErrorServices.postBookingList(error);

      console.log('postBookingList', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default BatchBookingsPageViewModel;
