import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BookingCard from 'src/components/BookingCard';
import { Waypoint } from 'react-waypoint';
import withRouter from 'src/components/withRouter';
import ResetSelect from 'src/components/ResetSelect';
import i18n from 'src/i18n';
import ICONS from 'src/constants/icons';
import PageHeader from 'src/components/PageHeader';
import PageContainer from 'src/components/PageContainer';
import PageCardsContainer from 'src/components/PageCardsContainer';
import BatchButton from './components/BatchButton';
import { BATCH_BUTTON_TYPE } from './components/BatchButton/viewModel';
import BatchBookingsPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class BatchBookingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new BatchBookingsPageViewModel({
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.inited ? (
      <PageContainer>
        <PageHeader back backIcon={ICONS.back2} />

        <div className={styles.filter}>
          <div className={styles.label}>
            <img src={ICONS.warningBlack} alt="warning" />
            <span>{i18n.t('batch_bookings_page_desc')}</span>
          </div>

          <ResetSelect
            className={styles.statusSelect}
            options={this.vm.options.status}
            value={this.vm.status}
            onChange={this.vm.onStatusChange}
            disabled
          />
        </div>

        {this.vm.checks.bookings ? (
          <PageCardsContainer onWaypointEnter={this.vm.onWaypointEnter}>
            {this.vm.bookings.map((vm) => (
              <BookingCard vm={vm} batch key={vm.id} />
            ))}
          </PageCardsContainer>
        ) : (
          <div className={styles.placeholder}>
            {i18n.t('batch_bookings_page_placeholder')}
          </div>
        )}

        <div className={styles.footer}>
          <BatchButton
            bookings={this.vm.bookings}
            type={BATCH_BUTTON_TYPE.reject}
            changed={this.vm.reset}
            disabled={this.vm.footer}
          />

          <BatchButton
            bookings={this.vm.bookings}
            type={BATCH_BUTTON_TYPE.accept}
            changed={this.vm.reset}
            disabled={this.vm.footer}
          />
        </div>
      </PageContainer>
    ) : (
      false
    );
  }
}

BatchBookingsPage.propTypes = {
  router: PropTypes.object.isRequired
};

BatchBookingsPage.defaultProps = {};

export default withRouter(BatchBookingsPage);
