import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Upload } from 'antd';
import ActionModal from 'src/components/ActionModal';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetButton from 'src/components/ResetButton';
import i18n from 'src/i18n';
import { PAGES } from './viewModel';
import styles from './styles.module.scss';

@observer
class IdCardModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  renderDescription = () => {
    return (
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>
          {i18n.t('id_card_modal_description')}
        </div>

        <ActionModalFooter
          cancelProps={{
            onClick: this.vm.close,
            children: i18n.t('id_card_modal_cancel_button_label')
          }}
          confirmProps={{
            onClick: this.vm.onNext,
            children: i18n.t('id_card_modal_next_button_label')
          }}
        />
      </div>
    );
  };

  renderUpload = () => {
    return (
      <div className={styles.uploadContainer}>
        <div className={styles.upload}>
          <Upload
            accept="image/jpg,image/jpeg,image/png"
            showUploadList={false}
            customRequest={this.vm.onUpload}
            disabled={this.vm.disabled.upload}
          >
            <ResetButton
              className={styles.button}
              disabled={this.vm.disabled.upload}
            >
              {i18n.t('id_card_modal_upload_button_label')}
            </ResetButton>
          </Upload>
        </div>

        <div className={styles.content}>
          {this.vm.check.src ? (
            <img className={styles.idCard} src={this.vm.src} alt="idCard" />
          ) : (
            <div className={styles.uploadPlaceholder}>
              <div className={styles.label1}>
                {i18n.t('id_card_modal_upload_label')}
              </div>

              <div className={styles.label2}>
                {i18n.t('id_card_modal_upload_id_card_placeholder')}
              </div>
            </div>
          )}
        </div>

        <ActionModalFooter
          cancelProps={{
            onClick: this.vm.close,
            children: i18n.t('id_card_modal_cancel_button_label')
          }}
          confirmProps={{
            onClick: this.vm.onConfirm,
            disabled: this.vm.disabled.confirm,
            children: i18n.t('id_card_modal_confirm_button_label')
          }}
        />
      </div>
    );
  };

  renderContent = () => {
    switch (this.vm.page) {
      case PAGES.description:
        return this.renderDescription();

      case PAGES.upload:
        return this.renderUpload();

      default:
        return null;
    }
  };

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        {this.renderContent()}
      </ActionModal>
    );
  }
}

IdCardModal.propTypes = {
  vm: PropTypes.object.isRequired
};

IdCardModal.defaultProps = {};

export default IdCardModal;
