import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import i18n from 'src/i18n';
import {
  RENTAL_STATUS,
  PARCEL_SHIPPING_STATUS,
  ITEM_STATUS
} from 'src/constants/rental/rental';
import ReportItemModalViewModel from '../ReportItemModal/viewModel';

class ParcelItemViewModel {
  @observable parcel = null;
  @observable item = null;

  changed = () => {};

  reportItemModalVM = new ReportItemModalViewModel();

  @computed
  get key() {
    return this.item.id;
  }

  @computed
  get check() {
    const parcelStatus
      = PARCEL_SHIPPING_STATUS[this.parcel.status] || RENTAL_STATUS.unknown;
    let report = false;

    switch (parcelStatus) {
      case RENTAL_STATUS.onTheWayToLand.value:
      case RENTAL_STATUS.deliveredLand.value:
      case RENTAL_STATUS.pickedUp.value: {
        report = false;
        break;
      }

      default: {
        report = true;
        break;
      }
    }

    return {
      normal: this.item.status === ITEM_STATUS.normal.value,
      missing: this.item.status === ITEM_STATUS.missing.value,
      report,
      signature: !!this.parcel.signature
    };
  }

  @computed
  get deconstruct() {
    const itemCopy = this.item.itemCopy; // 後端複製的資料
    const itemVariety = itemCopy.itemVariety; // 租借物品的款式
    const itemSource = itemVariety.item; // 租借的物品

    return {
      itemCopy,
      itemVariety,
      itemSource
    };
  }

  @computed
  get status() {
    return ITEM_STATUS[this.item.status];
  }

  @computed
  get labels() {
    let button = '';

    switch (this.status.value) {
      case ITEM_STATUS.normal.value: {
        button = i18n.t(
          'parcel_detail_drawer_parcel_item_report_issue_button_label'
        );
        break;
      }

      case ITEM_STATUS.missing.value:
      case ITEM_STATUS.damaged.value:
      case ITEM_STATUS.error.value: {
        button = i18n.t(
          'parcel_detail_drawer_parcel_item_edit_report_button_label'
        );
        break;
      }

      default:
        break;
    }

    return {
      count: 'x 1',
      button
    };
  }

  constructor(props) {
    this.item = props;
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.parcel = props.parcel;
    this.changed = props.changed;
  };

  @action
  didUpdate = (props, preProps) => {
    const diffParcel = diff(props.parcel, preProps.parcel);

    if (props.parcel && diffParcel) {
      this.parcel = props.parcel;
    }
  };

  onReport = () => {
    this.reportItemModalVM.open({
      parcel: this.parcel,
      item: this.item,
      changed: this.changed
    });
  };
}

export default ParcelItemViewModel;
