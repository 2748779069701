import { makeObservable, observable, action, computed } from 'mobx';
import { routerMethods } from 'src/constants/methods';
import { PARAMS } from 'src/constants/routes';
import { PAYOUT_TYPES, PAYOUT_STATUS } from 'src/constants/payout';

class PayoutDetailsPageViewModel {
  @observable type = null;
  @observable status = null;
  @observable payoutId = null;

  @computed
  get check() {
    return {
      payoutId: !!this.payoutId,
      rentalRecords:
        this.type === PAYOUT_TYPES.rental
        && this.status === PAYOUT_STATUS.paid
        && !this.payoutId
    };
  }

  constructor(props) {
    this.init(props.router);
    makeObservable(this);
  }

  @action
  init = (router) => {
    this.type = routerMethods.getSearch(router, PARAMS.payoutType);
    this.status = routerMethods.getSearch(router, PARAMS.payoutStatus);
    this.payoutId = routerMethods.getSearch(router, PARAMS.payoutId);
  };
}

export default PayoutDetailsPageViewModel;
