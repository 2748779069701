import Cookies from 'js-cookie';

import { AUTH_TOKEN_KEY } from 'src/constants/config';

export default class Auth {
  static hasToken() {
    return !!Cookies.get(AUTH_TOKEN_KEY);
  }

  static getToken() {
    return Cookies.get(AUTH_TOKEN_KEY);
  }

  static removeToken() {
    Cookies.remove(AUTH_TOKEN_KEY);
  }

  static setToken(token) {
    return Cookies.set(AUTH_TOKEN_KEY, token);
  }
}
