import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import ResetButton from 'src/components/ResetButton';
import { STATUS } from './viewModel';
import styles from './styles.module.scss';

@observer
class BusinessDayCard extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      land: props.land,
      tags: props.tags
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderStatus() {
    switch (this.vm.status) {
      case STATUS.fee:
        return (
          <div className={clsx([styles.item, styles.basicFee])}>
            <span className={styles.content}>{this.vm.labels.basicFee}</span>
          </div>
        );

      case STATUS.unAvailable:
        return (
          <div className={clsx([styles.item, styles.unAvailable])}>
            <span className={styles.content}>
              {i18n.t('calendar_page_business_day_status_un_available')}
            </span>
          </div>
        );

      case STATUS.final:
        return (
          <div className={clsx([styles.item, styles.final])}>
            <span className={styles.content}>
              {i18n.t('calendar_page_business_day_status_final')}
            </span>
          </div>
        );

      case STATUS.booking:
        return (
          <div className={clsx([styles.item, styles.booking])}>
            <span className={styles.content}>{this.vm.labels.booking}</span>
          </div>
        );

      default:
        return null;
    }
  }

  render() {
    return (
      <div
        className={clsx([
          styles.main,
          this.vm.check.background && styles.background
        ])}
      >
        <ResetButton
          className={styles.container}
          type="text"
          onClick={this.props.onClick}
          disabled={this.props.disabled}
        >
          <div className={styles.date}>{this.vm.labels.date}</div>

          {this.renderStatus()}

          {this.vm.labels.tags.map((item) => (
            <div className={styles.item} key={item}>
              <span className={styles.content}>{item}</span>
            </div>
          ))}

          {this.vm.check.tagsMore && (
            <div className={styles.item}>
              <span className={styles.content}>{this.vm.labels.tagsMore}</span>
            </div>
          )}
        </ResetButton>
      </div>
    );
  }
}

BusinessDayCard.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

BusinessDayCard.defaultProps = {
  onClick: () => {},
  disabled: false
};

export default BusinessDayCard;
