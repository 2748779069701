import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import i18n from 'src/i18n';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';
import LinkButton from 'src/components/LinkButton';
import styles from './styles.module.scss';

@observer
class NoStripeLabel extends React.Component {
  @observable isAwait = false;

  @computed
  get disabled() {
    return {
      sprite: this.isAwait
    };
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  onStripe = () => {
    this.postStripeLinkAPI();
  };

  @action
  postStripeLinkAPI = async () => {
    this.isAwait = true;

    try {
      const res = await UserService.postStripeLink();

      window.open(res.data.url);

      console.log('postStripeLink', res.data);
    } catch (error) {
      const msg = ErrorServices.postStripeLink(error);
      console.log('postStripeLink', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <LinkButton onClick={this.onStripe} disabled={this.disabled.sprite}>
          {i18n.t('payouts_page_set_stripe_button_label')}
        </LinkButton>

        <div className={styles.content}>
          {i18n.t('payouts_page_no_stripe_label')}
        </div>
      </div>
    );
  }
}

NoStripeLabel.propTypes = {};

NoStripeLabel.defaultProps = {};

export default NoStripeLabel;
