import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetInput from 'src/components/ResetInput';
import ResetButton from 'src/components/ResetButton';
import ActionDrawer from 'src/components/ActionDrawer';
import ActionDrawerHeader from 'src/components/ActionDrawer/components/ActionDrawerHeader';
import ResetRangePicker from 'src/components/ResetRangePicker';
import styles from './styles.module.scss';

@observer
class BookingsFilterDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ form: props.form });
  }

  render() {
    return (
      <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
        <div className={styles.container}>
          <ActionDrawerHeader
            content={i18n.t('bookings_filter_drawer_title')}
          />

          <Form className={styles.form} form={this.vm.form}>
            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('bookings_filter_drawer_dates_label')}
              </div>

              <ResetFormItem name="dates">
                <ResetRangePicker inputReadOnly />
              </ResetFormItem>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('bookings_filter_drawer_booking_id_label')}
              </div>

              <ResetFormItem name="keyword">
                <ResetInput
                  placeholder={i18n.t(
                    'bookings_filter_drawer_booking_id_placeholder'
                  )}
                />
              </ResetFormItem>
            </div>
          </Form>

          <div className={styles.footer}>
            <ResetButton className={styles.button} onClick={this.vm.close}>
              {i18n.t('bookings_filter_drawer_cancel_button_label')}
            </ResetButton>

            <ResetButton
              className={styles.button}
              type="primary"
              onClick={this.vm.onConfirm}
            >
              {i18n.t('bookings_filter_drawer_confirm_button_label')}
            </ResetButton>
          </div>
        </div>
      </ActionDrawer>
    );
  }
}

BookingsFilterDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

BookingsFilterDrawer.defaultProps = {};

export default withForm(BookingsFilterDrawer);
