import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Switch } from 'antd';
import clsx from 'clsx';
import i18n from 'src/i18n';
import styles from './styles.module.scss';

@observer
class Status extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ land: props.land });
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.status}>
            <div className={styles.label}>
              {i18n.t('land_edit_drawer_status_page_status_label')}
            </div>

            <div className={clsx([styles.content, this.vm.statusStyle])}>
              {this.vm.switchLabel}
            </div>
          </div>

          <div className={styles.switch}>
            <Switch value={this.vm.status} onChange={this.vm.onStatusChange} />
          </div>
        </div>

        <div className={styles.description}>{this.vm.description}</div>
      </div>
    );
  }
}

Status.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired
};

Status.defaultProps = {};

export default Status;
