/* eslint-disable */
export default {
  "": "",
  "ID": "en_US",
  "plus": "Plus",
  "off": "Off",
  "provided": "Provided",
  "not_provided": "Not provided",
  "not_set": "Not set",
  "btn_continue": "Continue",
  "btn_edit": "Edit",
  "btn_save": "Save",
  "btn_detail": "Detail",
  "btn_cancel": "Cancel",
  "btn_back": "Back",
  "btn_done": "Done",
  "btn_next": "Next",
  "btn_add": "Add",
  "btn_got_it": "Got it",
  "btn_discard": "Discard",
  "btn_undo": "Undo",
  "btn_delete": "Delete",
  "btn_verify": "Verify",
  "btn_decline": "Decline",
  "btn_accept": "Accept",
  "btn_edit_profile": "Edit profile",
  "input_number_placeholder": "Enter number",
  "btn_resend": "Resend verification code",
  "verify_code_error_message": "Verification code is incorrect",
  "input_required_message": "Required",
  "btn_logout": "Logout",
  "backstage_title": "KAVAVA",
  "deactivate_account_reason_1": "Can't comply with KAVAVA's terms and policy",
  "deactivate_account_reason_2": "Concern with KAVAVA's private policy",
  "deactivate_account_reason_3": "I rarely use KAVAVA",
  "deactivate_account_reason_4": "No longer engage with hunting activites",
  "deactivate_account_reason_5": "Other",
  "find_hunt_page_navigation_visitors_label": "Visitors",
  "find_hunt_page_navigation_landowners_label": "Outfitters / Landowners",
  "find_hunt_page_sign_button_label": "Log in",
  "find_hunt_page_hero_title_1": "Responsible",
  "find_hunt_page_hero_title_2": "&",
  "find_hunt_page_hero_title_3": "Gratitude",
  "find_hunt_page_hero_subtitle": "Share your secret spots.",
  "find_hunt_page_hero_label": "Bring your A game",
  "find_hunt_page_hero_sublabel": "A Professional Way To Manage Your Land Activities",
  "find_hunt_page_descriptions_item_1_title": "Sustainable Practice",
  "find_hunt_page_descriptions_item_1_content": "An opportunity to manage natural resource, maintain high quality of wildlife, and increase annual profits.",
  "find_hunt_page_descriptions_item_2_title": "All in One",
  "find_hunt_page_descriptions_item_2_content": "A complete set of tools and guidance, that gives you everything you need to be confident and be the best.",
  "find_hunt_page_descriptions_item_3_title": "Online Community",
  "find_hunt_page_descriptions_item_3_content": "See how others are sharing trail cam footage throughout the US. Promote your listing by sharing yours viewed by visitors nation wide.",
  "find_hunt_page_descriptions_item_4_title": "Free to List",
  "find_hunt_page_descriptions_item_4_content": "Unlimited listings, no subscriptions or membership fees. Minimal fee upon trasaction.",
  "find_hunt_page_descriptions_item_5_title": "Make your Own Leasing Rules",
  "find_hunt_page_descriptions_item_5_content": "Decide when and how you lease your land. Customize a listing tailored to you. Best of all, define your own pricing.",
  "find_hunt_page_descriptions_item_6_title": "Easy Payout",
  "find_hunt_page_descriptions_item_6_content": "Online payout is direct-deposited to your account. Automatic generated 1099 at the end of each year.",
  "find_hunt_page_lands_title": "Popular Land",
  "find_hunt_page_function_title": "Feel Good to Manage Your Land Leases With KAVAVA.",
  "find_hunt_page_function_content": "Get smart with your land lease management. List your land in a few steps, offer additional plan options, view your listing professionally displayed on KAVAVA APP, confirm and manage your bookings, interact with potential visitors through Q&A, and get monthly payouts.",
  "find_hunt_page_apps_title": "Download KAVAVA booking app",
  "find_hunt_page_footer_terms_of_service_label": "Terms of Service",
  "find_hunt_page_footer_privacy_policy_label": "Privacy Policy",
  "find_hunt_page_footer_contact_us_label": "Customer Support / Contact Us",
  "find_hunt_page_footer_email_label": "service@kavava.com",
  "find_hunt_page_footer_copyright_label": "© 2022 Develo LLC. All rights reserved.",
  "find_hunt_page_check_title": "Log in or Sign up",
  "find_hunt_page_check_email_placeholder": "Email",
  "find_hunt_page_check_email_btn": "Continue",
  "find_hunt_page_check_divider": "or",
  "find_hunt_page_check_facebook_btn": "Facebook",
  "find_hunt_page_check_apple_btn": "Apple",
  "find_hunt_page_check_email_error_from_facebook": "This email is already registered, please continue by facebook login.",
  "find_hunt_page_check_email_error_from_apple": "This email is already registered, please continue by apple login.",
  "find_hunt_page_sign_up_title": "Finish signing up",
  "find_hunt_page_sign_up_first_name_placeholder": "First name",
  "find_hunt_page_sign_up_last_name_placeholder": "Last name",
  "find_hunt_page_sign_up_name_desc": "Must match name on government ID.",
  "find_hunt_page_sign_up_birth_date_placeholder": "Birth date",
  "find_hunt_page_sign_up_birth_date_desc": "Your birthday won’t be shared with other people who use this platform.",
  "find_hunt_page_sign_up_gender_placeholder": "Gender",
  "find_hunt_page_sign_up_gender_desc": "This field is required",
  "find_hunt_page_sign_up_email_desc": "N/a",
  "find_hunt_page_sign_up_email_placeholder": "Email",
  "find_hunt_page_sign_up_email_error_message": "This email is already registered, please use another email.",
  "find_hunt_page_sign_up_password_placeholder": "Password",
  "find_hunt_page_sign_up_password_invalid_error_message": "The password must be more than eight characters",
  "find_hunt_page_sign_up_terms_desc_1": "By selecting Agree and Continue, I agree to the KAVAVA's Terms of Service, and acknowledge the Privacy Policy.",
  "find_hunt_page_sign_up_terms_desc_2": "",
  "find_hunt_page_sign_up_continue_btn": "Agree and Continue",
  "find_hunt_page_sign_up_notification_desc": "KAVAVA will send you members-only deals, newletters, marketing emails, and push notifications. You can opt out of receiving these at any time in your account settings or directly from the marketing notification.",
  "find_hunt_page_sign_up_marketing_desc": "Opt out deals, newsletters,marketing email from KAVAVA.",
  "find_hunt_page_login_title": "Log in",
  "find_hunt_page_login_password_placeholder": "Password",
  "find_hunt_page_login_password_error_message": "Incorrect password. If the password is incorrect for 10 consecutive times, the account will be locked for one hour.",
  "find_hunt_page_login_continue_btn": "Continue",
  "find_hunt_page_login_reset_password_btn": "Reset password",
  "find_hunt_page_reset_password_title": "Reset password",
  "find_hunt_page_reset_password_send_btn": "Send Reset Code",
  "find_hunt_page_reset_password_email_desc": "Press “Send Reset Code” button and we’ll send you a reset code to your email:",
  "find_hunt_page_reset_password_password_placeholder": "New password",
  "find_hunt_page_reset_password_continue_btn": "Save",
  "find_hunt_page_verify_title": "Verify email",
  "find_hunt_resend_button_label": "Resend verification code",
  "find_hunt_resend_unit": "sec",
  "routes_client_value": "client",
  "routes_lands_value": "properties",
  "routes_lands_label": "My Properties",
  "routes_bookings_value": "reservations",
  "routes_bookings_label": "Reservations",
  "routes_batch_bookings_value": "batch-reservations",
  "routes_packages_value": "plan-options",
  "routes_packages_label": "Plan Options",
  "routes_rentals_value": "rentals",
  "routes_rentals_label": "Rentals",
  "route_payouts_value": "income",
  "route_payouts_label": "Income",
  "route_payout_details_value": "income-details",
  "route_calendar_value": "calendar",
  "route_calendar_label": "Calendar",
  "booking_filters_all_booking": "All Booking",
  "booking_filters_currently_hosting": "Currently hosting",
  "booking_filters_arriving_soon": "Arriving soon",
  "booking_filters_ready_to_finish": "Ready to finish",
  "booking_filters_next_14_days": "Next 14 days",
  "booking_filters_requesting": "All Requesting",
  "booking_filters_accepted": "All Accepted",
  "booking_filters_declined": "All Declined",
  "booking_filters_completed": "All Completed",
  "booking_filters_disputed": "All Disputed",
  "booking_filters_canceled_by_hunter": "Canceled (by hunter)",
  "booking_filters_canceled_by_owner": "Canceled (by owner)",
  "booking_filters_canceled_by_admin": "Canceled (by admin)",
  "booking_filters_canceled_by_system": "Canceled (by system)",
  "form_input_required_message": "Required",
  "form_input_format_error_message": "Format error",
  "package_type_daily": "Daily",
  "package_type_weekly": "Weekly",
  "package_type_monthly": "Monthly",
  "package_type_per_package": "Per plan",
  "package_type_per_person": "Per person",
  "package_type_all_inclusive": "All inclusive",
  "rental_status_unknown_label": "Unknown",
  "rental_status_waiting_for_shipping_label": "Waiting for shipping to property",
  "rental_status_on_the_way_to_land_label": "On the way to property",
  "rental_status_delivered_land_label": "Delivered to property",
  "rental_status_picked_up_label": "Visitor pickup completed",
  "rental_status_on_the_way_to_warehouse_label": "Return shipping to KAVAVA",
  "rental_status_inspecting_label": "Inspecting returned items",
  "rental_status_processing_label": "Processing",
  "rental_status_waiting_for_refund_label": "Waiting for refund deposit fee",
  "rental_status_disputed_label": "Disputed",
  "rental_status_completed_label": "Rental completed",
  "rental_status_cancelled_without_charging_label": "Canceled without charging",
  "rental_status_cancelled_with_full_refund_label": "Canceled",
  "rental_status_cancelled_with_partial_refund_label": "Canceled",
  "item_plans_deposit_label": "Deposit",
  "item_plans_damage_waver_label": "Damage Waver",
  "item_status_normal_label": "Normal",
  "item_status_missing_label": "Missing",
  "item_status_damaged_label": "Damaged",
  "item_status_error_label": "Error",
  "genders_male_label": "Male",
  "genders_female_label": "Female",
  "genders_others_label": "Others",
  "booking_cancel_reason_1": "Place is unavailable.",
  "booking_cancel_reason_2": "The guest broke a property rule.",
  "booking_cancel_reason_3": "Listing doesn’t fit guest’s needs.",
  "booking_cancel_reason_4": "Having other concerns about this booking.",
  "booking_cancel_reason_5": "Other",
  "verifies_failed": "Failed",
  "verifies_verified": "Verified",
  "verifies_pending": "Verifying",
  "booking_status_accepted": "Accepted",
  "booking_status_declined": "Declined",
  "booking_status_requesting": "Requesting",
  "booking_status_completed": "Completed",
  "booking_status_canceled_by_hunter": "Canceled (by hunter)",
  "booking_status_canceled_by_owner": "Canceled (by owner)",
  "booking_status_canceled_by_admin": "Canceled (by admin)",
  "booking_status_canceled_by_system": "Canceled (by system)",
  "booking_status_ongoing": "Ongoing",
  "booking_status_disputed": "Disputed",
  "land_status_unlisted": "Unlisted",
  "land_status_draft": "Draft",
  "land_status_listed": "Listed",
  "land_status_suspension": "Suspension",
  "bookings_page_search_booking_placeholder": "Search by booking ID",
  "bookings_page_filter_select_land_placeholder": "All properties",
  "bookings_page_filter_button_label": "Filter",
  "bookings_page_more_button_booking_setting_label": "Reservation setting",
  "bookings_page_more_button_booking_requests_label": "batch review requests",
  "bookings_page_booking_setting_drawer_title": "Reservation setting",
  "bookings_page_booking_setting_drawer_alert_title_label": "You disabled the text message function.",
  "bookings_page_booking_setting_drawer_alert_content_label": "All of the reservations will be auto accepted.",
  "bookings_page_booking_setting_drawer_alert_cancel_button_label": "Cancel",
  "bookings_page_booking_setting_drawer_alert_confirm_button_label": "Got it",
  "bookings_page_booking_setting_drawer_auto_accept_label": "Automatic accept all reservations",
  "bookings_page_booking_setting_drawer_auto_accept_opened_content": "Status: Opened, All reservations will be automatically accepted.",
  "bookings_page_booking_setting_drawer_auto_accept_closed_content": "Status: Closed, You must review each request to decide whether to accept the reservation.",
  "bookings_page_booking_setting_drawer_message_label": "Receive text messages",
  "bookings_page_booking_setting_drawer_message_opened_content": "Status: Opened, Receive text messages when a new reservation is made",
  "bookings_page_booking_setting_drawer_message_closed_content": "Status: Closed, You will not receive text messages, all of the reservations will be auto accepted.",
  "bookings_page_no_land_label_1": "Unable to process your reservation",
  "bookings_page_no_land_label_2": "to earning income!",
  "bookings_page_no_land_label_link": "Add a property",
  "bookings_filter_drawer_title": "Filter",
  "bookings_filter_drawer_dates_label": "Staying date",
  "bookings_filter_drawer_booking_id_label": "Search by booking ID",
  "bookings_filter_drawer_booking_id_placeholder": "Search by booking ID",
  "bookings_filter_drawer_cancel_button_label": "Cancel",
  "bookings_filter_drawer_confirm_button_label": "Done",
  "booking_card_start_at_label": "From: ",
  "booking_card_end_at_label": "To: ",
  "booking_card_consumer_label": "Visitor: ",
  "booking_card_select_label": "Select",
  "booking_card_selected_label": "Selected",
  "booking_card_detail_button_label": "Detail",
  "booking_card_accept_button_label": "Accept",
  "booking_card_reject_button_label": "Decline",
  "booking_card_email_button_label": "Mail",
  "booking_card_phone_button_label": "Call",
  "booking_detail_drawer_booking_tab_label": "Booking",
  "booking_detail_drawer_booking_details_placeholder": "Not set",
  "booking_detail_drawer_booking_note_label": "Booking note",
  "booking_detail_drawer_booking_id_label": "Booking ID",
  "booking_detail_drawer_booking_status_label": "Booking status",
  "booking_detail_drawer_booking_land_label": "Property",
  "booking_detail_drawer_booking_duration_label": "Duration",
  "booking_detail_drawer_booking_duration_unit": "days",
  "booking_detail_drawer_booking_hunters_label": "Visitors",
  "booking_detail_drawer_booking_hunters_unit": "Visitors",
  "booking_detail_drawer_booking_contact_label": "Contact information",
  "booking_detail_drawer_booking_package_label": "Plan Options",
  "booking_detail_drawer_booking_package_table_item_label": "Item",
  "booking_detail_drawer_booking_package_table_qty_label": "Qty",
  "booking_detail_drawer_booking_earning_label": "Expected earning",
  "booking_detail_drawer_booking_payout_payable_total_label": "Pending settlement:",
  "booking_detail_drawer_booking_payout_paid_item_label": "settled on",
  "booking_detail_drawer_booking_accept_button_label": "Accept",
  "booking_detail_drawer_booking_reject_button_label": "Decline",
  "booking_detail_drawer_booking_cancel_button_label": "Cancel this booking",
  "booking_detail_drawer_rental_tab_label": "Rental",
  "booking_detail_drawer_rental_id_label": "Rental ID",
  "booking_detail_drawer_rental_create_at_label": "Booking date",
  "booking_detail_drawer_rental_duration_label": "Duration",
  "booking_detail_drawer_rental_duration_unit": "days",
  "booking_detail_drawer_rental_status_label": "Rental status",
  "booking_detail_drawer_rental_consumer_label": "Renter",
  "booking_detail_drawer_rental_price_label": "Expected earning",
  "booking_detail_drawer_rental_shippings_label": "Shipping information",
  "booking_detail_drawer_rental_shippings_table_address_label": "Property address",
  "booking_detail_drawer_rental_items_table_label": "items",
  "booking_detail_drawer_rental_items_table_plan_label": "Plan",
  "booking_detail_drawer_rental_items_table_rental_duration_label": "days",
  "booking_detail_drawer_rental_items_table_price_label": "Rental fee",
  "booking_detail_drawer_shipping_tab_label": "Shipping",
  "booking_detail_drawer_shipping_tab_no_shippings_label": "Waiting for KAVAVA to ship.",
  "booking_edit_drawer_note_page_note_label": "Booking notes",
  "booking_edit_drawer_note_page_note_placeholder": "Enter notes for this booking",
  "booking_edit_drawer_cancel_button_label": "Cancel",
  "booking_edit_drawer_confirm_button_label": "Save",
  "booking_cancel_modal_reason_placeholder": "Select a cancel reason",
  "booking_cancel_modal_description": "Cancellation fee: If you cancel a confirmed reservation, a fee will be deducted from your first payout after the cancellation. The amount depends on when you accepted the reservation and how soon before check-in you canceled it:\n\nMore than 7 days before check-in, $50 will be deducted from your next payout Less than 7 days before check-in, $100 will be deducted from your next payout\n  ",
  "booking_cancel_modal_cancel_button_label": "Cancel",
  "booking_cancel_modal_confirm_button_label": "Submit",
  "check_booking_page_booking_page_booking_detail_label": "Booking Details",
  "check_booking_page_booking_page_booking_id_label": "Booking ID",
  "check_booking_page_booking_page_booking_start_at_label": "Start date",
  "check_booking_page_booking_page_booking_end_at_label": "End date",
  "check_booking_page_booking_page_booking_duration_label": "Reservation",
  "check_booking_page_booking_page_booking_duration_unit": "days",
  "check_booking_page_booking_page_booking_hunters_label": "Hunters",
  "check_booking_page_booking_page_booking_hunters_unit": "Hunters",
  "check_booking_page_booking_page_booking_packages_label": "Plan Options",
  "check_booking_page_booking_page_booking_packages_unit": "Unit",
  "check_booking_page_booking_page_rental_items_label": "Rentals",
  "check_booking_page_booking_page_rental_items_unit": "Unit",
  "check_booking_page_booking_page_booking_earning_label": "Expected earning",
  "check_booking_page_booking_page_booking_price_label": "Leasing and Plan Options earning",
  "check_booking_page_booking_page_rental_profit_label": "Rental earning",
  "check_booking_page_booking_page_total_label": "Total",
  "check_booking_page_booking_page_accepted_button_label": "Accepted",
  "check_booking_page_booking_page_reject_button_label": "Decline",
  "check_booking_page_booking_page_status_label": "This reservation was",
  "check_booking_page_booking_page_trademark": "KAVAVA",
  "check_booking_page_login_page_title": "Log in or Sign up",
  "check_booking_page_login_page_email_placeholder": "Email",
  "check_booking_page_login_page_password_placeholder": "Password",
  "check_booking_page_login_page_confirm_button_label": "Accpeted",
  "check_booking_page_login_page_divider_label": "or",
  "parcel_detail_drawer_parcel_status_label": "Package status",
  "parcel_detail_drawer_parcel_shipping_label": "Package shipping label",
  "parcel_detail_drawer_parcel_return_label": "Package return label",
  "parcel_detail_drawer_parcel_items_label": "Package items",
  "parcel_detail_drawer_parcel_item_status_label": "Status",
  "parcel_detail_drawer_parcel_item_report_issue_button_label": "Report issue",
  "parcel_detail_drawer_parcel_item_edit_report_button_label": "Edit Report",
  "parcel_detail_drawer_parcel_item_reset_status_button_label": "Restore status",
  "report_item_modal_count_label": "Abnormal quantities",
  "report_item_modal_status_label": "Status type",
  "report_item_modal_count_placeholder": "Select status type",
  "report_item_modal_note_label": "Note",
  "report_item_modal_note_placeholder": "Enter details of item anomalies",
  "report_item_modal_cancel_button_label": "Cancel",
  "report_item_modal_confirm_button_label": "Save",
  "parcel_restore_alert_desc_1": "Once the restoration is confirmed, the signature for",
  "parcel_restore_alert_desc_2": "will be canceled.",
  "parcel_restore_alert_confirm_button_label": "Restore",
  "parcel_restore_alert_cancel_button_label": "Cancel",
  "parcel_note_modal_note_label": "Package Notes",
  "parcel_note_modal_note_placeholder": "Enter details of package anomalies",
  "parcel_note_modal_cancel_button_label": "Cancel",
  "parcel_note_modal_confirm_button_label": "Save",
  "rental_drawer_parcel_page_parcel_pdf_land_name_label": "Property name",
  "rental_drawer_parcel_page_parcel_pdf_land_address_label": "Property address",
  "rental_drawer_parcel_page_parcel_pdf_booking_id_label": "Booking ID",
  "rental_drawer_parcel_page_parcel_pdf_consumer_label": "Renter",
  "rental_drawer_parcel_page_parcel_pdf_shipping_label": "Shipping label:",
  "rental_drawer_parcel_page_parcel_pdf_return_label": "Return label:",
  "rental_drawer_parcel_page_parcel_pdf_item_name_label": "Item name",
  "rental_drawer_parcel_page_parcel_pdf_variety_name_label": "Variation",
  "rental_drawer_parcel_page_parcel_pdf_plan_label": "Rental plan",
  "rental_drawer_parcel_page_parcel_pdf_quantity_label": "Quantity",
  "rental_status_histories_drawer_header": "Status History",
  "rental_price_drawer_header": "Expected earning",
  "rental_price_drawer_desc": "Expected earning is 10% of the total rental fee.",
  "rental_price_drawer_items_table_item_label": "Item",
  "rental_price_drawer_items_table_price_label": "Amount (USD)",
  "rental_price_drawer_items_table_item_variety_price_label": "Rental fee",
  "rental_price_drawer_items_duration_unit": "days",
  "rental_price_drawer_refund_label": "Cancel refund",
  "rental_price_drawer_refund_description": "Visitor canceled with a partial refund.",
  "rental_price_drawer_refund_content": "Refund",
  "rental_price_drawer_refund_unit": "%",
  "rental_price_drawer_total_label": "Total expected earning",
  "booking_price_drawer_header": "Expected earning",
  "booking_price_drawer_desc": "Payment breakdown of services",
  "booking_price_drawer_item_label": "Item",
  "booking_price_drawer_count_label": "Amount (USD)",
  "booking_price_drawer_basic_fee_label": "Basic fee",
  "booking_price_drawer_date_unit": "days",
  "booking_price_drawer_extra_fee_label": "Additional visitor fee",
  "booking_price_drawer_hunters_unit": "poeple",
  "booking_price_drawer_packages_label": "Plan Options",
  "booking_price_drawer_discount_label": "Leasing for more than",
  "booking_price_drawer_discount_unit": "days",
  "booking_price_drawer_long_term_discount_label": "Long term discount",
  "booking_price_drawer_refund_label": "Cancel refund",
  "booking_price_drawer_refund_description": "Visitor canceled with a partial refund.",
  "booking_price_drawer_refund_content": "Refund",
  "booking_price_drawer_refund_unit": "%",
  "booking_price_drawer_total_label": "Total expected earning",
  "contact_drawer_header": "Contact information",
  "contact_drawer_name_label": "Name",
  "contact_drawer_email_label": "Email",
  "contact_drawer_phone_label": "Phone number",
  "batch_bookings_page_batch_button_accept_main_button_label": "Accept",
  "batch_bookings_page_batch_button_reject_main_button_label": "Decline",
  "batch_bookings_page_batch_button_modal_content": "of request(s) will be",
  "batch_bookings_page_batch_button_modal_accept_content": "accept",
  "batch_bookings_page_batch_button_modal_reject_content": "decline",
  "batch_bookings_page_batch_button_modal_cancel_button_label": "Cancel",
  "batch_bookings_page_batch_button_modal_accept_button_label": "Accept",
  "batch_bookings_page_batch_button_modal_reject_button_label": "Decline",
  "batch_bookings_page_desc": "After selecting the reservations, you can then accept or decline them.",
  "batch_bookings_page_placeholder": "There are currently no requests that need to be confirmed.",
  "lands_page_title": "Ｍy Properties",
  "lands_page_add_land_button_label": "Add a new property",
  "lands_page_select_land_all_land_label": "All properties ",
  "lands_page_placeholder_link_button_label": "Add a new property",
  "lands_page_placeholder": "to your account to earning income!",
  "lands_page_add_land_desc_1": "Add a new property",
  "lands_page_add_land_desc_2": "to your account to earning income!",
  "lands_page_land_card_booking_count_label": "Reserves:",
  "lands_page_land_card_qa_count_label": "Q&As:",
  "lands_page_land_card_rating_count_label": "Reviews:",
  "lands_page_land_card_detail_button_label": "Detail",
  "lands_page_land_card_list_button_label": "List",
  "lands_page_land_card_appeal_button_label": "Appeal",
  "lands_page_land_card_delete_button_label": "Delete",
  "lands_page_land_card_edit_button_label": "Edit",
  "delete_land_modal_cancel_button_label": "Cancel",
  "delete_land_modal_confirm_button_label": "Delete",
  "delete_land_modal_delete_land_title": "Are you sure to delete this property?",
  "delete_land_modal_delete_land_content": "The property will be permanently deleted.",
  "create_land_drawer_next_button_label": "Next",
  "create_land_drawer_back_button_label": "Previous",
  "create_land_drawer_created_button_label": "Done",
  "create_land_drawer_address_page_title": "Step1 - Address",
  "create_land_drawer_address_page_state_label": "State",
  "create_land_drawer_address_page_state_select_placeholder": "Select state",
  "create_land_drawer_address_page_county_label": "City",
  "create_land_drawer_address_page_county_select_placeholder": "Select city",
  "create_land_drawer_address_page_street_label": "Street address",
  "create_land_drawer_address_page_street_input_placeholder": "Enter street address",
  "create_land_drawer_address_page_postcode_label": "Postcode",
  "create_land_drawer_address_page_postcode_input_placeholder": "Enter postcode",
  "create_land_drawer_position_page_title": "Step2 - Location",
  "create_land_drawer_position_page_tabs_drag_map_label": "Pin on map",
  "create_land_drawer_position_page_tabs_drag_map_description": "Visitors will navigate using your pin location. Please mark the most suitable entrance to your property.",
  "create_land_drawer_position_page_tabs_drag_map_drag_description": "Drag the map to reposition the pin.",
  "create_land_drawer_position_page_tabs_input_map_label": "Enter lat. & lng.",
  "create_land_drawer_position_page_tabs_input_map_lat_lng_desc": "Latitude and longitude",
  "create_land_drawer_position_page_tabs_input_map_input_format_error": "Format error",
  "create_land_drawer_basic_info_page_title": "Step3 - Basic info",
  "create_land_drawer_basic_info_page_name_label": "Property name",
  "create_land_drawer_basic_info_page_name_placeholder": "Enter a descriptive name for your property",
  "create_land_drawer_basic_info_page_acres_label": "Property acres",
  "create_land_drawer_basic_info_page_acres_placeholder": "Enter numbers",
  "create_land_drawer_basic_info_page_acres_format_error": "Format error",
  "create_land_drawer_basic_info_page_species_label": "Species",
  "create_land_drawer_basic_info_page_species_placeholder": "Select species",
  "create_land_drawer_price_page_title": "Step4 - Price",
  "create_land_drawer_price_page_basic_fee_label": "Basic fee per day",
  "create_land_drawer_price_page_basic_fee_placeholder": "Enter numbers",
  "create_land_drawer_price_page_extra_fee_label": "Extra hunter fee per day",
  "create_land_drawer_price_page_extra_fee_placeholder": "Enter numbers",
  "create_land_drawer_price_page_basic_hunters_label": "Basic fee includes how many visitors?",
  "create_land_drawer_price_page_basic_hunters_placeholder": "Enter numbers",
  "create_land_drawer_price_page_extra_hunters_label": "How many EXTRA hunters can you host?",
  "create_land_drawer_price_page_extra_hunters_placeholder": "Enter numbers",
  "create_land_drawer_price_page_no_input_zero": "The number must be greater than 0.",
  "create_land_drawer_photos_page_title": "Step5 - Photos",
  "create_land_drawer_photos_page_description": "Add at least 5 photos of your property",
  "create_land_drawer_photos_page_upload_button_label": "Upload",
  "create_land_drawer_photos_page_photo_ratio_error": "The size of the photo is abnormal. Please upload a normal size photo.",
  "create_land_drawer_description_page_title": "Step6 - Description",
  "create_land_drawer_description_page_description_label": "Property description",
  "create_land_drawer_description_page_description_placeholder": "Introduce your property",
  "land_detail_drawer_null_item_placeholder": "Not set",
  "land_detail_drawer_detail_page_title": "Info",
  "land_detail_drawer_detail_page_status_label": "Status",
  "land_detail_drawer_detail_page_photos_label": "Photos",
  "land_detail_drawer_detail_page_name_label": "Property name",
  "land_detail_drawer_detail_page_acres_label": "Property acres",
  "land_detail_drawer_detail_page_species_label": "Species",
  "land_detail_drawer_detail_page_description_label": "Property description",
  "land_detail_drawer_detail_page_address_label": "Property address",
  "land_detail_drawer_detail_page_meet_address_label": "Meet up information",
  "land_detail_drawer_detail_page_rules_label": "Property rules",
  "land_detail_drawer_detail_page_final_booking_date_label_1": "Final booking date",
  "land_detail_drawer_detail_page_final_booking_date_label_2": "Until",
  "land_detail_drawer_fees_page_title": "Rates",
  "land_detail_drawer_fees_page_basic_fee_label": "Basic fee per day",
  "land_detail_drawer_fees_page_basic_hunters_label": "Basic fee includes how many visitors?",
  "land_detail_drawer_fees_page_extra_hunters_label": "How many EXTRA hunters can you host?",
  "land_detail_drawer_fees_page_extra_fee_label": "Extra hunter fee per day",
  "land_detail_drawer_fees_page_special_dates_label": "Special dates pricing",
  "land_detail_drawer_fees_page_discounts_label": "Long term discounts",
  "land_detail_drawer_fees_page_discounts_off_label": "Off",
  "land_detail_drawer_packages_page_title": "Plan Options",
  "land_detail_drawer_packages_page_description": "Once you have set up the plan options you can offer, you can then list these plan options within the property. Visitors can select these plan options when making reservations, all in one go.",
  "land_detail_drawer_packages_page_placeholder": "Go to plan options list",
  "land_detail_drawer_packages_page_add_package_button_label": "Add plan options to this property",
  "land_detail_drawer_packages_page_table_package_label": "Item",
  "land_detail_drawer_packages_page_table_delete_label": "Remove",
  "create_land_package_modal_package_label": "Optional plan options",
  "create_land_package_modal_package_placeholder": "Select plan options",
  "create_land_package_modal_cancel_button_label": "Cancel",
  "create_land_package_modal_confirm_button_label": "Add",
  "launch_land_modal_setting_profile_content": "Please complete the 'Phone number' and 'Government ID' verification in ‘Account settings’ before listing your property.",
  "launch_land_modal_setting_profile_cancel_button_label": "Cancel",
  "launch_land_modal_setting_profile_confirm_button_label": "Start Verification",
  "launch_land_modal_profile_id_card_verifying_content": "Your government ID is being verified. We'll email you once the verification is completed and available to list your properties.",
  "launch_land_modal_profile_id_card_verifying_cancel_button_label": "Got it",
  "launch_land_modal_profile_id_card_failed_content": "Your government ID did not pass verification. Please double-check and provide it again.",
  "launch_land_modal_profile_id_card_failed_cancel_button_label": "Re-upload",
  "launch_land_modal_terms_8_title": "8. Leasing on Kavava.",
  "launch_land_modal_terms_8_1_content": "8.1 Landowner means the individual or business who uses the\n              Services on the Kavava Platform (www.kavava.com) in order to offer\n              their land and/or property in their listings to third-party\n              Visitors, in exchange for a payment and in accordance with these\n              Terms of Service.",
  "launch_land_modal_terms_8_2_content": "8.2 Landowner/ Manager may create a listing on the Kavava Platform\n              (www.kavava.com). By posting the Listing, the Landowner is\n              warranting that any and all information supplied with the Listing,\n              including but not limited to pricing, requirements, policies,\n              specifications, and descriptions, are accurate, truthful, and up\n              to date.",
  "launch_land_modal_terms_8_3_content": "8.3 Land. Landowner may have one Listing per land and/or property.",
  "launch_land_modal_terms_9_title": "9. Managing Your Listing.",
  "launch_land_modal_terms_9_1_content": "9.1 Landowner/ Manager may update, modify an existing listing on\n              the Kavava Platform (www.kavava.com). By changing the listing\n              descriptions, requirements, dates availability, plan options, costs,\n              and discounts. Once the listing is modify, the modifications will\n              apply to all future listings and bookings, but will not modify the\n              current/past accepted bookings.",
  "launch_land_modal_terms_10_title": "10. Cancellations by Owner.",
  "launch_land_modal_terms_10_1_content": "10.1 Cancelling a Visitor’s booking may incur a cancellation fee.\n              Under limited circumstances, either Landowner or Visitor may\n              terminate the booking, such as when an extenuating circumstance\n              arises. In such an event, the amount refunded to the Visitor shall\n              be deducted from amounts paid to Landowner and the reasonable\n              costs incurred by Kavava Platform to handle the cancellation. If\n              you cancel on a reservation more than 7 days before booking date,\n              there will be a fee of 50 USD, if you cancel within 7 days there\n              will be a fee of 100 USD. This will be offset from your future\n              payout. If you cancel more than 3 times your listing will be\n              terminated and your account will be frozen and go under\n              investigation.",
  "launch_land_modal_terms_11_title": "11. Taxes.",
  "launch_land_modal_terms_11_1_content": "11.1 The Landowner shall be responsible for fulfilling all\n              obligations under applicable laws in relation to the collection,\n              remittance, and payment of taxes, such as VAT, occupancy, indirect\n              taxes, income, or tourist.",
  "launch_land_modal_terms_12_title": "12. Your Responsibilities",
  "launch_land_modal_terms_12_1_content": "12.1 Posting a Listing on the Platform shall constitute an offer\n              to contract, which may be accepted by a Visitor at any time. When\n              a Visitor places a booking, and Landowner accepts this booking,\n              the Landowner and Visitor are directly entering into a legally\n              binding agreement under the terms and conditions of the\n              Landowner’s Listing. Upon Landowner’s acceptance of the booking,\n              Landowner is also agreeing to all applicable fees due to the\n              Kavava Platform and other fees as they may accrue.",
  "launch_land_modal_terms_12_2_content": "12.2 Accuracy. By posting the Listing, the Landowner is warranting\n              that any and all information supplied with the Listing, including\n              but not limited to pricing, requirements, policies,\n              specifications, and descriptions, are accurate, truthful, and up\n              to date.",
  "launch_land_modal_terms_12_3_content": "12.3 Insurance. It is recommended that Landowner shall take out\n              appropriate insurance in order to safely and securely offer the\n              land and/or Property under the Listing.",
  "launch_land_modal_terms_12_4_content": "12.4 Landowner Compliance Responsibility. Landowner shall be\n              exclusively responsible all compliance with applicable laws,\n              rules, and regulations applicable to the use of the Platform.\n              Moreover, Landowner must abide by all contracts it has entered\n              into and it is Landowner’s responsibility to ensure that its use\n              of the Platform and offering of the Listing does not violate any\n              of its existing agreements or legal obligations.",
  "launch_land_modal_terms_12_5_content": "12.5 Further Responsibilities. The Landowner shall be fully\n              responsible and liable for their own acts and omissions, as well\n              as those of any third party who enables, helps, or assists the\n              Landowner with the Listing. The rules and requirements the Visitor\n              must abide by after booking the Listing must be specified by the\n              Landowner.",
  "launch_land_modal_terms_13_title": "13. No Circumvention",
  "launch_land_modal_terms_13_1_content": "13.1 The Landowner shall be prohibited from charging the Visitor\n              any additional charges outside of the Platform, thereby\n              circumventing the Kavava Platform, whether intentionally or not.",
  "launch_land_modal_terms_checkbox_label": "I agree to the above terms",
  "launch_land_modal_terms_cancel_button_label": "Cancel",
  "launch_land_modal_terms_confirm_button_label": "Confirm",
  "land_edit_drawer_cancel_button_label": "Cancel",
  "land_edit_drawer_confirm_button_label": "Save",
  "land_edit_drawer_status_page_status_label": "Status: ",
  "land_edit_drawer_status_page_status_unlisted_description": "Visitors can’t view this property or find it in search results.",
  "land_edit_drawer_status_page_status_listed_description": "Hunters can find this property in search results and request or book available dates.",
  "land_edit_drawer_rules_page_rules_label": "Property rules",
  "land_edit_drawer_rules_page_rules_placeholder": "Enter your property rules",
  "land_edit_drawer_final_booking_date_page_label": "Final booking date",
  "land_edit_drawer_final_booking_date_page_placeholder": "Select date",
  "land_edit_drawer_special_dates_page_main_label_description": "You can set mark-ups or discounts for five date ranges, The price settings will immediately apply to new orders after saving",
  "land_edit_drawer_special_dates_page_main_label_prices_label": "Your pricing now : ",
  "land_edit_drawer_special_dates_page_main_label_basic_fee_label": "Basic fee per day :",
  "land_edit_drawer_special_dates_page_main_label_extra_fee_label": "Each EXTRA hunter fee per day :",
  "land_edit_drawer_special_dates_page_add_button_label": "Add a period plan",
  "land_edit_drawer_special_dates_page_table_plan_label": "Period plan",
  "land_edit_drawer_special_dates_page_table_delete_label": "Delete",
  "land_edit_drawer_special_dates_plus_label": "Plus",
  "land_edit_drawer_special_dates_off_label": "Off",
  "land_edit_drawer_special_dates_basic_fee_label": "Basic fee - ",
  "land_edit_drawer_special_dates_extra_fee_label": "Extra hunter fee - ",
  "land_edit_drawer_special_add_modal_dates_date_label": "Plan period",
  "land_edit_drawer_special_add_modal_dates_ratio_label": "Period plan",
  "land_edit_drawer_special_add_modal_plus_label": "% Plus",
  "land_edit_drawer_special_add_modal_off_label": "% Off",
  "land_edit_drawer_special_add_modal_dates_cancel_button_label": "Cancel",
  "land_edit_drawer_special_add_modal_dates_confirm_button_label": "Add",
  "land_edit_drawer_discounts_page_main_label_description": "The discount settings will immediately apply to new orders after saving.",
  "land_edit_drawer_discounts_page_main_label_price_label": "Your pricing:",
  "land_edit_drawer_discounts_page_main_label_basic_fee_label": "Basic fee per day : ",
  "land_edit_drawer_discounts_page_main_label_extra_fee_label": "Each EXTRA hunter fee per day : ",
  "land_edit_drawer_discounts_page_add_button_label": "Add a Long term discount",
  "land_edit_drawer_discounts_page_table_discount_label": "Discount plan",
  "land_edit_drawer_discounts_page_table_delete_label": "Delete",
  "land_edit_drawer_discounts_page_discounts_30_dates_label": "More than 30 days",
  "land_edit_drawer_discounts_page_discounts_60_dates_label": "More than 60 days",
  "land_edit_drawer_discounts_page_discounts_90_dates_label": "More than 90 days",
  "land_edit_drawer_discounts_page_discounts_180_dates_label": "More than 180 days",
  "land_edit_drawer_discounts_page_discounts_240_dates_label": "More than 240 days",
  "land_edit_drawer_discounts_add_modal_discount_label": "Plan",
  "land_edit_drawer_discounts_add_modal_discount_placeholder": "Select plan",
  "land_edit_drawer_discounts_add_modal_ratio_label": "Discount",
  "land_edit_drawer_discounts_add_modal_ratio_off_label": "Off",
  "land_edit_drawer_discounts_add_modal_ratio_off_input_label": "% Off",
  "land_edit_drawer_discounts_add_modal_cancel_button_label": "Cancel",
  "land_edit_drawer_discounts_add_modal_container_button_label": "Add",
  "land_edit_drawer_discounts_add_modal_post_land_discount_error_message_412": "Addition failed. Discount rate must be equal to or greater than short-term plan's.",
  "land_edit_drawer_price_alert_modal_title": "Sure to save the fee settings?",
  "land_edit_drawer_price_alert_modal_content": "The new fee settings will only be applied to new bookings made after saving.",
  "land_edit_drawer_price_alert_modal_cancel_button_label": "Cancel",
  "land_edit_drawer_price_alert_modal_confirm_button_label": "Save",
  "land_meet_drawer_address_page_title": "Meeting address",
  "land_meet_drawer_position_page_title": "Meeting point",
  "land_meet_drawer_description_page_title": "Meet up information",
  "land_meet_drawer_description_page_description_label": "Description",
  "land_meet_drawer_description_page_description_placeholder": "provide information on how visitors can meet with you.",
  "land_meet_drawer_prev_button_label": "Previous",
  "land_meet_drawer_next_button_label": "Next",
  "land_meet_drawer_done_button_label": "Done",
  "land_messages_drawer_qas_title": "Q&As",
  "land_messages_drawer_ratings_title": "Reviews",
  "land_messages_drawer_message_replied_label": "Replied",
  "land_messages_drawer_message_reply_button_label": "Reply",
  "land_messages_drawer_message_reply_placeholder": "Enter your reply",
  "land_messages_drawer_message_reply_cancel_button_label": "Cancel",
  "land_messages_drawer_message_reply_confirm_button_label": "Send",
  "land_messages_drawer_message_reply_edit_button_label": "Reply",
  "packages_page_create_package_button_label": "Create a plan option",
  "packages_page_package_card_detail_button_label": "Detail",
  "packages_page_placeholder_link_button_label": "Create a plan option",
  "packages_page_placeholder_label": "to your account \nand apply to your properties!",
  "package_drawer_title": "Plan Option",
  "package_drawer_edit_title": "Edit plan option",
  "package_drawer_create_title": "Create plan option",
  "package_drawer_name_label": "Plan option name",
  "package_drawer_name_placeholder": "Enter plan option name",
  "package_drawer_type_label": "Plan option unit",
  "package_drawer_type_placeholder": "Select plan option unit",
  "package_drawer_price_label": "Rate / per unit",
  "package_drawer_price_placeholder": "Enter numbers",
  "package_drawer_description_label": "Description",
  "package_drawer_description_placeholder": "Enter what does this plan option include or exclude, please notice that the hunting ground will be suspended if the listed plan option/service violating local laws, public order, morality, or damaging ecological conservation.",
  "package_drawer_delete_button_label": "Delete",
  "package_drawer_cancel_button_label": "Cancel",
  "package_drawer_edit_button_label": "Edit",
  "package_drawer_save_button_label": "Save",
  "package_drawer_create_button_label": "Add",
  "package_delete_modal_title": "Delete plan option？",
  "package_delete_modal_content": "After deleting this plan option, it will also be removed from the Property's Plan options list, and visitors won't be able to purchase it during the booking process.",
  "package_delete_modal_cancel_button_label": "Cancel",
  "package_delete_modal_confirm_button_label": "Delete",
  "profile_drawer_profile_card_edit_label": "Edit",
  "profile_drawer_profile_card_display_name_label": "Display name",
  "profile_drawer_profile_card_legal_name_label": "Legal name",
  "profile_drawer_profile_card_gender_label": "Gender",
  "profile_drawer_profile_card_birth_date_label": "Birth date",
  "profile_drawer_profile_card_email_label": "Email",
  "profile_drawer_profile_card_phone_number_label": "Phone number",
  "profile_drawer_profile_card_id_card_label": "Government ID",
  "profile_drawer_profile_card_content_no_set_placeholder_label": "Not Provided",
  "profile_drawer_profile_card_content_set_placeholder_label": "Provided",
  "profile_drawer_logout_button_label": "Logout",
  "profile_drawer_deactivate_account_button_label": "Deactivate account",
  "id_card_modal_description": "When you click \"SUBMIT\" in next page, you fully agree that the information you provide to set up the account must be accurate, current, and complete information. And you acknowledge KAVAVA's User Registration and Accounts Terms and KAVAVA's Limitations of Liability, specifically under Disclaimer of Warranties. We do not warrant that verification, identity or background checks conducted on Listings or Visitors/Landowners (if any) will identify past misconduct or prevent future misconduct.",
  "id_card_modal_cancel_button_label": "Cancel",
  "id_card_modal_next_button_label": "Next",
  "id_card_modal_confirm_button_label": "Submit",
  "id_card_modal_upload_button_label": "Upload",
  "id_card_modal_upload_label": "Click to upload and select your identification document from your device.",
  "id_card_modal_upload_id_card_placeholder": "Kavava collects landowners' IDs to verify their accounts and ensure all listings are genuine. This process prevents scams and maintains a trustworthy platform where users can confidently book and lease properties.",
  "display_name_modal_display_name_label": "Display Name",
  "display_name_modal_confirm_button_label": "Save",
  "display_name_modal_cancel_button_label": "Cancel",
  "gender_modal_gender_label": "Gender",
  "gender_modal_gender_placeholder": "Select gender",
  "gender_modal_cancel_button_label": "Cancel",
  "gender_modal_confirm_button_label": "Save",
  "phone_modal_phone_label": "Phone number",
  "phone_modal_phone_placeholder": "Enter phone number",
  "phone_modal_phone_description": "By pressing “Continue”, Hunting Property will send you text message with a verification code. This may result in SMS and data charges.",
  "phone_modal_verify_label": "Verification code",
  "phone_modal_verify_placeholder": "Enter verification code",
  "phone_modal_verify_error_message": "Verification code is incorrect.",
  "phone_modal_verify_description_1": "The verification code has been sent to",
  "phone_modal_verify_description_2": ". If you haven't received the verification code, please click \"Resend\".",
  "phone_modal_cancel_button_label": "Cancel",
  "phone_modal_next_button_label": "Continue",
  "phone_modal_resend_button_label": "Resend",
  "phone_modal_resend_unit": "s",
  "phone_modal_verify_button_label": "Verify",
  "phone_modal_success_message": "The phone number is successfully binding.",
  "phone_modal_verification_code_error": "The verification code error",
  "deactivate_account_modal_description": "Upon confirmation of your request, your personal data will be deleted, except for information specified in our Privacy Policy or required by law. To request deletion, please submit a request and follow the steps outlined in the email to verify your account ownership. If you have made a booking within the last 60 days, we cannot process your deletion request until this period has passed. To use KAVAVA in the future, you will need to create a new account. If you have upcoming reservations as a host or guest, please cancel them according to the applicable cancellation policy before submitting your deletion request.",
  "deactivate_account_modal_state_label": "Which state do you reside?",
  "deactivate_account_modal_state_placeholder": "Select",
  "deactivate_account_modal_reason_label": "Reason for account deletion?",
  "deactivate_account_modal_reason_placeholder": "Select",
  "deactivate_account_modal_delete_description": "Deleting your account means you will no longer be able to use your KAVAVA account and your account will be permanently shut down.",
  "deactivate_account_modal_cancel_button_label": "Cancel",
  "deactivate_account_modal_next_button_label": "Next",
  "deactivate_account_modal_back_button_label": "Back",
  "deactivate_account_modal_delete_button_label": "Delete",
  "rentals_page_filter_land_select_placeholder": "All Properties",
  "rentals_page_filter_button_label": "Filter",
  "rentals_page_filter_select_placeholder": "All Rental",
  "rentals_page_filter_date_21_14_days_before": "Rental date 21-14 days before",
  "rentals_page_filter_date_within_14_days": "Rental date within 14 days",
  "rentals_filter_dates_type_booking_date_label": "Booking date",
  "rentals_filter_dates_type_rental_date_label": "Rental date",
  "rentals_filter_keyword_type_item_name_label": "Item name",
  "rentals_filter_keyword_type_visitor_name_label": "Visitor’s name",
  "rentals_filter_keyword_type_visitor_email_label": "Visitor’s email",
  "rentals_filter_drawer_title": "Filter",
  "rentals_filter_drawer_date_label": "Staying date",
  "rentals_filter_drawer_date_start_at_placeholder": "Start date",
  "rentals_filter_drawer_date_end_at_placeholder": "End date",
  "rentals_filter_drawer_keyword_label": "Search",
  "rentals_filter_drawer_keyword_placeholder": "Enter visitor’s name",
  "rentals_filter_drawer_confirm_button_label": "Apply",
  "rental_card_start_at_label": "Rent from: ",
  "rental_card_end_at_label": "Rent until: ",
  "rental_card_consumer_label": "Visitor: ",
  "rental_card_shopping_button_label": "Shipping",
  "rental_card_detail_button_label": "Detail",
  "payouts_page_check_stripe_button_label": "Check payout information",
  "payouts_page_set_stripe_button_label": "Set payout information",
  "payouts_page_no_stripe_label": "to start receiving your payouts",
  "payouts_page_booking_tab_title": "Leasing income",
  "payouts_page_booking_tab_paid_total_label": "Total credited (USD)",
  "payouts_page_booking_tab_payable_total_label": "Total unpaid (USD)",
  "payouts_page_booking_tab_payout_date_label": "Period",
  "payouts_page_booking_tab_payout_paid_label": "Credited (USD)",
  "payouts_page_booking_tab_payout_payable_label": "Unpaid  (USD)",
  "payouts_page_rental_tab_title": "Rental income",
  "payouts_page_rental_tab_paid_total_label": "Total credited (USD)",
  "payouts_page_rental_tab_payable_total_label": "Total unpaid (USD)",
  "payouts_page_rental_tab_payout_date_label": "Credit date",
  "payouts_page_rental_tab_payout_label": "Credited amount (USD)",
  "payout_details_page_booking_paids_title": "Credited leasing income",
  "payout_details_page_booking_payables_title": "Unpaid leasing income",
  "payout_details_page_rental_paids_title": "Credited rental income",
  "payout_details_page_rental_payables_title": "Unpaid rental income",
  "payout_details_page_rental_subtitle_label": "breakdown:",
  "payout_details_page_booking_subtitle_label": "breakdown",
  "payout_details_page_select_land_placeholder": "All properties",
  "calendar_page_business_day_status_available": "Vacant",
  "calendar_page_business_day_status_un_available": "Occupied",
  "calendar_page_business_day_status_booking": "Reserved",
  "calendar_page_business_day_status_final": "Unavailable",
  "calendar_page_business_day_status_label": "Availability Status",
  "calendar_page_business_day_tag_label": "Notes",
  "calendar_page_business_day_tag_placeholder": "Enter the notes you wish to add.",
  "calendar_page_business_day_tag_modal_delete_button_label": "Delete",
  "calendar_page_business_day_tag_modal_edit_button_label": "Save",
  "calendar_page_business_day_tag_modal_cancel_button_label": "Cancel",
  "calendar_page_business_day_tag_modal_create_button_label": "Add",
  "calendar_page_business_day_card_tags_more_label": "more",
  "calendar_page_more_button_batch_available_label": "Update availability",
  "calendar_page_more_button_batch_tag_label": "Update notes",
  "calendar_batch_available_modal_description": "Only dates without future reservations can be successfully set as Vacant or Occupied.",
  "calendar_batch_available_modal_date_label": "Update availability for following period",
  "calendar_batch_available_modal_un_available_button_label": "Occupied",
  "calendar_batch_available_modal_available_button_label": "Vacant",
  "calendar_batch_tag_modal_date_label": "Update notes for following period.",
  "calendar_batch_tag_modal_tag_label": "Note",
  "calendar_batch_tag_modal_tag_placeholder": "Enter the notes you wish to update. \n(If you choose to delete, only exact matches can be removed.)",
  "calendar_batch_tag_modal_delete_button_label": "Delete",
  "calendar_batch_tag_modal_add_button_label": "Add",
  "get_user_profile_403_title": "Account deletion in progress",
  "get_user_profile_403_content": "Your account deletion is scheduled for permanent deletion. If you have any questions, please contact us.",
  "post_user_exists_403_title": "Account deletion in progress",
  "post_user_exists_403_content": "Your account deletion is scheduled for permanent deletion. If you have any questions, please contact us.",
  "post_user_resend_auth_phone_400_title": "Send verification code failed",
  "post_user_resend_auth_phone_400_content": "Please check your phone number and try again.",
  "post_user_resend_auth_phone_409_title": "Can't use this phone number",
  "post_user_resend_auth_phone_409_content": "The phone number has been bound to another account, please use another phone number.",
  "post_user_resend_auth_phone_412_title": "Send verification code failed",
  "post_user_resend_auth_phone_412_content": "Please check your phone number and try again.",
  "post_user_login_404_title": "Login failed",
  "post_user_login_404_content": "Account or password is incorrect, please confirm and log in again.",
  "get_booking_detail_403_title": "No view permission",
  "get_booking_detail_403_content": "Please login the corrected account to view this booking.",
  "post_create_land_error_400_content": "The address as submitted could not be found. Please check for excessive abbreviations in the street address line or in the City name.",
  "put_land_status_listed_412": "Please complete your Account- Personal Info prior to listing your property."
};
