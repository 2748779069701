import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import ActionDrawer from 'src/components/ActionDrawer';
import ActionDrawerHeader from 'src/components/ActionDrawer/components/ActionDrawerHeader';
import ResetButton from 'src/components/ResetButton';
import Loading from 'src/components/Loading';
import Address from '../../../CreateLandDrawer/components/Address';
import Position from '../../../CreateLandDrawer/components/Position';
import Description from '../../../CreateLandDrawer/components/Description';
import Steps from '../../../CreateLandDrawer/components/Steps';
import { PAGES } from './viewModel';
import styles from './styles.module.scss';

@observer
class LandMeetDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  renderPage = () => {
    switch (this.vm.page) {
      case PAGES.address: {
        return <Address vm={this.vm.addressVM} land={this.vm.formatLand} />;
      }

      case PAGES.position: {
        return (
          <Position
            vm={this.vm.positionVM}
            land={this.vm.formatLand}
            type={this.vm.positionType}
          />
        );
      }

      case PAGES.description: {
        return (
          <Description
            vm={this.vm.descriptionVM}
            land={this.vm.formatLand}
            label={i18n.t(
              'land_meet_drawer_description_page_description_label'
            )}
            placeholder={i18n.t(
              'land_meet_drawer_description_page_description_placeholder'
            )}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  renderFooter = () => {
    switch (this.vm.page) {
      case PAGES.address: {
        return (
          <div className={styles.footer}>
            <span />

            <ResetButton
              className={clsx(styles.button, styles.next)}
              type="primary"
              onClick={this.vm.onNext}
            >
              {i18n.t('land_meet_drawer_next_button_label')}
            </ResetButton>
          </div>
        );
      }

      case PAGES.position: {
        return (
          <div className={styles.footer}>
            <ResetButton
              className={clsx(styles.button, styles.back)}
              onClick={this.vm.onPrev}
            >
              {i18n.t('land_meet_drawer_prev_button_label')}
            </ResetButton>

            <ResetButton
              className={clsx(styles.button, styles.next)}
              type="primary"
              onClick={this.vm.onNext}
            >
              {i18n.t('land_meet_drawer_next_button_label')}
            </ResetButton>
          </div>
        );
      }

      case PAGES.description: {
        return (
          <div className={styles.footer}>
            <ResetButton
              className={clsx(styles.button, styles.back)}
              onClick={this.vm.onPrev}
            >
              {i18n.t('land_meet_drawer_prev_button_label')}
            </ResetButton>

            <ResetButton
              className={clsx(styles.button, styles.done)}
              type="primary"
              onClick={this.vm.onDone}
            >
              {i18n.t('land_meet_drawer_done_button_label')}
            </ResetButton>
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  render() {
    return (
      <>
        <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
          <div className={styles.container}>
            <div className={styles.header}>
              <ActionDrawerHeader content={this.vm.labels.title} />
            </div>

            <div className={styles.steps}>
              <Steps steps={this.vm.steps.steps} step={this.vm.steps.step} />
            </div>

            <div className={styles.page}>{this.renderPage()}</div>

            {this.renderFooter()}
          </div>
        </ActionDrawer>

        {this.vm.isAwait && <Loading />}
      </>
    );
  }
}

LandMeetDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

LandMeetDrawer.defaultProps = {};

export default LandMeetDrawer;
