import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { message } from 'antd';
import i18n from 'src/i18n';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import { formatPrice } from 'src/constants/price';
import { formatDiscount, DISCOUNTS } from 'src/constants/land';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';

class DiscountsViewModel {
  @observable land = null;
  @observable discounts = [];
  @observable isAwait = false;

  form = null;
  changed = null;

  addModalVM = new ActionModalViewModel();

  @computed
  get checks() {
    return {
      land: !!this.land,
      addButton: !!this.land && this.land?.discounts?.length < 5
    };
  }

  @computed
  get mapDiscounts() {
    const res = [];
    const unit = i18n.t('land_edit_drawer_discounts_add_modal_ratio_off_label');

    Object.values(DISCOUNTS).forEach((item) => {
      const find = this.discounts.find((j) => item.value === j.name);

      if (find) {
        const label = DISCOUNTS[find.name].label;
        const discount = formatDiscount.toPercentage(find.discount);

        res.push({
          id: find.id,
          label: `${label} (${discount}% ${unit})`
        });
      }
    });

    return res;
  }

  @computed
  get disabled() {
    return {
      delete: this.isAwait,
      form: this.isAwait,
      confirm: this.isAwait
    };
  }

  @computed
  get rules() {
    const required = {
      required: true,
      message: FORM_INPUT_REQUIRED_MESSAGE
    };

    return {
      discount: [required],
      ratio: [
        required,
        {
          message: FORM_INPUT_REQUIRED_MESSAGE,
          validator: (_, v) =>
            v === '0' ? Promise.reject() : Promise.resolve()
        }
      ]
    };
  }

  @computed
  get basicFee() {
    const label = i18n.t(
      'land_edit_drawer_discounts_page_main_label_basic_fee_label'
    );
    const price = formatPrice(this.land.basicFee, true);

    return `${label} ${price}`;
  }

  @computed
  get extraFee() {
    const label = i18n.t(
      'land_edit_drawer_discounts_page_main_label_extra_fee_label'
    );
    const check = !!this.land.extraHunters;
    const price = check ? formatPrice(this.land.extraFee, true) : '';
    const notSet = i18n.t('land_detail_drawer_null_item_placeholder');

    return check ? `${label} ${price}` : `${label} ${notSet}`;
  }

  @computed
  get options() {
    const discounts = Object.values(DISCOUNTS).filter((discount) => {
      return !this.discounts.find((item) => item.name === discount.value);
    });

    return {
      discounts
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = ({ land, form, changed }) => {
    this.land = land;
    this.discounts = land.discounts;

    this.form = form;
    this.changed = changed;
  };

  onAdd = () => {
    this.form.resetFields();
    this.addModalVM.open();
  };

  onDelete = (id) => {
    this.deleteLandSpecialDateAPI(id);
  };

  onModalConfirm = () => {
    this.postLandDiscountAPI();
  };

  @action
  postLandDiscountAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();
      const res = await LandServices.postLandDiscount({
        id: this.land.id,
        type: 'longTerm',
        name: data.name,
        discount: formatDiscount.toRatio(data.ratio)
      });

      await Promise.all([this.changed(), this.getLandDetailAPI()]);

      this.addModalVM.close();
    } catch (error) {
      switch (error.response?.status) {
        case 412: {
          const msg = i18n.t(
            'land_edit_drawer_discounts_add_modal_post_land_discount_error_message_412'
          );
          message.warning(msg, 5);
          break;
        }

        default:
          break;
      }
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  // 刪除特殊日期定價.
  @action
  deleteLandSpecialDateAPI = async (did) => {
    this.isAwait = true;

    try {
      await LandServices.deleteLandDiscount({
        id: this.land.id,
        did
      });

      await Promise.all([this.changed(), this.getLandDetailAPI()]);
    } catch (error) {
      const msg = ErrorServices.deleteLandSpecialDate(error);

      console.log('deleteLandSpecialDate', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  getLandDetailAPI = async () => {
    try {
      const res = await LandServices.getLandDetail({ id: this.land.id });
      runInAction(() => {
        this.land = res.data;
        this.discounts = res.data.discounts;
      });
    } catch (error) {
      const msg = ErrorServices.getLandDetail(error);
      console.log('getLandDetail', msg);
    }
  };
}

export default DiscountsViewModel;
