import apis from 'src/apis';
import Auth from './auth';

class CalendarServices {
  static async getCalendarLandTags({ id, date }) {
    const res = await apis.calendar.getCalendarLandTags({
      token: Auth.getToken(),
      id,
      date
    });

    return {
      data: res.data.days.map((item) => {
        return {
          date: item.date,
          tags: item.tags
        };
      })
    };
  }

  static async postCalendarLandTags({ id, startAt, endAt, tags }) {
    const res = await apis.calendar.postCalendarLandTags({
      token: Auth.getToken(),
      id,
      startAt,
      endAt,
      tags
    });

    return {
      data: res.data
    };
  }

  static async deleteCalendarLandTags({ id, startAt, endAt, tags }) {
    const res = await apis.calendar.deleteCalendarLandTags({
      token: Auth.getToken(),
      id,
      startAt,
      endAt,
      tags
    });

    return {
      data: res.data
    };
  }

  static async getCalendarLandTagsSearch({ id, date }) {
    const res = await apis.calendar.getCalendarLandTagsSearch({
      token: Auth.getToken(),
      id,
      date
    });

    return {
      data: res.data.tags
    };
  }
}

export default CalendarServices;
