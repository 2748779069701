import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import i18n from 'src/i18n';
import { BUSINESS_DAY_KEY_FORMAT } from 'src/constants/calendar';
import ICONS from 'src/constants/icons';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';
import CalendarServices from 'src/services/CalendarServices';
import BusinessDayCardViewModel from './components/BusinessDayCard/viewModel';
import BusinessDayDrawerViewModel from './components/BusinessDayDrawer/viewModel';
import CalendarBatchAvailableModalViewModel from './components/CalendarBatchAvailableModal/viewModel';
import CalendarBatchTagModalViewModel from './components/CalendarBatchTagModal/viewModel';
import calendarStore from './modules/CalendarStore';

class CalendarPageViewModel {
  @observable profile = null;

  @observable lands = [];
  @observable landId = null;

  @observable date = dayjs();

  @observable businessDays = {};
  @observable tags = {};

  @observable inited = false;
  @observable isAwait = false;

  businessDayDrawerVM = new BusinessDayDrawerViewModel();
  batchAvailableModalVM = new CalendarBatchAvailableModalViewModel();
  calendarBatchTagModalVM = new CalendarBatchTagModalViewModel();

  @computed
  get check() {
    return {
      lands: !!this.lands.length
    };
  }

  @computed
  get formats() {
    return {
      datePicker: 'MMM, YYYY'
    };
  }

  @computed
  get disabled() {
    return {
      filter: this.isAwait,
      calendar: this.isAwait,
      businessDayDrawer: this.isAwait
    };
  }

  @computed
  get findLand() {
    return this.landId
      ? this.lands.find((item) => item.id === this.landId)
      : null;
  }

  @computed
  get findBusinessDay() {
    const key = this.date.format(BUSINESS_DAY_KEY_FORMAT);
    return this.businessDays[key] || null;
  }

  @computed
  get options() {
    return {
      lands: this.lands.map((item) => {
        return { value: item.id, label: item.name };
      })
    };
  }

  get moreItems() {
    return [
      {
        icon: ICONS.calendarBatchAvailable,
        label: i18n.t('calendar_page_more_button_batch_available_label'),
        onClick: this.onBatchAvailableModal
      },
      {
        icon: ICONS.calendarBatchTag,
        label: i18n.t('calendar_page_more_button_batch_tag_label'),
        onClick: this.onBatchTagModal
      }
    ];
  }

  constructor(props) {
    this.profile = props.profile;

    // 這樣寫可以避免 mobx 警告
    calendarStore.setRefresh(async () => {
      await this.reset();
    });
    calendarStore.setBusinessDaysChanged(async () => {
      await this.resetBusinessDays();
    });
    calendarStore.setTagsChanged(async () => {
      await this.resetTags();
    });

    makeObservable(this);
  }

  @action
  didMount = async () => {
    this.isAwait = true;

    await this.getLandListAPI();
    await this.getLandBusinessDayAPI();
    await this.getCalendarLandTagsAPI();

    runInAction(() => {
      this.inited = true;
      this.isAwait = false;
    });
  };

  onBatchAvailableModal = () => {
    this.batchAvailableModalVM.open({
      land: this.findLand,
      changed: this.resetBusinessDays
    });
  };

  onBatchTagModal = () => {
    this.calendarBatchTagModalVM.open({
      land: this.findLand,
      changed: this.resetTags
    });
  };

  @action
  onLandIdChange = (val) => {
    this.landId = val;
    this.reset();
  };

  @action
  onDatePickerChange = (val) => {
    this.date = val;
    this.reset();
  };

  @action
  onBusinessDayDrawer = (vm) => {
    this.date = vm.dayIns;
    this.businessDayDrawerVM.open();
  };

  @action
  onMonthPrev = () => {
    this.date = this.date.clone().subtract(1, 'month');
    this.reset();
  };

  @action
  onMonthNext = () => {
    this.date = this.date.clone().add(1, 'month');
    this.reset();
  };

  @action
  onDatePrev = () => {
    const dateClone = this.date.clone().subtract(1, 'day');
    const check = this.date.format('YYYY-MM') === dateClone.format('YYYY-MM');

    this.date = dateClone;

    if (!check) {
      this.reset();
    }
  };

  @action
  onDateNext = () => {
    const dateClone = this.date.clone().add(1, 'day');
    const check = this.date.format('YYYY-MM') === dateClone.format('YYYY-MM');

    this.date = dateClone;

    if (!check) {
      this.reset();
    }
  };

  @action
  reset = async () => {
    this.isAwait = true;

    this.businessDays = {};
    this.tags = {};

    await this.getLandBusinessDayAPI();
    await this.getCalendarLandTagsAPI();

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  resetBusinessDays = async () => {
    this.isAwait = true;

    this.businessDays = {};

    await this.getLandBusinessDayAPI();

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  resetTags = async () => {
    this.isAwait = true;

    this.tags = {};

    await this.getCalendarLandTagsAPI();

    runInAction(() => {
      this.isAwait = false;
    });
  };

  getLandListAPI = async () => {
    try {
      const res = await LandServices.getLandList();

      runInAction(() => {
        this.lands = res.data;
        this.landId = res.data[0].id;
      });
    } catch (error) {
      const msg = ErrorServices.getLandList(error);
      console.log('getLandList', msg);
    }
  };

  getLandBusinessDayAPI = async () => {
    try {
      const res = await LandServices.getLandBusinessDay({
        id: this.landId,
        date: this.date.format('YYYY-MM')
      });

      runInAction(() => {
        const firstDay = this.date.clone().startOf('month');

        this.businessDays = res.data.reduce((businessDays, item, index) => {
          const businessDaysClone = { ...businessDays };
          const date = firstDay
            .add(index, 'day')
            .format(BUSINESS_DAY_KEY_FORMAT);

          businessDaysClone[date] = new BusinessDayCardViewModel({
            ...item,
            date // 使用本地時區
          });

          return businessDaysClone;
        }, {});
      });
    } catch (error) {
      const msg = ErrorServices.getCalendarLandTags(error);
      console.log('getCalendarLandTags', msg);
    }
  };

  getCalendarLandTagsAPI = async () => {
    try {
      const res = await CalendarServices.getCalendarLandTags({
        id: this.landId,
        date: this.date.format('YYYY-MM')
      });

      runInAction(() => {
        const firstDay = this.date.clone().startOf('month');

        this.tags = res.data.reduce((tags, item, index) => {
          const tagsClone = { ...tags };
          const date = firstDay
            .add(index, 'day')
            .format(BUSINESS_DAY_KEY_FORMAT);

          tagsClone[date] = item.tags;

          return tagsClone;
        }, {});
      });
    } catch (error) {
      const msg = ErrorServices.getCalendarLandTags(error);
      console.log('getCalendarLandTags', msg);
    }
  };
}

export default CalendarPageViewModel;
