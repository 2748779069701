import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { message } from 'antd';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';
import LaunchLandViewModel from 'src/components/LaunchLand/viewModel';
import { LAND_STATUS } from 'src/constants/land';
import StatusViewModel from './components/Status/viewModel';
import RulesViewModel from './components/Rules/viewModel';
import FinalBookingDateViewModel from './components/FinalBookingDate/viewModel';
import SpecialDatesViewModel from './components/SpecialDates/viewModel';
import DiscountsViewModel from './components/Discounts/viewModel';
import PriceAlertModalViewModel from './components/PriceAlertModal/viewModel';
import PhotosViewModel from '../../../CreateLandDrawer/components/Photos/viewModel';
import BasicInfoViewModel from '../../../CreateLandDrawer/components/BasicInfo/viewModel';
import DescriptionViewModel from '../../../CreateLandDrawer/components/Description/viewModel';
import PositionViewModel from '../../../CreateLandDrawer/components/Position/viewModel';
import PricesViewModel from '../../../CreateLandDrawer/components/Prices/viewModel';

export const PAGES = {
  status: 'status',
  finalBookingDate: 'finalBookingDate',
  position: 'position',
  photos: 'photos',
  basicInfo: 'basicInfo',
  description: 'description',
  rules: 'rules',
  prices: 'prices',
  specialDates: 'specialDates',
  discounts: 'discounts'
};

class LandEditDrawerViewModel {
  @observable land = null;
  @observable page = null;
  @observable isAwait = false;

  statusVM = new StatusViewModel();
  photosVM = new PhotosViewModel();
  basicInfoVM = new BasicInfoViewModel();
  descriptionVM = new DescriptionViewModel();
  positionVM = new PositionViewModel();
  rulesVM = new RulesViewModel();
  finalBookingDateVM = new FinalBookingDateViewModel();
  pricesVM = new PricesViewModel();
  specialDatesVM = new SpecialDatesViewModel();
  discountsVM = new DiscountsViewModel();

  drawerVM = new ActionDrawerViewModel();

  priceAlertModalVM = new PriceAlertModalViewModel();

  launchLandVM = new LaunchLandViewModel();

  changed = async () => {};

  @computed
  get disabled() {
    const checkPriceChange
      = this.page === PAGES.prices && this.pricesVM.disabled.change;

    return {
      confirm: this.isAwait || checkPriceChange
    };
  }

  @computed
  get footer() {
    switch (this.page) {
      case PAGES.status:
      case PAGES.finalBookingDate:
      case PAGES.position:
      case PAGES.photos:
      case PAGES.basicInfo:
      case PAGES.description:
      case PAGES.rules:
      case PAGES.prices: {
        return true;
      }

      case PAGES.specialDates:
      case PAGES.discounts: {
        return false;
      }

      default:
        return false;
    }
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({ land, changed = async () => {} }) => {
    this.land = land;
    this.changed = changed;

    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  @action
  toPage = (val) => {
    this.page = val;
  };

  @action
  launched = async ({ status }) => {
    if (status) {
      await this.changed({ status });
      this.close();
    } else {
      this.statusVM.setStatus(this.land.status);
    }
  };

  @action
  onConfirm = async () => {
    this.isAwait = true;

    try {
      switch (this.page) {
        case PAGES.status: {
          const res = this.statusVM.validateFields();

          switch (res.status) {
            case LAND_STATUS.listed.value: {
              await this.launchLandVM.launch({
                land: this.land,
                changed: this.launched
              });
              break;
            }

            case LAND_STATUS.unlisted.value: {
              await this.putLandStatusAPI(LAND_STATUS.unlisted.value);
              break;
            }

            default:
              break;
          }

          break;
        }

        case PAGES.photos: {
          const res = await this.photosVM.validateFields();
          await this.changed(res);
          this.close();
          break;
        }

        case PAGES.basicInfo: {
          const res = await this.basicInfoVM.validateFields();
          await this.putUpdateLandInfoAPI(res);
          break;
        }

        case PAGES.description: {
          const res = await this.descriptionVM.validateFields();
          await this.putUpdateLandInfoAPI(res);
          break;
        }

        case PAGES.position: {
          const res = await this.positionVM.validateFields();
          await this.putUpdateLandInfoAPI(res);
          break;
        }

        case PAGES.rules: {
          const res = await this.rulesVM.validateFields();
          await this.putUpdateLandInfoAPI(res);
          break;
        }

        case PAGES.finalBookingDate: {
          const res = await this.finalBookingDateVM.validateFields();
          await this.putUpdateLandInfoAPI(res);
          break;
        }

        case PAGES.prices: {
          this.priceAlertModalVM.open({ confirm: this.onPriceAlertConfirm });
          break;
        }

        default:
          this.close();
          break;
      }
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  onPriceAlertConfirm = async () => {
    this.isAwait = true;

    const res = await this.pricesVM.validateFields();
    await this.putUpdateLandInfoAPI(res);

    runInAction(() => {
      this.isAwait = false;
      this.priceAlertModalVM.close();
    });
  };

  putLandStatusAPI = async (status) => {
    try {
      await LandServices.putLandStatus({
        id: this.land.id,
        status
      });

      await this.changed({ status });

      this.close();
    } catch (error) {
      const msg = ErrorServices.putLandStatus(error);

      switch (error.response?.status) {
        case 412: {
          message.error(msg, 5);
          break;
        }

        default:
          break;
      }

      console.log(msg);
    }
  };

  @action
  putUpdateLandInfoAPI = async (data) => {
    try {
      await LandServices.putUpdateLandInfo({
        ...data,
        id: this.land.id
      });

      await this.changed(data);

      this.close();
    } catch (error) {
      const msg = ErrorServices.getLandDetail(error);

      console.log('getLandDetail', msg);
    }
  };
}

export default LandEditDrawerViewModel;
