import dayjs from 'dayjs';

class BusinessDayDataModel {
  constructor(props) {
    this.date = props.date;

    this.isAvailable = props.isAvailable;

    // 地主是否有手動切換這一天的狀態
    // null = 沒有手動切換過
    // false || true = 手動切換過的狀態
    this.isManualOpened = props.isManualOpened;

    this.ratio = props.ratio;

    this.booking = props.booking ? {
      id: props.booking?.id,

      // 基本費用.
      basicFee: props.booking?.basicFee,

      // 預約起始時間與結束時間.
      startAt: props.booking?.startAt,
      endAt: props.booking?.endAt,

      // 預約的獵人.
      consumer: {
        id: props.booking?.consumer?.id,
        firstName: props.booking?.consumer?.firstName,
        lastName: props.booking?.consumer?.lastName,
        displayName: props.booking?.consumer?.displayName
      },

      // 特殊日售價.
      specialDates: {
        id: props.booking?.specialDates?.id,
        startAt: props.booking?.specialDates?.startAt,
        endAt: props.booking?.specialDates?.endAt,
        ratio: props.booking?.specialDates?.ratio
      }
    } : null;
  }
}

export default BusinessDayDataModel;
