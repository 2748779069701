import {
  makeObservable,
  observable,
  action
} from 'mobx';

class ActionModalViewModel {
  @observable isOpen = false;

  constructor() {
    makeObservable(this);
  }

  @action
  open = () => {
    this.isOpen = true;
  }

  @action
  close = () => {
    this.isOpen = false;
  }
}

export default ActionModalViewModel;
