import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input } from 'antd';
import i18n from 'src/i18n';
import ICONS from 'src/constants/icons';
import withForm from 'src/components/withForm';
import ActionDrawer from 'src/components/ActionDrawer';
import ActionDrawerHeader from 'src/components/ActionDrawer/components/ActionDrawerHeader';
import ActionDrawerItem from 'src/components/ActionDrawer/components/ActionDrawerItem';
import ActionModal from 'src/components/ActionModal';
import ActionModalAlertContainer from 'src/components/ActionModal/components/ActionModalAlertContainer';
import ActionModalDetailWarning from 'src/components/ActionModal/components/ActionModalDetailWarning';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetInput from 'src/components/ResetInput';
import ResetSelect from 'src/components/ResetSelect';
import ResetInputNumber from 'src/components/ResetInputNumber';
import ResetButton from 'src/components/ResetButton';
import styles from './styles.module.scss';

@observer
class PackageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ form: props.form });
  }

  renderForm = () => {
    return (
      <Form
        className={styles.form}
        form={this.vm.form}
        disabled={this.vm.disabled.form}
      >
        <div className={styles.item}>
          <div className={styles.label}>
            {i18n.t('package_drawer_name_label')}
          </div>

          <ResetFormItem name="name" rules={this.vm.rules} errorMessageAbsolute>
            <ResetInput
              maxLength={50}
              showCount
              countAbsolute
              placeholder={i18n.t('package_drawer_name_placeholder')}
            />
          </ResetFormItem>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>
            {i18n.t('package_drawer_type_label')}
          </div>

          <ResetFormItem name="type" rules={this.vm.rules} errorMessageAbsolute>
            <ResetSelect
              options={this.vm.options.types}
              placeholder={i18n.t('package_drawer_type_placeholder')}
            />
          </ResetFormItem>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>
            {i18n.t('package_drawer_price_label')}
          </div>

          <ResetFormItem
            name="price"
            rules={this.vm.rules}
            errorMessageAbsolute
          >
            <ResetInputNumber
              className={styles.input}
              prefix="US$"
              precision={2}
              controls={false}
              stringMode
              min={0}
              placeholder={i18n.t('package_drawer_price_placeholder')}
            />
          </ResetFormItem>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>
            {i18n.t('package_drawer_description_label')}
          </div>

          <ResetFormItem
            name="description"
            rules={this.vm.rules}
            errorMessageAbsolute
          >
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 6 }}
              maxLength={500}
              showCount
              placeholder={i18n.t('package_drawer_description_placeholder')}
            />
          </ResetFormItem>
        </div>
      </Form>
    );
  };

  renderReadOnly = () => {
    return (
      <div className={styles.readOnly}>
        <ActionDrawerItem label={i18n.t('package_drawer_name_label')} divider>
          {this.vm.labels.name}
        </ActionDrawerItem>

        <ActionDrawerItem label={i18n.t('package_drawer_type_label')} divider>
          {this.vm.labels.type}
        </ActionDrawerItem>

        <ActionDrawerItem label={i18n.t('package_drawer_price_label')} divider>
          {this.vm.labels.price}
        </ActionDrawerItem>

        <ActionDrawerItem
          label={i18n.t('package_drawer_description_label')}
          divider
        >
          <div className={styles.description}>{this.vm.labels.description}</div>
        </ActionDrawerItem>
      </div>
    );
  };

  render() {
    return (
      <>
        <ActionDrawer
          vm={this.vm.drawerVM}
          width={375}
          onClose={this.vm.close}
          disabled={this.vm.disabled.form}
        >
          <div className={styles.container}>
            <ActionDrawerHeader content={this.vm.header} paddingBottom />

            {this.vm.check.form ? this.renderForm() : this.renderReadOnly()}

            {this.vm.check.footer ? (
              <div className={styles.footer}>
                {this.vm.footer.delete && (
                  <ResetButton
                    className={styles.button}
                    type="primary"
                    ghost
                    danger
                    onClick={this.vm.deleteModalVM.open}
                    disabled={this.vm.disabled.footer}
                  >
                    {i18n.t('package_drawer_delete_button_label')}
                  </ResetButton>
                )}

                {this.vm.footer.edit && (
                  <ResetButton
                    className={styles.button}
                    onClick={this.vm.onEdit}
                    disabled={this.vm.disabled.footer}
                  >
                    {i18n.t('package_drawer_edit_button_label')}
                  </ResetButton>
                )}

                {this.vm.footer.cancel && (
                  <ResetButton
                    className={styles.button}
                    onClick={this.vm.onCancel}
                    disabled={this.vm.disabled.footer}
                  >
                    {i18n.t('package_drawer_cancel_button_label')}
                  </ResetButton>
                )}

                {this.vm.footer.save && (
                  <ResetButton
                    className={styles.button}
                    type="primary"
                    onClick={this.vm.onSave}
                    disabled={this.vm.disabled.footer}
                  >
                    {i18n.t('package_drawer_save_button_label')}
                  </ResetButton>
                )}

                {this.vm.footer.create && (
                  <ResetButton
                    className={styles.button}
                    type="primary"
                    onClick={this.vm.onCreate}
                    disabled={this.vm.disabled.footer}
                  >
                    {i18n.t('package_drawer_create_button_label')}
                  </ResetButton>
                )}
              </div>
            ) : null}
          </div>

          <ActionModal
            vm={this.vm.deleteModalVM}
            width={375}
            onCancel={this.vm.deleteModalVM.close}
          >
            <ActionModalAlertContainer>
              <ActionModalDetailWarning
                title={i18n.t('package_delete_modal_title')}
                content={i18n.t('package_delete_modal_content')}
                icon={ICONS.warningRed}
              />

              <ActionModalFooter
                cancelProps={{
                  onClick: this.vm.deleteModalVM.close,
                  children: i18n.t('package_delete_modal_cancel_button_label')
                }}
                confirmProps={{
                  type: 'primary',
                  danger: true,
                  onClick: this.vm.onDelete,
                  children: i18n.t('package_delete_modal_confirm_button_label'),
                  disabled: this.vm.disabled.footer
                }}
              />
            </ActionModalAlertContainer>
          </ActionModal>
        </ActionDrawer>
      </>
    );
  }
}

PackageDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

PackageDrawer.defaultProps = {};

export default withForm(PackageDrawer);
