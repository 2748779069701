import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { uuidv4 } from 'src/utils';
import { routerMethods } from 'src/constants/methods';
import { formatPrice } from 'src/constants/price';
import { ROUTES, PARAMS } from 'src/constants/routes';
import { PAYOUT_TYPES, PAYOUT_STATUS } from 'src/constants/payout';
import { LIST_LIMIT } from 'src/constants/config';
import RentalServices from 'src/services/RentalServices';
import ErrorServices from 'src/services/ErrorServices';
import PayoutViewModel from './viewModels/PayoutViewModel';

class RentalPayoutsViewModel {
  @observable paids = [];
  @observable statistics = {
    paid: {
      startAt: null,
      endAt: null,
      total: '0'
    },
    payable: {
      startAt: null,
      endAt: null,
      total: '0'
    }
  };

  @observable inited = false;
  @observable isAwait = false;

  anchor = undefined;

  router = null;

  @computed
  get totals() {
    return [
      {
        id: uuidv4(),
        paid: formatPrice(this.statistics.paid.total, true),
        payable: formatPrice(this.statistics.payable.total, true)
      }
    ];
  }

  constructor(props) {
    this.router = props.router;
    makeObservable(this);
  }

  didMount = async () => {
    await Promise.all([
      this.getPayoutPaidDateListAPI(),
      this.getRentalPayoutStatisticsAPI()
    ]);

    runInAction(() => {
      this.inited = true;
    });
  };

  onWaypointEnter = () => {
    if (this.inited && !this.isAwait && this.anchor === undefined) {
      this.getPayoutPaidDateListAPI();
    }
  };

  onPaid = (payout) => {
    const route = `/${ROUTES.payoutDetails.value}`;
    const search = routerMethods.createSearch({
      [PARAMS.payoutType]: PAYOUT_TYPES.rental,
      [PARAMS.payoutStatus]: PAYOUT_STATUS.paid,
      [PARAMS.payoutId]: payout.id
    });

    this.router.navigate({ pathname: route, search });
  };

  onPaidTotal = () => {
    const route = `/${ROUTES.payoutDetails.value}`;
    const search = routerMethods.createSearch({
      [PARAMS.payoutType]: PAYOUT_TYPES.rental,
      [PARAMS.payoutStatus]: PAYOUT_STATUS.paid
    });

    this.router.navigate({ pathname: route, search });
  };

  onPayableTotal = () => {
    const route = `/${ROUTES.payoutDetails.value}`;
    const search = routerMethods.createSearch({
      [PARAMS.payoutType]: PAYOUT_TYPES.rental,
      [PARAMS.payoutStatus]: PAYOUT_STATUS.payable
    });

    this.router.navigate({ pathname: route, search });
  };

  @action
  getPayoutPaidDateListAPI = async () => {
    this.isAwait = true;

    try {
      const res = await RentalServices.getPayoutPaidDateList({
        limit: LIST_LIMIT,
        anchor: this.anchor
      });

      runInAction(() => {
        this.paids = res.data.map((item) => new PayoutViewModel(item));
        this.anchor = res.anchor;
      });
    } catch (error) {
      const msg = ErrorServices.getPayoutPaidDateList(error);
      console.log('getPayoutPaidDateList', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  getRentalPayoutStatisticsAPI = async () => {
    this.isAwait = true;

    try {
      const res = await RentalServices.getRentalPayoutStatistics({
        lanId: undefined
      });

      runInAction(() => {
        this.statistics = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getRentalPayoutStatistics(error);
      console.log('getRentalPayoutStatistics', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default RentalPayoutsViewModel;
