class RentalParcelListItemDataModel {
  constructor(props) {
    this.id = props.id;

    this.addressFromId = props.addressFromId;

    this.addressTo = {
      id: props.addressTo.id,
      isResidential: props.addressTo.isResidential
    };

    this.isLocked = props.isLocked;

    this.items = props.items.map((item) => {
      const itemCopy = item.itemCopy;
      const itemVariety = item.itemCopy.itemVariety;
      const sourceItem = itemVariety.item;

      return {
        id: item.id,
        report: item.report,
        status: item.status,
        itemCopy: {
          id: itemCopy.id,
          count: itemCopy.count,
          plan: itemCopy.plan,
          warehouseId: itemCopy.warehouseId,
          itemVariety: {
            id: itemVariety.id,
            customId: itemVariety.customId,
            name: itemVariety.name,
            thumbnail: itemVariety.thumbnail,
            item: {
              id: sourceItem.id,
              name: sourceItem.name
            }
          }
        }
      };
    });

    this.label = {
      id: props.label.id,
      labelUrl: props.label.labelUrl,
      rateAmount: props.label.rateAmount,
      rateCurrency: props.label.rateCurrency,
      rateId: props.label.rateId,
      rateProvider: props.label.rateProvider,
      servicelevelToken: props.label.servicelevelToken,
      shipmentId: props.label.shipmentId,
      trackingNumber: props.label.trackingNumber,
      trackingUrl: props.label.trackingUrl
    };

    this.name = props.name;

    this.rentalId = props.rentalId;

    this.report = props.report;

    this.returnLabel = {
      id: props.returnLabel.id,
      labelUrl: props.returnLabel.labelUrl,
      rateAmount: props.returnLabel.rateAmount,
      rateCurrency: props.returnLabel.rateCurrency,
      rateId: props.returnLabel.rateId,
      rateProvider: props.returnLabel.rateProvider,
      servicelevelToken: props.returnLabel.servicelevelToken,
      shipmentId: props.returnLabel.shipmentId,
      trackingNumber: props.returnLabel.trackingNumber,
      trackingUrl: props.returnLabel.trackingUrl
    };

    this.returnLabelId = props.returnLabelId;

    this.signature = props.signature;

    this.size = {
      length: props.size.length,
      width: props.size.width,
      height: props.size.height,
      distanceUnit: props.size.distanceUnit,
      weight: props.size.weight,
      massUnit: props.size.massUnit
    };

    this.status = props.status;

    this.warehouseId = props.warehouseId;
  }
}

export default RentalParcelListItemDataModel;
