import i18n from 'src/i18n';
import styles from './styles.module.scss';

function Games() {
  return (
    <section className={styles.box}>
      <div className={styles.list}>
        <div className={styles.wrapper6}>
          <div className={styles.wrapper6__item}>
            <img
              className={styles.icon}
              src={
                require('src/assets/findHunt/432c98d2d86763cba5fef46eca5d01a3.svg')
                  .default
              }
              alt="alt text"
            />
          </div>

          <div className={styles.wrapper5}>
            <div className={styles.col2}>
              <h2 className={styles.medium_title2}>
                {i18n.t('find_hunt_page_descriptions_item_1_title')}
              </h2>
              <h5 className={styles.highlights1}>
                {i18n.t('find_hunt_page_descriptions_item_1_content')}
              </h5>
            </div>
          </div>
        </div>

        <div className={styles.wrapper4}>
          <div className={styles.wrapper4__item}>
            <img
              className={styles.image2}
              src={
                require('src/assets/findHunt/c8183d4bdf032862031a6a151f73ec65.svg')
                  .default
              }
              alt="alt text"
            />
          </div>

          <div className={styles.wrapper5}>
            <div className={styles.col2}>
              <h2 className={styles.medium_title21}>
                {i18n.t('find_hunt_page_descriptions_item_2_title')}
              </h2>
              <h5 className={styles.highlights11}>
                {i18n.t('find_hunt_page_descriptions_item_2_content')}
              </h5>
            </div>
          </div>
        </div>

        <div className={styles.wrapper4}>
          <div className={styles.wrapper4__item1}>
            <img
              className={styles.image2}
              src={
                require('src/assets/findHunt/7a583a9a24bde64336b7ed280ad04c50.svg')
                  .default
              }
              alt="alt text"
            />
          </div>

          <div className={styles.wrapper51}>
            <div className={styles.col2}>
              <h2 className={styles.medium_title21}>
                {i18n.t('find_hunt_page_descriptions_item_3_title')}
              </h2>
              <h5 className={styles.highlights12}>
                {i18n.t('find_hunt_page_descriptions_item_3_content')}
              </h5>
            </div>
          </div>
        </div>

        <div className={styles.wrapper8}>
          <div className={styles.wrapper8__item}>
            <img
              className={styles.wrapper9}
              src={
                require('src/assets/findHunt/678ad5246dc2364010c1d6529d0cf2da.svg')
                  .default
              }
              alt="alt text"
            />
          </div>

          <div className={styles.wrapper5}>
            <div className={styles.col2}>
              <h2 className={styles.medium_title21}>
                {i18n.t('find_hunt_page_descriptions_item_4_title')}
              </h2>
              <h5 className={styles.highlights13}>
                {i18n.t('find_hunt_page_descriptions_item_4_content')}
              </h5>
            </div>
          </div>
        </div>

        <div className={styles.wrapper7}>
          <div className={styles.wrapper7__item}>
            <img
              className={styles.image3}
              src={
                require('src/assets/findHunt/abdaec96cfad0264daf6fd281660efc3.svg')
                  .default
              }
              alt="alt text"
            />
          </div>

          <div className={styles.wrapper5}>
            <div className={styles.col2}>
              <h2 className={styles.medium_title21}>
                {i18n.t('find_hunt_page_descriptions_item_5_title')}
              </h2>
              <h5 className={styles.highlights14}>
                {i18n.t('find_hunt_page_descriptions_item_5_content')}
              </h5>
            </div>
          </div>
        </div>

        <div className={styles.wrapper10}>
          <div className={styles.wrapper10__item}>
            <img
              className={styles.image6}
              src={
                require('src/assets/findHunt/6c1d38dc7a490812d1343ea0e4f1187d.svg')
                  .default
              }
              alt="alt text"
            />
          </div>

          <div className={styles.wrapper5}>
            <div className={styles.col2}>
              <h2 className={styles.medium_title21}>
                {i18n.t('find_hunt_page_descriptions_item_6_title')}
              </h2>
              <h5 className={styles.highlights15}>
                {i18n.t('find_hunt_page_descriptions_item_6_content')}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Games;
