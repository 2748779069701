import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import ActionDrawerViewModel from 'src/components/ActionDrawer/viewModel';

class BookingsFilterDrawerViewModel {
  drawerVM = new ActionDrawerViewModel();

  form = null;

  confirm = () => {};

  init = ({ form }) => {
    this.form = form;
  };

  open = ({ startAt = null, endAt = null, confirm = () => {} }) => {
    if (startAt && endAt) {
      this.form.setFieldsValue({ dates: [dayjs(startAt), dayjs(endAt)] });
    } else {
      this.form.setFieldsValue({ dates: null });
    }

    this.confirm = confirm;

    this.drawerVM.open();
  };

  close = () => {
    this.drawerVM.close();
  };

  reset = () => {
    this.form.resetFields();
  };

  onConfirm = () => {
    this.validateFields();
  };

  validateFields = async () => {
    const data = await this.form.validateFields();

    const startAt = data?.dates?.[0] || null;
    const endAt = data?.dates?.[1] || null;

    const keyword = data?.keyword ? data.keyword.trim() : null;

    const res = { startAt, endAt, keyword };

    this.confirm(res);
    this.close();
  };
}

export default BookingsFilterDrawerViewModel;
