import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { AppStateContext } from 'src/stores';
import { Divider } from 'antd';
import i18n from 'src/i18n';
import ICONS from 'src/constants/icons';
import ActionDrawer from 'src/components/ActionDrawer';
import ResetButton from 'src/components/ResetButton';
import BasicCard from 'src/components/BasicCard';
import CustomItem from '../CustomItem';
import ReplyModal from '../ReplyModal';
import styles from './styles.module.scss';

@observer
class MessageDrawer extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ type: props.type });
  }

  componentDidMount() {
    this.vm.didMount(this.context);
  }

  render() {
    return (
      <>
        <ActionDrawer
          vm={this.vm.drawerVM}
          width={375}
          onClose={this.vm.drawerVM.close}
        >
          {this.vm.check.message ? (
            <div className={styles.container}>
              <BasicCard border>
                <CustomItem
                  name={this.vm.user.name}
                  date={this.vm.user.date}
                  rating={this.vm.check.rating}
                  score={this.vm.score}
                  content={this.vm.user.content}
                />

                {this.vm.check.reply ? (
                  <>
                    <Divider />

                    <CustomItem
                      name={this.vm.owner.name}
                      date={this.vm.owner.date}
                      content={this.vm.owner.content}
                    />

                    <div className={styles.createReply}>
                      <ResetButton
                        className={styles.button}
                        type="primary"
                        onClick={this.vm.onReply}
                      >
                        <img src={ICONS.reply} alt="reply" />
                        <span>
                          {i18n.t(
                            'land_messages_drawer_message_reply_edit_button_label'
                          )}
                        </span>
                      </ResetButton>
                    </div>
                  </>
                ) : (
                  <div className={styles.createReply}>
                    <ResetButton
                      className={styles.button}
                      type="primary"
                      onClick={this.vm.onReply}
                    >
                      <img src={ICONS.reply} alt="reply" />
                      <span>
                        {i18n.t(
                          'land_messages_drawer_message_reply_button_label'
                        )}
                      </span>
                    </ResetButton>
                  </div>
                )}
              </BasicCard>
            </div>
          ) : null}
        </ActionDrawer>

        <ReplyModal vm={this.vm.replyModalVM} type={this.props.type} />
      </>
    );
  }
}

MessageDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

MessageDrawer.defaultProps = {};

export default MessageDrawer;
