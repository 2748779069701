class PayoutDataModel {
  constructor(data) {
    this.id = data.id;

    this.status = data.status;

    this.date = data.date;

    this.transactionsCount = data.transactionsCount;

    this.createdAt = data.createdAt;

    this.updatedAt = data.updatedAt;

    this.amount = data.amount;
  }
}

export default PayoutDataModel;
