import dayjs from 'dayjs';
import i18n from 'src/i18n';
import BigNumber from 'bignumber.js';
import styles from './styles.module.scss';

export class RentalCalc {
  static duration({ startAt, endAt, timezoneId = 'Asia/Taipei' }) {
    const start = dayjs(startAt).tz(timezoneId).startOf('date');
    const end = dayjs(endAt).tz(timezoneId).endOf('date');

    return end.diff(start, 'day') + 1;
  }

  static refund = ({ price, profitRatio }) => {
    const fee = price || '0';
    const ratio = profitRatio || '0';

    console.log('refund', 'price', price);
    console.log('refund', 'profitRatio', profitRatio);

    return {
      amount: new BigNumber(fee).multipliedBy(ratio).toString(),
      ratio
    };
  };

  static earning({
    copies,
    profitRatio,
    startAt,
    endAt,
    timezoneId
  }) {
    const duration = RentalCalc.duration({ startAt, endAt, timezoneId });
    const total = copies
      .reduce((t, item) => {
        const price = item.itemVariety.price;
        const count = item.itemVariety.count;
        const fee = new BigNumber(price).multipliedBy(count);

        return t.plus(fee);
      }, new BigNumber(0))
      .multipliedBy(duration);

    const res = total.multipliedBy(profitRatio);

    console.log('total', total.toString());
    console.log('res', res.toString());

    return res.toString();
  }

  // 有沒有退款
  static checkRefundEarning(refundRatio) {
    return refundRatio && new BigNumber(refundRatio).isLessThan(1);
  }

  static checkShowRentalEarning({ status }) {
    switch (status) {
      case RENTAL_STATUS.cancelledWithFullRefund.value:
      case RENTAL_STATUS.cancelledWithPartialRefund.value:
      case RENTAL_STATUS.cancelledWithoutCharging.value: {
        return false;
      }

      default:
        return true;
    }
  }
}

// 訂單狀態
export const RENTAL_STATUS = {
  unknown: {
    value: 'unknown',
    // 沒有找到
    label: i18n.t('rental_status_unknown_label')
  },

  waitingForShipping: {
    value: 'waitingForShipping',
    label: i18n.t('rental_status_waiting_for_shipping_label')
  },

  onTheWayToLand: {
    value: 'onTheWayToLand',
    label: i18n.t('rental_status_on_the_way_to_land_label')
  },

  deliveredLand: {
    value: 'deliveredLand',
    label: i18n.t('rental_status_delivered_land_label')
  },

  pickedUp: {
    value: 'pickedUp',
    label: i18n.t('rental_status_picked_up_label')
  },

  onTheWayToWarehouse: {
    value: 'onTheWayToWarehouse',
    label: i18n.t('rental_status_on_the_way_to_warehouse_label')
  },

  inspecting: {
    value: 'inspecting',
    label: i18n.t('rental_status_inspecting_label')
  },

  processing: {
    value: 'processing',
    // 未包裝可以當作尚未出貨
    label: i18n.t('rental_status_processing_label')
  },

  waitingForRefund: {
    value: 'waitingForRefund',
    label: i18n.t('rental_status_waiting_for_refund_label')
  },

  disputed: {
    value: 'disputed',
    label: i18n.t('rental_status_disputed_label')
  },

  completed: {
    value: 'completed',
    label: i18n.t('rental_status_completed_label')
  },

  cancelledWithoutCharging: {
    value: 'cancelledWithoutCharging',
    label: i18n.t('rental_status_cancelled_without_charging_label')
  },

  cancelledWithFullRefund: {
    value: 'cancelledWithFullRefund',
    label: i18n.t('rental_status_cancelled_with_full_refund_label')
  },

  cancelledWithPartialRefund: {
    value: 'cancelledWithPartialRefund',
    label: i18n.t('rental_status_cancelled_with_partial_refund_label')
  }
};

// 租借物品時選的方案
export const ITEM_PLANS = {
  deposit: {
    value: 'deposit',
    label: i18n.t('item_plans_deposit_label')
  },
  damageWaiver: {
    value: 'damageWaiver',
    label: i18n.t('item_plans_damage_waver_label')
  }
};

//
export const ITEM_STATUS = {
  normal: {
    value: 'normal',
    label: i18n.t('item_status_normal_label')
  },

  missing: {
    value: 'missing',
    label: i18n.t('item_status_missing_label')
  },

  damaged: {
    value: 'damaged',
    label: i18n.t('item_status_damaged_label')
  },

  error: {
    value: 'error',
    label: i18n.t('item_status_error_label')
  }
};

// 包裹狀態
export const PARCEL_STATUS = {
  unknown: 'UNKNOWN',
  delivered: 'DELIVERED',
  preTransit: 'PRE_TRANSIT',
  transit: 'TRANSIT',
  failure: 'FAILURE',
  returned: 'RETURNED'
};

// 物品的狀態對應的包裹狀態
export const PARCEL_SHIPPING_STATUS = {
  [`${PARCEL_STATUS.unknown},${PARCEL_STATUS.unknown}`]:
    RENTAL_STATUS.waitingForShipping,

  [`${PARCEL_STATUS.preTransit},${PARCEL_STATUS.unknown}`]:
    RENTAL_STATUS.onTheWayToLand,

  [`${PARCEL_STATUS.transit},${PARCEL_STATUS.unknown}`]:
    RENTAL_STATUS.onTheWayToLand,

  [`${PARCEL_STATUS.delivered},${PARCEL_STATUS.unknown}`]:
    RENTAL_STATUS.deliveredLand,

  [`${PARCEL_STATUS.delivered},${PARCEL_STATUS.preTransit}`]:
    RENTAL_STATUS.onTheWayToWarehouse,

  [`${PARCEL_STATUS.delivered},${PARCEL_STATUS.transit}`]:
    RENTAL_STATUS.onTheWayToWarehouse,

  [`${PARCEL_STATUS.delivered},${PARCEL_STATUS.delivered}`]:
    RENTAL_STATUS.inspecting
};

export const RENTALS_FILTER_DATE_TYPES = {
  createdAt: {
    value: 'createdAt',
    label: i18n.t('rentals_filter_dates_type_booking_date_label')
  },

  rentalDate: {
    value: 'rentalDate',
    label: i18n.t('rentals_filter_dates_type_rental_date_label')
  }
};

export const RENTALS_FILTER_KEYWORD_TYPES = {
  itemName: {
    value: 'itemName',
    label: i18n.t('rentals_filter_keyword_type_item_name_label')
  },

  visitorName: {
    value: 'visitorName',
    label: i18n.t('rentals_filter_keyword_type_visitor_name_label')
  },

  visitorEmail: {
    value: 'visitorEmail',
    label: i18n.t('rentals_filter_keyword_type_visitor_email_label')
  }
};

export const getRentalStatusStyle = (status) => {
  switch (status) {
    case RENTAL_STATUS.deliveredLand.value:
      return styles.deliveredLand;

    case RENTAL_STATUS.waitingForShipping.value:
      return styles.waitingForShipping;

    case RENTAL_STATUS.disputed.value:
      return styles.disputed;

    default:
      return '';
  }
};

export const RENTAL_FILTER_STATUS = {
  ...RENTAL_STATUS,
  date_21_14_days_before: {
    value: 'date_21_14_days_before',
    label: i18n.t('rentals_page_filter_date_21_14_days_before')
  },
  date_within_14_days: {
    value: 'date_within_14_days',
    label: i18n.t('rentals_page_filter_date_within_14_days')
  }
};

export const getRentalFilters = (status) => {
  switch (status) {
    case RENTAL_FILTER_STATUS.date_21_14_days_before.value: {
      const startAt = dayjs().subtract(21, 'day');
      const endAt = dayjs().endOf('date').subtract(14, 'day');

      return {
        status: undefined,
        isPackingCompleted: undefined,
        date: {
          type: RENTALS_FILTER_DATE_TYPES.rentalDate.value,
          startAt: startAt.valueOf(),
          endAt: endAt.valueOf()
        }
      };
    }

    case RENTAL_FILTER_STATUS.date_within_14_days.value: {
      const startAt = dayjs().startOf('date').subtract(14, 'day');
      const endAt = dayjs().endOf('date');

      return {
        status: undefined,
        isPackingCompleted: undefined,
        date: {
          type: RENTALS_FILTER_DATE_TYPES.rentalDate.value,
          startAt: startAt.valueOf(),
          endAt: endAt.valueOf()
        }
      };
    }

    default: {
      return status
        ? {
          status: [status],
          isPackingCompleted: undefined
        }
        : {
          landId: undefined,
          status: undefined,
          isPackingCompleted: undefined,
          search: undefined,
          date: undefined
        };
    }
  }
};
