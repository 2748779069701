import { CARDS_LIMIT } from 'src/constants/config';
import API from 'src/apis';
import RentalOrderDataModel from 'src/models/response/RentalOrderDataModel';
import RentalDetailDataModel from 'src/models/response/RentalDetailDataModel';
import RentalParcelListItemDataModel from 'src/models/response/RentalParcelListItemDataModel';
import RentalStatusHistoryItemDataModel from 'src/models/response/RentalStatusHistoryItemDataModel';
import PayoutPaidDateDataModel from 'src/models/response/PayoutPaidDateDataModel';
import PayoutRentalDataModel from 'src/models/response/PayoutRentalDataModel';
import RentalCopyDetailDataModel from 'src/models/response/RentalCopyDetailDataModel';
import RentalParcelDetailDataModel from 'src/models/response/RentalParcelDetailDataModel';
import PayoutStatisticsDataModel from 'src/models/response/PayoutStatisticsDataModel';
import RentalPayoutRecordDataModel from 'src/models/response/RentalPayoutRecordDataModel';
import Auth from './auth';

class RentalServices {
  static async getRentalsList({
    landId,
    statuses,
    isPackingCompleted,
    search,
    date,
    limit = 20,
    anchor
  }) {
    const res = await API.rentals.list({
      token: Auth.getToken(),
      landId,
      statuses,
      isPackingCompleted,
      search,
      date,
      limit,
      anchor
    });

    return {
      data: res.data.list.map((item) => new RentalOrderDataModel(item)),
      anchor: res.data.anchor
    };
  }

  static async getRentalStatistic() {
    const res = await API.rentals.statistic(Auth.getToken());

    return {
      data: {
        total: res.data.total,
        within14: res.data.within14,
        inThePast14to21: res.data.inThePast14to21
      }
    };
  }

  static async getRentalDetail({ id }) {
    const res = await API.rentals.getRentalDetail({
      token: Auth.getToken(),
      id
    });

    return {
      data: new RentalDetailDataModel(res.data)
    };
  }

  // 訂單所有的包裹
  static async getRentalParcels({ id }) {
    const res = await API.rentals.getRentalParcels({
      token: Auth.getToken(),
      id
    });

    return {
      data: res.data.list.map((item) => new RentalParcelListItemDataModel(item))
    };
  }

  // 訂單包裹詳情
  static async getRentalParcelDetail({ id }) {
    const res = await API.rentals.getRentalParcelDetail({
      token: Auth.getToken(),
      id
    });

    return {
      data: new RentalParcelDetailDataModel(res.data)
    };
  }

  static async getRentalStatusHistory({ id }) {
    const res = await API.rentals.getRentalStatusHistory({
      token: Auth.getToken(),
      id
    });

    return {
      data: res.data.list.map(
        (item) => new RentalStatusHistoryItemDataModel(item)
      )
    };
  }

  static async putUpdateRentalParcel({ id, report }) {
    const res = await API.rentals.putUpdateRentalParcel({
      token: Auth.getToken(),
      id,
      report
    });

    return {
      data: res.data
    };
  }

  // 包裹裡面的物品損壞要回報
  static async putRentalParcelItem({ parcelId, itemId, status, report }) {
    const res = await API.rentals.putRentalParcelItem({
      token: Auth.getToken(),
      parcelId,
      itemId,
      status,
      report
    });

    return {
      data: res.data
    };
  }

  // 租借支付, paid 日期列表
  static async getPayoutPaidDateList({ limit, anchor }) {
    const res = await API.rentals.getPayoutPaidDateList({
      token: Auth.getToken(),
      limit,
      anchor
    });

    return {
      data: res.data.list.map((item) => new PayoutPaidDateDataModel(item)),
      anchor: res.data.anchor
    };
  }

  // 已經付款
  static async getPayoutPaidRentals({ id, land }) {
    const res = await API.rentals.getPayoutPaidRentals({
      token: Auth.getToken(),
      id,
      land
    });

    return {
      data: res.data.list.map((item) => new PayoutRentalDataModel(item))
    };
  }

  // 未付款
  static async getPayoutPayableRentals({ land, limit = CARDS_LIMIT, anchor }) {
    const res = await API.rentals.getPayoutPayableRentals({
      token: Auth.getToken(),
      limit,
      anchor,
      land
    });

    return {
      data: res.data.list.map((item) => new PayoutRentalDataModel(item))
    };
  }

  // 租借物品的副本詳情
  static async getRentalCopyDetail({ id }) {
    const res = await API.rentals.getRentalCopyDetail({
      token: Auth.getToken(),
      id
    });

    return {
      data: new RentalCopyDetailDataModel(res.data)
    };
  }

  static async getRentalPayoutStatistics({ landId }) {
    const res = await API.rentals.getRentalPayoutStatistics({
      token: Auth.getToken(),
      landId
    });

    return {
      data: new PayoutStatisticsDataModel(res.data)
    };
  }

  static async getRentalPaidRecords({ landId, limit = CARDS_LIMIT, anchor }) {
    const res = await API.rentals.getRentalPaidRecords({
      token: Auth.getToken(),
      landId,
      limit,
      anchor
    });

    return {
      data: res.data.list.map((item) => new RentalPayoutRecordDataModel(item)),
      anchor: res.data.anchor
    };
  }
}

export default RentalServices;
