import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetInput from 'src/components/ResetInput';
import ResetInputNumber from 'src/components/ResetInputNumber';
import ResetSelect from 'src/components/ResetSelect';
import styles from './styles.module.scss';

@observer
class BasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      land: props.land,
      form: props.form
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.container}>
        <Form className={styles.form} form={this.vm.form}>
          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_basic_info_page_name_label')}
            </div>

            <ResetFormItem
              name="name"
              rules={this.vm.rules.name}
              errorMessageAbsolute
            >
              <ResetInput
                maxLength={50}
                showCount
                placeholder={i18n.t(
                  'create_land_drawer_basic_info_page_name_placeholder'
                )}
                countAbsolute
              />
            </ResetFormItem>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_basic_info_page_acres_label')}
            </div>

            <ResetFormItem
              name="acres"
              rules={this.vm.rules.acres}
              errorMessageAbsolute
            >
              <ResetInputNumber
                className={styles.inputNumber}
                min={0}
                controls={false}
                stringMode
                placeholder={i18n.t(
                  'create_land_drawer_basic_info_page_acres_placeholder'
                )}
              />
            </ResetFormItem>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('create_land_drawer_basic_info_page_species_label')}
            </div>

            <ResetFormItem name="species">
              <ResetSelect
                mode="multiple"
                placeholder={i18n.t(
                  'create_land_drawer_basic_info_page_species_label'
                )}
                options={this.vm.options.species}
                allowClear
                showSearch
                optionFilterProp="label"
              />
            </ResetFormItem>
          </div>
        </Form>
      </div>
    );
  }
}

BasicInfo.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

BasicInfo.defaultProps = {};

export default withForm(BasicInfo);
