import { CARDS_LIMIT } from 'src/constants/config';
import API from 'src/apis';
import PayoutDataModel from 'src/models/response/PayoutDataModel';
import PayoutBookingDataModel from 'src/models/response/PayoutBookingDataModel';
import PayoutStatisticsDataModel from 'src/models/response/PayoutStatisticsDataModel';
import PayoutBookingRecordDataModel from 'src/models/response/PayoutBookingRecordDataModel';
import Auth from './auth';

class PayoutServices {
  // 查詢已收款.
  static async getPayoutPaid({ year }) {
    const res = await API.payout.getPayoutPaid({
      token: Auth.getToken(),
      year
    });

    return {
      data: res.data.list.map((item) => new PayoutDataModel(item))
    };
  }

  // 查詢尚未收款.
  static async getPayoutPayable({ year }) {
    const res = await API.payout.getPayoutPayable({
      token: Auth.getToken(),
      year
    });

    return {
      data: res.data.list.map((item) => new PayoutDataModel(item))
    };
  }

  // 使用 payout id 讀取 bookings.
  static async getPayoutBookings({ limit, anchor, id, land }) {
    const res = await API.payout.getPayoutBookings({
      token: Auth.getToken(),
      limit,
      anchor,
      id,
      land
    });

    return {
      date: res.data.date,
      status: res.data.status,
      data: res.data.list.map((item) => new PayoutBookingDataModel(item)),
      anchor: res.data.anchor
    };
  }

  // 查詢 payout total
  static async getPayoutStatistics({ landId }) {
    const res = await API.payout.getPayoutStatistics({
      token: Auth.getToken(),
      landId
    });

    return {
      data: new PayoutStatisticsDataModel(res.data)
    };
  }

  // 讀取 booking payout paid 所有的紀錄
  // 這是 record 不是 payout 也不是 booking
  static async getPayoutPaidRecords({
    landId,
    limit = CARDS_LIMIT,
    anchor
  }) {
    const res = await API.payout.getPayoutPaidRecords({
      token: Auth.getToken(),
      landId,
      limit,
      anchor
    });

    return {
      data: res.data.list.map((item) => new PayoutBookingRecordDataModel(item)),
      anchor: res.data.anchor
    };
  }

  static async getPayoutPayableRecords({
    landId,
    limit = CARDS_LIMIT,
    anchor
  }) {
    const res = await API.payout.getPayoutPayableRecords({
      token: Auth.getToken(),
      landId,
      limit,
      anchor
    });

    return {
      data: res.data.list.map((item) => new PayoutBookingRecordDataModel(item)),
      anchor: res.data.anchor
    };
  }
}

export default PayoutServices;
