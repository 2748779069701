import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeObservable, observable, action, computed } from 'mobx';
import { Button } from 'antd';
import clsx from 'clsx';
import iconShowPassword from 'src/assets/findHunt/icon_show_password.png';
import iconHidePassword from 'src/assets/findHunt/icon_hide_password.png';
import styles from './styles.module.scss';

@observer
class FormInput extends React.Component {
  @observable isFocus = false;
  @observable showPassword = false;
  inputRef = React.createRef();

  // 縮小 placeholder, 移動 input.
  @computed
  get isValidInput() {
    return this.isFocus || !!this.props.value;
  }

  // check input type.
  @computed
  get isPassword() {
    return this.props.type === 'password';
  }

  // password input type.
  @computed
  get passwordType() {
    return this.showPassword ? 'text' : 'password';
  }

  // input type.
  @computed
  get resType() {
    return this.isPassword ? this.passwordType : this.props.type;
  }

  // toggle password button icon.
  @computed
  get resIcon() {
    return this.showPassword ? iconShowPassword : iconHidePassword;
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  onFocus = () => {
    this.isFocus = true;
  };

  @action
  onBlur = () => {
    this.isFocus = false;
  };

  @action
  onSetShowPassword = () => {
    this.showPassword = !this.showPassword;
  };

  onClickPlaceholder = () => {
    if (this.inputRef?.current) {
      this.inputRef.current.focus();
    }
  }

  render() {
    return (
      <div
        id="formInput"
        className={clsx([
          styles.container,
          this.props.hasBorder && styles.border
        ])}
        style={{ borderRadius: this.props.borderRadius }}
      >
        <div className={styles.inputContainer}>
          <div
            className={clsx([
              styles.placeholder,
              this.isValidInput && styles.fold
            ])}
            onClick={this.onClickPlaceholder}
          >
            {this.props.placeholder}
          </div>

          <input
            type={this.resType}
            name={this.props.name}
            className={clsx([styles.input, this.isValidInput && styles.valid])}
            value={this.props.value}
            onChange={this.props.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            ref={this.inputRef}
          />
        </div>

        {this.isPassword && (
          <div className={styles.buttonContainer}>
            <Button
              type="text"
              onClick={this.onSetShowPassword}
              className={styles.button}
            >
              <div className={styles.iconContainer}>
                <img
                  src={this.resIcon}
                  alt="hide_password"
                  className={clsx([
                    styles.icon,
                    !this.showPassword && styles.hide
                  ])}
                />
              </div>
            </Button>
          </div>
        )}

        {/* input 錯誤提示. */}
        {this.props.showErrorMessage && (
          <div className={styles.errorMessage}>{this.props.errorMessage}</div>
        )}
      </div>
    );
  }
}

FormInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  hasBorder: PropTypes.bool,
  borderRadius: PropTypes.number,
  errorMessage: PropTypes.string,
  showErrorMessage: PropTypes.bool
};

FormInput.defaultProps = {
  name: '',
  placeholder: '',
  type: 'text',
  hasBorder: false,
  borderRadius: 5,
  errorMessage: 'Error',
  showErrorMessage: false
};

export default FormInput;
