import API from 'src/apis';
import BookingListItemDataModel from 'src/models/response/BookingListItemDataModel';
import BookingDetailDataModel from 'src/models/response/BookingDetailDataModel';
import BookingStatisticsDataModel from 'src/models/response/BookingStatisticsDataModel';
import dayjs from 'dayjs';
import { LIST_LIMIT, CARDS_LIMIT } from 'src/constants/config';
import Auth from './auth';

class BookingServices {
  // 讀取訂單列表.
  static async postBookingList({
    limit = CARDS_LIMIT,
    anchor,
    landId,
    status,
    dateType,
    startAt,
    endAt,
    keyword
  }) {
    const res = await API.booking.postBookingList({
      token: Auth.getToken(),
      limit,
      anchor,
      landId,
      status,
      dateType,
      startAt,
      endAt,
      keyword
    });

    return {
      data: res.data.list.map((item) => new BookingListItemDataModel(item)),
      anchor: res.data.anchor
    };
  }

  // 接受訂單.
  static async putBookingAccept({ id }) {
    const res = await API.booking.putBookingAccept({
      token: Auth.getToken(),
      id
    });

    return {
      data: res.data
    };
  }

  // 拒絕訂單.
  static async putBookingReject({ id }) {
    const res = await API.booking.putBookingReject({
      token: Auth.getToken(),
      id
    });

    return {
      data: res.data
    };
  }

  // 訂單詳情.
  static async getBookingDetail({ id }) {
    const res = await API.booking.getBookingDetail({
      token: Auth.getToken(),
      id
    });

    return {
      data: new BookingDetailDataModel(res.data)
    };
  }

  // 取消訂單.
  static async putBookingCancel({ id, reason }) {
    const res = await API.booking.putBookingCancel({
      token: Auth.getToken(),
      id,
      reason
    });

    return {
      data: res.data
    };
  }

  // 訂單分類數量.
  static async getBookingStatistics({ landId }) {
    const res = await API.booking.getBookingStatistics({
      token: Auth.getToken(),
      landId,
      timezone: dayjs.tz.guess()
    });

    return {
      data: new BookingStatisticsDataModel(res.data)
    };
  }

  static async putBookingInfo({ bookingId, note }) {
    const res = await API.booking.putBookingInfo({
      token: Auth.getToken(),
      bookingId,
      note
    });

    return {
      data: res.data
    };
  }
}

export default BookingServices;
