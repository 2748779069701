import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class ResetSelect extends React.Component {
  render() {
    const { className, placeholderSolid, ...ps } = this.props;

    return (
      <Select
        className={clsx([
          'resetSelect',
          styles.select,
          placeholderSolid && 'placeholderSolid',
          className
        ])}
        {...ps}
      />
    );
  }
}

ResetSelect.propTypes = {
  className: PropTypes.string,
  popupMatchSelectWidth: PropTypes.bool,
  placeholderSolid: PropTypes.bool
};

ResetSelect.defaultProps = {
  className: '',
  popupMatchSelectWidth: false,
  placeholderSolid: false
};

export default ResetSelect;
