import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Calendar } from 'antd';
import clsx from 'clsx';
import { ROUTES } from 'src/constants/routes';
import { BUSINESS_DAY_KEY_FORMAT } from 'src/constants/calendar';
import ICONS from 'src/constants/icons';
import PageHeader from 'src/components/PageHeader';
import ResetSelect from 'src/components/ResetSelect';
import ResetButton from 'src/components/ResetButton';
import ResetDatePicker from 'src/components/ResetDatePicker';
import NoLandLabel from 'src/components/NoLandLabel';
import CustomPopover from 'src/components/CustomPopover';
import BusinessDayCard from './components/BusinessDayCard';
import BusinessDayDrawer from './components/BusinessDayDrawer';
import CalendarBatchAvailableModal from './components/CalendarBatchAvailableModal';
import CalendarBatchTagModal from './components/CalendarBatchTagModal';
import CalendarPageViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class CalendarPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CalendarPageViewModel({
      profile: props.context.state.profile
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  renderContent = () => {
    return this.vm.inited ? (
      <>
        <div className={clsx(['calendarPage', styles.container])}>
          <div className={styles.header}>
            <PageHeader back label={ROUTES.calendar.label}>
              {this.vm.check.lands && (
                <CustomPopover items={this.vm.moreItems} zIndex={1000}>
                  <ResetButton type="text">
                    <img src={ICONS.more} alt="more" />
                  </ResetButton>
                </CustomPopover>
              )}
            </PageHeader>
          </div>

          {this.vm.check.lands ? (
            <>
              <div className={styles.filters}>
                <div className={styles.land}>
                  <ResetSelect
                    options={this.vm.options.lands}
                    value={this.vm.landId}
                    onChange={this.vm.onLandIdChange}
                    disabled={this.vm.disabled.filter}
                    showSearch
                    optionFilterProp="label"
                  />
                </div>

                <div className={styles.date}>
                  <ResetDatePicker
                    className={styles.month}
                    size="large"
                    picker="month"
                    format={this.vm.formats.datePicker}
                    allowClear={false}
                    value={this.vm.date}
                    onChange={this.vm.onDatePickerChange}
                    disabled={this.vm.disabled.filter}
                    inputReadOnly
                  />

                  <div className={styles.buttons}>
                    <ResetButton
                      className={styles.button}
                      type="text"
                      onClick={this.vm.onMonthPrev}
                      disabled={this.vm.disabled.filter}
                    >
                      <img src={ICONS.arrowLeft} alt="arrow-left" />
                    </ResetButton>

                    <ResetButton
                      className={styles.button}
                      type="text"
                      onClick={this.vm.onMonthNext}
                      disabled={this.vm.disabled.filter}
                    >
                      <img src={ICONS.arrowRight} alt="arrow-right" />
                    </ResetButton>
                  </div>
                </div>
              </div>

              <div className={styles.calendar}>
                <Calendar
                  headerRender={() => null}
                  value={this.vm.date}
                  fullCellRender={(current) => {
                    const key = current.format(BUSINESS_DAY_KEY_FORMAT);
                    const vm = this.vm.businessDays[key];
                    const tags = this.vm.tags[key] || [];

                    return vm ? (
                      <BusinessDayCard
                        vm={vm}
                        land={this.vm.findLand}
                        tags={tags}
                        onClick={() => this.vm.onBusinessDayDrawer(vm)}
                        disabled={this.vm.disabled.calendar}
                      />
                    ) : null;
                  }}
                />
              </div>
            </>
          ) : (
            <NoLandLabel />
          )}
        </div>

        <BusinessDayDrawer
          vm={this.vm.businessDayDrawerVM}
          land={this.vm.findLand}
          businessDay={this.vm.findBusinessDay}
          onDateNext={this.vm.onDateNext}
          onDatePrev={this.vm.onDatePrev}
          disabled={this.vm.disabled.businessDayDrawer}
        />

        <CalendarBatchAvailableModal vm={this.vm.batchAvailableModalVM} />

        <CalendarBatchTagModal vm={this.vm.calendarBatchTagModalVM} />
      </>
    ) : null;
  };

  render() {
    return this.vm.inited ? this.renderContent() : null;
  }
}

CalendarPage.propTypes = {
  context: PropTypes.object.isRequired
};

CalendarPage.defaultProps = {};

export default CalendarPage;
