import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';
import styles from './styles.module.scss';

@observer
class PageCardsContainer extends React.Component {
  render() {
    const { className, children, paddingBottom, onWaypointEnter, ...ps }
      = this.props;

    return (
      <div className={styles.container}>
        <div
          className={clsx([
            styles.cards,
            paddingBottom && styles.paddingBottom,
            className
          ])}
          {...ps}
        >
          {children}
        </div>

        <Waypoint onEnter={onWaypointEnter} />
      </div>
    );
  }
}

PageCardsContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  paddingBottom: PropTypes.bool,
  onWaypointEnter: PropTypes.func
};

PageCardsContainer.defaultProps = {
  className: '',
  children: null,
  paddingBottom: false,
  onWaypointEnter: () => {}
};

export default PageCardsContainer;
