import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ICONS from 'src/constants/icons';
import withRouter from '../withRouter';
import ResetButton from '../ResetButton';
import styles from './styles.module.scss';

@observer
class PageBack extends React.Component {
  onBack = () => {
    this.props.router.navigate(-1);
  };

  render() {
    return (
      <div className={styles.container}>
        <ResetButton
          className={styles.button}
          type="text"
          onClick={this.onBack}
        >
          <img src={ICONS.back2} alt="back" />
        </ResetButton>
      </div>
    );
  }
}

PageBack.propTypes = {
  router: PropTypes.object.isRequired
};

PageBack.defaultProps = {};

export default withRouter(PageBack);
