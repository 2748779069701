import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { assemble } from 'src/constants/methods';
import { formatPrice } from 'src/constants/price';
import BookingDetailDrawerViewModel from 'src/components/BookingDetailDrawer/viewModel';

class BookingCardViewModel {
  @observable booking = null;
  @observable date = null;

  detailDrawerVM = new BookingDetailDrawerViewModel();

  @computed
  get id() {
    return this.booking.id;
  }

  @computed
  get labels() {
    const consumer = this.booking.consumer;
    const startAt = dayjs(this.booking.startAt).format('MMM D, YYYY');
    const endAt = dayjs(this.booking.endAt).format('MMM D, YYYY');

    return {
      date: dayjs(this.date).format('MMM D, YYYY'),
      amount: formatPrice(this.booking.payout.amount, true),
      consumer: assemble.name({
        firstName: consumer.firstName,
        lastName: consumer.lastName
      }),
      duration: `${startAt} - ${endAt}`,
      landName: this.booking.land.name
    };
  }

  constructor(props) {
    this.booking = props.booking;
    this.date = props.date;

    makeObservable(this);
  }

  onDetailDrawer = () => {
    this.detailDrawerVM.openBooking({ bookingId: this.id });
  }
}

export default BookingCardViewModel;
