import i18n from 'src/i18n';
import { SUPPORT_EMAIL, PDF_LINK } from 'src/constants/config';
import styles from './styles.module.scss';

function Footer() {
  return (
    <section className={styles.box2}>
      <hr className={styles.line} size={1} />

      <div className={styles.row3}>
        <div className={styles.row3__item}>
          <div className={styles.row4}>
            <div className={styles.row4__item}>
              <a
                className={styles.text}
                href={PDF_LINK.termOfUse}
                target="_blank"
                rel="noreferrer"
              >
                {i18n.t('find_hunt_page_footer_terms_of_service_label')}
              </a>
            </div>
            <div className={styles.row4__spacer} />
            <div className={styles.row4__item1}>
              <a
                className={styles.text}
                href={PDF_LINK.privatePolicy}
                target="_blank"
                rel="noreferrer"
              >
                {i18n.t('find_hunt_page_footer_privacy_policy_label')}
              </a>
            </div>
            <div className={styles.row4__spacer} />
            <div className={styles.row4__item2}>
              <a
                className={styles.text}
                href="mailto:customersupport@kavava.com"
              >
                {i18n.t('find_hunt_page_footer_contact_us_label')}
              </a>
            </div>
            <div className={styles.row4__spacer} />
            <div className={styles.row4__item3}>
              <a className={styles.text} href={`mailto:${SUPPORT_EMAIL}`}>
                {i18n.t('find_hunt_page_footer_email_label')}
              </a>
            </div>
          </div>
        </div>
        <div className={styles.row3__spacer} />
        {/* <div className={styles.row3__item1}>
        <div className={styles.row5}>
          <div className={styles.row5__item}>
            <img
              className={styles.icon1}
              src={require('src/assets/findHunt/83fa1e09b3875c304555192af359a4bd.png')}
              alt="alt text"
            />
          </div>
          <div className={styles.row5__item1}>
            <img
              className={styles.image8}
              src={require('src/assets/findHunt/09089c89fb2ddb65687f87d2fb3d5f62.png')}
              alt="alt text"
            />
          </div>
          <div className={styles.row5__item1}>
            <img
              className={styles.image81}
              src={require('src/assets/findHunt/0e585edf1eab67c4e1d6f767db496a63.png')}
              alt="alt text"
            />
          </div>
        </div>
      </div> */}
        <div className={styles.row3__spacer1} />
        <div className={styles.row3__item2}>
          <div className={styles.text1}>
            {i18n.t('find_hunt_page_footer_copyright_label')}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
