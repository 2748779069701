import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed
} from 'mobx';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';
import i18n from 'src/i18n';
import ResendButtonViewModel from 'src/components/ResendButton/viewModel';
import {
  PASSWORD_LENGTH,
  VERIFY_CODE_LENGTH
} from 'src/constants/config';

export const PAGES = {
  send: 'send',
  reset: 'reset'
};

class ResetPasswordViewModel {
  @observable email = '';
  @observable password = '';
  @observable code = '';
  @observable page = PAGES.send;
  // api 使用.
  @observable isAwait = false;

  resendButtonVM = new ResendButtonViewModel();

  @computed
  get emailLabel() {
    const desc = i18n.t('find_hunt_page_reset_password_email_desc');

    return `${desc} ${this.email}`;
  }

  // 檢查驗證碼.
  @computed
  get checkCode() {
    return this.code.length === VERIFY_CODE_LENGTH;
  }

  // 檢查密碼.
  @computed
  get checkPassword() {
    return this.password.length >= PASSWORD_LENGTH;
  }

  // 鎖定保存按鈕.
  @computed
  get disabledSave() {
    return !this.checkCode || !this.checkPassword;
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.props = props;
    this.email = props.email;
  };

  unMount = () => {
    this.resendButtonVM.clearTimer();
  };

  @action
  toReset = () => {
    this.page = PAGES.reset;
    this.postUserResendAuthResetPasswordAPI();
  };

  @action
  onChangeCode = (value) => {
    this.code = value;

    console.log('code', value);
  };

  @action
  onChangePassword = (event) => {
    const value = event.target.value;

    this.password = value;

    console.log('password', value);
  };

  // 重新發送信.
  @action
  onResend = () => {
    this.postUserResendAuthResetPasswordAPI();
  };

  // 保存新密碼.
  onSave = () => {
    this.putUserAuthResetPasswordAPI();
  };

  // 發送重置密碼驗證信.
  @action
  postUserResendAuthResetPasswordAPI = async () => {
    this.isAwait = true;

    try {
      const res = await UserService.postUserResendAuthResetPassword({
        email: this.email
      });

      runInAction(() => {
        // 開啟排程.
        this.resendButtonVM.setTimer();

        this.isAwait = false;
      });
    } catch (error) {
      runInAction(() => {
        const msg = ErrorServices.postUserResendAuthResetPassword(error);

        this.isAwait = false;

        // 還在 180 秒以內.
        if (error.response?.status === 429) {
          // 開啟排程.
          this.resendButtonVM.setTimer();
        }

        console.log('postUserResendAuthResetPassword', msg);
      });
    }
  };

  @action
  putUserAuthResetPasswordAPI = async () => {
    this.isAwait = true;

    try {
      const res = await UserService.putUserAuthResetPassword({
        email: this.email,
        password: this.password,
        code: this.code
      });

      runInAction(() => {
        // 前往登入.
        this.props.toLogin();

        this.isAwait = false;
      });
    } catch (error) {
      runInAction(() => {
        const msg = ErrorServices.putUserAuthResetPassword(error);

        this.isAwait = false;

        console.log('putUserAuthResetPassword', msg);
      });
    }
  };
}

export default ResetPasswordViewModel;
