import Papa from 'papaparse';
import i18n from 'src/i18n';

export const AUTH_TOKEN_KEY = 'token';

export const GOOGLE_KEY = 'AIzaSyCAL0bS5qqNZKxZSbKtdbwNJmD7UMHC9Cs';

export const SUPPORT_EMAIL = 'service@kavava.com';

export const APP = {
  googlePlay: 'https://play.google.com/store/apps/details?id=com.develo.kavava',
  appStore: 'https://apps.apple.com/us/app/kavava/id1668711137'
};

export const PASSWORD_LENGTH = 8;

export const VERIFY_CODE_LENGTH = 6;

export const VERIFY_CODE_RESET_SECOND = 180;

export const LIST_LIMIT = 20;

function getCardsLimit() {

  if (window.innerWidth > 2560) {
    return 40;
  }

  if (window.innerWidth > 1920) {
    return 30;
  }

  if (window.innerWidth > 1200) {
    return 20;
  }

  return 20;
}

export const CARDS_LIMIT = getCardsLimit();

export const COUNTRY_CODE_US = '+1';

export const REGEXPS = {
  email: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
  displayName: /^([a-zA-Z0-9 ]{1,100})$/,
  phone: /^[0-9]{10,10}$/,
  verifyCode: /^[0-9]{6,6}$/,
  postcode: /^[0-9]{5,5}$/,
  number: /^\d+(\.\d+)?$/
};

export const SOURCE_TYPES = {
  email: 'email',
  facebook: 'facebook',
  apple: 'apple'
};

export const EMAIL_STATUS = {
  pending: 'pending',
  verified: 'verified'
};

export const PDF_LINK = {
  // 隱私政策.
  privatePolicy: 'https://dev-7ddd5a31.kavava.com/privacy-policy',
  // 服務條款.
  termOfUse: 'https://dev-7ddd5a31.kavava.com/terms-of-service.pdf'
};

export const CHECK_BOOKING_LINKS = {
  instagram: '',
  facebook: '',
  twitter: '',
  youtube: ''
};

export class PapaConfig {
  static csv = {
    export: {
      quotes: false, // or array of booleans
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ',',
      header: true,
      newline: '\r\n',
      skipEmptyLines: false, // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
      columns: null // or array of strings
    },
    import: {
      delimiter: '', // auto-detect
      newline: '', // auto-detect
      quoteChar: '"',
      escapeChar: '"',
      header: false,
      transformHeader: undefined,
      dynamicTyping: false,
      preview: 0,
      encoding: '',
      worker: false,
      comments: false,
      step: undefined,
      complete: undefined,
      error: undefined,
      download: false,
      downloadRequestHeaders: undefined,
      downloadRequestBody: undefined,
      skipEmptyLines: false,
      chunk: undefined,
      chunkSize: undefined,
      fastMode: undefined,
      beforeFirstChunk: undefined,
      withCredentials: undefined,
      transform: undefined,
      delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
    }
  };
}

export const FORM_INPUT_REQUIRED_MESSAGE = i18n.t(
  'form_input_required_message'
);

export const FORM_INPUT_FORMAT_ERROR_MESSAGE = i18n.t(
  'form_input_format_error_message'
);

export const GENDERS = {
  male: {
    value: 'male',
    label: i18n.t('genders_male_label')
  },
  female: {
    value: 'female',
    label: i18n.t('genders_female_label')
  },
  others: {
    value: 'others',
    label: i18n.t('genders_others_label')
  }
};

export const DEACTIVATE_ACCOUNT_REASON = [
  {
    value: i18n.t('deactivate_account_reason_1'),
    label: i18n.t('deactivate_account_reason_1')
  },
  {
    value: i18n.t('deactivate_account_reason_2'),
    label: i18n.t('deactivate_account_reason_2')
  },
  {
    value: i18n.t('deactivate_account_reason_3'),
    label: i18n.t('deactivate_account_reason_3')
  },
  {
    value: i18n.t('deactivate_account_reason_4'),
    label: i18n.t('deactivate_account_reason_4')
  },
  {
    value: i18n.t('deactivate_account_reason_5'),
    label: i18n.t('deactivate_account_reason_5')
  }
];

// 身分證狀態
// 獵人執照狀態
export const VERIFIES = {
  failed: {
    value: 'failed',
    label: i18n.t('verifies_failed')
  },
  verified: {
    value: 'verified',
    label: i18n.t('verifies_verified')
  },
  pending: {
    value: 'pending',
    label: i18n.t('verifies_pending')
  }
};
