import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import ActionDrawer from 'src/components/ActionDrawer';
import ResetButton from 'src/components/ResetButton';
import Note from './components/Note';
import { PAGES } from './viewModel';
import styles from './styles.module.scss';

@observer
class BookingEditDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  renderPage = () => {
    switch (this.vm.page) {
      case PAGES.note:
        return <Note vm={this.vm.noteVM} booking={this.vm.booking} />;

      default:
        return null;
    }
  }

  render() {
    return (
      <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
        <div className={styles.container}>
          <div className={styles.page}>
            {this.renderPage()}
          </div>

          <div className={styles.footer}>
            <ResetButton className={styles.button} onClick={this.vm.close}>
              {i18n.t('booking_edit_drawer_cancel_button_label')}
            </ResetButton>

            <ResetButton
              className={styles.button}
              type="primary"
              onClick={this.vm.onSave}
            >
              {i18n.t('booking_edit_drawer_confirm_button_label')}
            </ResetButton>
          </div>
        </div>
      </ActionDrawer>
    );
  }
}

BookingEditDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

BookingEditDrawer.defaultProps = {};

export default BookingEditDrawer;
