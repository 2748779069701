import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import clsx from 'clsx';
import i18n from 'src/i18n';
import ActionDrawerItem from 'src/components/ActionDrawer/components/ActionDrawerItem';
import LinkButton from 'src/components/LinkButton';
import ResetTable from 'src/components/ResetTable';
import ContactDrawer from 'src/components/ContactDrawer';
import RentalStatusHistoriesDrawer from './components/RentalStatusHistoriesDrawer';
import RentalPriceDrawer from './components/RentalPriceDrawer';
import RentalDetailViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class RentalDetail extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new RentalDetailViewModel({
      booking: props.booking,
      rental: props.rental,
      changed: props.changed
    });
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_rental_id_label')}
            divider
          >
            {this.vm.id}
          </ActionDrawerItem>

          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_rental_create_at_label')}
            divider
          >
            {this.vm.createAt}
          </ActionDrawerItem>

          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_rental_duration_label')}
            divider
          >
            {this.vm.duration}
          </ActionDrawerItem>

          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_rental_status_label')}
            divider
          >
            <LinkButton onClick={this.vm.onStatus}>
              {this.vm.rentalStatus}
            </LinkButton>
          </ActionDrawerItem>

          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_rental_consumer_label')}
            divider
          >
            <LinkButton onClick={this.vm.onContact}>
              {this.vm.consumer}
            </LinkButton>
          </ActionDrawerItem>

          {this.vm.check.earning && (
            <ActionDrawerItem
              label={i18n.t('booking_detail_drawer_rental_price_label')}
              divider
            >
              <LinkButton onClick={this.vm.onRentalPrice}>
                {this.vm.price}
              </LinkButton>
            </ActionDrawerItem>
          )}

          <ActionDrawerItem
            label={i18n.t('booking_detail_drawer_rental_shippings_label')}
          >
            <ResetTable data={this.vm.shippings}>
              <Table.Column
                title={i18n.t(
                  'booking_detail_drawer_rental_shippings_table_address_label'
                )}
                align="center"
                render={(item) => (
                  <div className={styles.address}>{item.address}</div>
                )}
              />
            </ResetTable>
          </ActionDrawerItem>

          <ActionDrawerItem>
            <ResetTable data={this.vm.items}>
              <Table.Column
                title={i18n.t('booking_detail_drawer_rental_items_table_label')}
                align="center"
                render={(vm) => (
                  <div className={styles.item}>
                    <div className={styles.name}>{vm.itemName}</div>

                    <div className={styles.row}>
                      <div className={clsx([styles.label, styles.variety])}>
                        {vm.itemVarietyName}
                      </div>

                      <div className={clsx([styles.value, styles.count])}>
                        {vm.count}
                      </div>
                    </div>

                    <div className={styles.row}>
                      <div className={styles.label}>
                        {i18n.t(
                          'booking_detail_drawer_rental_items_table_plan_label'
                        )}
                      </div>

                      <div className={clsx([styles.value, styles.count])}>
                        {vm.plan}
                      </div>
                    </div>

                    <div className={styles.row}>
                      <div className={styles.label}>
                        {i18n.t(
                          'booking_detail_drawer_rental_items_table_rental_duration_label'
                        )}
                      </div>

                      <div className={clsx([styles.value, styles.count])}>
                        {vm.duration}
                      </div>
                    </div>

                    <div className={styles.row}>
                      <div className={styles.label}>
                        {i18n.t(
                          'booking_detail_drawer_rental_items_table_price_label'
                        )}
                      </div>

                      <div className={clsx([styles.value, styles.count])}>
                        {vm.price}
                      </div>
                    </div>
                  </div>
                )}
              />
            </ResetTable>
          </ActionDrawerItem>
        </div>

        <RentalStatusHistoriesDrawer vm={this.vm.statusHistoriesDrawerVM} />

        <ContactDrawer vm={this.vm.contactDrawerVM} />

        <RentalPriceDrawer vm={this.vm.rentalPriceDrawerVM} />
      </>
    );
  }
}

RentalDetail.propTypes = {
  booking: PropTypes.object.isRequired,
  rental: PropTypes.object.isRequired,
  changed: PropTypes.func.isRequired
};

RentalDetail.defaultProps = {};

export default RentalDetail;
