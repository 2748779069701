import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input } from 'antd';
import i18n from 'src/i18n';
import { BUSINESS_DAY_TAG_MAX_LENGTH } from 'src/constants/calendar';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetButton from 'src/components/ResetButton';
import { TYPES } from './viewModel';
import styles from './styles.module.scss';

@observer
class TagModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ form: props.form });
  }

  renderFooter = () => {
    switch (this.vm.type) {
      case TYPES.create:
        return (
          <ActionModalFooter
            cancelProps={{
              onClick: this.vm.close,
              children: i18n.t('calendar_page_business_day_tag_modal_cancel_button_label')
            }}
            confirmProps={{
              onClick: this.vm.onCreate,
              disabled: this.vm.disabled.footer,
              children: i18n.t('calendar_page_business_day_tag_modal_create_button_label')
            }}
          />
        );

      case TYPES.edit:
        return (
          <ActionModalFooter
            cancelProps={{
              type: 'primary',
              danger: true,
              onClick: this.vm.onDelete,
              disabled: this.vm.disabled.footer,
              children: i18n.t('calendar_page_business_day_tag_modal_delete_button_label')
            }}
            confirmProps={{
              onClick: this.vm.onEdit,
              disabled: this.vm.disabled.footer,
              children: i18n.t('calendar_page_business_day_tag_modal_edit_button_label')
            }}
          />
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        <div className={styles.container}>
          <Form form={this.vm.form} disabled={this.vm.disabled.form}>
            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('calendar_page_business_day_tag_label')}
              </div>

              <ResetFormItem name="tag" rules={this.vm.rules.tag}>
                <Input.TextArea
                  size="large"
                  maxLength={BUSINESS_DAY_TAG_MAX_LENGTH}
                  showCount
                  autoSize={{ minRows: 6, maxRows: 10 }}
                />
              </ResetFormItem>
            </div>
          </Form>

          {this.renderFooter()}
        </div>
      </ActionModal>
    );
  }
}

TagModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

TagModal.defaultProps = {};

export default withForm(TagModal);
