import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { CREATE_LAND_PAGES } from 'src/constants/land';
import ActionDrawer from 'src/components/ActionDrawer';
import ResetButton from 'src/components/ResetButton';
import i18n from 'src/i18n';
import ActionDrawerHeader from 'src/components/ActionDrawer/components/ActionDrawerHeader';
import Loading from 'src/components/Loading';
import Address from './components/Address';
import Position from './components/Position';
import BasicInfo from './components/BasicInfo';
import Prices from './components/Prices';
import Photos from './components/Photos';
import Description from './components/Description';
import styles from './styles.module.scss';
import Steps from './components/Steps';

@observer
class CreateLandDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  renderPage = () => {
    switch (this.vm.page) {
      case CREATE_LAND_PAGES.address.value:
        return (
          <Address
            land={this.vm.land}
            vm={this.vm.addressVM}
            disabled={this.vm.disabled.address}
          />
        );

      case CREATE_LAND_PAGES.position.value:
        return (
          <Position
            land={this.vm.land}
            vm={this.vm.positionVM}
            type={this.vm.positionType}
          />
        );

      case CREATE_LAND_PAGES.basicInfo.value:
        return <BasicInfo land={this.vm.land} vm={this.vm.basicInfoVM} />;

      case CREATE_LAND_PAGES.price.value:
        return <Prices land={this.vm.land} vm={this.vm.pricesVM} />;

      case CREATE_LAND_PAGES.photos.value:
        return <Photos land={this.vm.land} vm={this.vm.photosVM} />;

      case CREATE_LAND_PAGES.description.value:
        return <Description land={this.vm.land} vm={this.vm.descriptionVM} />;

      default:
        return null;
    }
  };

  render() {
    return (
      <ActionDrawer vm={this.vm.drawerVM} width={375} onClose={this.vm.close}>
        {this.vm.inited ? (
          <div className={styles.container}>
            <div className={styles.header}>
              <ActionDrawerHeader content={this.vm.factoryVM.header} />
            </div>

            <div className={styles.steps}>
              <Steps steps={this.vm.steps.steps} step={this.vm.steps.step} />
            </div>

            <div className={styles.pages}>{this.renderPage()}</div>

            <div className={styles.footer}>
              <div>
                {this.vm.factoryVM.footer.back && (
                  <ResetButton
                    className={clsx([styles.button, styles.back])}
                    onClick={this.vm.factoryVM.onBack}
                    disabled={this.vm.disabled.back}
                  >
                    <div className={styles.label}>
                      {i18n.t('create_land_drawer_back_button_label')}
                    </div>
                  </ResetButton>
                )}
              </div>

              <div>
                {this.vm.factoryVM.footer.done && (
                  <ResetButton
                    className={clsx([styles.button, styles.done])}
                    type="primary"
                    onClick={this.vm.factoryVM.onDone}
                    disabled={this.vm.disabled.done}
                  >
                    <div className={styles.label}>
                      {i18n.t('create_land_drawer_created_button_label')}
                    </div>
                  </ResetButton>
                )}

                {this.vm.factoryVM.footer.next && (
                  <ResetButton
                    className={clsx([styles.button, styles.next])}
                    type="primary"
                    onClick={this.vm.factoryVM.onNext}
                    disabled={this.vm.disabled.next}
                  >
                    <div className={styles.label}>
                      {i18n.t('create_land_drawer_next_button_label')}
                    </div>
                  </ResetButton>
                )}
              </div>
            </div>
          </div>
        ) : null}

        {this.vm.isAwait && <Loading />}
      </ActionDrawer>
    );
  }
}

CreateLandDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

CreateLandDrawer.defaultProps = {};

export default CreateLandDrawer;
