import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ROUTES, PARAMS } from 'src/constants/routes';
import { routerMethods } from 'src/constants/methods';
import i18n from 'src/i18n';
import LinkButton from '../LinkButton';
import withRouter from '../withRouter';
import styles from './styles.module.scss';

@observer
class NoLandLabel extends React.Component {
  landsPageRoute = `/${ROUTES.lands.value}`

  constructor(props) {
    super(props);
    this.router = props.router;
  }

  onLinkClick = () => {
    const search = routerMethods.createSearch({ [PARAMS.createLand]: true });
    this.router.navigate({ pathname: this.landsPageRoute, search });
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.label}>
            {i18n.t('bookings_page_no_land_label_1')}
          </div>

          <div className={styles.label}>
            <LinkButton onClick={this.onLinkClick}>
              {i18n.t('bookings_page_no_land_label_link')}
            </LinkButton>

            &nbsp;

            {i18n.t('bookings_page_no_land_label_2')}
          </div>
        </div>
      </div>
    );
  }
}

NoLandLabel.propTypes = {
  router: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

NoLandLabel.defaultProps = {
  onClick: () => {}
};

export default withRouter(NoLandLabel);
