import dayjs from 'dayjs';
import { request, getHost } from './utils';

export const list = ({
  token,
  landId,
  statuses,
  isPackingCompleted,
  search,
  date,
  limit,
  anchor
}) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v2/backstage/rental/orders`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      limit,
      anchor
    },

    data: {
      landId,
      statuses,
      isPackingCompleted,
      search,
      date
    }
  };

  return request(options);
};

export const statistic = (token) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/backstage/rental/statistics`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },
    params: {
      timezone: dayjs.tz.guess()
    }
  };

  return request(options);
};

export const getRentalDetail = ({ token, id }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/rental/${id}/detail`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

export const getRentalParcels = ({ token, id }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/rental/${id}/parcels`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

export const getRentalParcelDetail = ({ token, id }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/rental-parcel/${id}/detail`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

export const getRentalStatusHistory = ({ token, id }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/rental/${id}/status/history`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// 更新包裹的留言
export const putUpdateRentalParcel = ({ token, id, report }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v2/backstage/rental-parcel/${id}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      report
    }
  };

  return request(options);
};

export const putRentalParcelItem = ({
  token,
  parcelId,
  itemId,
  status,
  report
}) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v2/backstage/rental-parcel/${parcelId}/item`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    data: {
      id: itemId,
      status,
      report
    }
  };

  return request(options);
};

// 租借支付, paid 日期列表
export const getPayoutPaidDateList = ({
  token,
  limit,
  anchor
}) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/backstage/rental-payout/paid/list`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// 已經付款的租借訂單
export const getPayoutPaidRentals = ({
  token,
  id,
  land
}) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/backstage/rental-payout/${id}/rentals`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      land
    }
  };

  return request(options);
};

// 尚未付款的租借訂單
export const getPayoutPayableRentals = ({
  token,
  limit,
  anchor,
  land
}) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/backstage/rental-payout/payable/list`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      limit,
      anchor,
      land
    }
  };

  return request(options);
};

// 租借物品的副本詳情
export const getRentalCopyDetail = ({
  token,
  id
}) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/rental-copy/${id}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    }
  };

  return request(options);
};

// rental payouts total
export const getRentalPayoutStatistics = ({
  token,
  landId
}) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/backstage/rental-payout/statistics`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      land: landId
    }
  };

  return request(options);
};

export const getRentalPaidRecords = ({
  token,
  landId,
  limit,
  anchor
}) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/backstage/rental-payout/paid/records`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`
    },

    params: {
      land: landId,
      limit,
      anchor
    }
  };

  return request(options);
};
