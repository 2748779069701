import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import ErrorServices from 'src/services/ErrorServices';
import UserService from 'src/services/UserService';

export const PAGES = {
  description: 'description',
  upload: 'upload'
};

class IdCardModalViewModel {
  @observable page = PAGES.description;

  @observable profile = null;
  @observable src = null;

  @observable uploadFile = null;

  @observable isAwait = false;

  changed = () => {};

  modalVM = new ActionModalViewModel();

  @computed
  get check() {
    return {
      src: !!this.src
    };
  }

  @computed
  get disabled() {
    return {
      upload: this.isAwait,
      confirm: this.isAwait || !this.uploadFile
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({ profile, changed }) => {
    this.page = PAGES.description;

    this.uploadFile = null;

    this.profile = profile;
    this.src = profile.idCard || null;

    this.changed = changed;

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  @action
  onNext = () => {
    this.page = PAGES.upload;
  };

  @action
  onUpload = async (event) => {
    // 異步中.
    this.isAwait = true;

    // 上傳的檔案.
    this.uploadFile = event.file;

    // 需要圖片網址, 使用 file 物件下載.
    const targetFile = new FileReader();

    targetFile.addEventListener('load', this.downloadFile);
    targetFile.readAsDataURL(event.file);
  };

  @action
  downloadFile = (event) => {
    this.src = event.target.result;
    this.isAwait = false;
  };

  onConfirm = () => {
    this.putUserProfileAPI();
  }

  @action
  putUserProfileAPI = async () => {
    this.isAwait = true;

    try {
      // 使用 formData 傳檔案.
      const bodyFormData = new FormData();
      bodyFormData.append('idCard', this.uploadFile);

      const res = await UserService.putUserProfile(bodyFormData);

      // 更新 store profile.
      await this.changed(res.data);

      // 關閉彈窗.
      this.close();
    } catch (error) {
      const msg = ErrorServices.putUserProfile(error);
      console.log('putUserProfile', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default IdCardModalViewModel;
