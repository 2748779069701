import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { message } from 'antd';
import i18n from 'src/i18n';
import optionStore from 'src/global/optionStore';
import { ROUTES, PARAMS } from 'src/constants/routes';
import {
  LAND_STATUS,
  getLandStatusIcon,
  getLandStatusStyle,
  APPEAL_EMAIL
} from 'src/constants/land';
import { routerMethods } from 'src/constants/methods';
import LandServices from 'src/services/LandServices';
import ErrorServices from 'src/services/ErrorServices';
import LaunchLandViewModel from 'src/components/LaunchLand/viewModel';
import CreateLandDrawerViewModel from '../CreateLandDrawer/viewModel';
import LandDetailDrawerViewModel from '../LandDetailDrawer/viewModel';
import LandMessagesDrawerViewModel from '../LandMessagesDrawer/viewModel';
import DeleteLandModalViewModel from '../DeleteLandModal/viewModel';

class LandCardViewModel {
  @observable land = null;
  @observable isAwait = false;

  router = null;

  createLandDrawerVM = new CreateLandDrawerViewModel();
  landDetailDrawerVM = new LandDetailDrawerViewModel();
  messagesDrawerVM = new LandMessagesDrawerViewModel();
  deleteLandModalVM = new DeleteLandModalViewModel();
  launchLandVM = new LaunchLandViewModel();

  deleted = () => {};

  @computed
  get disabled() {
    return {
      footer: this.isAwait
    };
  }

  @computed
  get check() {
    const isDraft = this.land.status === LAND_STATUS.draft.value;
    const hasBooking = !!this.land.bookingsCount;

    return {
      isDraft,
      photo: !!this.land.photos?.length,
      bookingCount: hasBooking,
      qaCount: !!this.land.qaCount,
      ratingCount: !!this.land.rating.count,
      delete: isDraft || !hasBooking
    };
  }

  @computed
  get id() {
    return this.land.id;
  }

  @computed
  get name() {
    return this.land.name;
  }

  @computed
  get status() {
    const status = LAND_STATUS[this.land.status];

    return {
      value: status.value,
      label: status.label,
      style: getLandStatusStyle(status.value),
      icon: getLandStatusIcon(status.value)
    };
  }

  @computed
  get bookingCount() {
    return this.land.bookingsCount;
  }

  @computed
  get qa() {
    return {
      count: this.land.qaCount,
      unRead: this.land.unreadQACount
    };
  }

  // 評分
  @computed
  get rating() {
    return {
      count: this.land.rating.count,
      unRead: this.land.unreadRatingsCount
    };
  }

  @computed
  get photo() {
    return this.land.photos?.[0]?.src || '';
  }

  // 洲
  @computed
  get state() {
    return optionStore.states[this.land.state]?.name || '';
  }

  constructor(props) {
    makeObservable(this);
    this.land = props;
  }

  init = (props) => {
    this.router = props.router;
    this.deleted = props.deleted;
  };

  @action
  update = (land) => {
    this.land = { ...this.land, ...land };
  };

  onDraftEdit = () => {
    this.createLandDrawerVM.open({
      landId: this.id,
      change: this.update,
      changed: this.update
    });
  };

  onDetail = () => {
    this.landDetailDrawerVM.open({
      landId: this.id,
      closeEnd: this.update
    });
  };

  onAppeal = () => {
    const label = 'mailto';
    const a = document.createElement('a');

    a.ref = `${label}: ${APPEAL_EMAIL}`;

    a.click();
  }

  onToBookingPage = () => {
    const route = `/${ROUTES.bookings.value}`;
    const search = routerMethods.createSearch({ [PARAMS.landId]: this.id });

    this.router.navigate({ pathname: route, search });
  };

  onQas = () => {
    this.messagesDrawerVM.openQas({ land: this.land });
  };

  onRatings = () => {
    this.messagesDrawerVM.openRatings({ land: this.land });
  };

  onDelete = () => {
    this.deleteLandModalVM.open({
      landId: this.id,
      title: i18n.t('delete_land_modal_delete_land_title'),
      content: i18n.t('delete_land_modal_delete_land_content'),
      deleted: this.deleted
    });
  };

  onListed = () => {
    this.launchLandVM.launch({ land: this.land, changed: this.launched });
  };

  launched = ({ status }) => {
    if (status) {
      this.land = { ...this.land, status };
    }
  }

  @action
  putLandStatusAPI = async () => {
    this.isAwait = true;

    try {
      await LandServices.putLandStatus({
        id: this.land.id,
        status: LAND_STATUS.listed.value
      });

      runInAction(() => {
        this.land = { ...this.land, status: LAND_STATUS.listed.value };
      });
    } catch (error) {
      const msg = ErrorServices.putLandStatus(error);

      switch (error.response?.status) {
        case 412: {
          message.error(msg, 5);
          break;
        }

        default:
          break;
      }

      console.log(msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default LandCardViewModel;
