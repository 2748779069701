import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import withForm from 'src/components/withForm';
import { Form } from 'antd';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetDatePicker from 'src/components/ResetDatePicker';
import i18n from 'src/i18n';
import styles from './styles.module.scss';

@observer
class FinalBookingDate extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ land: props.land, form: props.form });
  }

  render() {
    return (
      <div className={styles.container}>
        <Form form={this.vm.form}>
          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('land_edit_drawer_final_booking_date_page_label')}
            </div>

            <ResetFormItem
              name="finalBookingDate"
              rules={this.vm.rules.finalBookingDate}
            >
              <ResetDatePicker
                size="large"
                minDate={this.vm.minDate}
                placeholder={i18n.t(
                  'land_edit_drawer_final_booking_date_page_placeholder'
                )}
                inputReadOnly
              />
            </ResetFormItem>
          </div>
        </Form>
      </div>
    );
  }
}

FinalBookingDate.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

FinalBookingDate.defaultProps = {};

export default withForm(FinalBookingDate);
