import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import withForm from 'src/components/withForm';
import ResetTabs from 'src/components/ResetTabs';
import { POSITION_TYPES } from './viewModel';
import styles from './styles.module.scss';

@observer
class Position extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ type: props.type, land: props.land, form: props.form });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.inited ? (
      <div className={styles.container}>
        <ResetTabs tabFull items={this.vm.items} />
      </div>
    ) : null;
  }
}

Position.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  type: PropTypes.string
};

Position.defaultProps = {
  type: POSITION_TYPES.address
};

export default withForm(Position);
