import dayjs from 'dayjs';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { assemble } from 'src/constants/methods';
import { formatPrice } from 'src/constants/price';
import BookingDetailDrawerViewModel from 'src/components/BookingDetailDrawer/viewModel';

class RentalCardViewModel {
  @observable rental = null;

  detailDrawerVM = new BookingDetailDrawerViewModel();

  @computed
  get id() {
    return this.rental.id;
  }

  @computed
  get profit() {
    return this.rental.profit;
  }

  @computed
  get completedAt() {
    return this.rental.completedAt;
  }

  @computed
  get labels() {
    const startAt = dayjs(this.rental.startAt).format('MMM D, YYYY');
    const endAt = dayjs(this.rental.endAt).format('MMM D, YYYY');

    return {
      date: dayjs(this.completedAt).format('MMM D, YYYY'),
      amount: formatPrice(this.rental.profit, true),
      consumer: assemble.name({
        firstName: this.rental.consumer.firstName,
        lastName: this.rental.consumer.lastName
      }),
      duration: `${startAt} - ${endAt}`,
      landName: this.rental.land.name
    };
  }

  constructor(props) {
    this.rental = props;
    makeObservable(this);
  }

  onDetailDrawer = () => {
    this.detailDrawerVM.openRental({ rentalId: this.id });
  };
}

export default RentalCardViewModel;
