import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Checkbox } from 'antd';
import { Waypoint } from 'react-waypoint';
import i18n from 'src/i18n';
import { AppStateContext } from 'src/stores';
import ActionModal from '../ActionModal';
import ActionModalAlertContainer from '../ActionModal/components/ActionModalAlertContainer';
import ProfileDrawer from '../ProfileDrawer';
import ResetButton from '../ResetButton';
import Terms from './components/Terms';
import { PAGES } from './viewModel';
import styles from './styles.module.scss';

@observer
class LaunchLand extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  componentDidMount() {
    this.vm.didMount(this.context);
  }

  componentDidUpdate() {
    this.vm.didUpdate(this.context);
  }

  renderContent = () => {
    switch (this.vm.page) {
      case PAGES.profile: {
        return (
          <>
            <div className={styles.content}>
              {i18n.t('launch_land_modal_setting_profile_content')}
            </div>

            <div className={styles.footer}>
              <ResetButton className={styles.button} onClick={this.vm.close}>
                {i18n.t(
                  'launch_land_modal_setting_profile_cancel_button_label'
                )}
              </ResetButton>

              <ResetButton
                className={styles.button}
                type="primary"
                onClick={this.vm.onProfile}
              >
                {i18n.t(
                  'launch_land_modal_setting_profile_confirm_button_label'
                )}
              </ResetButton>
            </div>
          </>
        );
      }

      case PAGES.pending: {
        return (
          <>
            <div className={styles.content}>
              {i18n.t('launch_land_modal_profile_id_card_verifying_content')}
            </div>

            <div className={styles.footer}>
              <ResetButton className={styles.button} onClick={this.vm.close}>
                {i18n.t(
                  'launch_land_modal_profile_id_card_verifying_cancel_button_label'
                )}
              </ResetButton>
            </div>
          </>
        );
      }

      case PAGES.failed: {
        return (
          <>
            <div className={styles.content}>
              {i18n.t('launch_land_modal_profile_id_card_failed_content')}
            </div>

            <div className={styles.footer}>
              <ResetButton
                className={styles.button}
                onClick={this.vm.onProfile}
              >
                {i18n.t(
                  'launch_land_modal_profile_id_card_failed_cancel_button_label'
                )}
              </ResetButton>
            </div>
          </>
        );
      }

      case PAGES.terms: {
        return (
          <>
            <div className={styles.terms}>
              <Terms />
              <Waypoint onEnter={this.vm.onScrollEnd} />
            </div>

            <div className={styles.form}>
              <Checkbox
                id="termsAgreed"
                checked={this.vm.termsAgreed}
                onChange={this.vm.onTermsAgreedChange}
                disabled={this.vm.disabled.termsAgreed}
              />

              <div className={styles.label}>
                <label htmlFor="termsAgreed">
                  {i18n.t('launch_land_modal_terms_checkbox_label')}
                </label>
              </div>
            </div>

            <div className={styles.footer}>
              <ResetButton className={styles.button} onClick={this.vm.close}>
                {i18n.t('launch_land_modal_terms_cancel_button_label')}
              </ResetButton>

              <ResetButton
                className={styles.button}
                type="primary"
                onClick={this.vm.onTermsAgreedConfirm}
                disabled={this.vm.disabled.termsAgreedConfirm}
              >
                {i18n.t('launch_land_modal_terms_confirm_button_label')}
              </ResetButton>
            </div>
          </>
        );
      }

      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
          <ActionModalAlertContainer className={styles.container}>
            {this.renderContent()}
          </ActionModalAlertContainer>
        </ActionModal>

        <ProfileDrawer vm={this.vm.profileDrawerVM} />
      </>
    );
  }
}

LaunchLand.propTypes = {
  vm: PropTypes.object.isRequired
};

LaunchLand.defaultProps = {};

export default LaunchLand;
