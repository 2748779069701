import dayjs from 'dayjs';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { BUSINESS_DAY_KEY_FORMAT } from 'src/constants/calendar';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import CalendarServices from 'src/services/CalendarServices';
import ErrorServices from 'src/services/ErrorServices';
import calendarStore from '../../modules/CalendarStore';

export const TYPES = {
  create: 'create',
  edit: 'edit'
};

class TagModalViewModel {
  @observable type = TYPES.create;

  @observable land = null;
  @observable businessDay = null;

  // tag 在資料庫中沒有表, 所以只能將內容當作 id
  @observable tagId = null;

  @observable isAwait = false;

  modalVM = new ActionModalViewModel();

  form = null;

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      footer: this.isAwait
    };
  }

  get rules() {
    return {
      tag: [
        {
          required: true,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form }) => {
    this.form = form;
  };

  @action
  openCreate = ({ land, businessDay }) => {
    this.type = TYPES.create;

    this.land = land;
    this.businessDay = businessDay;

    this.form.setFieldsValue({ tag: '' });

    this.modalVM.open();
  };

  @action
  openEdit = ({ land, businessDay, tag }) => {
    this.type = TYPES.edit;

    this.land = land;
    this.businessDay = businessDay;

    this.tagId = tag;

    this.form.setFieldsValue({ tag });

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  onCreate = () => {
    this.postCalendarLandTagsAPI();
  };

  onEdit = () => {
    this.postEditTagAPI();
  };

  onDelete = () => {
    this.deleteTagAPI();
  };

  @action
  postCalendarLandTagsAPI = async () => {
    this.isAwait = true;

    try {
      const date = dayjs(this.businessDay.date).format(BUSINESS_DAY_KEY_FORMAT);
      const data = await this.form.validateFields();

      await CalendarServices.postCalendarLandTags({
        id: this.land.id,
        startAt: date,
        endAt: date,
        tags: [data.tag]
      });

      await calendarStore.tagsChanged();

      this.close();
    } catch (error) {
      const msg = ErrorServices.postCalendarLandTags(error);
      console.log('postCalendarLandTags', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  postEditTagAPI = async () => {
    this.isAwait = true;

    try {
      const date = dayjs(this.businessDay.date).format(BUSINESS_DAY_KEY_FORMAT);
      const data = await this.form.validateFields();

      await CalendarServices.deleteCalendarLandTags({
        id: this.land.id,
        startAt: date,
        endAt: date,
        tags: [this.tagId]
      });

      await CalendarServices.postCalendarLandTags({
        id: this.land.id,
        startAt: date,
        endAt: date,
        tags: [data.tag]
      });

      await calendarStore.tagsChanged();

      this.close();
    } catch (error) {
      console.log('postEditTag', error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  deleteTagAPI = async () => {
    this.isAwait = true;

    try {
      const date = dayjs(this.businessDay.date).format(BUSINESS_DAY_KEY_FORMAT);
      const data = await this.form.validateFields();

      await CalendarServices.deleteCalendarLandTags({
        id: this.land.id,
        startAt: date,
        endAt: date,
        tags: [data.tag]
      });

      await calendarStore.tagsChanged();

      this.close();
    } catch (error) {
      const msg = ErrorServices.deleteCalendarLandTags(error);
      console.log('deleteCalendarLandTags', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  }
}

export default TagModalViewModel;
