import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Collapse } from 'antd';
import clsx from 'clsx';
import { uuidv4 } from 'src/utils';
import ICONS from 'src/constants/icons';
import styles from './styles.module.scss';
import './reset.scss';

const data = {
  label: 'Basic fee x 6 days',
  value: '$ 600',
  items: [
    {
      label: '$100 × 4 days',
      value: '$ 1000'
    }
  ]
};

@observer
class FeeCollapse extends React.Component {
  get check() {
    return {
      header: {
        value: !!this.props.data.value?.trim()
      }
    };
  }

  items = [
    {
      key: '1',
      label: (
        <div className={styles.headerRow}>
          <div
            className={clsx([
              styles.item1,
              !this.check.header.value && styles.full
            ])}
          >
            {this.props.data.label}
          </div>

          {this.check.header.value && (
            <div className={styles.item2}>{this.props.data.value}</div>
          )}
        </div>
      ),
      children: this.props.data.items?.length
        ? this.props.data.items.map((item) => {
          const key = uuidv4();
          const checkValue = !!item.value?.trim();

          return (
            <div
              className={clsx([styles.contentRow, styles.content])}
              key={key}
            >
              <div
                className={clsx([styles.item1, !checkValue && styles.full])}
              >
                {item.label}
              </div>

              {checkValue && <div className={styles.item2}>{item.value}</div>}
            </div>
          );
        })
        : undefined
    }
  ];

  render() {
    return (
      <div className={clsx(['feeCollapse', styles.container])}>
        <Collapse
          ghost
          expandIcon={(event) => {
            const icon = event.isActive
              ? ICONS.feeCollapseShrink
              : ICONS.feeCollapseGrow;

            return this.props.data.items?.length ? (
              <img className={styles.icon} src={icon} alt="icon" />
            ) : null;
          }}
          items={this.items}
        />
      </div>
    );
  }
}

FeeCollapse.propTypes = {
  data: PropTypes.object
};

FeeCollapse.defaultProps = {
  data
};

export default FeeCollapse;
