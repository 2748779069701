import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Form } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ResetFormItem from 'src/components/ResetFormItem';
import styles from './styles.module.scss';

@observer
class Description extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      land: props.land,
      form: props.form
    });
  }

  render() {
    return (
      <div className={styles.container}>
        <Form form={this.vm.form}>
          <div className={styles.item}>
            <div className={styles.label}>{this.props.label}</div>

            <div className={styles.input}>
              <ResetFormItem name="description" rules={this.vm.rules}>
                <Input.TextArea
                  size="large"
                  maxLength={500}
                  showCount
                  autoSize={{ minRows: 6 }}
                  placeholder={this.props.placeholder}
                />
              </ResetFormItem>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

Description.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string
};

Description.defaultProps = {
  label: i18n.t('create_land_drawer_description_page_description_label'),
  placeholder: i18n.t(
    'create_land_drawer_description_page_description_placeholder'
  )
};

export default withForm(Description);
