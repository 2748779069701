import { request } from './utils';

// 使用地址獲得經緯度.
export const getGeocoding = ({
  // google api key.
  key,
  // 國家.
  region,
  // 地址.
  address
}) => {
  const options = {
    method: 'get',

    url: `https://maps.googleapis.com/maps/api/geocode/json?key=${key}&loading=async&region=${region}&address=${address}`
  };

  return request(options);
};

// 上傳圖片至 google storage.
export const putGoogleStorage = ({
  url,
  mimetype,
  size,
  file
}) => {
  const options = {
    method: 'put',

    url,

    headers: {
      'Content-Type': mimetype,
      'X-Upload-Content-Length': size
    },

    data: file
  };

  return request(options);
};
