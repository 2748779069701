import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import BigNumber from 'bignumber.js';
import { BookingCalc } from 'src/constants/booking/booking';
import { ITEM_PLANS } from 'src/constants/rental/rental';
import { formatPrice } from 'src/constants/price';
import configStore from 'src/global/configStore';

class RentalItemViewModel {
  @observable rental = null;
  @observable item = null;
  @observable itemVariety = null;

  @computed
  get id() {
    return this.itemVariety.id;
  }

  @computed
  get itemName() {
    return this.item.name;
  }

  @computed
  get itemVarietyName() {
    return this.itemVariety.name;
  }

  @computed
  get count() {
    return `x ${this.itemVariety.count}`;
  }

  @computed
  get plan() {
    return ITEM_PLANS[this.itemVariety.plan].label;
  }

  @computed
  get price() {
    const total = new BigNumber(this.itemVariety.price)
      .multipliedBy(this.itemVariety.count)
      .multipliedBy(this.duration);
    const p = formatPrice(total);

    return `US$ ${p}`;
  }

  @computed
  get duration() {
    return BookingCalc.duration({
      startAt: this.rental.startAt,
      endAt: this.rental.endAt,
      timezoneId: this.rental.land.timezoneId
    });
  }

  constructor(props) {
    this.item = props.item;
    this.itemVariety = props.itemVariety;

    this.rental = props.rental;

    makeObservable(this);
  }
}

export default RentalItemViewModel;
