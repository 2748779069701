class MessageDataModel {
  constructor(data) {
    this.id = data.id;

    // 提問的用戶.
    this.user = {
      id: data.user.id,
      avatar: data.user.avatar,
      displayName: data.user.displayName,
      firstName: data.user.firstName,
      lastName: data.user.lastName
    };

    // qa 或是 評論.
    this.message = data.question || data.comment;

    // 回覆.
    this.reply = data.answer || data.reply || '';

    // 評分.
    this.score = data.score || null;

    this.createdAt = data.createdAt;

    this.answerAt = data.answerAt;

    this.updatedAt = data.updatedAt;
  }
}

export default MessageDataModel;
