// 這個是對應 api pay 的 status, 不可以隨意改變
export const PAYOUT_STATUS = {
  paid: 'paid',
  payable: 'payable'
};

export const PAYOUT_TYPES = {
  booking: 'reservation',
  rental: 'rental'
};

export const AMOUNT_LABEL = '$ 0';

export const PAYOUT_DETAIL_TYPES = {
  bookingPaid: 'bookingPaid',
  bookingPayable: 'bookingPayable',
  rentalPaid: 'rentalPaid',
  rentalPayable: 'rentalPayable'
};

export const getPayoutDetailType = ({ type, status }) => {
  if (type === PAYOUT_TYPES.booking && status === PAYOUT_STATUS.payable) {
    return PAYOUT_DETAIL_TYPES.bookingPayable;
  }

  if (type === PAYOUT_TYPES.rental && status === PAYOUT_STATUS.paid) {
    return PAYOUT_DETAIL_TYPES.rentalPaid;
  }

  if (type === PAYOUT_TYPES.rental && status === PAYOUT_STATUS.payable) {
    return PAYOUT_DETAIL_TYPES.rentalPayable;
  }

  return PAYOUT_DETAIL_TYPES.bookingPaid;
};
