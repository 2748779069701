import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enUS from './locales/en_US.gen';

/**
 * @see https://react.i18next.com/
 */
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // if you're using a language detector, do not define the lng option
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // React already does escaping
    },
    resources: {
      en: {
        translation: enUS
      }
    }
  });

// const locale = {
//   'zh-Hant_TW': 'tw',
//   'zh-Hant_US': 'tw',
//   'zh-Hant_CN': 'tw',
//   'zh-Hant_HK': 'tw',
//   'zh-Hant_SG': 'tw',
//   'zh-Hant_MY': 'tw',
//   'zh-Hant_MO': 'tw',
//   'zh-Hant_JP': 'tw',
//   'zh-TW_#Hant': 'tw',
//   'zh-US_#Hant': 'tw',
//   'zh-CN_#Hant': 'tw',
//   'zh-HK_#Hant': 'tw',
//   'zh-SG_#Hant': 'tw',
//   'zh-MY_#Hant': 'tw',
//   'zh-MO_#Hant': 'tw',
//   'zh-Hans_TW': 'cn',
//   'zh-Hans_US': 'cn',
//   'zh-Hans_CN': 'cn',
//   'zh-Hans_HK': 'cn',
//   'zh-Hans_SG': 'cn',
//   'zh-Hans_MY': 'cn',
//   'zh-Hans_MO': 'cn',
//   'zh-Hans_JP': 'cn',
//   'zh-TW_#Hans': 'cn',
//   'zh-US_#Hans': 'cn',
//   'zh-CN_#Hans': 'cn',
//   'zh-HK_#Hans': 'cn',
//   'zh-SG_#Hans': 'cn',
//   'zh-MY_#Hans': 'cn',
//   'zh-MO_#Hans': 'cn',
//   'zh-JP_#Hans': 'cn',
//   'zh-Hant': 'tw',
//   'zh-Hans': 'cn',
//   zh_TW: 'tw',
//   zh_US: 'tw',
//   zh_HK: 'tw',
//   'zh-MO': 'tw',
//   zh_CN: 'cn',
//   zh_SG: 'cn',
//   zh_MY: 'cn',
//   zh_JP: 'cn'
// };

// export const getLocaleMap = (deviceLocale) => locale[deviceLocale] ?? 'en';

export default i18n;
