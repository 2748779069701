import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import CustomRate from '../CustomRate';
import styles from './styles.module.scss';

@observer
class CustomItem extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.name}>{this.props.name}</div>

        {this.props.rating && <CustomRate value={this.props.score} disabled />}

        <div className={styles.date}>{this.props.date}</div>
        <div className={styles.content}>{this.props.content}</div>
      </div>
    );
  }
}

CustomItem.propTypes = {
  name: PropTypes.string,
  rating: PropTypes.bool,
  score: PropTypes.number,
  date: PropTypes.string,
  content: PropTypes.string
};

CustomItem.defaultProps = {
  name: '',
  rating: false,
  score: 0,
  date: '',
  content: ''
};

export default CustomItem;
