import { makeObservable, observable, action, computed } from 'mobx';
import dayjs from 'dayjs';
import { AMOUNT_LABEL, PAYOUT_STATUS } from 'src/constants/payout';
import { formatPrice } from 'src/constants/price';

class PayoutViewModel {
  @observable payout = null;
  @observable index = 0;

  @computed
  get id() {
    return this.payout.id;
  }

  @computed
  get status() {
    return this.payout.status;
  }

  @computed
  get date() {
    return this.payout.date;
  }

  @computed
  get amount() {
    return this.payout.amount;
  }

  @computed
  get checks() {
    const isPaid = this.payout.status === PAYOUT_STATUS.paid;
    const isPayable = this.payout.status === PAYOUT_STATUS.payable;
    const amount = Number(this.payout.amount) !== 0;

    return {
      paid: isPaid && amount,
      payable: isPayable && amount
    };
  }

  @computed
  get formats() {
    return {
      timestamp: dayjs(this.payout.date).startOf('date').valueOf(),
      dateKey: dayjs(this.payout.date).startOf('date').format('YYYY-MM')
    };
  }

  @computed
  get labels() {
    const checkIndex = this.index !== 0;
    const index = ` (${this.index})`;
    const date = dayjs(this.payout.date).format('MMM YYYY');

    return {
      date: `${date}${checkIndex ? index : ''}`,
      amount: `$ ${formatPrice(this.payout.amount)}`
    };
  }

  constructor(props) {
    this.payout = props;
    makeObservable(this);
  }

  @action
  setIndex = (val) => {
    this.index = val;
  }
}

export default PayoutViewModel;
