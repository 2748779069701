import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import ActionModal from 'src/components/ActionModal';
import ActionModalFooter from 'src/components/ActionModal/components/ActionModalFooter';
import ActionModalAlertContainer from 'src/components/ActionModal/components/ActionModalAlertContainer';
import ResetFormItem from 'src/components/ResetFormItem';
import ResetRangePicker from 'src/components/ResetRangePicker';
import styles from './styles.module.scss';

@observer
class CalendarBatchAvailableModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ form: props.form });
  }

  render() {
    return (
      <ActionModal vm={this.vm.modalVM} width={375} onCancel={this.vm.close}>
        <ActionModalAlertContainer className={styles.container}>
          <div className={styles.description}>
            {i18n.t('calendar_batch_available_modal_description')}
          </div>

          <Form
            className={styles.form}
            form={this.vm.form}
            disabled={this.vm.disabled.form}
          >
            <div className={styles.item}>
              <div className={styles.label}>
                {i18n.t('calendar_batch_available_modal_date_label')}
              </div>

              <ResetFormItem name="dates" rules={this.vm.rules.required}>
                <ResetRangePicker
                  className={styles.datePicker}
                  minDate={this.vm.minDate}
                  inputReadOnly
                />
              </ResetFormItem>
            </div>
          </Form>

          <ActionModalFooter
            cancelProps={{
              type: 'primary',
              danger: true,
              children: i18n.t(
                'calendar_batch_available_modal_un_available_button_label'
              ),
              onClick: this.vm.onUnAvailable,
              disabled: this.vm.disabled.footer
            }}
            confirmProps={{
              type: 'primary',
              children: i18n.t(
                'calendar_batch_available_modal_available_button_label'
              ),
              onClick: this.vm.onAvailable,
              disabled: this.vm.disabled.footer
            }}
          />
        </ActionModalAlertContainer>
      </ActionModal>
    );
  }
}

CalendarBatchAvailableModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

CalendarBatchAvailableModal.defaultProps = {};

export default withForm(CalendarBatchAvailableModal);
