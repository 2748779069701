import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Upload, Table } from 'antd';
import clsx from 'clsx';
import i18n from 'src/i18n';
import { FORM_INPUT_REQUIRED_MESSAGE } from 'src/constants/config';
import uploadIcon from 'src/assets/icon_upload_land_photo.svg';
import ICONS from 'src/constants/icons';
import ResetButton from 'src/components/ResetButton';
import DragTable from 'src/components/DragTable';
import styles from './styles.module.scss';

@observer
class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ land: props.land });
  }

  render() {
    return (
      <div className={clsx(['createLandPhotos', styles.container])}>
        <div className={styles.description}>
          {i18n.t('create_land_drawer_photos_page_description')}
        </div>

        <div className={styles.upload}>
          <Upload
            fileList={[]}
            accept="image/jpg,image/jpeg,image/png"
            beforeUpload={this.vm.onBeforeUpload}
            customRequest={this.vm.onUpload}
            disabled={this.vm.disabled.upload}
            multiple
          >
            <ResetButton
              className={styles.uploadButton}
              disabled={this.vm.disabled.upload}
            >
              {i18n.t('create_land_drawer_photos_page_upload_button_label')}
            </ResetButton>
          </Upload>

          <div
            className={clsx([
              styles.message,
              this.vm.check.showRequired && styles.show
            ])}
          >
            {FORM_INPUT_REQUIRED_MESSAGE}
          </div>
        </div>

        {this.vm.check.showTable && (
          <div className={styles.table}>
            <DragTable
              data={this.vm.photos}
              onDragEnd={this.vm.onDragEnd}
              showHeader={false}
            >
              <Table.Column
                align="center"
                render={(vm) => (
                  <img className={styles.landPhoto} src={vm.src} alt="land" />
                )}
              />

              <Table.Column
                width={50}
                align="center"
                render={(vm) => (
                  <ResetButton
                    className={styles.deleteButton}
                    type="text"
                    onClick={() => this.vm.onPhotoDelete(vm.id)}
                  >
                    <img src={ICONS.delete} alt="delete" />
                  </ResetButton>
                )}
              />
            </DragTable>
          </div>
        )}
      </div>
    );
  }
}

Photos.propTypes = {
  vm: PropTypes.object.isRequired,
  land: PropTypes.object.isRequired
};

Photos.defaultProps = {};

export default Photos;
