import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Rate } from 'antd';
import ICONS from 'src/constants/icons';
import styles from './styles.module.scss';

@observer
class CustomRate extends React.Component {
  render() {
    const { character, ...ps } = this.props;

    return (
      <div className={styles.container}>
        <Rate
          {...ps}
          character={(props) =>
            props.index < props.value ? (
              <img src={ICONS.starFll} alt="star-full" />
            ) : (
              <img src={ICONS.starEmpty} alt="star-empty" />
            )}
        />
      </div>
    );
  }
}

CustomRate.propTypes = {
  character: PropTypes.func
};

CustomRate.defaultProps = {
  character: () => {}
};

export default CustomRate;
