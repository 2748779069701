import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import i18n from 'src/i18n';
import configStore from 'src/global/configStore';
import { formatPrice } from 'src/constants/price';
import { BOOKING_STATUS, BookingCalc } from 'src/constants/booking/booking';
import BookingServices from 'src/services/BookingServices';
import RentalServices from 'src/services/RentalServices';
import ErrorServices from 'src/services/ErrorServices';
import { delay } from 'src/utils';

class BookingViewModel {
  @observable bookingId = null;
  @observable booking = null;

  @observable rental = null;

  @observable inited = false;
  @observable isAwait = false;

  toLogin = () => {};

  @computed
  get check() {
    return {
      show: this.inited && !!this.booking,
      booking: !!this.booking,
      rental: !!this.rental,
      rentalId: !!this.booking?.rentalId,
      packages: !!this.booking?.packages?.length,
      requesting: this.booking?.status === BOOKING_STATUS.requesting.value
    };
  }

  @computed
  get land() {
    return this.booking.land;
  }

  @computed
  get packages() {
    return this.booking.packages;
  }

  // rental items
  @computed
  get items() {
    if (this.check.rental) {
      return this.rental.copies.map((copy) => {
        return {
          id: copy.itemVariety.id,
          name: copy.itemVariety.name,
          count: copy.itemVariety.count
        };
      });
    }

    return [];
  }

  @computed
  get disabled() {
    return {
      actions: this.isAwait
    };
  }

  @computed
  get labels() {
    const durationUnit = i18n.t(
      'check_booking_page_booking_page_booking_duration_unit'
    );
    const duration = BookingCalc.duration({
      startAt: this.booking.startAt,
      endAt: this.booking.endAt,
      timezoneId: this.booking.land.timezoneId
    });

    const huntersUnit = i18n.t(
      'check_booking_page_booking_page_booking_hunters_unit'
    );

    // rental 的價格不等於地主的收益, 地主只能抽成
    const rentalPrice = new BigNumber(this.rental?.price || 0);
    const rentalProfit = rentalPrice.multipliedBy(
      configStore.rentalProfitRatio
    );

    const total = rentalProfit.plus(this.booking.price);

    const status = BOOKING_STATUS[this.booking.status];
    const statusLabel = i18n.t('check_booking_page_booking_page_status_label');

    return {
      bookingId: this.booking.id,

      startAt: dayjs(this.booking.startAt).format('D MMM, YYYY'),
      endAt: dayjs(this.booking.endAt).format('D MMM, YYYY'),

      duration: `${duration} ${durationUnit}`,

      hunters: `${this.booking.hunters} ${huntersUnit}`,

      bookingPrice: formatPrice(this.booking.price, true),
      rentalProfit: formatPrice(rentalProfit, true),

      total: formatPrice(total, true),

      status: `${statusLabel} ${status.label}`
    };
  }

  constructor(props) {
    this.bookingId = props.router.params.id;
    this.toLogin = props.toLogin;
    makeObservable(this);
  }

  didMount = async () => {
    await this.getDetailAPIs();

    runInAction(() => {
      this.inited = true;
    });
  };

  @action
  onAccepted = async () => {
    this.isAwait = true;

    await this.putBookingAcceptAPI();
    await this.getDetailAPIs();

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  onReject = async () => {
    this.isAwait = true;

    await this.putBookingRejectAPI();
    await this.getDetailAPIs();

    runInAction(() => {
      this.isAwait = false;
    });
  };

  getDetailAPIs = async () => {
    await this.getBookingDetailAPI();

    if (this.check.rentalId) {
      await this.getRentalDetailAPI();
    }
  };

  getBookingDetailAPI = async () => {
    try {
      const res = await BookingServices.getBookingDetail({
        id: this.bookingId
      });

      runInAction(() => {
        this.booking = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getBookingDetail(error);

      switch (error.response?.status) {
        case 403: {
          this.toLogin();
          break;
        }

        default:
          break;
      }

      console.log('getBookingDetail', msg);
    }
  };

  putBookingAcceptAPI = async () => {
    try {
      await BookingServices.putBookingAccept({ id: this.booking.id });
    } catch (error) {
      const msg = ErrorServices.putBookingAccept(error);
      console.log('putBookingAccept', msg);
    }
  };

  putBookingRejectAPI = async () => {
    try {
      await BookingServices.putBookingReject({ id: this.booking.id });
    } catch (error) {
      const msg = ErrorServices.putBookingReject(error);
      console.log('putBookingReject', msg);
    }
  };

  getRentalDetailAPI = async () => {
    try {
      const res = await RentalServices.getRentalDetail({
        id: this.booking.rentalId
      });

      runInAction(() => {
        this.rental = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getRentalDetail(error);
      console.log('getRentalDetail', msg);
    }
  };
}

export default BookingViewModel;
