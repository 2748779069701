import BigNumber from 'bignumber.js';

// Note: 無條件進位到小數第二位 (多給地主，由平台方吸收)
export const formatPrice = (val, unit = false) => {
  const price = new BigNumber(val)
    .decimalPlaces(2, BigNumber.ROUND_CEIL)
    .toFormat();
  const u = unit ? 'US$ ' : '';

  return `${u}${price}`;
};
