import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { message } from 'antd';
import i18n from 'src/i18n';
import {
  VERIFY_CODE_RESET_SECOND,
  COUNTRY_CODE_US,
  REGEXPS,
  FORM_INPUT_REQUIRED_MESSAGE
} from 'src/constants/config';
import ActionModalViewModel from 'src/components/ActionModal/viewModel';
import ResendButtonViewModel from 'src/components/ResendButton/viewModel';
import UserService from 'src/services/UserService';
import ErrorServices from 'src/services/ErrorServices';

export const PAGES = {
  inputPhone: 'inputPhone',
  verifyCode: 'verifyCode'
};

class PhoneModalViewModel {
  @observable page = PAGES.inputPhone;

  @observable profile = null;

  @observable phone = '';

  @observable isAwait = false;

  form = null;

  modalVM = new ActionModalViewModel();
  resendButtonVM = new ResendButtonViewModel();

  @computed
  get check() {
    return {
      inputPhone: this.page === PAGES.inputPhone,
      verifyCode: this.page === PAGES.verifyCode
    };
  }

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      resend: this.isAwait,
      next: this.isAwait
    };
  }

  get rules() {
    return {
      phone: [
        {
          required: true,
          pattern: REGEXPS.phone,
          message: FORM_INPUT_REQUIRED_MESSAGE
        }
      ],
      verifyCode: [
        {
          required: true,
          pattern: REGEXPS.verifyCode,
          message: i18n.t('phone_modal_verify_error_message')
        }
      ]
    };
  }

  @computed
  get resend() {
    const label = i18n.t('phone_modal_resend_button_label');
    return this.check.resend ? label : `${label} (${this.second}s)`;
  }

  @computed
  get description() {
    switch (this.page) {
      case PAGES.inputPhone:
        return i18n.t('phone_modal_phone_description');

      case PAGES.verifyCode: {
        const label1 = i18n.t('phone_modal_verify_description_1');
        const label2 = i18n.t('phone_modal_verify_description_2');

        return `${label1} ${COUNTRY_CODE_US} ${this.phone} ${label2}`;
      }

      default:
        return '';
    }
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ form }) => {
    this.form = form;
  };

  @action
  open = ({ profile, changed }) => {
    this.page = PAGES.inputPhone;

    this.profile = profile;
    this.changed = changed;

    this.form.resetFields();

    this.modalVM.open();
  };

  @action
  close = () => {
    this.second = VERIFY_CODE_RESET_SECOND;
    this.modalVM.close();
  };

  @action
  onPhoneChange = (value) => {
    this.phone = value;
  };

  onNext = () => {
    this.postUserResendAuthPhoneAPI();
  };

  onResend = () => {
    this.postUserResendAuthPhoneAPI();
  };

  onConfirm = () => {
    this.putUserPhoneAPI();
  };

  @action
  postUserResendAuthPhoneAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();
      const phone = `${COUNTRY_CODE_US}${data.phone}`;

      await UserService.postUserResendAuthPhone({
        phone
      });

      // 開啟排程.
      this.resendButtonVM.setTimer();

      runInAction(() => {
        this.page = PAGES.verifyCode;
      });
    } catch (error) {
      const msg = ErrorServices.postUserResendAuthPhone(error);

      message.error(msg, 5);

      // 發送驗證碼的等待時間未結束.
      if (error.response?.status === 429) {
        // 開啟排程.
        this.resendButtonVM.setTimer();

        runInAction(() => {
          this.page = PAGES.verifyCode;
        });
      }

      console.log('postUserResendAuthPhone', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  putUserPhoneAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();
      const phone = `${COUNTRY_CODE_US}${this.phone}`;

      await UserService.putUserAuthPhone({
        code: data.code,
        phone
      });

      await this.getUserProfileAPI();
    } catch (error) {
      const msg = ErrorServices.putUserAuthPhone(error);

      message.error(msg, 5);

      console.log('putUserAuthPhone', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  getUserProfileAPI = async () => {
    try {
      const res = await UserService.getUserProfile();

      await this.changed(res.data);

      message.success(i18n.t('phone_modal_success_message'), 5);

      this.close();
    } catch (error) {
      const msg = ErrorServices.getUserProfile(error);
      console.log('getUserProfile', msg);
    }
  };
}

export default PhoneModalViewModel;
