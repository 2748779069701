import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ActionDrawerCard from 'src/components/ActionDrawer/components/ActionDrawerCard';
import ParcelDetailDrawer from '../ParcelDetailDrawer';
import styles from './styles.module.scss';

@observer
class Parcel extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ rental: props.rental });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <>
        <ActionDrawerCard label={this.vm.name} onClick={this.vm.onDetail} next>
          {this.vm.status.label}
        </ActionDrawerCard>

        <ParcelDetailDrawer vm={this.vm.detailDrawerVM} />
      </>
    );
  }
}

Parcel.propTypes = {
  vm: PropTypes.object.isRequired,
  rental: PropTypes.object.isRequired
};

Parcel.defaultProps = {};

export default Parcel;
